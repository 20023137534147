import React, { useState } from "react";
import cloneDeep from "lodash.clonedeep";
import TextField from "@mui/material/TextField";
import { ReactComponent as XAxisIcon } from "../../../icons/xaxis.svg";
import { ReactComponent as YAxisIcon } from "../../../icons/yaxis.svg";
import ConfigTab from "./ConfigTab/ConfigTab";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "./Accordion/Accordion";

export function XAxisConfig({ graphType, xaxisConfig, setOption }) {
  const [expanded, setExpanded] = useState("");

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function handleUpdate(id, newValue, name) {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.option.xAxis.map((elem) => {
        if (elem.id === id) {
          elem[name] = newValue;
        }
        return elem;
      });
      return newOption;
    });
  }

  function getPossibleAxisTypes(graphType) {
    switch (graphType) {
      case "timeseries":
        return ["time"];
      case "scatter":
        return ["value", "category", "log"];
      default:
        return ["value", "category", "log"];
    }
  }

  return (
    <Accordion
      expanded={expanded === "xaxis"}
      onChange={handleChangeAccordion("xaxis")}
      backgroundColor="white"
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="flex items-center">
          <p className="font-medium flex gap-2">
            <XAxisIcon />
            Xaxis config
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <div className="mb-2">
            <p className="font-medium text-xs">Hidden :</p>
            <ConfigTab
              items={["true", "false"]}
              color="primary"
              name="show"
              selectedValue={(!xaxisConfig.show).toString()}
              handleOnClick={(ev) => {
                handleUpdate(
                  xaxisConfig.id,
                  ev.target.value === "true" ? false : true,
                  ev.target.name
                );
              }}
            />
          </div>
          <div className="mb-2">
            <p className="font-medium text-xs">Type :</p>
            <ConfigTab
              items={getPossibleAxisTypes(graphType)}
              color="primary"
              name="type"
              selectedValue={xaxisConfig.type}
              handleOnClick={(ev) => {
                handleUpdate(xaxisConfig.id, ev.target.value, ev.target.name);
              }}
            />
          </div>
          {xaxisConfig.type === "log" && (
            <div className="mb-2">
              <p className="font-medium text-xs">LogBase :</p>
              <TextField
                onChange={(ev) => {
                  handleUpdate(xaxisConfig.id, ev.target.value, ev.target.name);
                }}
                name="logBase"
                value={xaxisConfig.logBase}
                className="w-full"
                id="standard-basic"
                variant="outlined"
                placeholder="logBase"
              />
            </div>
          )}
          <div className="mb-2">
            <p className="font-medium text-xs">Name :</p>
            <TextField
              onChange={(ev) => {
                handleUpdate(xaxisConfig.id, ev.target.value, ev.target.name);
              }}
              name="name"
              value={xaxisConfig.name}
              className="w-full"
              id="standard-basic"
              variant="outlined"
              placeholder="axisName"
            />
          </div>
          <div className="mb-2">
            <p className="font-medium text-xs">Name Location :</p>
            <ConfigTab
              items={["start", "center", "end"]}
              color="primary"
              name="nameLocation"
              selectedValue={xaxisConfig.nameLocation}
              handleOnClick={(ev) => {
                handleUpdate(xaxisConfig.id, ev.target.value, ev.target.name);
              }}
            />
          </div>
          <div className="mb-2">
            <p className="font-medium text-xs">Name Gap :</p>
            <TextField
              onChange={(ev) => {
                handleUpdate(xaxisConfig.id, ev.target.value, ev.target.name);
              }}
              name="nameGap"
              value={xaxisConfig.nameGap}
              className="w-full"
              id="standard-basic"
              variant="outlined"
              placeholder="nameGap"
            />
          </div>
          <div className="mb-2">
            <p className="font-medium text-xs">Min :</p>
            <TextField
              onChange={(ev) => {
                handleUpdate(xaxisConfig.id, ev.target.value, ev.target.name);
              }}
              name="min"
              value={xaxisConfig.min}
              className="w-full"
              id="standard-basic"
              variant="outlined"
              placeholder="min"
            />
          </div>
          <div className="mb-2">
            <p className="font-medium text-xs">Max :</p>
            <TextField
              onChange={(ev) => {
                handleUpdate(xaxisConfig.id, ev.target.value, ev.target.name);
              }}
              name="max"
              value={xaxisConfig.max}
              className="w-full"
              id="standard-basic"
              variant="outlined"
              placeholder="max"
            />
          </div>
          <div className="mb-2">
            <p className="font-medium text-xs">Inverse :</p>
            <ConfigTab
              items={["true", "false"]}
              color="primary"
              name="inverse"
              selectedValue={xaxisConfig.inverse.toString()}
              handleOnClick={(ev) => {
                handleUpdate(
                  xaxisConfig.id,
                  ev.target.value === "true" ? true : false,
                  ev.target.name
                );
              }}
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export function YAxisConfig({ yaxisConfig, setOption }) {
  const [expanded, setExpanded] = useState("");

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function handleUpdate(id, newValue, name) {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.option.yAxis.map((elem) => {
        if (elem.id === id) {
          elem[name] = newValue;
        }
        return elem;
      });
      return newOption;
    });
  }

  return (
    <Accordion
      expanded={expanded === "yaxis"}
      onChange={handleChangeAccordion("yaxis")}
      backgroundColor="white"
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="flex items-center">
          <p className="font-medium flex gap-2">
            <YAxisIcon />
            Yaxis config
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <div className="mb-2">
            <p className="font-medium text-xs">Hidden :</p>
            <ConfigTab
              items={["true", "false"]}
              color="primary"
              name="show"
              selectedValue={(!yaxisConfig.show).toString()}
              handleOnClick={(ev) => {
                handleUpdate(
                  yaxisConfig.id,
                  ev.target.value === "true" ? false : true,
                  ev.target.name
                );
              }}
            />
          </div>
          <div className="mb-2">
            <p className="font-medium text-xs">Type :</p>
            <ConfigTab
              items={["value", "log"]}
              color="primary"
              name="type"
              selectedValue={yaxisConfig.type}
              handleOnClick={(ev) => {
                handleUpdate(yaxisConfig.id, ev.target.value, ev.target.name);
              }}
            />
          </div>
          {yaxisConfig.type === "log" && (
            <div className="mb-2">
              <p className="font-medium text-xs">LogBase :</p>
              <TextField
                onChange={(ev) => {
                  handleUpdate(yaxisConfig.id, ev.target.value, ev.target.name);
                }}
                name="logBase"
                value={yaxisConfig.logBase}
                className="w-full"
                id="standard-basic"
                variant="outlined"
                placeholder="logBase"
              />
            </div>
          )}
          <div className="mb-2">
            <p className="font-medium text-xs">Name :</p>
            <TextField
              onChange={(ev) => {
                handleUpdate(yaxisConfig.id, ev.target.value, ev.target.name);
              }}
              name="name"
              value={yaxisConfig.name}
              className="w-full"
              id="standard-basic"
              variant="outlined"
              placeholder="axisName"
            />
          </div>
          <div className="mb-2">
            <p className="font-medium text-xs">Name Location :</p>
            <ConfigTab
              items={["start", "center", "end"]}
              color="primary"
              name="nameLocation"
              selectedValue={yaxisConfig.nameLocation}
              handleOnClick={(ev) => {
                handleUpdate(yaxisConfig.id, ev.target.value, ev.target.name);
              }}
            />
          </div>
          <div className="mb-2">
            <p className="font-medium text-xs">Name Gap :</p>
            <TextField
              onChange={(ev) => {
                handleUpdate(yaxisConfig.id, ev.target.value, ev.target.name);
              }}
              name="nameGap"
              value={yaxisConfig.nameGap}
              className="w-full"
              id="standard-basic"
              variant="outlined"
              placeholder="nameGap"
            />
          </div>
          <div className="mb-2">
            <p className="font-medium text-xs">Min :</p>
            <TextField
              onChange={(ev) => {
                handleUpdate(yaxisConfig.id, ev.target.value, ev.target.name);
              }}
              name="min"
              value={yaxisConfig.min}
              className="w-full"
              id="standard-basic"
              variant="outlined"
              placeholder="min"
            />
          </div>
          <div className="mb-2">
            <p className="font-medium text-xs">Max :</p>
            <TextField
              onChange={(ev) => {
                handleUpdate(yaxisConfig.id, ev.target.value, ev.target.name);
              }}
              name="max"
              value={yaxisConfig.max}
              className="w-full"
              id="standard-basic"
              variant="outlined"
              placeholder="max"
            />
          </div>
          <div className="mb-2">
            <p className="font-medium text-xs">Inverse :</p>
            <ConfigTab
              items={["true", "false"]}
              color="primary"
              name="inverse"
              selectedValue={yaxisConfig.inverse.toString()}
              handleOnClick={(ev) => {
                handleUpdate(
                  yaxisConfig.id,
                  ev.target.value === "true" ? true : false,
                  ev.target.name
                );
              }}
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
