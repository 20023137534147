import React, { useState } from "react";
import { cloneDeep } from "lodash.clonedeep";
import ConfigTab from "../ConfigTab/ConfigTab";
import TextField from "@mui/material/TextField";
import { ReactComponent as SymbolIcon } from "../../../../icons/symbol.svg";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "./../Accordion/Accordion";

export default function SymbolStyleSection({ seriesConfig, setOption }) {
  const [expandedSymbol, setExpandedSymbol] = useState("");

  const handleChangeAccordionSymbolStyle = (panel) => (event, newExpanded) => {
    setExpandedSymbol(newExpanded ? panel : false);
  };

  function handleUpdate(id, newValue, name) {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.option.series.map((elem) => {
        if (elem.id === id) {
          elem[name] = newValue;
        }
        return elem;
      });
      return newOption;
    });
  }

  function translateToEchartsValues(value) {
    switch (value) {
      case "auto":
        return "auto";
      case "true":
        return true;
      case "false":
        return false;
      case true:
        return "true";
      case false:
        return "false";
      default:
      //do nothing
    }
  }

  return (
    <Accordion
      expanded={expandedSymbol === "symbolStyle"}
      onChange={handleChangeAccordionSymbolStyle("symbolStyle")}
      backgroundColor="rgba(142, 155, 187, 0.08)"
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="flex items-center">
          <p className="font-medium flex gap-2 items-center text-primary">
            <SymbolIcon className="text-primary" />
            SymbolStyle
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="mb-2">
          <p className="font-medium text-xs">Symbol size :</p>
          <TextField
            onChange={(ev) => {
              handleUpdate(seriesConfig.id, ev.target.value, ev.target.name);
            }}
            name="symbolSize"
            value={seriesConfig.symbolSize}
            className="w-full"
            id="standard-basic"
            variant="outlined"
            placeholder="Symbol size"
          />
        </div>
        <div className="mb-2">
          <p className="font-medium text-xs">Symbol :</p>
          <ConfigTab
            items={["circle", "emptyCircle", "rect", "roundRect", "triangle"]}
            color="primary"
            name="symbol"
            selectedValue={seriesConfig.symbol}
            handleOnClick={(ev) => {
              handleUpdate(seriesConfig.id, ev.target.value, ev.target.name);
            }}
          />
          <ConfigTab
            items={["diamond", "pin", "arrow", "none"]}
            color="primary"
            name="symbol"
            selectedValue={seriesConfig.symbol}
            handleOnClick={(ev) => {
              handleUpdate(seriesConfig.id, ev.target.value, ev.target.name);
            }}
          />
        </div>
        <div className="mb-2">
          <p className="font-medium text-xs">Show all symbols:</p>
          <ConfigTab
            items={["auto", "true", "false"]}
            color="primary"
            name="showAllSymbol"
            selectedValue={translateToEchartsValues(seriesConfig.showAllSymbol)}
            handleOnClick={(ev) => {
              handleUpdate(
                seriesConfig.id,
                translateToEchartsValues(ev.target.value),
                ev.target.name
              );
            }}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
