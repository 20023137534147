/* eslint-disable jsx-a11y/alt-text */
import { FormattedMessage } from "react-intl";
import { ReactComponent as MoreIcon } from "../../../icons/more.svg";
import { ReactComponent as MapLineIcon } from "../../../icons/map-line.svg";
import { ReactComponent as CalendarIcon } from "../../../icons/calendar.svg";

export default function ProtocoleCard({ p, tag }) {
  return (
    <div
      className={`${
        tag === "current" ? "bg-dark-bg text-white" : "bg-white text-dark-text"
      }  rounded-8px p-4 block border-primary border-opacity-15 border-1 w-full h-full`}
    >
      <div className="flex justify-between mb-3">
        {tag === "current" && (
          <div className="bg-white text-primary px-6px py-4px rounded-5px font-medium text-9px inline-block">
            <FormattedMessage id="protocol.firstView.param6" />
          </div>
        )}
        {tag === "active" && (
          <div className="bg-green text-white px-6px py-4px rounded-5px font-medium text-9px inline-block">
            <FormattedMessage id="protocol.firstView.param7" />
          </div>
        )}
        {tag === "notActive" && (
          <div className="bg-grey-text bg-opacity-8 text-grey-text text-opacity-65 px-6px py-4px rounded-5px font-medium text-9px inline-block ">
            <FormattedMessage id="protocol.firstView.param8" />
          </div>
        )}
        <MoreIcon
          className={`${tag === "current" ? "text-white" : "text-grey-text"}`}
        />
      </div>
      <div className="flex justify-between mb-4">
        <div className="flex">
          <img
            src="./assets/postlogo.jpeg"
            className="h-6 w-6 rounded-full flex items-center mr-2"
          />
          <div>
            <p className="font-medium text-base">{p.name}</p>
            <p className="font-medium text-xs text-left">
              <FormattedMessage id="protocol.firstView.param9" />
              <span className="ml-1">{p.owner ? p.owner : "Paul Floury"}</span>
            </p>
          </div>
        </div>
        <div className="flex items-end">
          <p
            className={`${
              tag === "current" ? "text-white" : "text-dark-text"
            } font-normal text-xs mr-2`}
          >
            {p.observatory}
          </p>
          <MapLineIcon className="text-secondary h-3.5 w-3" />
        </div>
      </div>
      <div
        className={`${
          tag === "current" ? "bg-white" : "bg-light-bg"
        } flex justify-center items-center bg-white bg-opacity-15  rounded-5px py-2`}
      >
        <CalendarIcon className="text-secondary h-3 w-3 mr-1" />
        <p
          className={`${
            tag === "current" ? "text-white" : "text-normal"
          } flex text-xs font-normal`}
        >
          <span className="mr-1">
            <FormattedMessage id="protocol.firstView.param10" />
          </span>
          <span
            className={`${
              tag === "current" ? "text-white" : "text-primary"
            } font-semibold`}
          >
            {p.start_date}
          </span>
          <span className="mx-1">
            <FormattedMessage id="protocol.firstView.param11" />
          </span>
          <span
            className={`${
              tag === "current" ? "text-white" : "text-primary"
            } font-semibold`}
          >
            {p.end_date}
          </span>
        </p>
      </div>
    </div>
  );
}
