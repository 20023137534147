import { PrimaryItemButton, GreyItemButton } from "../Butttons";

export function WithBadgeButton({ item, ...props }) {
  const PrimaryItemButtonWithSelected = WithHocSelectedButton(
    PrimaryItemButton,
    GreyItemButton,
    "text"
  );

  return (
    <div className="relative">
      <div className="relative inline-flex">
        <PrimaryItemButtonWithSelected {...props} item={item.text} />
        <div className=" z-50 h-4 w-4 rounded-full bg-warning absolute top-0 right-0 -mt-2 -mr-2 text-xs flex items-center text-white justify-center">
          {item.number}
        </div>
      </div>
    </div>
  );
}

//adds a badge for any custom componenet
export const WithBadgeCustomButton = (BaseCompo) => {
  return ({ item, ressourceName, ...props }) => {
    return (
      <div className="relative">
        <div className="relative inline-flex">
          <BaseCompo {...props} {...{ [ressourceName]: item.text }} />
          <div className=" z-50 h-4 w-4 rounded-full bg-warning absolute top-0 right-0 -mt-2 -mr-2 text-xs flex items-center text-white justify-center">
            {item.badgeContent}
          </div>
        </div>
      </div>
    );
  };
};

export const WithHocSelectableButton = (
  SelectedComponenet,
  UnSelectedComponenet,
  ressourceName
) => {
  return ({ checkSelected, item, ...props }) => {
    if (checkSelected(item)) {
      return <SelectedComponenet {...{ [ressourceName]: item }} {...props} />;
    } else {
      return <UnSelectedComponenet {...{ [ressourceName]: item }} {...props} />;
    }
  };
};

export const WithHocSelectedButton = (
  SelectedComponenet,
  UnSelectedComponenet,
  ressourceName
) => {
  return ({ target, item, itemId, ...props }) => {
    function checkSelected(item, target) {
      if (target && target.length !== 0 && target.includes(item)) {
        return true;
      } else {
        return false;
      }
    }

    let test = checkSelected(itemId ?? item, target);

    if (test) {
      return <SelectedComponenet {...{ [ressourceName]: item }} {...props} />;
    } else {
      return <UnSelectedComponenet {...{ [ressourceName]: item }} {...props} />;
    }
  };
};
