import { capitalizeFirstLetter } from "./char";

export const stationToRiverlab = (station) => {
  if (station === "eaukey") return "R2Lavage";
  if (station === "aeag") return "MasCamargues";
  if (station === "demo") return "RLDemo1";

  return station;
};

export const formatRiverlabForBackend = (riverlab) => {
  const unchangedRiverlabs = [
    "MasCamarguesVortex",
    "MasCamargues",
    "R2Lavage",
    "RLDemo1",
  ];
  if (unchangedRiverlabs.includes(riverlab)) {
    return riverlab;
  } else if (riverlab === undefined) {
    return "";
  }
  return riverlab.toLowerCase();
};

export const stationToUpperCase = (station) => {
  if (station === "eaukey") return "R2Lavage";
  if (station === "aeag") return "MasCamargues";
  if (station === "demo") return "RLDemo1";
  if (station === "mascamarguesvortex") return "MascamarguesVortex";
  return capitalizeFirstLetter(station);
};

export const stationToUpperCaseProt = (stations) => {
  return stations
    .split(", ")
    .map((station) => {
      if (station === "eaukey") return "R2Lavage";

      if (station === "aeag") return "MasCamargues";

      if (station === "demo") return "RLDemo1";

      if (station === "mascamarguesvortex") return "MascamarguesVortex";
      if (station === "mascamargues") return "Mascamargues";

      return capitalizeFirstLetter(station);
    })
    .join(", ");
};
export const keycloakGrpToRL = (stations) => {
  return stations.map((station) => {
    if (station === "/monticello") return "Monticello";
    if (station === "/naizin") return "Naizin";
    if (station === "/aubure") return "Aubure";
    if (station === "/orgeval") return "Orgeval";
    if (station === "/eaukey") return "R2Lavage";
    if (station === "/aeag") return ["MasCamargues", "MasCamarguesVortex"];
    if (station === "/guilin") return "Guilin";
    if (station === "/others") return ["Naizin", "Aubure", "Orgeval"];
    if (station === "/demo") return "RLDemo1";
    return station.slice(1);
  });
};
