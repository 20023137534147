import RegularList, { RegularListTemp } from "../Lists/RegularLists";
import { WithHocSelectedButton, WithBadgeButton } from "../Buttons/HOCS";
import { PrimaryItemButton, GreyItemButton } from "../Butttons";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from "react";

export function CustomForm({ children }) {
  const [compo1, compo2, compo3, compo4, compo5, compo6] = children;
  const intl = useIntl();

  return (
    <div className="divide-y divide-primary divide-opacity-15">
      <div className="px-7 py-2">
        <p className="text-lg text-dark-text font-medium mb-4">
          <FormattedMessage id="protocol.SecondView.param29" />
        </p>
        <div className="flex flex-wrap gap-1">{compo1}</div>
      </div>
      <div className="px-7 py-1">
        <p className="text-xl text-dark-text font-medium mb-2">
          {intl.formatMessage({ id: "paramétres" }).charAt(0).toUpperCase() +
            intl.formatMessage({ id: "paramétres" }).slice(1)}
        </p>
        <div className="mb-3">
          <p className="text-primary text-sm font-medium mb-1">ANIONS</p>
          <div className="flex flex-wrap gap-1">{compo2}</div>
        </div>
        <div className="mb-3">
          <p className="text-primary text-sm font-medium mb-1">CATIONS</p>
          <div className="flex flex-wrap gap-1">{compo3}</div>
        </div>
        <div className="mb-3">
          <p className="text-primary text-sm font-medium mb-1">
            <FormattedMessage id="protocol.SecondView.param31" />
          </p>
          <div className="flex flex-wrap gap-1">{compo4}</div>
        </div>
        <div>
          <p className="text-primary text-sm font-medium mb-1">
            <FormattedMessage id="protocol.SecondView.param32" />
          </p>
          <div className="flex flex-wrap gap-1">{compo5}</div>
        </div>
        <div className="mb-3">
          <p className="text-primary text-sm font-medium mb-1">PARAMETRES METEO</p>
          <div className="flex flex-wrap gap-1">{compo6}</div>
        </div>
      </div>
    </div>
  );
}

export function ChooseParametersSection({
  selectedObs,
  obsList,
  setselectedObs,
  localselectedData,
  anions,
  cations,
  handleOnClick,
  physico_chemicalParams,
  technicalParams,
  meteoParams
}) {
  const PrimaryItemButtonWithSelected = WithHocSelectedButton(
    PrimaryItemButton,
    GreyItemButton,
    "text"
  );
  const intl = useIntl();
  const [target, setTarget] = useState([]);

  useEffect(() => {
    if (selectedObs) {
      let test = localselectedData.reduce((res, elem) => {
        if (elem.riverlab === selectedObs) {
          return [...res, elem.element];
        } else {
          return res;
        }
      }, []);
      setTarget(test);
    }
  }, [localselectedData, selectedObs]);

  function selectedParamsPerObs(item) {
    let test = localselectedData.reduce((res, elem) => {
      if (elem.riverlab === item) {
        return ++res;
      } else {
        return res;
      }
    }, 0);
    return test;
  }

  return (
    <div className="divide-y divide-primary divide-opacity-15">
      <div className="px-8 py-5">
        <p className="text-lg text-dark-text font-medium mb-7">
          <FormattedMessage id="protocol.SecondView.param29" />
        </p>
        <div className="flex flex-wrap gap-1">
          {}
          <RegularListTemp
            target={[selectedObs]}
            items={obsList.map((item) => {
              return { text: item, number: selectedParamsPerObs(item) };
            })}
            itemComponenet={WithBadgeButton}
            ressourceName="item"
            onClick={(e) => {
              setselectedObs(e.target.name);
            }}
          />
        </div>
      </div>
      <div className="px-8 py-7">
        <p className="text-xl text-dark-text font-medium mb-5">
          {intl.formatMessage({ id: "paramétres" }).charAt(0).toUpperCase() +
            intl.formatMessage({ id: "paramétres" }).slice(1)}
        </p>
        <div className="mb-6">
          <p className="text-primary text-sm font-medium mb-4">Anions</p>
          <div className="flex flex-wrap gap-1">
            <RegularList
              target={target}
              items={anions ?? null}
              itemComponenet={PrimaryItemButtonWithSelected}
              ressourceName="item"
              onClick={(e) => {
                handleOnClick(e.target.id);
              }}
            />
          </div>
        </div>
        <div className="mb-6">
          <p className="text-primary text-sm font-medium mb-4">Cations</p>
          <div className="flex flex-wrap gap-1">
            <RegularList
              target={target}
              items={cations ?? null}
              itemComponenet={PrimaryItemButtonWithSelected}
              ressourceName="item"
              onClick={(e) => {
                handleOnClick(e.target.id);
              }}
            />
          </div>
        </div>
        <div className="mb-6">
          <p className="text-primary text-sm font-medium mb-4">
            <FormattedMessage id="protocol.SecondView.param31" />
          </p>
          <div className="flex flex-wrap gap-1">
            <RegularList
              target={target}
              items={physico_chemicalParams ?? null}
              itemComponenet={PrimaryItemButtonWithSelected}
              ressourceName="item"
              onClick={(e) => {
                handleOnClick(e.target.id);
              }}
            />
          </div>
        </div>
        <div>
          <p className="text-primary text-sm font-medium mb-4">
            <FormattedMessage id="protocol.SecondView.param32" />
          </p>
          <div className="flex flex-wrap gap-1">
            <RegularList
              target={target}
              items={technicalParams ?? null}
              itemComponenet={PrimaryItemButtonWithSelected}
              ressourceName="item"
              onClick={(e) => {
                handleOnClick(e.target.id);
              }}
            />
          </div>
        </div>
        <div className="mb-6">
          <p className="text-primary text-sm font-medium mb-4">PARAMETRES METEO</p>
          <div className="flex flex-wrap gap-1">
            <RegularList
              target={target}
              items={meteoParams ?? null}
              itemComponenet={PrimaryItemButtonWithSelected}
              ressourceName="item"
              onClick={(e) => {
                handleOnClick(e.target.id);
              }}
            />
          </div>
        </div>

      </div>
    </div>
  );
}
