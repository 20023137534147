import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { keycloakGrpToRL } from "../utils/riverlabs";

export function useSearchRiverlabs() {
  const user = useSelector((state) => state.user);
  const [allObs, setAllObs] = useState([]);
  useEffect(() => {
    if (user) {
      user.groups &&
        setAllObs(keycloakGrpToRL(user.groups).flatMap((param) => param));
    }
  }, [user]);
  return allObs;
}
