import moment from "moment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DatePicker";
import MomentDateAdapter from "@mui/lab/AdapterMoment";
import { CssTextField } from "../../../../Shared/Selects/selectsStyles";

export default function DateFormSection({ from, to, handleChange }) {
  return (
    <>
      <p className="font-medium">From</p>
      <LocalizationProvider dateAdapter={MomentDateAdapter}>
        <DesktopDatePicker
          inputFormat="YYYY-MM-DD HH:mm:ss"
          label="Custom input"
          value={moment(from)}
          onChange={(newValue) => {
            handleChange(newValue, "from");
          }}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <div className="flex items-center">
              <CssTextField
                className="w-52 h-10"
                variant="outlined"
                ref={inputRef}
                {...inputProps}
                InputProps={{
                  endAdornment: InputProps?.endAdornment,
                }}
              />
            </div>
          )}
        />
      </LocalizationProvider>
      <p className="font-medium">To</p>
      <LocalizationProvider dateAdapter={MomentDateAdapter}>
        <DesktopDatePicker
          inputFormat="YYYY-MM-DD HH:mm:ss"
          label="Custom input"
          value={moment(to)}
          onChange={(newValue) => {
            handleChange(newValue, "to");
          }}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <div className="flex items-center">
              <CssTextField
                className="w-52 h-10"
                variant="outlined"
                ref={inputRef}
                {...inputProps}
                InputProps={{
                  endAdornment: InputProps?.endAdornment,
                }}
              />
            </div>
          )}
        />
      </LocalizationProvider>
    </>
  );
}
