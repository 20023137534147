const RegularList = ({
  items,
  ressourceName,
  itemComponenet: ItemComponenet,
  ...props
}) => {
  if (!items) return [];

  return (
    <>
      {items.map((item, i) => {
        return (
          <ItemComponenet
            key={i}
            {...{ [ressourceName]: item.label, itemId: item.id }}
            {...props}
            name={item.label}
            id={item.id}
          />
        );
      })}
    </>
  );
};

export const RegularListTemp = ({
  items,
  ressourceName,
  itemComponenet: ItemComponenet,
  ...props
}) => {
  if (!items) return [];

  return (
    <>
      {items.map((item, i) => {
        return (
          <ItemComponenet
            key={i}
            {...{ [ressourceName]: item }}
            {...props}
            name={item.text}
          />
        );
      })}
    </>
  );
};

export default RegularList;
