import React, { useState } from "react";
import moment from "moment";
import { ReactComponent as ClockSkeletonIcon } from "../../../../icons/clock-skeleton.svg";
import { ReactComponent as ArrowSkeletonComplete } from "../../../../icons/arrow-skeleton-complete.svg";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../Accordion/Accordion";
import { RELATIVE_DATES } from "../../Const";
import DateFormSection from "./DateFormSection";

export default function ChooseTimeSection({
  expanded,
  handleChangeAccordion,
  date_start,
  date_end,
  updateAllData,
  setOption,
}) {
  const [chosenRelativeTime, setchosenRelativeTime] = useState("Custom Date");

  function handleChange(newValue, propName) {
    setchosenRelativeTime("Custom Date");
    setOption((prev) => {
      let newInterval = {
        date_start: prev.date_start,
        date_end: prev.date_end,
        [propName]: newValue,
      };
      updateAllData(newInterval.date_start, newInterval.date_end);
      return { ...prev, [propName]: newValue };
    });
  }

  function handleRelativeDateChange(newRelativeDate) {
    let newInterval;
    switch (newRelativeDate) {
      case "Custom Date": {
        setchosenRelativeTime("Custom Date");
        break;
      }
      case "Last 5 minutes": {
        setchosenRelativeTime("Last 5 minutes");
        newInterval = {
          date_start: moment().subtract(5, "minutes"),
          date_end: moment(),
        };
        break;
      }
      case "Last 15 minutes": {
        setchosenRelativeTime("Last 15 minutes");
        newInterval = {
          date_start: moment().subtract(15, "minutes"),
          date_end: moment(),
        };
        break;
      }
      case "Last 30 minutes": {
        setchosenRelativeTime("Last 30 minutes");
        newInterval = {
          date_start: moment().subtract(30, "minutes"),
          date_end: moment(),
        };
        break;
      }
      case "Last 1 hour": {
        setchosenRelativeTime("Last 1 hour");
        newInterval = {
          date_start: moment().subtract(1, "hours"),
          date_end: moment(),
        };
        break;
      }
      case "Last 3 hours": {
        setchosenRelativeTime("Last 3 hours");
        newInterval = {
          date_start: moment().subtract(3, "hours"),
          date_end: moment(),
        };
        break;
      }
      case "Last 6 hours": {
        setchosenRelativeTime("Last 6 hours");
        newInterval = {
          date_start: moment().subtract(6, "hours"),
          date_end: moment(),
        };
        break;
      }
      case "Last 12 hours": {
        setchosenRelativeTime("Last 12 hours");
        newInterval = {
          date_start: moment().subtract(12, "hours"),
          date_end: moment(),
        };
        break;
      }
      case "Last 24 hours": {
        setchosenRelativeTime("Last 24 hours");
        newInterval = {
          date_start: moment().subtract(24, "hours"),
          date_end: moment(),
        };
        break;
      }
      case "Last 2 days": {
        setchosenRelativeTime("Last 2 days");
        newInterval = {
          date_start: moment().subtract(2, "days"),
          date_end: moment(),
        };
        break;
      }
      case "Last 7 days": {
        setchosenRelativeTime("Last 7 days");
        newInterval = {
          date_start: moment().subtract(7, "days"),
          date_end: moment(),
        };
        break;
      }
      case "Yesterday": {
        setchosenRelativeTime("Yesterday");
        newInterval = {
          date_start: moment().subtract(1, "days").startOf("day"),
          date_end: moment().subtract(1, "days").endOf("day"),
        };
        break;
      }
      case "Last week": {
        setchosenRelativeTime("Last week");
        newInterval = {
          date_start: moment().subtract(7, "days").startOf("week"),
          date_end: moment().subtract(7, "days").endOf("week"),
        };
        break;
      }
      case "Last month": {
        setchosenRelativeTime("Last month");
        newInterval = {
          date_start: moment().subtract(1, "months").startOf("month"),
          date_end: moment().subtract(1, "month").endOf("month"),
        };
        break;
      }
      case "Last year": {
        setchosenRelativeTime("Last year");
        newInterval = {
          date_start: moment().subtract(1, "years").startOf("year"),
          date_end: moment().subtract(1, "year").endOf("year"),
        };
        break;
      }
      case "Today": {
        setchosenRelativeTime("Today");
        newInterval = {
          date_start: moment().startOf("day"),
          date_end: moment().endOf("month"),
        };
        break;
      }
      case "This week": {
        setchosenRelativeTime("This week");
        newInterval = {
          date_start: moment().startOf("week"),
          date_end: moment().endOf("week"),
        };
        break;
      }
      case "This month": {
        setchosenRelativeTime("This month");
        newInterval = {
          date_start: moment().startOf("month"),
          date_end: moment().endOf("month"),
        };
        break;
      }
      case "This year": {
        setchosenRelativeTime("This year");
        newInterval = {
          date_start: moment().startOf("year"),
          date_end: moment().endOf("year"),
        };
        break;
      }
      default:
      //do nothing
    }
    updateAllData(
      newInterval.date_start.format("YYYY-MM-DD HH:mm:ss"),
      newInterval.date_end.format("YYYY-MM-DD HH:mm:ss")
    );
    setOption((prev) => {
      return {
        ...prev,
        date_start: newInterval.date_start.format("YYYY-MM-DD HH:mm:ss"),
        date_end: newInterval.date_end.format("YYYY-MM-DD HH:mm:ss"),
      };
    });
  }

  return (
    <Accordion
      expanded={expanded.has("panel3")}
      onChange={handleChangeAccordion("panel3")}
      backgroundColor="white"
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div>
          <p className="text-primary font-medium flex gap-2">
            <ClockSkeletonIcon className="h-5 w-5 bg-white text-primary" />
            <span className="text-normal">Time options</span>
          </p>
          <div className="flex gap-1 text-xs items-center">
            <p className="text-grey-light">{date_start && date_start}</p>
            <ArrowSkeletonComplete className="w-4 text-secondary" />
            <p className="text-grey-light">{date_end && date_end}</p>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="inline-block flex justify-center ">
          <div className="flex-1 border-r-1 border-primary border-opacity-8 p-1">
            <DateFormSection
              from={date_start}
              to={date_end}
              handleChange={handleChange}
            />
          </div>
          <div className="flex-1 p-1 max-h-60 overflow-y-scroll">
            <div className="flex flex-col">
              {RELATIVE_DATES.map((relativeDate) => {
                return (
                  <button
                    className={`${
                      chosenRelativeTime === relativeDate
                        ? "text-primary border-1 border-primary"
                        : ""
                    } font-medium py-1 hover:text-primary focus:outline-none rounded-8px`}
                    onClick={() => {
                      handleRelativeDateChange(relativeDate);
                    }}
                  >
                    {relativeDate}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
