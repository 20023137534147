export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const GET_POSTS = "GET_POSTS";
export const SELECT_PARAMS = "SELECT_PARAMS";
export const DESELECT_PARAM = "DESELECT_PARAM";
export const CURRENT_SELECTED_CARD = "CURRENT_SELECTED_CARD";
export const CURRENT_SELECTED_PREFERRED_OBS = "CURRENT_SELECTED_PREFERRED_OBS";
export const CURRENT_SELECTED_PREFERRED_ELEMENT =
  "CURRENT_SELECTED_PREFERRED_ELEMENT";
export const CURRENT_SELECTED_PREFERRED_PARAMETER =
  "CURRENT_SELECTED_PREFERRED_PARAMETER";
export const SET_PROTOCOL_START_DATE = "GET_PROTOCOL_START_DATE";
export const SET_PROTOCOL_END_DATE = "GET_PROTOCOL_END_DATE";
export const SET_DASHBOARD_CHART_SELECTED_DATE =
  "SET_DASHBOARD_CHART_SELECTED_DATE";
export const SET_DASHBOARD_FEED_SELECTED_DATE =
  "SET_DASHBOARD_FEED_SELECTED_DATE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_KC = "SET_KC";
export const SET_SOCKET = "SET_SOCKET";
export const SET_USER_REGISTERED_WS = "SET_USER_REGISTERED_WS ";
export const IS_MOBILE = "IS_MOBILE";
export const ADD_POST_DIALOG_OPEN = "ADD_POST_DIALOG_OPEN";
export const SET_SELECTED_TAB = "SET_SELECTED_TAB";
export const NEW_USER = "NEW_USER";
export const USER_ROLES = "USER_ROLES";
export const USER_PREFERENCES = "USER_PREFERENCES";

//new
export function setGraphsOptions(graphs) {
  return { type: "setGraphsOptions", graphs: graphs };
}

//new
export function setInterventionDates(interventionDates) {
  return { type: "setInterventionDates", interventionDates: interventionDates };
}

//new
export function setExtraPlotChartConfig(chartConfig) {
  return { type: "setExtraPlotChartConfig", chartConfig: chartConfig };
}

//new
export function deletechartSerie(toDeleteSerie) {
  return { type: "deletechartSerie", toDeleteSerie: toDeleteSerie };
}

//new
export function setchartVars(vars) {
  return { type: "setchartVars", vars: vars };
}

//new
export function setPrefferedCards(preferredCards) {
  return { type: "setPrefferedCards", preferredCards: preferredCards };
}

//new
export function setPrefferedParams(preferredParams) {
  return { type: "setPrefferedParams", preferredParams: preferredParams };
}

//new
export function setToken(token) {
  return { type: "setToken", token: token };
}

//new
export function setSelectedTab(index) {
  return { type: SET_SELECTED_TAB, index: index };
}

//new
export function setAddPostDialogOpen(isOpen) {
  return { type: ADD_POST_DIALOG_OPEN, isOpen: isOpen };
}

//new
export function setIsMobile(isMobile) {
  return { type: IS_MOBILE, isMobile: isMobile };
}

export function setUserRoles(userRoles) {
  return { type: USER_ROLES, userRoles: userRoles };
}

export function setUserPreferences(userPreferences) {
  return { type: USER_PREFERENCES, userPreferences: userPreferences };
}

//new
export function setUserRegisteredWs(registered) {
  return { type: SET_USER_REGISTERED_WS, registered: registered };
}

//new
export function setSocket(socket) {
  return { type: SET_SOCKET, socket: socket };
}

//new
export function setKcObj(kc) {
  return { type: SET_KC, kc: kc };
}

//new
export function setLanguage(language) {
  return { type: SET_LANGUAGE, language: language };
}

//new
export function setDashboardFeedSelectedDate(date) {
  return { type: SET_DASHBOARD_FEED_SELECTED_DATE, date: date };
}

//new
export function setDashboardChartSelectedDate(date) {
  return { type: SET_DASHBOARD_CHART_SELECTED_DATE, date: date };
}

//new
export function setProtocolEndDate(date) {
  return { type: SET_PROTOCOL_END_DATE, end_date: date };
}

//new
export function setProtocolStartDate(date) {
  return { type: SET_PROTOCOL_START_DATE, start_date: date };
}

export function setUser(user) {
  return { type: NEW_USER, user: user };
}

export function getPosts(posts) {
  return { type: GET_POSTS, posts: posts };
}

export function selectParam(label, isSelected, itemType, id) {
  return {
    type: SELECT_PARAMS,
    label: label,
    isSelected: isSelected,
    itemType: itemType,
    id: id,
  };
}

export function deselectParam(label) {
  return { type: DESELECT_PARAM, label: label };
}

export function setCurrentSelectedElement(currentSelectedCard) {
  return {
    type: CURRENT_SELECTED_CARD,
    currentSelectedCard: currentSelectedCard,
  };
}

export function setPreferredObservatory(obs) {
  return {
    type: CURRENT_SELECTED_PREFERRED_OBS,
    currentSelectedPreferredObs: obs,
  };
}

export function setPreferredElement(elem) {
  return {
    type: CURRENT_SELECTED_PREFERRED_ELEMENT,
    currentSelectedPreferredElem: elem,
  };
}

export function setPreferredParameter(param) {
  return {
    type: CURRENT_SELECTED_PREFERRED_PARAMETER,
    currentSelectedPreferredParam: param,
  };
}
