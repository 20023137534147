import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import DownloadCSVDialog from "./DownloadCSVDialog";

export default function DownloadSection({
  series,
  x,
  protocolId,
  observatory,
}) {
  const [isOpenCSVDialog, setIsOpenCSVDialog] = useState(false);

  return (
    <>
      <button
        className="flex bg-secondary bg-opacity-8 rounded-8px px-2 py-3 text-secondary items-center font-semibold focus:outline-none"
        onClick={() => {
          setIsOpenCSVDialog(true);
        }}
      >
        <FormattedMessage id="protocol.SecondView.param35" />
      </button>
      <DownloadCSVDialog
        isOpen={isOpenCSVDialog}
        setIsOpen={setIsOpenCSVDialog}
        series={series}
        x={x}
        protocolId={protocolId}
        observatory={observatory}
      />
    </>
  );
}
