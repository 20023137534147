import React, { useState } from "react";
import cloneDeep from "lodash.clonedeep";
import TextField from "@mui/material/TextField";
import ConfigTab from "../ConfigTab/ConfigTab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "./../Accordion/Accordion";

export default function MarkLineConfig({ serieid, markLineConfig, setOption }) {
  const [expanded, setExpanded] = useState("");

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function handleUpdate(id, newValue, name) {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.option.series.map((serie) => {
        if (serie.id === serieid) {
          serie.markLine.data = serie.markLine.data.map((markLine) => {
            if (markLine.id === id) {
              markLine[name] = newValue;
            }
            return markLine;
          });
        }
        return serie;
      });
      return newOption;
    });
  }

  return (
    <Accordion
      expanded={expanded === "markLine"}
      onChange={handleChangeAccordion("markLine")}
      backgroundColor="white"
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="flex items-center">
          <p className="text-primary font-medium">
            MarkLine: {markLineConfig.type}
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <div className="mb-2">
            <p className="font-medium text-xs">Type:</p>
            <ConfigTab
              items={["min", "max", "average"]}
              color="primary"
              name="type"
              selectedValue={markLineConfig.type}
              handleOnClick={(ev) => {
                handleUpdate(
                  markLineConfig.id,
                  ev.target.value,
                  ev.target.name
                );
              }}
            />
          </div>
          <div className="mb-2">
            <p className="font-medium text-xs">Label :</p>
            <TextField
              onChange={(ev) => {
                handleUpdate(
                  markLineConfig.id,
                  ev.target.value,
                  ev.target.name
                );
              }}
              name="name"
              value={markLineConfig.label}
              className="w-full"
              id="standard-basic"
              variant="outlined"
              placeholder="Label"
            />
          </div>
          <div className="mb-2">
            <p className="font-medium text-xs">Precision :</p>
            <TextField
              onChange={(ev) => {
                handleUpdate(
                  markLineConfig.id,
                  ev.target.value,
                  ev.target.name
                );
              }}
              name="precision"
              value={markLineConfig.precision}
              className="w-full"
              id="standard-basic"
              variant="outlined"
              placeholder="precision"
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
