import { ReactComponent as ClockIcon } from "../../../icons/clock.svg";
import { ReactComponent as TimeSeriesIcon } from "../../../icons/timeseries.svg";
export function TimeseriesIconComposite({ height, width }) {
  return (
    <div className="relative">
      <TimeSeriesIcon className={`h-${height} w-${width}`} />
      <ClockIcon
        className={`absolute bottom-0 right-0 h-${Math.floor(
          height / 2.5
        )} w-${Math.floor(width / 2.5)}`}
        style={{ color: "#3897ff" }}
      />
    </div>
  );
}

export function ScatterSeriesIconComposite({ height, width }) {
  return (
    <div className="relative">
      <TimeSeriesIcon className={`h-${height} w-${width}`} />
      <p
        className="absolute bottom-0 right-0 mb-1  font-medium"
        style={{ color: "#3897ff", fontSize: "0.5rem" }}
      >
        X
      </p>
      <p
        className={`absolute top-0 left-0  ml-2 font-medium`}
        style={{ color: "#3897ff", fontSize: "0.5rem" }}
      >
        Y
      </p>
    </div>
  );
}
