import React,{useState} from 'react'

export function WireframePostOne() {
    return (
        <div>
        <div className="bg-white rounded-large h-auto w-full items-center py-4 px-5 relative">
            <div className="absolute flex right-0 top-0 m-5 animate-pulse">
            <div className="rounded-full bg-gray-100 h-3 w-3 mx-1"></div>
            <div className="rounded-full bg-gray-100 h-3 w-3 mx-1"></div>
            <div className="rounded-full bg-gray-100 h-3 w-3 mx-1"></div>
            </div>
            <div className="flex animate-pulse  items-center">
            <img
                className="h-16 w-16 mr-3 rounded-full object-cover"
                src="./assets/postlogo1.jpeg"
                alt=""
            />
            <div className="bg-gray-100 rounded-full h-5 w-4/5"></div>
            </div>
        </div>
        </div>
    );
}
      
export function WireframePosttwo() {
return (
    <div>
    <div className="bg-white rounded-large h-auto w-full  items-center py-4 px-5 relative">
        <div className="absolute flex right-0 top-0 m-5 animate-pulse">
        <div className="rounded-full bg-gray-100 h-3 w-3 mx-1"></div>
        <div className="rounded-full bg-gray-100 h-3 w-3 mx-1"></div>
        <div className="rounded-full bg-gray-100 h-3 w-3 mx-1"></div>
        </div>
        <div className="flex animate-pulse">
        <img
            className="h-16 w-16 mr-3 rounded-full object-cover"
            src="./assets/postlogo.jpeg"
            alt=""
        />
        <div className="w-full">
            <div className="bg-gray-100 rounded-full h-5 w-1/5 mb-7"></div>
            <div className="bg-gray-100 rounded-full h-5 w-4/5 mb-2"></div>
            <div className="bg-gray-100 rounded-full h-5 w-4/5 mb-2"></div>
            <div className="bg-gray-100 rounded-full h-5 w-2/5 mb-4"></div>
            <div className="flex">
            <div className="bg-dark w-20 h-6 rounded-lg mr-2"></div>
            <div className="bg-primary w-20 h-6 rounded-lg"></div>
            </div>
        </div>
        </div>
    </div>
    </div>
);
}
      
export function WireframePostthree() {
    return (
        <div>
        <div className="bg-white rounded-large h-auto w-full  items-center py-4 px-5 relative">
            <div className="absolute flex right-0 top-0 m-5 animate-pulse">
            <div className="rounded-full bg-gray-100 h-3 w-3 mx-1"></div>
            <div className="rounded-full bg-gray-100 h-3 w-3 mx-1"></div>
            <div className="rounded-full bg-gray-100 h-3 w-3 mx-1"></div>
            </div>
            <div className="flex animate-pulse">
            <img
                className="h-16 w-16 mr-3 rounded-full object-cover"
                src="./assets/postlogo4.jpeg"
                alt=""
            />
            <div className="w-full">
                <div className="bg-gray-100 rounded-full h-5 w-1/5 mb-7"></div>
                <div className="bg-gray-100 rounded-full h-5 w-4/5 mb-2"></div>
                <div className="bg-gray-100 rounded-full h-5 w-4/5 mb-2"></div>
                <div className="bg-gray-100 rounded-full h-5 w-2/5 mb-4"></div>
            </div>
            </div>
        </div>
        </div>
    )
}
      
export function WireframePostfour() {
    return (
        <div>
        <div className="bg-white rounded-large h-auto w-full  items-center py-4 px-5 relative">
            <div className="absolute flex right-0 top-0 m-5 animate-pulse">
            <div className="rounded-full bg-gray-100 h-3 w-3 mx-1"></div>
            <div className="rounded-full bg-gray-100 h-3 w-3 mx-1"></div>
            <div className="rounded-full bg-gray-100 h-3 w-3 mx-1"></div>
            </div>
            <div className="flex animate-pulse">
            <img
                className="h-16 w-16 mr-3 rounded-full object-cover"
                src="/assets/postlogo.jpeg"
                alt=""
            />
            <div className="w-full">
                <div className="bg-gray-100 rounded-full h-5 w-1/5 mb-7"></div>
                <div className="bg-gray-100 rounded-full h-5 w-4/5 mb-2"></div>
                <div className="bg-gray-100 rounded-full h-5 w-4/5 mb-2"></div>
                <div className="bg-gray-100 rounded-full h-5 w-2/5 mb-4"></div>
                <div className="flex">
                <div className="bg-dark w-20 h-6 rounded-lg mr-2"></div>
                </div>
            </div>
            </div>
        </div>
        </div>
    )
}

export default function WireframeSection() {
    return (
      <div className="grid gap-y-4">
        <WireframePostOne />
        <WireframePosttwo />
        <WireframePostthree />
        <WireframePostfour />
      </div>
    );
  
}
    