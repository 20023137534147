export default function DialogButton({ selected, onSelect, text }) {
  return (
    <button
      className={`${
        selected
          ? "bg-primary text-white shadow-blue-25 outline-none "
          : " bg-app-bg"
      } 
        flex  text-xs font-medium rounded-8px items-center justify-center border-1 border-app-bg hover:border-primary px-3 py-2 mr-2 focus:outline-none mb-1`}
      onClick={() => {
        onSelect(text);
      }}
    >
      {text}
    </button>
  );
}
