import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as AddIcon } from "../../icons/add.svg";
import { useSearchRiverlabs } from "../../hooks/useSearchRiverlabs";
import { useSearchParameters } from "../../hooks/useSearchParameters";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactComponent as InfoIcon } from "../../icons/info.svg";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { SquareButton } from "../../Shared/Butttons";
import { DialogContent } from "@mui/material";
import { ChooseParametersSection } from "../../Shared/Forms/forms";
import { useSnackbar } from "notistack";
import {
  setCurrentSelectedElement,
  setPrefferedCards,
} from "../../Redux/actions";
import axios from "axios";

function AddCardSection({
  open,
  setopen,
  localselectedData,
  setlocalselectedData,
}) {
  return (
    <>
      <button
        className=" rounded-full bg-primary text-white h-12 w-12 flex justify-center items-center focus:outline-none"
        onClick={() => {
          setopen(true);
        }}
      >
        <AddIcon />
      </button>
      {open && (
        <AddCardDialog
          open={open}
          setopen={setopen}
          localselectedData={localselectedData}
          setlocalselectedData={setlocalselectedData}
        />
      )}
    </>
  );
}

function AddCardDialog({
  open,
  setopen,
  localselectedData,
  setlocalselectedData,
}) {
  const selectedData = useSelector((state) => state.preferredParams);
  const obsList = useSearchRiverlabs();
  const [selectedObs, setselectedObs] = useState(null);
  const [anions, cations, physico_chemicalParams, technicalParams, meteoParams] =
    useSearchParameters(selectedObs);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  useEffect(() => {
    setselectedObs(obsList[0]);
  }, [obsList]);

  function handleOnClick(elem) {
    setlocalselectedData((prev) => {
      let test = prev.some((prevElem) => {
        return elem === prevElem.element && selectedObs === prevElem.riverlab;
      });
      if (!test) {
        return [...prev, { element: elem, riverlab: selectedObs }];
      } else {
        if (prev.length > 1) {
          return prev.filter((prevElem) => {
            return !(
              elem === prevElem.element && selectedObs === prevElem.riverlab
            );
          });
        } else {
          enqueueSnackbar(
            "Could not delete card 😔, at least one card is required!",
            {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            }
          );
          return prev;
        }
      }
    });
  }

  function handleSubmit() {
    let payload = localselectedData.map((card) => {
      return { observatory: card.riverlab, element: card.element };
    });
    axios
      .post(
        process.env.REACT_APP_API_URL +
          `/user/${user.preferred_username}/elements`,
        payload
      )
      .then((res) => {
        axios
          .get(
            process.env.REACT_APP_API_URL +
              `/user/${user.preferred_username}/elements/realtime`
          )
          .then((res) => {
            dispatch(setPrefferedCards(res.data.data));
            dispatch(setCurrentSelectedElement(res.data.data[0]));
            enqueueSnackbar("Cards updated successfully 🥳", {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            });
          });
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          "Could not update cards 😔, please reload page or contact tech team !",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }
        );
      });
  }

  function reset() {
    setlocalselectedData(selectedData);
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setopen(false);
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
    >
      <DialogTitle
        id="form-dialog-title"
        className="bg-primary bg-opacity-8 text-primary text-xs font-medium"
      >
        <div className="flex items-center justify-center relative">
          <InfoIcon className="w-4 h-4 mr-2" />
          <p className="text-xs font-medium mr-4">
            <FormattedMessage id="addcard.param1" />
          </p>
          <button
            className="absolute right-0 top-1"
            onClick={() => {
              setopen(false);
            }}
          >
            <CloseIcon className="text-primary h-3 w-3 " />
          </button>
        </div>
      </DialogTitle>
      <DialogContent>
        <>
          {localselectedData && (
            <ChooseParametersSection
              selectedObs={selectedObs}
              obsList={obsList}
              setselectedObs={setselectedObs}
              anions={anions}
              cations={cations}
              handleOnClick={handleOnClick}
              physico_chemicalParams={physico_chemicalParams}
              technicalParams={technicalParams}
              meteoParams={meteoParams}
              target={localselectedData ? localselectedData[selectedObs] : null}
              localselectedData={localselectedData ? localselectedData : []}
            />
          )}
          {!localselectedData && <p>Loading ...</p>}
        </>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "space-between",
          borderTopWidth: 1,
          borderColor: "rgba(61, 128, 251, 0.15)",
          borderStyle: "solid",
        }}
      >
        <SquareButton
          borderColor="warning"
          bgColor="white"
          textColor="warning"
          size="large"
          text={intl.formatMessage({
            id: "addcard.param2",
          })}
          onClick={reset}
        />
        <div className="flex gap-1">
          <SquareButton
            borderColor="primary"
            bgColor="white"
            textColor="primary"
            size="large"
            text="annuler"
            onClick={() => {
              setopen(false);
            }}
          />
          <SquareButton
            borderColor={null}
            bgColor="primary"
            textColor="white"
            size="large"
            text="enregistrer"
            onClick={() => {
              setopen(false);
              handleSubmit();
            }}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(AddCardSection);
