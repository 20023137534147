import { useSelector } from "react-redux";

function ExtraCodePage() {
  const user = useSelector((state) => state.user);
  function getnotebookLink() {
    if (user.groups.includes("/monticello")) {
      return "https://internal.extralab-system.com/notebookus/";
    }
    else if (user.groups.includes("/demo")) {
      return "https://internal.extralab-system.com/notebookdemo/";
    }
    else if (user.groups.includes("/aeag")) {
      return "https://internal.extralab-system.com/notebookaeag/";
    } 
    else {
      return "https://internal.extralab-system.com/notebookfr/";
    }
  }

  return (
    <div className="md:ml-16 flex flex-grow">
      {user && (
        <iframe className="w-full h-full" src={getnotebookLink()}></iframe>
      )}
    </div>
  );
}

export default ExtraCodePage;
