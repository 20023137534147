/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ReactComponent as HomeIcon } from "../../../../icons/home.svg";

export default function ObsPanel({
  getParameters,
  elem,
  selectedObs,
  setSelectedObs,
}) {
  const [isSelected, setSelected] = useState(false);

  useEffect(() => {
    if (selectedObs && selectedObs === elem) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  });

  return (
    <button
      className={`${
        isSelected
          ? "bg-primary text-white shadow-blue-25"
          : "bg-app-bg text-dark-text"
      } flex  rounded-8px  text-sm font-medium  py-2.5 px-3 mr-2 border-1 
        border-app-bg hover:border-primary focus:outline-none`}
      onClick={() => {
        setSelectedObs(elem);
        getParameters(elem);
      }}
    >
      <HomeIcon className="mr-1" />
      {elem}
    </button>
  );
}
