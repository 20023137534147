import React from "react";
import moment from "moment";
import withStyles from "@mui/styles/withStyles";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import InputBase from "@mui/material/InputBase";
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAlert from "@mui/material/Alert";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export const CustomDatePicker = withStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    fontSize: "14px",
    "& .MuiOutlinedInput-input": {
      padding: "10px 23px 10px",
    },
    "& .MuiInputBase-root": {
      fontSize: "14px",
      fontFamily: "Inter",
    },

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      height: "100%",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(44, 90, 255, 0.15)",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2C5AFF",
    },
  },
}))(KeyboardDateTimePicker);

export const NewSelectInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: 3,
    },
    color: "rgba(44, 90, 255, 1)",
    width: "100%",
  },
  icon: {
    color: "rgba(44, 90, 255, 1)",
    backgroundColor: "rgba(44, 90, 255, 1)",
  },
  input: {
    borderRadius: "9999px",
    position: "relative",
    backgroundColor: "rgba(44, 90, 255, 0.08)",
    border: "none",
    fontSize: 20,
    fontWeight: 500,
    padding: "15px 26px 15px 15px",
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Arial",
      "Helvetica",
      "Satoshi",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      boxShadow: "0 12px 20px 0px rgba(44, 90, 255, 0.20)",
    },
    "&:focus": {
      borderRadius: "9999px",
      backgroundColor: "rgba(44, 90, 255, 0.08)",
    },
  },
}))(InputBase);

export const SelectTextField = withStyles({
  root: {
    width: "100%",
    "& .MuiInputBase-root": {
      height: "37px",
      width: "100%",
    },

    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(44, 90, 255, 0.15)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(44, 90, 255, 0.15)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(44, 90, 255, 0.15)",
      },
    },
  },
})(TextField);

export const CssTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      height: "37px",
      width: "100%",
    },
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2C5AFF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(44, 90, 255, 0.15)",
      },
      "&:hover fieldset": {
        borderColor: "#2C5AFF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2C5AFF",
      },
    },
  },
})(TextField);

export const useStylesecond = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: 1,
    },
  },
  datePicker: {
    width: "100%",
    height: "37px",
    fontSize: "14px",
    "& .MuiOutlinedInput-input": {
      padding: "10px 23px 10px",
    },
    "& .MuiInputBase-root": {
      fontSize: "14px",
      fontFamily: "Inter",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(44, 90, 255, 0.15)",
    },
  },

  select: {
    "& .MuiInput-underline:before": {
      border: "none",
    },
    "& .MuiInput-underline:after": {
      border: "none",
    },
    "& .MuiInput-underline:hover": {
      border: "none",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "#FFFF",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: "none",
    },
    "& .MuiFormControl-root": {
      border: "none",
    },
  },
  title: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2C5AFF",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "rgba(44, 90, 255, 0.15)",
    },
    "& .MuiInputBase-input": {
      color: "#6C7195",
      fontSize: "20px",
      fontWeight: 300,
      fontFamily: "Inter",
    },
  },
  description: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2C5AFF",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "rgba(44, 90, 255, 0.15)",
    },
    "& .MuiInputBase-input": {
      color: "#6C7195",
      fontSize: "12px",
      fontFamily: "Inter",
    },
  },
}));

export const useStylesAccordion = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    flexBasis: "33.33%",
    flexShrink: 0,
    color: "rgba(44, 90, 255, 0.8)",
    fontWeight: 600,
  },
  secondaryHeading: {
    fontSize: "0.8rem",
    color: "#6C7195",
    marginLeft: "auto",
  },
}));
export const Accordion = withStyles({
  root: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "#FFFF",
    },
    boxShadow: "none",

    "&.MuiPaper-root": {
      backgroundColor: "#FAFBFF",
    },
    "&.MuiAccordion-rounded": {
      borderRadius: "8px",
    },
  },

  expanded: {
    "&.MuiPaper-elevation1": {
      boxShadow: "0 4px 34px  0px rgba(44, 90, 255, 0.2)",
    },
  },
})(MuiAccordion);
export const CustomInputComponent = ({ inputRef, ...rest }) => (
  <input ref={inputRef} {...rest} type="text" />
);

export const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const blankProtocol = {
  name: null,
  protocol_id: "",
  observatory: "",
  groups: {},
  ordered_rules: [],
  start_date: moment(),
  end_date: moment(),
};

export const CssSearchProtoTextField = withStyles({
  root: {
    "& ::placeholder": {
      color: "#2C5AFF",
      opacity: 0.5 /* Firefox */,
      size: "14px",
    },
    "& .MuiInputBase-root": {
      height: "37px",
      width: "100%",
    },
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2C5AFF",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      borderColor: "rgba(44, 90, 255, 0.15)",
      "& fieldset": {
        borderColor: "rgba(44, 90, 255, 0.15)",
      },
      "&:hover fieldset": {
        borderColor: "#2C5AFF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2C5AFF",
      },
    },
  },
})(TextField);

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "rgba(44, 90, 255, 0.05)",
    color: "rgba(44, 90, 255, 1)",
    fontWeight: 500,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(44, 90, 255, 0.05)",
    },
  },
}))(TableRow);
