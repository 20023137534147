/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import ReactECharts from "echarts-for-react";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ReactComponent as ArrowDownIcon } from "../../../../icons/arrow-down.svg";
import DownloadSection from "./DownloadSection";
import { NewSelectInput } from "../../Const";

export default function ChartSection({
  applyProtocol_ref,
  protocolId,
  applyProtocolWithoutSave_ref,
  observatory,
}) {
  const [x, setx] = useState(null);
  const [series, setSeries] = useState(null);
  const [selectedView, setselectedView] = useState(0);
  const [selectedVariable, setselectedVariable] = useState(null);
  let echartRef = useRef(null);
  let chartPerVariableRef = useRef(null);
  const [applyNewProtocol, setApplyNewProtocol] = useState(false);
  const optionGlobalView = {
    tooltip: {
      show: true,
      trigger: "axis",
    },
    legend: {
      data: series ? Object.keys(series) : [],
    },
    grid: {
      left: "1%",
      right: "1%",
      bottom: "8%",
      top: "16%",
      containLabel: true,
    },
    toolbox: {
      top: "bottom",
      left: "middle",
      right: "middle",
      showTitle: false,
      show: true,
      feature: {
        dataZoom: {
          yAxisIndex: "none",
        },
        dataView: { readOnly: true },
        restore: {},
        saveAsImage: {},
      },
      tooltip: {
        // same as option.tooltip
        show: true,
        formatter: function (param) {
          return "<div>" + param.title + "</div>"; // user-defined DOM structure
        },
        backgroundColor: "rgba(44, 90, 255, 0.08)",
        textStyle: {
          fontSize: 12,
          color: "#2C5AFF",
        },
        extraCssText: "box-shadow: 0 4px 34px 0px rgba(44, 90, 255, 0.08)", // user-defined CSS styles
      },
    },
    xAxis: {
      type: "category",
      axisPointer: { show: true, label: { show: true }, z: 100 },
      boundaryGap: false,
      data: x,
    },
    yAxis: {
      type: "value",
      scale: true,
    },
    series: series
      ? Object.keys(series).map((key) => {
          return {
            name: key,
            type: "line",
            stack: key,
            data: series[key],
            sampling: "average",
            connectNulls: true,
          };
        })
      : [],
  };

  const optionPerVariable = {
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: x ? x : [],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: selectedVariable,
        type: "line",
        stack: selectedVariable,
        itemStyle: {
          color: "#0D1441",
        },
        data: series ? series[selectedVariable] : [],
        sampling: "average",
      },
    ],
  };

  const loadingOption = {
    text: "Please wait! Millions of data are being processed 😀",
    color: "#2C5AFF",
    textColor: "#2C5AFF",
    maskColor: "rgba(44, 90, 255, 0.1)",
    zlevel: 0,
  };

  const getQualifiedDataWithoutSaving = (obj) => {
    setSeries(null);
    setx(null);
    setApplyNewProtocol(true);
    axios
      .post(process.env.REACT_APP_API_URL + `/protocols/qualify`, obj)
      .then((res) => {
        setx(res.data.x);
        const temp = res.data;
        delete temp["x"];
        delete temp["sizes"];
        setSeries(temp);
      })
      .catch((err) => console.log("error", err));
  };
  const getQualifiedData = useCallback((id) => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/protocols/${observatory}/id/${id}/qualify`
      )
      .then((res) => {
        setx(res.data.x);
        const temp = res.data;
        delete temp["x"];
        delete temp["sizes"];
        setSeries(temp);
      })
      .catch((err) => console.log("error", err));
  }, []);

  useEffect(() => {
    if (protocolId !== "") {
      getQualifiedData(protocolId);
    }
    applyProtocol_ref.current = getQualifiedData;
    applyProtocolWithoutSave_ref.current = getQualifiedDataWithoutSaving;
  }, []);

  useEffect(() => {
    if (series && selectedView === 0) {
      echartRef.getEchartsInstance().hideLoading();
    }
    if (selectedVariable && selectedView === 1) {
      chartPerVariableRef.getEchartsInstance().hideLoading();
    }
  });

  useEffect(() => {
    if (series && selectedVariable === null) {
      setselectedVariable(Object.keys(series)[0]);
    }
  }, [series]);

  const domSelectItems = series ? (
    Object.keys(series).map((elem) => {
      return <MenuItem value={elem}>{elem}</MenuItem>;
    })
  ) : (
    <MenuItem value={0}>LOADING ...</MenuItem>
  );

  const handleSelectVariableChange = (ev) => {
    setselectedVariable(ev.target.value);
  };

  return (
    <div className="h-4/5 w-full">
      <div className="w-full bg-primary bg-opacity-15 rounded-full h-30 p-3 mb-3">
        <button
          className={`${
            selectedView === 0
              ? "bg-primary text-white shadow-blue-25"
              : "bg-transparent text-opacity-50 hover:text-opacity-1 text-primary"
          }  rounded-full px-2 py-2  text-sm  mr-3 font-medium focus:outline-none`}
          onClick={() => {
            setselectedView(0);
          }}
        >
          <FormattedMessage id="protocol.firstView.param24" />
        </button>
        <button
          className={`${
            selectedView === 1
              ? "bg-primary text-white shadow-blue-25"
              : "bg-transparent text-opacity-50 hover:text-opacity-1 text-primary"
          }  rounded-full px-2 py-2  text-sm  mr-3 font-medium focus:outline-none`}
          onClick={() => {
            setselectedView(1);
          }}
        >
          <FormattedMessage id="protocol.firstView.param25" />
        </button>
      </div>
      {selectedView === 0 && (protocolId !== "" || applyNewProtocol) && (
        <>
          {series && x && (
            <div className="flex justify-end my-2">
              <DownloadSection
                series={series}
                x={x}
                protocolId={protocolId}
                observatory={observatory}
              />
            </div>
          )}
          <ReactECharts
            ref={(e) => {
              echartRef = e;
            }}
            option={optionGlobalView}
            style={{ height: "100%" }}
            loadingOption={loadingOption}
            showLoading={true}
          />
        </>
      )}
      {selectedView === 1 && (protocolId !== "" || applyNewProtocol) && (
        <div className="h-3/4">
          <Select
            className="mb-4"
            id="demo-customized-select"
            value={selectedVariable}
            onChange={handleSelectVariableChange}
            input={<NewSelectInput />}
            NewSelectInput={<ArrowDownIcon className="bg-primary" />}
          >
            {domSelectItems}
          </Select>
          <ReactECharts
            ref={(e) => {
              chartPerVariableRef = e;
            }}
            option={optionPerVariable}
            style={{ height: "100%", width: "100%" }}
            loadingOption={loadingOption}
            showLoading={true}
          />
        </div>
      )}
    </div>
  );
}
