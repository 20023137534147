/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import moment from "moment";
import ProtocoleCard from "./ProtocoleCard";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as GridIcon } from "../../../icons/grid.svg";
import { ReactComponent as ListIcon } from "../../../icons/list.svg";
import InputAdornment from "@mui/material/InputAdornment";
import NewProtocolButton from "./NewProtocolButton";
import { ReactComponent as SearchIcon } from "../../../icons/search.svg";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  CssSearchProtoTextField,
  StyledTableCell,
  StyledTableRow,
} from "../Const";

export default function FirstView({
  protocols,
  setfirstView,
  setSelectedProtocol,
}) {
  const [searchedProtocols, setSearchedProtocols] = useState(protocols);
  const [listView, setlistView] = useState(true);
  const intl = useIntl();

  useEffect(() => {
    setSearchedProtocols(protocols);
  }, [protocols]);

  const DivideRulesIntoBlocks = (e) => {
    let ruleBlocksCopy = [];
    let temp = [];
    let lastTarget = [];
    e.ordered_rules.forEach((item) => {
      if (lastTarget.length === 0) {
        temp.push(item);
        lastTarget = item.target;
        return;
      }
      if (arrayEquals(item.target, lastTarget)) {
        temp.push(item);
      } else {
        ruleBlocksCopy.push(temp);
        //Item does not belong to the same group push it to another
        temp = [];
        temp.push(item);
        lastTarget = item.target;
      }
    });
    if (temp.length !== 0) {
      ruleBlocksCopy.push(temp);
    }
    return {
      name: e.name,
      protocol_id: e.protocol_id,
      observatory: e.observatory,
      groups: {},
      ordered_rules: ruleBlocksCopy,
      start_date: e.start_date,
      end_date: e.end_date,
    };
  };

  const arrayEquals = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    } else {
      for (var i = 0; i < arr1.length; i++) {
        if (!arr2.includes(arr1[i])) {
          return false;
        }
      }
      return true;
    }
  };

  const protocolCardsList = searchedProtocols
    ? searchedProtocols.map((e, idx) => {
        let tag = moment(e.end_date) > moment() ? "current" : "active";
        return (
          <button
            onClick={() => {
              setSelectedProtocol(DivideRulesIntoBlocks(e));
              setfirstView(false);
            }}
            key={e.protocol_id}
            className={`${
              tag === "current" ? "order-first" : "order-none"
            } focus:outline-none`}
          >
            <ProtocoleCard p={e} tag={tag} />
          </button>
        );
      })
    : [];

  const handleOnProtoNameChange = (event) => {
    setSearchedProtocols(
      protocols.filter((elem) => {
        return elem.name
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      })
    );
  };

  if (searchedProtocols === [])
    return (
      <div className="flex justify-center items-center">
        <img src="empty.png" />
      </div>
    );

  return (
    <div className="bg-white h-full rounded-8px flex divide-x divide-primary divide-opacity-15 mx-2 p-4 overflow-y-auto">
      <div className="w-72 mr-2 ">
        <div className=" flex justify-between mb-3">
          <p className="font-medium text-xl text-dark-text">
            <FormattedMessage id="protocol.firstView.param1" />
          </p>
          <div className="flex">
            <button
              className={`${
                !listView
                  ? "bg-primary shadow-blue-25 text-white"
                  : "bg-white text-black border-1 border-primary border-opacity-15"
              } h-9.25 w-9.25  rounded-8px flex items-center justify-center border-1 border-primary  mr-2 focus:outline-none`}
              onClick={() => {
                setlistView(false);
              }}
            >
              <GridIcon />
            </button>
            <button
              className={`${
                listView
                  ? "bg-primary shadow-blue-25 text-white"
                  : "bg-white border-1 border-primary border-opacity-15"
              } h-9.25 w-9.25  rounded-8px flex items-center justify-center  focus:outline-none`}
              onClick={() => {
                setlistView(true);
              }}
            >
              <ListIcon />
            </button>
          </div>
        </div>
        <div>
          {/* <FormattedMessage id="dashboard.chart.param38"> */}
          <CssSearchProtoTextField
            className="w-full bg-primary bg-opacity-3"
            variant="outlined"
            id="custom-css-outlined-input"
            placeholder={intl.formatMessage({
              id: "protocol.firstView.param21",
            })}
            onChange={handleOnProtoNameChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <p className="text-primary">
                    <SearchIcon className=" h-4 w-4 mr-4" />
                  </p>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <NewProtocolButton
          setfirstView={setfirstView}
          setSelectedProtocol={setSelectedProtocol}
        />
      </div>
      <div className="flex-grow">
        {listView && (
          <div className="grid grid-cols-3 gap-4 ml-5">{protocolCardsList}</div>
        )}
        {!listView && (
          <div className="p-3">
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">
                      <FormattedMessage id="protocol.firstView.param17" />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <FormattedMessage id="protocol.firstView.param18" />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {" "}
                      <FormattedMessage id="protocol.firstView.param19" />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {" "}
                      <FormattedMessage id="protocol.firstView.param20" />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchedProtocols.map((row) => (
                    <StyledTableRow
                      key={row.name}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedProtocol(DivideRulesIntoBlocks(row));
                        setfirstView(false);
                      }}
                    >
                      <StyledTableCell align="center">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.observatory}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.start_date}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.end_date}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
}
