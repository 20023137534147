import React, { useCallback, useState ,useEffect} from "react";
import _ from "lodash";
import { useSelector} from "react-redux";

export const useDebounce = (obj = null, wait = 1000,submit) => {
  const [state, setState] = useState(obj);
  const [url, setUrl] = useState('/automaton/Monticello/push')
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard)
  const user = useSelector((state) => state.user);

  useEffect(() => {
      if(user && currentSelectedCard && user.groups.includes("/demo")){
          setUrl(`/automaton/${currentSelectedCard.riverlab}/push`)
      }
  }, [user,currentSelectedCard])

    const setDebouncedState = (_val) => {
      debounce(_val);
    };

  const debounce = useCallback(
    _.debounce((_prop) => {
      console.log("updating search")
      submit(_prop,url)
      setState(_prop);
    }, wait),
    [url]
  );

  return [state, setDebouncedState]
};