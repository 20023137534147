export const DEFAULT_OPTION = {
  option: {
    grid: {
      bottom: 65,
      left: 40,
      right: 40,
      top: 40,
    },
    legend: {
      show: false,
      left: "center",
      top: "top",
    },
    title: {
      show: true,
      text: "",
      subtext: "",
      left: "left",
      textStyle: {
        fontSize: 20,
        fontWeight: 500,
      },
    },
    toolbox: {
      show: true,
      feature: {
        dataZoom: {},
      },
    },
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "cross",
      },
    },
    yAxis: [],
    xAxis: [],
    dataZoom: [
      {
        type: "inside",
        id: "insidex",
        start: 0,
        end: 100,
      },
      {
        type: "slider",
        id: "sliderx",
        height: 25,
        showDetail: false,
        realtime: false,
        fillerColor: "rgba(47,69,84,0.25)",
        dataBackground: {
          lineStyle: { color: "#3D80FB", width: 2, opacity: 1 },
          areaStyle: { color: "#3D80FB", opacity: 0.15 },
        },
        start: 0,
        end: 100,
      },
    ],
    markPoint: {
      data: [],
    },
    series: [],
  },
  notMerge: false,
  theme: "shine",
  graph_id: "",
  timedate: "",
  graph_type: "timeseries",
  date_start: "2021-01-01 00:00:00",
  date_end: "2021-12-31 23:59:59",
  couples: [],
};

export const DEFAULT_YAXIS_CONFIG = {
  show: true,
  type: "value",
  logBase: 10,
  boundaryGap: true,
  nameLocation: "middle",
  nameLocation: "center",
  nameGap: 20,
  position: "auto",
  offset: 0,
  scale: true,
  axisLabel: {
    rotate: 90,
  },
  axisLine: {
    show: true,
    onZero: false,
  },
  axisTick: { show: true },
  min: "dataMin",
  max: "dataMax",
  inverse: false,
};

export const DEFAULT_XAXIS_CONFIG = {
  show: true,
  type: "time",
  logBase: 10,
  boundaryGap: true,
  nameLocation: "middle",
  nameLocation: "center",
  nameGap: 20,
  position: "auto",
  offset: 0,
  scale: true,
  axisLabel: {},
  axisLine: {
    show: true,
    onZero: false,
    lineStyle: {},
  },
  axisTick: { show: true },
  min: "dataMin",
  max: "dataMax",
  inverse: false,
};

export const DEFAULT_SERIE_OPTION = {
  id: null,
  name: null,
  xAxisIndex: null,
  yAxisIndex: null,
  large: true,
  largeThreshold: 4000,
  sampling: "lttb",
  type: "line",
  data: null,
  symbolSize: 3,
  symbol: "circle",
  showAllSymbol: "auto",
  smooth: false,
  step: false,
  itemStyle: {
    color: null,
  },
  lineStyle: {
    type: "solid",
    width: 2,
    opacity: 1,
  },
  markPoint: {
    data: [],
  },
  markLine: {
    data: [],
  },
  markArea: {
    data: [],
  },
};
