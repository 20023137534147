import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as DeleteIcon } from "../../../icons/delete.svg";
import { ReactComponent as DataIcon } from "../../../icons/data.svg";
import { ReactComponent as AddIcon } from "../../../icons/add.svg";
import { XAxisConfig, YAxisConfig } from "./AxisConfig";
import SeriesConfig from "./SeriesConfig";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "./Accordion/Accordion";
import DialogContentContainer from "./DialogContentContainer/DialogContentContainer";
import { GRAPH_TYPES } from "../Const";

export default function ChooseCoupleSection({
  echartRef,
  expanded,
  handleChangeAccordion,
  couples,
  handleAddNewCouple,
  handleDeleteCouple,
  setOption,
  option,
}) {
  const [openn, setopenn] = useState(false);
  const [expandedcouple, setExpandedcouple] = useState("");
  const [colours, setcolours] = useState(null);

  useEffect(() => {
    if (
      echartRef &&
      echartRef.current &&
      echartRef.current.getEchartsInstance()
    ) {
      setcolours(
        option.couples.map((couple, id) => {
          return echartRef.current.getEchartsInstance().getVisual(
            {
              seriesIndex: option.option.series
                .map((e) => e.id)
                .indexOf(couple.id),
            },
            "color"
          );
        })
      );
    }
  }, [echartRef, option]);

  const handleChangeAccordionCouple = (panel) => (event, newExpanded) => {
    setExpandedcouple(newExpanded ? panel : false);
  };

  const list = couples
    ? couples.map((couple, index) => {
        return (
          <Accordion
            key={couple.id}
            expanded={expandedcouple === `${"couple" + index}`}
            onChange={handleChangeAccordionCouple(`${"couple" + index}`)}
            backgroundColor="rgba(142, 155, 187, 0.08)"
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <div className="flex items-center justify-between w-full">
                <p className="text-primary font-medium">
                  {couple.x ? couple.x.element + " at " : "Time"}
                  {couple.x ? couple.x.observatory : ""} VS {couple.y.element}{" "}
                  at {couple.y.observatory}
                </p>
                <div className="flex gap-1">
                  {colours && colours[index] && (
                    <div
                      className="rounded-full h-4 w-4"
                      style={{ backgroundColor: colours[index] }}
                    ></div>
                  )}
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDeleteCouple(couple.id);
                    }}
                    className="focus:outline-none"
                  >
                    <DeleteIcon className="h-3.5 w-3.5 text-grey-lighter" />
                  </button>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <XAxisConfig
                  graphType={option.graph_type}
                  xaxisConfig={
                    option.graph_type === "timeseries"
                      ? option.option.xAxis[0]
                      : option.option.xAxis.find((elem) => {
                          return elem.id === couple.id;
                        })
                  }
                  setOption={setOption}
                />
                <YAxisConfig
                  graphType={option.graph_type}
                  yaxisConfig={option.option.yAxis.find((elem) => {
                    return elem.id === couple.id;
                  })}
                  setOption={setOption}
                />
                <SeriesConfig
                  seriesConfig={option.option.series.find((elem) => {
                    return elem.id === couple.id;
                  })}
                  setOption={setOption}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })
    : [];

  return (
    <Accordion
      expanded={expanded.has("panel4")}
      onChange={handleChangeAccordion("panel4")}
      backgroundColor="white"
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="flex items-center w-full justify-between">
          <p className="text-primary font-medium flex gap-2">
            <DataIcon />
            <span className="text-normal">
              <FormattedMessage id="extraplot.annex.param2" />(
              {option.couples ? option.couples.length : ""})
            </span>
          </p>
          <button
            className="text-primary text-xs py-1 px-2 border-1 border-primary rounded-8px focus:outline-none flex gap-2 justify-center items-center font-medium"
            onClick={(event) => {
              event.stopPropagation();
              setopenn(true);
            }}
          >
            <AddIcon className="h-3 w-3" />
            <FormattedMessage id="extraplot.annex.param3" />
          </button>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="flex flex-col justify-center gap-1">
          <div>{list}</div>
          <DialogContentContainer
            open={openn}
            setopen={setopenn}
            handleAddNewCouple={handleAddNewCouple}
            graphType={GRAPH_TYPES[option.graph_type].title}
            option={option}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
