import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Radio,
  Divider,
  ListItemText,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as CloseIcon } from "../../icons/gray-close.svg";
import { ReactComponent as VectorIcon } from "../../icons/vector.svg";
import { ReactComponent as DropDownIcon } from "../../icons/dropDown.svg";
import { ReactComponent as DropUpIcon } from "../../icons/dropUp.svg";
import { setLanguage } from "../../Redux/actions";
import { Row, Col } from "react-flexbox-grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DialogActions from "@mui/material/DialogActions";
import {
  StyledText,
  BlueButton,
  BlackMediumText,
  CancelButton,
  StyledRadioButtonText,
  StyledDiv,
  StyledDarkParagraphe,
  StyledGreyParagraphe,
  StyledRadioBlueText,
  BlueBorderButton,
  GreyButton,
  StyledDarkParagrapheWithProps,
  StyledGreyParagrapheWithProps,
} from "./Settings.style";
import axios from "axios";
import { LOCALES } from "../../i18n/locales";
import withStyles from "@mui/styles/withStyles";
import { timeZones } from "../../utils/timeZones";

const Settings = (props) => {
  const open = props.open;
  const setOpen = props.setOpen;
  const intl = useIntl();

  const concentrations = ["µmol/L", "mmol/L", "ppb", "ppm"];
  const temperatures = ["°F", "°C", "", ""];
  const flows = ["m³/h", "L/h", "", ""];

  const userPreferencesInfo = useSelector((state) => state.userPreferences);
  const user = useSelector((state) => state.user);

  const [chosenLanguage, setChosenLanguage] = useState(
    intl.formatMessage({ id: userPreferencesInfo.language })
  );

  const [chosenConcentration, setChosenConcentration] = useState(
    userPreferencesInfo.concentration
  );
  const [chosenTemperature, setChosenTemperature] = useState(
    userPreferencesInfo.temperature
  );
  const [chosenFlow, setChosenFlow] = useState(userPreferencesInfo.flow);
  const [chosenTime, setChosenTime] = useState([userPreferencesInfo.time]);
  const [chooseTimeAnchor, setChooseTimeAnchor] = useState(null);

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };
  const handleModifTimeClose = () => {
    setChooseTimeAnchor(null);
  };
  const saveSettings = async () => {
    chosenLanguage &&
      (chosenLanguage === "English"
        ? dispatch(setLanguage(LOCALES["ENGLISH"]))
        : dispatch(setLanguage(LOCALES["FRENSH"])));
    await axios.put(
      process.env.REACT_APP_FRONTEND_URL +
        `/api/users/user/${user.email}/preferences`,
      {
        language: chosenLanguage,
        time: chosenTime[0].toString(),
        concentration: chosenConcentration,
        temperature: chosenTemperature,
        flow: chosenFlow,
      }
    );
    setOpen(false);
    window.location.reload();
  };

  return (
    <Dialog
      maxWidth={"xs"}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            overflow: "hidden",
          },
        },
      }}
      fullWidth={true}
      open={open}
      onClose={() => setOpen(false)}
      style={{ fontFamily: "Roboto" }}
    >
      <DialogTitle style={{ padding: "15px" }}>
        <div className="flex items-center relative">
          <StyledText>
            <FormattedMessage id="My preferences" />
          </StyledText>
          <button
            className="absolute right-0 top-1"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon className="h-3 w-3" />
          </button>
        </div>
      </DialogTitle>
      <Divider />
      <>
        <BlackMediumText
          style={{ fontFamily: "Roboto" }}
          id="demo-radio-buttons-group-label"
          className="ml-4 mt-4"
        >
          <FormattedMessage id="settings.param1" />
        </BlackMediumText>
        <Row
          className="flex items-center"
          style={{ marginLeft: "6px", width: "100px" }}
        >
          <Col>
            <Radio
              checked={
                chosenLanguage === "Anglais" || chosenLanguage === "English"
              }
              onChange={(event) => {
                setChosenLanguage(event.target.value);
              }}
              value="English"
              name="radio-buttons"
            />
          </Col>
          <Col>
            {chosenLanguage === "Anglais" || chosenLanguage === "English" ? (
              <StyledRadioBlueText>
                <FormattedMessage id="Anglais" />
              </StyledRadioBlueText>
            ) : (
              <StyledRadioButtonText>
                <FormattedMessage id="Anglais" />
              </StyledRadioButtonText>
            )}
          </Col>
        </Row>
        <Row
          className="flex items-center"
          style={{ marginLeft: "6px", width: "100px" }}
        >
          <Col>
            <Radio
              checked={
                chosenLanguage === "French" || chosenLanguage === "Français"
              }
              onChange={(event) => {
                setChosenLanguage(event.target.value);
              }}
              value="French"
              name="radio-buttons"
            />
          </Col>
          <Col>
            {chosenLanguage === "French" || chosenLanguage === "Français" ? (
              <StyledRadioBlueText>
                <FormattedMessage id="Français" />
              </StyledRadioBlueText>
            ) : (
              <StyledRadioButtonText>
                <FormattedMessage id="Français" />
              </StyledRadioButtonText>
            )}
          </Col>
        </Row>
      </>
      <Divider />
      <BlackMediumText className="ml-4 mt-4">
        <FormattedMessage id="settings.param2" />
      </BlackMediumText>
      <StyledDiv
        style={{
          fontFamily: "Roboto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "justify-between",
            alignItems: "center",
          }}
          onClick={(event) => {
            setChooseTimeAnchor(event.currentTarget);
          }}
        >
          <div style={{ width: "355px" }}>
            <>
              <StyledDarkParagraphe>
                {chosenTime[0].toString()}
              </StyledDarkParagraphe>
              <StyledGreyParagraphe>
                {timeZones.map(
                  (timeZone) => timeZone[chosenTime[0].toString()]
                )}
              </StyledGreyParagraphe>
            </>
          </div>
          <div>
            {chooseTimeAnchor ? (
              <DropUpIcon style={{ cursor: "pointer" }} />
            ) : (
              <DropDownIcon style={{ cursor: "pointer" }} />
            )}
          </div>
        </div>
        <Menu
          style={{ maxHeight: "230px" }}
          anchorEl={chooseTimeAnchor}
          open={Boolean(chooseTimeAnchor)}
          onClose={() => {
            handleModifTimeClose();
          }}
          PaperProps={{
            style: {
              width: "410px",
              marginLeft: "-10px",
              marginTop: "6px",
            },
          }}
        >
          {timeZones.map((timeZone) => (
            <StyledMenuItem
              onClick={() => {
                handleModifTimeClose();
                setChosenTime(Object.keys(timeZone));
              }}
            >
              <ListItemText>
                <StyledDarkParagrapheWithProps
                  chosen={
                    chosenTime[0].toString() ===
                    Object.keys(timeZone)[0].toString()
                  }
                >
                  {Object.keys(timeZone)}
                </StyledDarkParagrapheWithProps>
                <StyledGreyParagrapheWithProps
                  chosen={
                    chosenTime[0].toString() ===
                    Object.keys(timeZone)[0].toString()
                  }
                >
                  {Object.values(timeZone)}
                </StyledGreyParagrapheWithProps>
              </ListItemText>
              {chosenTime[0].toString() ===
                Object.keys(timeZone)[0].toString() && <VectorIcon />}
            </StyledMenuItem>
          ))}
        </Menu>
      </StyledDiv>
      <Divider />
      <BlackMediumText className="mt-4" style={{ marginLeft: "12px" }}>
        <FormattedMessage id="settings.param5" />
      </BlackMediumText>
      <Row
        className="flex items-center justify-between"
        style={{ width: "458px" }}
      >
        <Col>
          <StyledDarkParagraphe className="ml-5 mt-4">
            Concentrations
          </StyledDarkParagraphe>
        </Col>
        <Col className="ml-4 mt-4 flex">
          {concentrations.map((unit) =>
            unit === "" ? (
              <div
                style={{
                  width: "69px",
                  height: "23px",
                  borderRadius: "5px",
                  border: "1px solid #ffffff",
                }}
                className="mr-4"
              />
            ) : unit === chosenConcentration ? (
              <BlueBorderButton className="mr-4">{unit}</BlueBorderButton>
            ) : (
              <GreyButton
                className="mr-4"
                onClick={() => setChosenConcentration(unit)}
              >
                {unit}
              </GreyButton>
            )
          )}
        </Col>
        <Col>
          <StyledDarkParagraphe className="ml-5 mt-4">
            Temperatures
          </StyledDarkParagraphe>
        </Col>
        <Col className="ml-4 mt-4 flex">
          {temperatures.map((unit) =>
            unit === "" ? (
              <div
                style={{
                  width: "69px",
                  height: "23px",
                  borderRadius: "5px",
                  border: " 1px solid #ffffff",
                }}
                className="mr-4"
              />
            ) : unit === chosenTemperature ? (
              <BlueBorderButton className="mr-4">{unit}</BlueBorderButton>
            ) : (
              <GreyButton
                className="mr-4"
                onClick={() => setChosenTemperature(unit)}
              >
                {unit}
              </GreyButton>
            )
          )}
        </Col>
        <Col>
          <StyledDarkParagraphe className="ml-5 mt-4">
            Flows
          </StyledDarkParagraphe>
        </Col>
        <Col className="ml-4 mt-4 flex">
          {flows.map((unit) =>
            unit === "" ? (
              <div
                style={{
                  width: "69px",
                  height: "23px",
                  borderRadius: "5px",
                  border: " 1px solid #ffffff",
                }}
                className="mr-4"
              />
            ) : unit === chosenFlow ? (
              <BlueBorderButton className="mr-4">{unit}</BlueBorderButton>
            ) : (
              <GreyButton className="mr-4" onClick={() => setChosenFlow(unit)}>
                {unit}
              </GreyButton>
            )
          )}
        </Col>
      </Row>
      <Divider className="p-2" />
      <DialogActions style={{ fontFamily: "Roboto" }}>
        <CancelButton onClick={handleClose}>
          <FormattedMessage id="protocol.SecondView.param48" />
        </CancelButton>
        <BlueButton onClick={saveSettings}>
          <FormattedMessage id="settings.param4" />
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default Settings;

const StyledMenuItem = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "#EBECF3",
    },
  },
})(MenuItem);
