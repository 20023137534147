import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import {
  StyledText,
  StyledLabel,
  StyledInputButton,
  StyledRow,
  BlackSpan,
  BlueText,
  RedText,
  StyledImage,
} from "./Signup.style";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import StrengthMeter from "./PasswordStrengthChecker/StrengthMeter";
import TextField from "@mui/material/TextField";

import { ReactComponent as ExtralabLogo } from "../../icons/extralabLogo.svg";
import withStyles from "@mui/styles/withStyles";

import { Col, Row } from "react-flexbox-grid";

const Signup = () => {
  const history = useHistory();
  const { id: invitationId } = useParams();
  const [username, setUsername] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [invitedUserData, setInvitedUserData] = useState();
  const [poorPassword, setPoorPassword] = useState(false);
  const [passwordError, setPasswordErr] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_FRONTEND_URL +
          `/api/users/invite/${invitationId}`
      )
      .then((response) => {
        if (response.status === 200) {
          setInvitedUserData(response.data);
        }
      })
      .catch(() => {
        history.push("/dashboard");
      });
  }, [history, invitationId]);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!poorPassword && password === confirmPassword) {
      axios
        .post(
          process.env.REACT_APP_FRONTEND_URL +
            "/api/users",
          {
            invitationId,
            username,
            firstName,
            lastName,
            password,
          }
        )
        .then((response) => {
          if (response.status === 201) {
            history.push("/dashboard");
          }
        })
        .catch(() => {
          // TODO handle error, ie show error message to user
        });
    }
  };

  const passwordStrength = (evnt) => {
    const passwordValue = evnt.target.value;
    const passwordLength = passwordValue.length;

    if (passwordValue === "") {
      setPasswordErr("Password is Empty");
    } else {
      if (passwordLength <= 8) {
        setPoorPassword(true);
        setPasswordErr("Password must be at least 8 characters.");
      } else {
        setPoorPassword(false);
        setPasswordErr("");
      }
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="bg-white overflow-hidden h-full w-full p-2">
      <ExtralabLogo />
      <Row className="flex items-center justify-between h-full w-full">
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "100px",
            paddingTop: "50px",
          }}
        >
          <StyledText style={{ marginBottom: "15px" }}>
            Create your account
          </StyledText>
          <StyledRow
            style={{
              textAlign: "center",
              fontSize: "12px",
              fontWeight: 400,
            }}
          >
            <Col style={{ marginBottom: "5px" }}>
              <BlackSpan>Your username is</BlackSpan>
            </Col>
            <Col>
              <BlueText>{invitedUserData && invitedUserData.email}</BlueText>
            </Col>
          </StyledRow>

          <form onSubmit={handleSubmit}>
            <StyledLabel>What is your first name?</StyledLabel>
            <StyledTextField
              className="bg-opacity-3"
              required
              style={{
                fontFamily: "Roboto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "308px",
              }}
              error={false}
              correct={false}
              variant="outlined"
              value={firstName}
              placeholder="Your first name"
              onChange={(e) => {
                setFirstName(e.target.value);
                setUsername(invitedUserData.email);
              }}
            />
            <StyledLabel>What is your last name?</StyledLabel>
            <StyledTextField
              className="bg-opacity-3"
              required
              style={{
                fontFamily: "Roboto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "308px",
              }}
              error={false}
              correct={false}
              variant="outlined"
              placeholder="Your last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <StyledLabel>
              <div style={{ display: "flex" }}>Choose a password</div>
            </StyledLabel>
            <div>
              <StyledTextField
                className="bg-opacity-3"
                required
                style={{
                  fontFamily: "Roboto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "308px",
                }}
                variant="outlined"
                error={poorPassword}
                correct={!poorPassword}
                type={showPassword ? "text" : "password"}
                placeholder=". . . . . ."
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onInput={passwordStrength}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <StrengthMeter
                poorPassword={poorPassword}
                passwordError={passwordError}
              />
            </div>
            <StyledLabel>
              <div style={{ display: "flex" }}>Confirm your password</div>
            </StyledLabel>
            <StyledTextField
              className="bg-opacity-3"
              required
              style={{
                fontFamily: "Roboto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "308px",
              }}
              variant="outlined"
              placeholder=". . . . . ."
              error={
                password !== confirmPassword && confirmPassword !== undefined
              }
              correct={password === confirmPassword}
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {password !== confirmPassword && confirmPassword !== undefined ? (
              <RedText>These two passwords are different.</RedText>
            ) : (
              <></>
            )}
            <div
              style={{ position: "relative", left: "63%", marginTop: "15px" }}
            >
              {passwordError === "" &&
              password === confirmPassword &&
              !poorPassword &&
              firstName !== "" &&
              lastName !== "" ? (
                <StyledInputButton
                  type="submit"
                  value="Validate"
                  style={{ backgroundColor: "#2975ff", color: "#ffffff" }}
                />
              ) : (
                <StyledInputButton
                  type="submit"
                  value="Validate"
                  style={{ backgroundColor: "#EBECF3", color: "#8488A5" }}
                />
              )}
            </div>
          </form>
        </Col>
        <Col>
          <StyledImage alt="" src="/assets/protocol.png" />
        </Col>
      </Row>
    </div>
  );
};

export default Signup;

export const StyledTextField = withStyles({
  root: {
    padding: "10px",
    "& ::placeholder": {
      color: "#8488A5",
      opacity: 0.5 /* Firefox */,
      size: "14px",
      fontFamily: "Roboto",
    },
    "& .MuiInputBase-root": {
      height: "46px",
      width: "308px",
    },
    "& .MuiOutlinedInput-root": {
      borderColor: `${(props) =>
        props.error ? "#F04545" : props.correct ? "#50D86E" : "#FAFBFF"}`,
      backgroundColor: "#FAFBFF",
      "& fieldset": {
        borderColor: `${(props) =>
          props.error === true
            ? "#F04545"
            : props.correct
            ? "green"
            : "#FAFBFF"}`,
      },
      "&:hover fieldset": {
        borderColor: `${(props) =>
          props.error ? "red" : props.correct ? "green" : "#2975FF"}`,
      },
      "&.Mui-focused": {
        backgroundColor: "#EBECF3",
      },
    },
  },
})(TextField);
