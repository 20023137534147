/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import InputAdornment from "@mui/material/InputAdornment";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import ParamPanel from "./ParamPanel";
import ObsPanel from "./ObsPanel";
import { ReactComponent as DeleteIcon } from "../../../../icons/delete.svg";
import { ReactComponent as DragIcon } from "../../../../icons/drag-indicator.svg";
import { ReactComponent as InfoIcon } from "../../../../icons/info.svg";
import { ReactComponent as CloseIcon } from "../../../../icons/close.svg";
import { ReactComponent as AddIcon } from "../../../../icons/add.svg";
import { CssTextField, SelectTextField } from "../../Const";
import { keycloakGrpToRL } from "../../../../utils/riverlabs";

export default function ParamsDialog({
  setModalIsOpen,
  modalIsOpen,
  ordered_rules,
  saveNewBlock,
  selectedObs,
  setSelectedObs,
}) {
  const [obsList, setobsList] = useState([]);
  const [NewobsList, setNewobsList] = useState(null);
  const [anions, setAnions] = useState([]);
  const [cations, setCations] = useState([]);
  const [physico_chemicalParams, setPhysico_chemicalParams] = useState([]);
  const [technicalParams, setTechnicalParams] = useState([]);
  const [meteoParams, setMeteoParams] = useState([]);
  const [selectedObsCopy, setSelectedObsCopy] = useState(selectedObs);
  const [ordered_rulesDraft, setOrdered_rulesDraft] = useState(ordered_rules);
  const [targetCopy, setTargetCopy] = useState(
    ordered_rules.length !== 0 ? ordered_rules[0].target : []
  );
  const [units, setUnits] = useState(null);
  const user = useSelector((state) => state.user);
  const blankInstruction = {
    filtering_quantity: "",
    min_threshold: "",
    max_threshold: "",
    target: targetCopy,
  };

  useEffect(() => {
    if (selectedObsCopy) {
      getParameters(selectedObsCopy);
    }
  }, []);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + `/units`)
      .then((res) => {
        setUnits(res.data.units);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(process.env.REACT_APP_API_URL + `/observatories`)
      .then((res) => {
        setNewobsList(res.data.observatories);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (user && NewobsList) {
      user.groups &&
        setobsList(keycloakGrpToRL(user.groups).flatMap((param) => param));
    }
  }, [user, NewobsList]);

  const updateTarget = () => {
    let ordered_rulesDraftCopy = ordered_rulesDraft.map((elem) => {
      elem.target = targetCopy;
      return elem;
    });
    setOrdered_rulesDraft(ordered_rulesDraftCopy);
  };

  const getParameters = (obs) => {
    axios
      .get(process.env.REACT_APP_API_URL + `/observatories/${obs}/elements`)
      .then((res) => {
        setAnions(res.data.anions);
        setCations(res.data.cations);
        setPhysico_chemicalParams(res.data.parametres_physico_chimiques);
        setTechnicalParams(res.data.parametres_techniques);
        setMeteoParams(res.data.parametres_meteo);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (prop, idx) => (event) => {
    const ordered_rulesDraftUpdated = Array.from(ordered_rulesDraft);
    ordered_rulesDraftUpdated[idx] = {
      ...ordered_rulesDraftUpdated[idx],
      [prop]: event.target.value,
    };
    setOrdered_rulesDraft(ordered_rulesDraftUpdated);
  };

  const handleFilteredParamsChange = (elem) => {
    if (targetCopy.includes(elem)) {
      setTargetCopy(
        targetCopy.filter((item) => {
          return elem !== item;
        })
      );
    } else {
      setTargetCopy([...targetCopy, elem]);
    }
  };

  const domAnionsList = anions.map((elem) => {
    return (
      <button
        onClick={() => {
          handleFilteredParamsChange(elem.id);
        }}
        className="focus:outline-none  mr-2"
        key={`anion ${elem.id}`}
      >
        <ParamPanel target={targetCopy} id={elem.id}>
          <div className="w-12 h-12 flex items-center justify-center">
            <p>{elem.formule_chimique.element}</p>
            <sub>{elem.formule_chimique.indice}</sub>
            <sup>{elem.formule_chimique.exposant}</sup>
          </div>
        </ParamPanel>
      </button>
    );
  });

  const domCationsList = cations.map((elem) => {
    return (
      <button
        onClick={() => {
          handleFilteredParamsChange(elem.id);
        }}
        className="focus:outline-none  mr-2"
        key={`cation ${elem.id}`}
      >
        <ParamPanel target={targetCopy} id={elem.id}>
          <div className="w-12 h-12 flex items-center justify-center">
            <p>{elem.formule_chimique.element}</p>
            <sub>{elem.formule_chimique.indice}</sub>
            <sup>{elem.formule_chimique.exposant}</sup>
          </div>
        </ParamPanel>
      </button>
    );
  });

  const domPCParamsList = physico_chemicalParams.map((elem) => {
    return (
      <button
        onClick={() => {
          handleFilteredParamsChange(elem.id);
        }}
        className="focus:outline-none mr-2 mb-1"
        key={`pcc ${elem.id}`}
      >
        <ParamPanel target={targetCopy} id={elem.id}>
          <div className="px-3 py-2">
            <p className="text-sm font-medium">{elem.label}</p>
          </div>
        </ParamPanel>
      </button>
    );
  });

  const domTParamsList = technicalParams.map((elem) => {
    return (
      <button
        onClick={() => {
          handleFilteredParamsChange(elem.id);
        }}
        className="focus:outline-none mr-2 mb-1"
        key={`tp ${elem.id}`}
      >
        <ParamPanel target={targetCopy} id={elem.id}>
          <div className="px-3 py-2">
            <p className="text-sm font-medium">{elem.label}</p>
          </div>
        </ParamPanel>
      </button>
    );
  });

  const domMeteoList = meteoParams.map((elem) => {
    return (
      <button
        onClick={() => {
          handleFilteredParamsChange(elem.id);
        }}
        className="focus:outline-none mr-2 mb-1"
        key={`tp ${elem.id}`}
      >
        <ParamPanel target={targetCopy} id={elem.id}>
          <div className="px-3 py-2">
            <p className="text-sm font-medium">{elem.label}</p>
          </div>
        </ParamPanel>
      </button>
    );
  });

  useEffect(() => {
    if (obsList.length !== 0) {
      setSelectedObsCopy(selectedObs);
      getParameters(selectedObs);
    }
  }, [obsList]);
  const domObsList = obsList.map((elem) => {
    return (
      <ObsPanel
        key={elem}
        getParameters={getParameters}
        elem={elem}
        selectedObs={selectedObsCopy}
        setSelectedObs={setSelectedObsCopy}
      />
    );
  });

  const domInSelectAnionsList = anions.map((elem) => {
    return <MenuItem value={elem.id}>{elem.id}</MenuItem>;
  });

  const domInSelectCationsList = cations.map((elem) => {
    return <MenuItem value={elem.id}>{elem.id}</MenuItem>;
  });

  const domInSelectPCParamsList = physico_chemicalParams.map((elem) => {
    return <MenuItem value={elem.id}>{elem.id}</MenuItem>;
  });

  const domInSelectTParamsList = technicalParams.map((elem) => {
    return <MenuItem value={elem.id}>{elem.id}</MenuItem>;
  });

  const domInSelectMeteoList = meteoParams.map((elem) => {
    return <MenuItem value={elem.id}>{elem.id}</MenuItem>;
  });

  const deleteRule = (idx) => {
    let copy = Array.from(ordered_rulesDraft);
    copy.splice(idx, 1);
    setOrdered_rulesDraft(copy);
  };

  const domRulesList = ordered_rulesDraft.map((elem, idx) => {
    return (
      <Draggable key={`rule ${idx}`} draggableId={`rule ${idx}`} index={idx}>
        {(provided, snapshot) => (
          <div
            className="mb-3"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <div className="flex justify-between">
              <p className="text-primary text-sm font-medium mb-2">
                <FormattedMessage id="protocol.SecondView.param27" /> {idx + 1}
              </p>
              <button
                className="focus:outline-none"
                onClick={() => {
                  deleteRule(idx);
                }}
              >
                <DeleteIcon className="text-grey-text h-4 w-4" />
              </button>
            </div>
            <div className="flex">
              <div className="flex items-center justify-center mr-6">
                <DragIcon className="text-grey-text" />
              </div>
              <div className="grid grid-cols-9 gap-3 mr-3">
                <div className="col-span-5">
                  <SelectTextField
                    id="demo-customized-select"
                    select
                    variant="outlined"
                    value={ordered_rulesDraft[idx].filtering_quantity}
                    onChange={handleChange("filtering_quantity", idx)}
                    //input={<BootstrapInput />}
                  >
                    <ListSubheader>Anions</ListSubheader>
                    {domInSelectAnionsList}
                    <ListSubheader>Cations</ListSubheader>
                    {domInSelectCationsList}
                    <ListSubheader>Paramétrs physico-chimiques</ListSubheader>
                    {domInSelectPCParamsList}
                    <ListSubheader>Paramétrs techniques</ListSubheader>
                    {domInSelectTParamsList}
                    <ListSubheader>paramètres météo</ListSubheader>
                    {domInSelectMeteoList}
                  </SelectTextField>
                </div>
                <div className="col-span-2">
                  <CssTextField
                    variant="outlined"
                    id="custom-css-outlined-input"
                    onChange={handleChange("min_threshold", idx)}
                    value={ordered_rulesDraft[idx].min_threshold}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <p className="text-primary">Min :</p>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="col-span-2">
                  <CssTextField
                    variant="outlined"
                    id="custom-css-outlined-input"
                    onChange={handleChange("max_threshold", idx)}
                    value={ordered_rulesDraft[idx].max_threshold}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <p className="text-primary">Max :</p>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <p className="text-sm font-normal py-2 px-3 border-1 border-primary border-opacity-15 bg-primary bg-opacity-3 rounded-8px ">
                {units ? units[elem.filtering_quantity] : "Unit"}
              </p>
            </div>
          </div>
        )}
      </Draggable>
    );
  });

  const addNewInstruction = () => {
    const ordered_rulesDraft_copy = [...ordered_rulesDraft];
    ordered_rulesDraft_copy.push(blankInstruction);
    setOrdered_rulesDraft(ordered_rulesDraft_copy);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    let newList = reorder(
      ordered_rulesDraft,
      result.source.index,
      result.destination.index
    );
    setOrdered_rulesDraft(newList);
  };
  const grid = 8;
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    // change background colour if dragging
    background: isDragging ? "rgba(61, 128, 251, 0.05)" : "white",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "rgba(61, 128, 251, 0.15)" : "white",
    padding: grid,
  });

  return (
    <Dialog
      open={modalIsOpen}
      onClose={() => {
        setModalIsOpen(false);
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
    >
      <DialogTitle
        id="form-dialog-title"
        className="bg-primary bg-opacity-8 text-primary text-xs font-medium"
      >
        <div className="flex items-center justify-center relative">
          <InfoIcon className="w-4 h-4 mr-2" />
          <p className="text-xs font-medium mr-4">
            <FormattedMessage id="protocol.SecondView.param28" /> 😉
          </p>
          <button
            className="absolute right-0 top-1"
            onClick={() => {
              setModalIsOpen(false);
            }}
          >
            <CloseIcon className="text-primary h-3 w-3 " />
          </button>
        </div>
      </DialogTitle>
      <div className="divide-y divide-primary divide-opacity-15">
        <div className="px-8 py-5">
          <p className="text-lg text-dark-text font-medium mb-7">
            <FormattedMessage id="protocol.SecondView.param29" />
          </p>
          <div className="flex">{domObsList}</div>
        </div>
        {selectedObsCopy && (
          <div className="px-8 py-7">
            <p className="text-xl text-dark-text font-medium mb-5">
              <FormattedMessage id="protocol.SecondView.param30" />
            </p>
            <div className="mb-6">
              <p className="text-primary text-sm font-medium mb-4">Anions</p>
              <div className="flex">{domAnionsList}</div>
            </div>
            <div className="mb-6">
              <p className="text-primary text-sm font-medium mb-4">Cations</p>
              <div className="flex">{domCationsList}</div>
            </div>
            <div className="mb-6">
              <p className="text-primary text-sm font-medium mb-4">
                <FormattedMessage id="protocol.SecondView.param31" />
              </p>
              <div className="flex flex-wrap">{domPCParamsList}</div>
            </div>
            <div>
              <p className="text-primary text-sm font-medium mb-4">
                <FormattedMessage id="protocol.SecondView.param32" />
              </p>
              <div className="flex flex-wrap">{domTParamsList}</div>
            </div>
            <div>
              <p className="text-primary text-sm font-medium mb-4">
                paramètres météo
              </p>
              <div className="flex flex-wrap">{domMeteoList}</div>
            </div>
          </div>
        )}
        {selectedObsCopy && (
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="px-4">
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    className="px-8 py-5"
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    <p className="text-xl font-medium text-dark-text my-7">
                      <FormattedMessage id="protocol.SecondView.param33" />
                    </p>
                    <div>{domRulesList}</div>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <button
                className="rounded-8px w-full bg-primary bg-opacity-8 text-primary flex items-center justify-center py-2 focus:outline-none mt-3"
                onClick={addNewInstruction}
              >
                <AddIcon className="text-primary h-3 w-3 mr-3" />
                <p className="text-sm font-medium ">
                  <FormattedMessage id="protocol.SecondView.param34" />
                </p>
              </button>
            </div>
          </DragDropContext>
        )}
      </div>
      <DialogActions>
        <button
          onClick={() => {
            setModalIsOpen(false);
          }}
          className="text-primary border-1 border-primary border-opacity-15 mr-2 py-2 px-3 rounded-8px text-sm font-medium focus:outline-none"
        >
          <FormattedMessage id="annuler" />
        </button>
        <button
          onClick={() => {
            setModalIsOpen(false);
            updateTarget();
            saveNewBlock(ordered_rulesDraft);
            setSelectedObs(selectedObsCopy);
          }}
          className="text-white rounded-8px bg-primary shadow-blue-shadow py-2 px-3 text-sm font-medium focus:outline-none"
        >
          <FormattedMessage id="protocol.SecondView.param23" />
        </button>
      </DialogActions>
    </Dialog>
  );
}
