import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import {
  setCurrentSelectedElement,
  setPrefferedCards,
} from "../../Redux/actions";
import Tooltip from "@mui/material/Tooltip";
import cloneDeep from "lodash.clonedeep";
import { useIntl } from "react-intl";
import AddCardSection from "./addCardSection";
import {
  transformUnit,
  transformValueWithePreferncesUnit,
} from "../../utils/preferences";

const responsive = {
  hugeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1701 },
    items: 7,
  },
  superSuperLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 1700, min: 1601 },
    items: 6,
    slidesToSlide: 6,
  },
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 1600, min: 1401 },
    items: 5,
    slidesToSlide: 5,
  },
  LargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 1400, min: 1201 },
    items: 6,
    slidesToSlide: 6,
  },
  desktop: {
    breakpoint: { max: 1200, min: 970 },
    items: 5,
    slidesToSlide: 5,
  },
  tablet: {
    breakpoint: { max: 969, min: 600 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 599, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 45,
  },
};

export default function TopBanner() {
  const [open, setopen] = useState(false);
  const preferredCards = useSelector((state) => state.preferredCards);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const socket = useSelector((state) => state.socket);
  const [localselectedData, setlocalselectedData] = useState(null);

  useEffect(() => {
    if (preferredCards && open && localselectedData === null) {
      let test = preferredCards.map((card) => {
        return { element: card.element, riverlab: card.riverlab };
      });
      setlocalselectedData(test);
    }
  }, [preferredCards, open, localselectedData]);

  useEffect(() => {
    if (!open) {
      setlocalselectedData(null);
    }
  }, [open]);

  function getPreferredCards() {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/user/${user.preferred_username}/elements/realtime`
      )
      .then((res) => {
        dispatch(setPrefferedCards(res.data.data));
      })
      .catch((err) => console.error(err));
  }

  //one get cards data
  useEffect(() => {
    user && getPreferredCards();
  }, [user]);

  //register to listen to realtime data chanel
  useEffect(() => {
    socket && socket.on("realtime", updatePreferredCards);
    return () => {
      if (socket) {
        socket.removeAllListeners("realtime");
      }
    };
  }, [socket, preferredCards]);

  function updatePreferredCards(data) {
    if (preferredCards) {
      let temp = cloneDeep(preferredCards);
      data.data.forEach((newelem) => {
        preferredCards.forEach((oldelem, idx) => {
          if (
            newelem.riverlab === oldelem.riverlab &&
            newelem.element === oldelem.element &&
            (newelem.value !== oldelem.value) |
              (newelem.online !== oldelem.online)
          ) {
            temp[idx].value = newelem.value;
            temp[idx].online = newelem.online;
          }
        });
      });
      dispatch(setPrefferedCards(temp));
    }
  }

  return (
    <div className="fixed grid grid-cols-12 right-0 left-0 top-0 pt-1 pr-1 z-10 h-18 md:ml-16">
      <div className="col-span-11">
        <StatCardsSection
          open={open}
          setopen={setopen}
          preferredCards={preferredCards}
        />
      </div>
      <div className="flex items-center justify-end">
        <AddCardSection
          open={open}
          setopen={setopen}
          localselectedData={localselectedData}
          setlocalselectedData={setlocalselectedData}
        />
      </div>
    </div>
  );
}

export function StatCardsSection({ preferredCards }) {
  let cardsList = preferredCards
    ? preferredCards.map((e, idx) => {
        return <NewStatCard element={e} key={idx} />;
      })
    : [];

  if (!preferredCards) return <WireframeStatCardsSection />;

  return (
    <Carousel
      responsive={responsive}
      itemClass="px-1 flex justify-center"
      partialVisible={true}
      transitionDuration={100}
      customTransition="transform 100ms ease-in-out"
      removeArrowOnDeviceType="mobile"
    >
      {cardsList}
    </Carousel>
  );
}

function WireframeStatCardsSection() {
  return (
    <div className="flex bg-app-bg fixed right-0 left-0 top-0 py-2 pl-2 h-20">
      <div className="flex gap-x-5">
        <WireframeCard selected={false} />
        <WireframeCard selected={true} />
      </div>
      <div className="hidden ml-2 gap-x-5">
        <WireframeCard selected={false} />
        <WireframeCard selected={false} />
        <WireframeCard selected={false} />
      </div>
    </div>
  );
}

function WireframeCard({ selected }) {
  return (
    <div
      className={`${
        selected ? "bg-dark" : "bg-white"
      } h-16 w-60  rounded-8px relative p-5 animate-pulse`}
    >
      <div className="mt-2">
        <div
          className={`${
            selected ? "bg-white" : "bg-gray-100"
          } rounded-full h-2 w-2/5`}
        ></div>
        <div className="bg-gray-100 rounded-full h-2 w-4/5 mt-1"></div>
      </div>
      <div className="absolute h-2 w-2 rounded-full bg-primary-3 top-0 right-0 m-3"></div>
    </div>
  );
}

export function NewStatCard({ element }) {
  const userPreferencesInfo = useSelector((state) => state.userPreferences);
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);
  const dispatch = useDispatch();
  const selectCard =
    currentSelectedCard &&
    currentSelectedCard.riverlab === element.riverlab &&
    currentSelectedCard.element === element.element;
  const intl = useIntl();
  const trad = intl.formatMessage({ id: element.label });

  return (
    <div
      className={`${
        selectCard ? "bg-dark text-white" : "bg-white"
      }  shadow-small rounded-8px cursor-pointer relative w-full h-16 mb-2 p-2`}
      onClick={(e) => {
        dispatch(setCurrentSelectedElement(element));
      }}
    >
      <div className="flex gap-1">
        <p className="text-xl font-medium">
          {element.value !== "no rt data" && element.value !== "no data"
            ? parseFloat(
                transformValueWithePreferncesUnit(
                  element.unit,
                  element.value,
                  transformUnit(userPreferencesInfo, element.unit),
                  element.label
                ).toFixed(2)
              )
            : element.value}
        </p>
        <p className="text-xs mt-auto">
          {transformUnit(userPreferencesInfo, element.unit)}
        </p>
      </div>
      <div className="flex gap-1 text-xs mt-1">
        <p>{trad.charAt(0).toUpperCase() + trad.slice(1)},</p>
        <p>{element.riverlab}</p>
      </div>
      <div className="absolute top-0 right-0 m-2">
        <p
          className={`font-medium ${
            selectCard ? "text-white" : "text-grey-dark"
          } uppercase flex items-center`}
          style={{ fontSize: "8px" }}
        >
          <span className="mr-1 ">
            <FormattedMessage id="cardssection.param1" />
          </span>
          <Tooltip title="Online" arrow={true} placement="top">
            <span
              className={`${
                element.online ? "bg-success" : "bg-error"
              } inline-block rounded-full border border-white`}
              style={{ width: "9px", height: "9px" }}
            ></span>
          </Tooltip>
        </p>
      </div>
    </div>
  );
}
