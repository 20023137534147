import React, { useState, useCallback, useEffect } from "react";
import { ReactComponent as GearSkeletonIcon } from "../icons/gear-skeleton.svg";
import { ReactComponent as DropIcon } from "../icons/drop.svg";
import arrowDown, {
  ReactComponent as ArrowDownIcon,
} from "../icons/arrow-down.svg";
import filter, { ReactComponent as FilterIcon } from "../icons/filter.svg";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { FormattedMessage, useIntl } from "react-intl";

//FINAL transport to buttons.js
//supports single and multiple click according to selected and onclick func you pass
//this button has grey border and primary text
export function SkeletonGreybgButton({
  id,
  handleOnClick,
  children,
  selected,
}) {
  const localhandleOnClick = () => {
    handleOnClick(id);
  };

  return (
    <button
      onClick={localhandleOnClick}
      className={`${
        selected ? "border-grey-light" : "border-primary"
      } border-1 focus:outline-none font-semibold py-2 px-3 text-xs text-primary rounded-8px`}
    >
      {children}
    </button>
  );
}

export function SkeletonSquareGreybgButton({
  id,
  handleOnClick,
  children,
  selected,
}) {
  const localhandleOnClick = () => {
    handleOnClick(id);
  };

  return (
    <button
      onClick={localhandleOnClick}
      className={`${
        selected ? "border-primary" : "border-grey-light border-opacity-20"
      } border-1 focus:outline-none font-semibold p-1 text-xs text-primary rounded-8px`}
    >
      {children}
    </button>
  );
}

export function CirclehoverButton({ handleOnClick, children, selected }) {
  return (
    <button
      onClick={handleOnClick}
      className={`${
        selected ? "text-primary" : "text-grey-light"
      } ml-2 h-8 w-8 flex items-center justify-center rounded-full bg-transparent focus:outline-none hover:text-primary hover:bg-gray-200 transition ease-in-out duration-150`}
    >
      {children}
    </button>
  );
}

//FINAL transport to buttons.js
//supports single and multiple click according to selected and onclick func you pass
//this button is grey when not selected and primary when selected
export function FilledGreybgButton({ id, handleOnClick, text, selected }) {
  const localhandleOnClick = () => {
    handleOnClick(id);
  };

  return (
    <button
      onClick={localhandleOnClick}
      className={`${
        selected
          ? "bg-primary shadow-blue-25 text-white"
          : "bg-app-bg text-normal"
      } focus:outline-none font-semibold py-2 px-3 text-xs rounded-8px`}
    >
      {text}
    </button>
  );
}

export function SquareButton({
  bgColor,
  borderColor,
  fullWidth,
  textColor,
  size,
  text,
  ...props
}) {
  const intl = useIntl();
  const trad = intl.formatMessage({ id: text });

  return (
    <button
      {...props}
      value={text}
      className={`${
        borderColor ? `border-1 border-${borderColor}` : ""
      } bg-${bgColor} text-${textColor} ${size === "small" ? "text-xs" : ""} ${
        size === "large" ? "text-sm" : ""
      } focus:outline-none font-semibold py-2 rounded-8px ${
        fullWidth ? `w-full` : "px-3"
      }`}
    >
      {trad.charAt(0).toUpperCase() + trad.slice(1)}
    </button>
  );
}

export function PrimaryItemButton(props) {
  return (
    <SquareButton {...props} size="large" bgColor="primary" textColor="white" />
  );
}

export function PrimaryFullWidthButton(props) {
  return (
    <SquareButton
      {...props}
      size="large"
      bgColor="primary"
      textColor="white"
      fullWidth={true}
    />
  );
}

export function BorderPrimaryButton(props) {
  return (
    <SquareButton
      {...props}
      size="large"
      bgColor="white"
      borderColor="primary"
      textColor="primary"
      fullWidth={false}
    />
  );
}

export function BorderPrimaryFullWidthButton(props) {
  return (
    <SquareButton
      {...props}
      size="large"
      bgColor="white"
      borderColor="primary"
      textColor="primary"
      fullWidth={true}
    />
  );
}

export function SecondaryFullWidthButton(props) {
  return (
    <SquareButton
      {...props}
      size="large"
      bgColor="secondary"
      textColor="white"
      fullWidth={true}
    />
  );
}

export function GreyItemButton(props) {
  return (
    <SquareButton {...props} size="large" bgColor="app-bg" textColor="normal" />
  );
}

export function SelectLikeButtonSkeleton({ text, icon, handleOnClick }) {
  return (
    <button
      onClick={handleOnClick}
      className=" focus:outline-none px-2 flex text-primary flex items-center justify-center border-1 border-light-grey-border border-opacity-20 rounded-8px"
    >
      {icon && <GearSkeletonIcon className="md:mr-1 h-3.5 w-3.5" />}
      {text}
    </button>
  );
}

//shared component
export function FilledPrimaryButton({ handleOnClick, text }) {
  return (
    <button
      onClick={handleOnClick}
      className="focus:outline-none text-white text-xs bg-primary border-1 border-primary rounded-8px px-3 py-2 shadow-blue-25"
    >
      {text}
    </button>
  );
}

//shared component
export function ObsPanel({ selected, onclick, elem }) {
  return (
    <button
      onClick={() => onclick(elem)}
      className={`${
        selected
          ? "bg-primary text-white"
          : "bg-grey-panel border border-border-panel text-grey-light"
      }  rounded-full h-12 w-12 flex items-center justify-center  focus:outline-none`}
    >
      <DropIcon />
    </button>
  );
}

//shared component
export function ElemPanel({ selected, children, onclick, elem }) {
  console.log(selected);
  return (
    <div className="relative inline-flex">
      <button
        onClick={() => onclick(elem)}
        className={`${
          selected
            ? "bg-transparent-blue-8% text-primary"
            : "bg-white border border-border-panel text-grey-light"
        }  rounded-full h-10 w-10 flex items-center justify-center  focus:outline-none`}
      >
        <p
          className={`${
            selected ? "text-primary" : "text-grey-lighter"
          } text-xs font-bold `}
        >
          {children}
        </p>
      </button>
    </div>
  );
}

//a button that launches a process that takes time
export function GreenProtocolButton({ active, text, handleClick, loading }) {
  return (
    <button
      onClick={handleClick}
      className={`${
        active
          ? "bg-success text-white "
          : "bg-grey-text bg-opacity-8 text-grey-text text-opacity-65"
      } flex items-center justify-center px-2 rounded-8px font-medium text-xs focus:outline-none`}
    >
      <FilterIcon className="h-4 mr-1" />
      {text}
      <ArrowDownIcon className="ml-1 mr-1" />
      {loading && <FacebookCircularProgress />}
    </button>
  );
}

function FacebookCircularProgress(props) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => "transparent",
        }}
        size={20}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "white" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={20}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

export function FullWidthSecondaryButton({ handleOnClick, children }) {
  return (
    <button
      className="w-full py-2 bg-secondary text-white font-medium rounded-8px"
      onClick={handleOnClick}
    >
      {children}
    </button>
  );
}
