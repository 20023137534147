/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import StatCardsSection from "../../sharedComponents/StatCardsSection/StatCardsSection";
import ReactECharts from "echarts-for-react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { ReactComponent as ShowChartIcon } from "../../icons/show-chart.svg";
import { ReactComponent as HorizentalDotsIcon } from "../../icons/horizental-dots.svg";
import { ReactComponent as DeleteIcon } from "../../icons/delete.svg";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { ReactComponent as TestTubeIcon } from "../../icons/test-tube.svg";
import { ReactComponent as BofEmogyIcon } from "../../icons/bof-emogy.svg";
import { ReactComponent as ClapIcon } from "../../icons/clap.svg";
import { ReactComponent as InfoFilledIcon } from "../../icons/info-filled.svg";
import { ReactComponent as WarningFilledIcon } from "../../icons/warning-filled.svg";
import { ReactComponent as ErrorFilledIcon } from "../../icons/error-filled.svg";
import { ReactComponent as CheckFilledIcon } from "../../icons/check.svg";
import { ReactComponent as SimpleHomeIcon } from "../../icons/simple-home.svg";
import { ReactComponent as LastGraphPointIcon } from "../../icons/last-graph-point.svg";
import { ReactComponent as EmptyPostsIcon } from "../../icons/empty-posts.svg";
import { ReactComponent as ExtendIcon } from "../../icons/extend.svg";
import { ReactComponent as ArrowDownIcon } from "../../icons/arrow-down.svg";
import { ReactComponent as ArrowSkeletonComplete } from "../../icons/arrow-skeleton-complete.svg";
import { ReactComponent as DownloadFileIcon } from "../../icons/download-file.svg";
import { ReactComponent as ArrowRightIcon } from "../../icons/arrow-right.svg";
import { ReactComponent as ImageIcon } from "../../icons/image.svg";
import { ReactComponent as FileIcon } from "../../icons/file.svg";
import { ReactComponent as AddIcon } from "../../icons/add.svg";
import { ReactComponent as LandscapeIcon } from "../../icons/landscape.svg";
import { ReactComponent as DownloadIcon } from "../../icons/download.svg";
import { ReactComponent as GearSkeletonIcon } from "../../icons/gear-skeleton.svg";
import { ReactComponent as CalendarIcon } from "../../icons/calendar.svg";
import ListSubheader from "@mui/material/ListSubheader";
import Divider from "@mui/material/Divider";
import { useSearchParameters } from "../../hooks/useSearchParameters";
import { ElemPanel } from "../../Shared/Butttons";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { LinearProgress } from "@mui/material";
import {
  getPosts,
  setDashboardChartSelectedDate,
  setDashboardFeedSelectedDate,
  setIsMobile,
  setAddPostDialogOpen,
  setSelectedTab,
} from "../../Redux/actions";
import { MenuItem } from "@material-ui/core";
import { Menu } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import CircularProgress from "@mui/material/CircularProgress";
import "emoji-mart/css/emoji-mart.css";
import screenfull from "screenfull";
import * as PDFJS from "pdfjs-dist/legacy/build/pdf";
import cloneDeep from "lodash.clonedeep";
import Truncate from "react-truncate";
import { useMediaQuery } from "react-responsive";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SwipeableViews from "react-swipeable-views";
import {
  CssTextField,
  SimpleMenuStyles,
} from "../../Shared/Selects/selectsStyles";

import MomentDateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DatePicker";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { styled } from "@mui/material/styles";
import { CustomSwitch } from "../../Shared/Switches/CustomSwitch";
import { CustomMenu } from "../../Shared/Menues/CustomMenu";
import {
  SkeletonGreybgButton,
  SkeletonSquareGreybgButton,
  FilledGreybgButton,
  CirclehoverButton,
  FullWidthSecondaryButton,
} from "../../Shared/Butttons";
import { CSVLink } from "react-csv";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import WireframeSection from "../../Shared/Wireframes/postWireframes";
import {
  BorderPrimaryFullWidthButton,
  PrimaryFullWidthButton,
} from "../../Shared/Butttons";
import { WithHocSelectableButton } from "../../Shared/Buttons/HOCS";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { useSnackbar } from "notistack";
import { applyUTCtoDate, formateDate } from "../../utils/date";
import {
  transformUnit,
  transformValueWithePreferncesUnit,
} from "../../utils/preferences";
PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.js`;

const tagsNames = [
  "panne",
  "pompe",
  "eau brut",
  "eau filtrée",
  "sondes",
  "analyseurs",
  "chromato-ionique",
  "data connexion",
  "riviére",
];

const dataTypesNames = ["raw data", "validated data"];

const cadencesNames = ["minute", "10minutes", "hour", "day", "all"];

const DEFAULT_SERIES = {
  showAllSymbol: true,
  type: "scatter",
  data: [],
  smooth: true,
  symbolSize: 4,
  large: true,
  largeThreshold: 3000,
  lineStyle: {
    color: "#3D80FB",
    width: 1,
  },
};

const AntTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#FF7715",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#FF7715",
      fontWeight: 700,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="h-full"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="h-full">{children}</div>}
    </div>
  );
}

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "8px",
  "&:not(:last-child)": {
    borderBottom: 0,
    marginBottom: "5px",
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  dispatch(setIsMobile(isMobile));
  return isMobile ? children : null;
};

const DashboardPage = () => {
  const selectedTabIndex = useSelector((state) => state.selectedTab);
  const dispatch = useDispatch();

  const handleTabChange = (event, newValue) => {
    dispatch(setSelectedTab(newValue));
  };

  const handleChangeIndex = (index) => {
    dispatch(setSelectedTab(index));
  };

  return (
    <>
      <Desktop>
        <div className="pl-16 pt-18 w-screen h-screen">
          <StatCardsSection />
          <div className="grid grid-cols-12 gap-2 h-full pl-2 relative max-h-full">
            <div className="col-span-7 rounded-8px p-1 " id="chartSection">
              <ChartSection />
            </div>
            <div className="col-span-5 overflow-auto pt-1" id="AnnexSection">
              <AnnexSection />
            </div>
          </div>
        </div>
      </Desktop>
      <Tablet>
        <div className="pl-16 pt-20 w-screen h-screen">
          <StatCardsSection />
          <div className="grid grid-cols-12 gap-2 h-full pl-2 relative max-h-full">
            <div className="col-span-7 rounded-8px p-1 " id="chartSection">
              <ChartSection />
            </div>
            <div className="col-span-5 overflow-auto pt-1" id="AnnexSection">
              <AnnexSection />
            </div>
          </div>
        </div>
      </Tablet>
      <Mobile>
        <div className="pt-18 pb-14 h-full w-full flex flex-col">
          <StatCardsSection />
          <AntTabs
            variant="fullWidth"
            value={selectedTabIndex}
            onChange={handleTabChange}
            aria-label="ant example"
            className="h-6 w-full flex items-center justify-center"
          >
            <AntTab label="Courbe" className="focus:outline-none " />
            <AntTab label="Publications" className="focus:outline-none " />
          </AntTabs>
          <SwipeableViews
            index={selectedTabIndex}
            onChangeIndex={handleChangeIndex}
            className="flex-grow"
            containerStyle={{ height: "100%" }}
          >
            <TabPanel value={selectedTabIndex} index={0} className="h-full">
              <div className="h-full" id="chartSection">
                <ChartSection />
              </div>
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={1} className="h-full">
              <div className=" overflow-auto" id="AnnexSection">
                <AnnexSection />
              </div>
            </TabPanel>
          </SwipeableViews>
        </div>
      </Mobile>
    </>
  );
};
const applyUTCtoDataxis = (
  dataAxis,
  utcPrefrence,
  unit,
  valueToFormat,
  userPreferencesInfo,
  param
) => {
  if (!dataAxis) return dataAxis;

  return dataAxis.map(([date, value]) => [
    applyUTCtoDate(date, utcPrefrence),
    transformUnit(userPreferencesInfo, unit) !== unit
      ? transformValueWithePreferncesUnit(
          unit,
          valueToFormat,
          transformUnit(userPreferencesInfo, unit),
          param
        )
      : value,
  ]);
};

//  CHART SECTION COMPONENTS
function ChartSection() {
  const [data, setData] = useState(null);
  const userPreferencesInfo = useSelector((state) => state.userPreferences);
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);
  const [chartEvent, setchartEvent] = useState({ click: onChartClick });
  const [echartRef, setEchartRef] = useState(null);
  const [range, setrange] = useState({
    startDate: moment(),
    endDate: moment(),
  });
  const dispatch = useDispatch();
  const intl = useIntl();
  const [active, setactive] = useState(null);
  const [chosenValue, setchosenValue] = useState(
    intl.formatMessage({
      id: "dashboard.chart.param27",
    })
  );
  const [option, setOption] = useState({
    option: {
      grid: {
        bottom: 65,
        left: 20,
        right: 10,
        top: 20,
      },
      legend: {},
      title: {
        top: -4,
        left: "center",
        textStyle: {
          color: "#6B7CA5",
          fontSize: 13,
          fontWeight: 500,
        },
      },
      toolbox: {
        shiw: false,
        top: -4,
        itemSize: 14,
        emphasis: {
          iconStyle: {
            textBackgroundColor: "rgba(61, 128, 251, 0.15)",
            textBorderRadius: 8,
            textPadding: 5,
            textAlign: "right",
          },
        },
        feature: {
          dataZoom: {},
        },
      },
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "cross",
        },
      },
      yAxis: {
        type: "value",
        boundaryGap: true,
        scale: true,
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: true },
        axisLabel: {
          rotate: 90,
          align: "bottom",
          margin: 4,
        },
      },
      xAxis: {
        type: "time",
        boundaryGap: false,
        splitLine: {
          show: true,
        },
        axisLine: { show: true },
      },
      dataZoom: [
        {
          type: "inside",
          id: "insidex",
          xAxisIndex: 0,
          zoomOnMouseWheel: false,
          moveOnMouseMove: true,
          moveOnMouseWheel: true,
          start: 0,
          end: 100,
        },
        {
          type: "slider",
          id: "sliderx",
          xAxisIndex: 0,
          zoomLock: false,
          height: 30,
          showDetail: false,
          realtime: true, // maybe disable on large dataset to avoid rendering issue
          fillerColor: "rgba(47,69,84,0.25)",
          dataBackground: {
            lineStyle: { color: "#3D80FB", width: 2, opacity: 1 },
            areaStyle: { color: "#3D80FB", opacity: 0.15 },
          },
          start: 0,
          end: 100,
        },
      ],
      series: [
        {
          id: "chartLine",
          showAllSymbol: true,
          type: "scatter",
          data: [],
          smooth: true,
          symbolSize: 3,
          large: true,
          largeThreshold: 3000,
          lineStyle: {
            color: "#3D80FB",
            width: 1,
          },
          itemStyle: {
            color: "#3D80FB",
          },
        },
      ],
    },
    notMerge: false,
  });
  const [config, setconfig] = useState({
    connectNulls: false,
    yAxisZoom: false,
    autoScale: true,
    yaxis: {
      min: { show: true, value: "dataMin" },
      max: { show: true, value: "dataMax" },
      boundaryGap: { show: false, value: 20 },
    },
    toolbox: { saveAsImage: false, dataZoom: true, dataView: false },
    markpoint: { min: false, max: false, average: false },
    type: "scatter",
  });
  const [live, setlive] = useState(null);
  const newPostsList = useSelector((state) => state.posts);
  const language = useSelector((state) => state.language);
  const [interventionsList, setinterventionsList] = useState(null);
  const [showintervention, setShowIntervention] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (interventionsList && showintervention) {
      setOption((prev) => {
        let newOption = cloneDeep(prev);
        newOption.option.series[0].markArea = {
          itemStyle: {
            color: "rgba(255, 173, 177, 0.4)",
          },
          data: interventionsList,
        };
        return newOption;
      });
    }
    if (interventionsList && !showintervention) {
      setOption((prev) => {
        let newOption = cloneDeep(prev);
        newOption.option.series[0].markArea = {
          itemStyle: {
            color: "rgba(255, 173, 177, 0.4)",
          },
          data: [],
        };
        return newOption;
      });
    }
  }, [interventionsList, showintervention]);

  useEffect(() => {
    if (newPostsList) {
      let temp = [];
      newPostsList.forEach((post) => {
        if (
          post.intervention &&
          post.post_start &&
          post.post_end &&
          post.post_start !== "NaT" &&
          post.post_end !== "NaT"
        ) {
          temp.push([{ xAxis: post.post_start }, { xAxis: post.post_end }]);
        }
      });
      setinterventionsList(temp);
    } else {
      setinterventionsList(null);
    }
  }, [newPostsList]);

  function onLastPointClick() {
    setrange({ startDate: moment(), endDate: moment() });
  }
  function onChartClick(ev) {
    dispatch(setDashboardChartSelectedDate(ev.value[0]));
  }
  function setChartData(start, end) {
    setData(null);
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/observatories/qualified/${currentSelectedCard?.riverlab}/elements/${currentSelectedCard?.element}/from/${start}/to/${end}`
      )
      .then((res) => {
        setOption((prev) => {
          return {
            ...prev,
            notMerge: true,
            option: {
              ...prev.option,
              series: [
                {
                  ...DEFAULT_SERIES,
                  data: applyUTCtoDataxis(
                    res.data.y,
                    userPreferencesInfo?.time,
                    currentSelectedCard.unit,
                    currentSelectedCard.value,
                    userPreferencesInfo,
                    currentSelectedCard.label
                  ),
                  name: intl.formatMessage({
                    id: "dashboard.chart.param40",
                  }),
                  type: config.type,
                  itemStyle: { color: "#52BE80" },
                },
                {
                  ...DEFAULT_SERIES,
                  data: applyUTCtoDataxis(
                    res.data.y_raw,
                    userPreferencesInfo?.time,
                    currentSelectedCard.unit,
                    currentSelectedCard.value,
                    userPreferencesInfo,
                    currentSelectedCard.label
                  ),
                  name: intl.formatMessage({
                    id: "dashboard.chart.param41",
                  }),
                  type: config.type,
                  itemStyle: { color: "#3498DB" },
                },
                {
                  ...DEFAULT_SERIES,
                  data: applyUTCtoDataxis(
                    res.data.y_red,
                    userPreferencesInfo?.time,
                    currentSelectedCard.unit,
                    currentSelectedCard.value,
                    userPreferencesInfo,
                    currentSelectedCard.label
                  ),
                  name: intl.formatMessage({
                    id: "dashboard.chart.param42",
                  }),
                  type: "scatter",
                  itemStyle: { color: "#E74C3C" },
                },
              ],
            },
          };
        });
        setData(res.data);
      })
      .catch((err) => {
        console.error("err", err);
        enqueueSnackbar(
          intl.formatMessage({
            id: "dashboard.annex.param19",
          }),
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }
        );
      });
  }
  useEffect(() => {
    if (data) {
      setOption((prev) => {
        return {
          ...prev,
          notMerge: true,
          option: {
            ...prev.option,
            series: [
              {
                ...DEFAULT_SERIES,
                data: applyUTCtoDataxis(
                  data.y,
                  userPreferencesInfo?.time,
                  currentSelectedCard.unit,
                  currentSelectedCard.value,
                  userPreferencesInfo,
                  currentSelectedCard.label
                ),
                name: intl.formatMessage({
                  id: "dashboard.chart.param40",
                }),
                type: config.type,
                itemStyle: { color: "#52BE80" },
              },
              {
                ...DEFAULT_SERIES,
                data: applyUTCtoDataxis(
                  data.y_raw,
                  userPreferencesInfo?.time,
                  currentSelectedCard.unit,
                  currentSelectedCard.value,
                  userPreferencesInfo,
                  currentSelectedCard.label
                ),
                name: intl.formatMessage({
                  id: "dashboard.chart.param41",
                }),
                type: config.type,
                itemStyle: { color: "#3498DB" },
              },
              {
                ...DEFAULT_SERIES,
                data: applyUTCtoDataxis(
                  data.y_red,
                  userPreferencesInfo?.time,
                  currentSelectedCard.unit,
                  currentSelectedCard.value,
                  userPreferencesInfo,
                  currentSelectedCard.label
                ),
                name: intl.formatMessage({
                  id: "dashboard.chart.param42",
                }),
                type: "scatter",
                itemStyle: { color: "#E74C3C" },
              },
            ],
          },
        };
      });
    }
  }, [data, language]);
  useEffect(() => {
    setchosenValue(
      intl.formatMessage({
        id: "dashboard.chart.param27",
      })
    );
  }, [language]);
  return (
    <div className="bg-white rounded-8px p-1 pb-0 h-full">
      <div className="h-full flex flex-col">
        <div className="h-8 flex justify-between">
          <div className="flex gap-2">
            <DateSelectionSection
              setChartData={setChartData}
              live={live}
              setlive={setlive}
              setOption={setOption}
              range={range}
              setrange={setrange}
              active={active}
              setactive={setactive}
              chosenValue={chosenValue}
              setchosenValue={setchosenValue}
            />
            {live && (
              <div className="flex justify-between w-18 p-1 border-1 border-primary border-opacity-20 rounded-8px">
                <p className="font-bold text-error ml-1">
                  <FormattedMessage id="dashboard.chart.param45" />
                </p>
                <span className="flex h-2 w-2 relative mr-1 mt-1">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-error opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-2 w-2 bg-error"></span>
                </span>
              </div>
            )}
          </div>
          <div className="flex gap-2 items-center">
            <div className="flex rounded-8px border-1 border-primary border-opacity-8 items-center pl-2">
              <p className="text-xs text-grey-lighter">
                <FormattedMessage id="dashboard.chart.param4" />
              </p>
              <CustomSwitch
                size="small"
                selected={showintervention}
                handleChange={(name, checked) => {
                  setShowIntervention((prev) => {
                    return !prev;
                  });
                }}
              />
            </div>
            <DownloadSection rangeprop={range} />
            <ChartConfigSection
              setOption={setOption}
              realtime={false}
              config={config}
              setconfig={setconfig}
            />
            <button
              className=" focus:outline-none h-full px-2 flex text-primary flex items-center justify-center border-1 border-primary border-opacity-15 rounded-8px"
              onClick={() => {
                onLastPointClick();
              }}
            >
              <LastGraphPointIcon className="transform rotate-90 text-primary h-4 w-4 mr-2" />
              <Desktop>
                <p className="font-medium text-xs">
                  <FormattedMessage id="dashboard.chart.param6" />
                </p>
              </Desktop>
            </button>
          </div>
        </div>
        <div className="flex-grow relative">
          {!data && (
            <div className="text-3xl text-primary-3 flex flex-col items-center justify-center h-full w-full">
              <FormattedMessage id="dashboard.chart.param44" />
              <p className="text-dark text-lg block mb-3">
                <FormattedMessage id="dashboard.chart.param43" />
              </p>
              <CircularProgress />
            </div>
          )}
          {data &&
            (!("y_raw" in data) ||
            (data.y_raw.length === 0 &&
              data.y.length === 0 &&
              data.y_red.length === 0) ? (
              <div className="flex items-center justify-center h-full w-full">
                <div className="text-center">
                  <p className="text-xl text-primary font-medium">
                    <FormattedMessage id="dashboard.chart.param47" />
                  </p>
                </div>
              </div>
            ) : (
              <ReactECharts
                ref={(e) => {
                  setEchartRef(e);
                }}
                option={option.option}
                style={{ height: "100%", width: "100%" }}
                onEvents={chartEvent}
                notMerge={option.notMerge}
                className="absolute top-0 bottom-0 left-0 right-0"
              />
            ))}
        </div>
      </div>
    </div>
  );
}

function DateSelectionSection({
  setChartData,
  live,
  setlive,
  setOption,
  range,
  setrange,
  active,
  setactive,
  chosenValue,
  setchosenValue,
}) {
  const userPreferencesInfo = useSelector((state) => state.userPreferences);
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);
  const socket = useSelector((state) => state.socket);
  const dispatch = useDispatch();
  const dashboardFeedSelectedDate = useSelector(
    (state) => state.dashboardFeedSelectedDate
  );
  const [anchorEl, setAnchorEl] = useState(null);

  //Remove live mode listeners on unmount
  useEffect(() => {
    return () => {
      if (socket) {
        socket.removeAllListeners("dynamic_graph");
        socket.emit("leave", "dynamic_graph", () => {});
      }
    };
  }, []);

  function checkScale(value, range) {
    if (["Hier", "Aujourd'hui", "Yesterday", "Today"].includes(value)) {
      return { length: 1, type: "day" };
    } else if (
      [
        "cette semaine",
        "la semaine derniére",
        "this week",
        "last week",
      ].includes(value)
    ) {
      return { length: 1, type: "week" };
    } else if (
      ["ce mois", "le mois dernier", "this month", "last month"].includes(value)
    ) {
      return { length: 1, type: "month" };
    } else if (
      ["cette année", "l'année derniére", "This year", "Last year"].includes(
        value
      )
    ) {
      return { length: 1, type: "year" };
    } else if (
      [
        "Personaliser la période",
        "All data",
        "Customize the period",
        "All data",
      ]
    ) {
      let diff = range.startDate.diff(range.endDate, "days");
      return { length: diff, type: "day" };
    }
  }

  //when post clicked
  useEffect(() => {
    if (dashboardFeedSelectedDate) {
      if (live) {
        setlive(false);
      }
      if (
        range.startDate.isAfter(moment(dashboardFeedSelectedDate)) |
        range.endDate.isBefore(moment(dashboardFeedSelectedDate))
      ) {
        let scale = checkScale(chosenValue, range);
        let start = null;
        let end = null;
        if (scale.length === 1) {
          start = moment(dashboardFeedSelectedDate).startOf(scale.type);
          end = moment(dashboardFeedSelectedDate).endOf(scale.type);
        } else {
          start = moment(dashboardFeedSelectedDate)
            .subtract(Math.floor(Math.abs(scale.length) / 2), "days")
            .startOf("day");
          end = moment(dashboardFeedSelectedDate)
            .add(Math.floor(Math.abs(scale.length) / 2), "days")
            .endOf("day");
        }
        setrange({ startDate: start, endDate: end });
        setchosenValue(
          intl.formatMessage({
            id: "dashboard.chart.param35",
          })
        );
      }
      setTimeout(
        () =>
          setOption((prev) => {
            const newOption = cloneDeep(prev);
            newOption.option.notMerge = false;
            newOption.option.series[0].markLine = {
              data: [{ name: "Chosen date", xAxis: dashboardFeedSelectedDate }],
              silent: false,
              label: {
                show: true,
                position: "middle",
                color: "#FC610A",
              },
              lineStyle: {
                color: "#FC610A",
              },
            };
            return newOption;
          }),
        [1000]
      );
      dispatch(setDashboardFeedSelectedDate(null));
    }
  }, [dashboardFeedSelectedDate, range, chosenValue]);

  //when range or currentSelectedCard Changes exept on live mode on
  useEffect(() => {
    if (currentSelectedCard && !live && !active) {
      let start = range.startDate.startOf("day").format("YYYY-MM-DD HH:mm:ss");
      let end = range.endDate.endOf("day").format("YYYY-MM-DD HH:mm:ss");
      setChartData(
        applyUTCtoDate(start, userPreferencesInfo?.time?.replace("+", "-")),
        applyUTCtoDate(end, userPreferencesInfo?.time?.replace("+", "-"))
      );
    }
  }, [
    range,
    currentSelectedCard?.element,
    currentSelectedCard?.riverlab,
    active,
    userPreferencesInfo,
  ]);
  const intl = useIntl();
  //if live mode is on add listener and join dynamic graph room
  useEffect(() => {
    if (live === true) {
      //disable proto
      setactive(false);
      //empty chart data
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        newOption.notMerge = true;
        delete newOption.option.series[0].markLine;
        delete newOption.option.dataZoom;
        newOption.option.series = [];
        newOption.option.series.push({
          id: "chartLine",
          showAllSymbol: true,
          type: "scatter",
          data: [],
          smooth: true,
          symbolSize: 5,
          lineStyle: {
            color: "#3D80FB",
            width: 1,
          },
          itemStyle: {
            color: "#3D80FB",
          },
        });
        newOption.option.title.text =
          currentSelectedCard.element +
          intl.formatMessage({
            id: "dashboard.chart.param46",
          }) +
          currentSelectedCard.riverlab +
          "(" +
          currentSelectedCard.unit +
          ")";
        newOption.option.grid.bottom = 20;
        return newOption;
      });
      //sign on to modifs
      socket &&
        socket.on("dynamic_graph", (res) => {
          listener(res);
        });
      //emit join
      socket && socket.emit("join", "dynamic_graph", () => {});
    }
    if (live === false) {
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        newOption.notMerge = true;
        newOption.option.title.text = "";
        return newOption;
      });
      restoreXAxisDataZoom();
      socket && socket.removeAllListeners("dynamic_graph");
      socket && socket.emit("leave", "dynamic_graph", () => {});
    }
  }, [live]);

  //when currentSelectedCard changes and live mode is on
  useEffect(() => {
    if (currentSelectedCard && live) {
      socket.emit("changeactiveelement", {
        riverlab: currentSelectedCard.riverlab,
        element: currentSelectedCard.element,
      });
      //empty chart data
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        newOption.notMerge = true;
        delete newOption.option.series[0].markLine;
        delete newOption.option.dataZoom;
        newOption.option.series[0].data = [];
        return newOption;
      });
    }
  }, [currentSelectedCard]);

  function restoreXAxisDataZoom() {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.notMerge = false;
      newOption.option.grid.bottom = 65;
      newOption.option.dataZoom = [
        {
          type: "inside",
          id: "insidex",
          xAxisIndex: 0,
          zoomOnMouseWheel: false,
          moveOnMouseMove: true,
          moveOnMouseWheel: true,
          start: 0,
          end: 100,
        },
        {
          type: "slider",
          id: "sliderx",
          xAxisIndex: 0,
          zoomLock: false,
          height: 30,
          showDetail: false,
          realtime: true, // maybe disable on large dataset to avoid rendering issue
          fillerColor: "rgba(47,69,84,0.25)",
          dataBackground: {
            lineStyle: { color: "#3D80FB", width: 2, opacity: 1 },
            areaStyle: { color: "#3D80FB", opacity: 0.15 },
          },
          start: 0,
          end: 100,
        },
      ];

      return newOption;
    });
  }

  //dynamic_graph listener when live mode is on
  function listener(res) {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.notMerge = false;
      newOption.option.series[0].data.push([
        moment().format("YYYY-MM-DD HH:mm:ss"),
        res.value,
      ]);
      return newOption;
    });
  }

  // handle menu click
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <DateSelectionButton text={chosenValue} handleClick={handleClick} />
      <CustomMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <MenuContent
          chosenValue={chosenValue}
          setchosenValue={setchosenValue}
          setlive={setlive}
          live={live}
          setChartData={setChartData}
          setOption={setOption}
          range={range}
          setrange={setrange}
          setAnchorEl={setAnchorEl}
        />
      </CustomMenu>
    </>
  );
}
function MenuContent({
  chosenValue,
  setchosenValue,
  setlive,
  live,
  setrange,
  range,
  setAnchorEl,
}) {
  const intl = useIntl();
  //handle select change
  function handleChange(event) {
    setchosenValue((prev) => {
      if (prev === "Live" || prev === "Directe") {
        setlive(false);
      }
      return event.target.value;
    });
    switch (event.target.value) {
      case intl.formatMessage({
        id: "dashboard.chart.param26",
      }): {
        let startDate = moment().subtract(1, "days");
        setrange({ startDate, endDate: startDate });
        break;
      }
      case intl.formatMessage({
        id: "dashboard.chart.param27",
      }): {
        let startDate = moment();
        setrange({ startDate, endDate: startDate });
        break;
      }
      case "Demain": {
        let startDate = moment().add(1, "days");
        setrange({ startDate, endDate: startDate });
        break;
      }
      case intl.formatMessage({
        id: "dashboard.chart.param29",
      }): {
        let startDate = moment().startOf("week");
        let endDate = moment().endOf("week");
        setrange({ startDate, endDate });
        break;
      }
      case intl.formatMessage({
        id: "dashboard.chart.param30",
      }): {
        let startDate = moment().subtract(1, "weeks").startOf("week");
        let endDate = moment().subtract(1, "weeks").endOf("week");
        setrange({ startDate, endDate });
        break;
      }
      case intl.formatMessage({
        id: "dashboard.chart.param31",
      }): {
        let startDate = moment().startOf("month");
        let endDate = moment().endOf("month");
        setrange({ startDate, endDate });
        break;
      }
      case intl.formatMessage({
        id: "dashboard.chart.param32",
      }): {
        let startDate = moment().subtract(1, "months").startOf("month");
        let endDate = moment().subtract(1, "months").endOf("month");
        setrange({ startDate, endDate });
        break;
      }
      case intl.formatMessage({
        id: "dashboard.chart.param33",
      }): {
        let startDate = moment().startOf("year");
        let endDate = moment().endOf("year");
        setrange({ startDate, endDate });
        break;
      }
      case intl.formatMessage({
        id: "dashboard.chart.param34",
      }): {
        let startDate = moment().subtract(1, "years").startOf("year");
        let endDate = moment().subtract(1, "years").endOf("year");
        setrange({ startDate, endDate });
        break;
      }
      case intl.formatMessage({
        id: "dashboard.chart.param35",
      }): {
        let startDate = moment().startOf("day");
        let endDate = moment().endOf("day");
        setrange({ startDate, endDate });
        break;
      }
      case intl.formatMessage({
        id: "dashboard.chart.param36",
      }): {
        let startDate = moment("2015-01-01").startOf("day");
        let endDate = moment();
        setrange({ startDate, endDate });
        break;
      }
      case intl.formatMessage({
        id: "dashboard.chart.param37",
      }): {
        setlive(true);
        setAnchorEl(null);
        break;
      }
      default:
      //do nothing
    }
  }

  function handleDateInputChange(newValue, name) {
    setrange((prev) => {
      let test = cloneDeep(prev);
      //if user chooses a start date bigger than the end date moove end date
      if (name === "startDate" && moment(newValue).isAfter(test.endDate)) {
        test.endDate = newValue;
      }
      test[name] = newValue;
      return test;
    });
    setchosenValue(
      intl.formatMessage({
        id: "dashboard.chart.param35",
      })
    );
  }
  return (
    <div className="p-2 h-full w-full">
      <CustomSelect chosenValue={chosenValue} handleChange={handleChange} />
      {!live && (
        <div className="flex items-center">
          <LocalizationProvider dateAdapter={MomentDateAdapter}>
            <DesktopDatePicker
              inputFormat="YYYY-MM-DD"
              label="Custom input"
              value={range.startDate}
              onChange={(newValue) => {
                handleDateInputChange(newValue, "startDate");
              }}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <div className="flex items-center">
                  <CssTextField
                    className="w-52 h-12"
                    variant="outlined"
                    ref={inputRef}
                    {...inputProps}
                    InputProps={{
                      endAdornment: InputProps?.endAdornment,
                    }}
                  />
                </div>
              )}
            />
          </LocalizationProvider>
          <p className="mx-2">to</p>
          <LocalizationProvider dateAdapter={MomentDateAdapter}>
            <DesktopDatePicker
              inputFormat="YYYY-MM-DD"
              minDate={range.startDate}
              label="Custom input"
              value={range.endDate}
              onChange={(newValue) => {
                handleDateInputChange(newValue, "endDate");
              }}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <div className="flex items-center">
                  <CssTextField
                    className="w-52 h-12"
                    variant="outlined"
                    ref={inputRef}
                    {...inputProps}
                    InputProps={{
                      endAdornment: InputProps?.endAdornment,
                    }}
                  />
                </div>
              )}
            />
          </LocalizationProvider>
        </div>
      )}
    </div>
  );
}

function ConfigDialog({
  open,
  setopen,
  config,
  setconfig,
  handleChangeConfig,
}) {
  function localHandleOnClickToolboxOption(id) {
    let newVal = null;
    setconfig((prev) => {
      let test = cloneDeep(prev);
      newVal = !test.toolbox[id];
      test.toolbox[id] = newVal;
      return test;
    });
    localHandleChangeConfig(id, newVal);
  }

  function localHandleOnClickMarkpoint(id) {
    let newVal = null;
    setconfig((prev) => {
      let test = cloneDeep(prev);
      newVal = !test.toolbox[id];
      test.markpoint[id] = newVal;
      return test;
    });
    localHandleChangeConfig(id, newVal);
  }

  function localHandleChangeConfig(name, value) {
    handleChangeConfig(name, value);
    setconfig((prev) => {
      let test = cloneDeep(prev);
      test[name] = value;
      return test;
    });
  }

  function handleChangeConfigYaxisValue(event) {
    switch (event.target.name) {
      case "yaxisMaxValue": {
        handleChangeConfig(event.target.name, event.target.value);
        setconfig((prev) => {
          let test = cloneDeep(prev);
          test.yaxis.max.value = event.target.value;
          return test;
        });
        break;
      }
      case "yaxisMinValue": {
        handleChangeConfig(event.target.name, event.target.value);
        setconfig((prev) => {
          let test = cloneDeep(prev);
          test.yaxis.min.value = event.target.value;
          return test;
        });
        break;
      }
      case "yaxisBoundaryGapValue": {
        handleChangeConfig(event.target.name, event.target.value);
        setconfig((prev) => {
          let test = cloneDeep(prev);
          test.yaxis.boundaryGap.value = event.target.value;
          return test;
        });
        break;
      }
      default:
      //do nothing
    }
  }

  function handleChangeConfigYaxisShow(name, value) {
    switch (name) {
      case "yaxisMaxShow": {
        setconfig((prev) => {
          let test = cloneDeep(prev);
          test.yaxis.max.show = value;
          return test;
        });
        handleChangeConfig(name, value);
        break;
      }
      case "yaxisMinShow": {
        setconfig((prev) => {
          let test = cloneDeep(prev);
          test.yaxis.min.show = value;
          return test;
        });
        handleChangeConfig(name, value);
        break;
      }
      case "yaxisBoundaryGapShow": {
        setconfig((prev) => {
          let test = cloneDeep(prev);
          test.yaxis.boundaryGap.show = value;
          return test;
        });
        handleChangeConfig(name, value);
        break;
      }
      default:
      //do nothing
    }
  }

  function localHandleOnClickPoint(id) {
    setconfig((prev) => {
      let test = cloneDeep(prev);
      test.type = id;
      return test;
    });
    localHandleChangeConfig(id, true);
  }

  const toolBoxOptionsList = Object.keys(config.toolbox).map((item) => {
    return (
      <FilledGreybgButton
        key={item}
        id={item}
        text={item}
        handleOnClick={localHandleOnClickToolboxOption}
        selected={config.toolbox[item]}
      />
    );
  });

  const markpointOptionsList = Object.keys(config.markpoint).map((item) => {
    return (
      <FilledGreybgButton
        key={item}
        id={item}
        text={item}
        handleOnClick={localHandleOnClickMarkpoint}
        selected={config.markpoint[item]}
      />
    );
  });

  const chartTypesList = ["scatter", "line", "bar"].map((item) => {
    return (
      <FilledGreybgButton
        key={item}
        id={item}
        text={item}
        handleOnClick={localHandleOnClickPoint}
        selected={config.type === item}
      />
    );
  });

  return (
    <Dialog
      maxWidth={"xs"}
      fullWidth={true}
      open={open}
      onClose={() => {
        setopen(false);
      }}
    >
      <DialogTitle>
        <FormattedMessage id="dashboard.chart.param7" />
      </DialogTitle>
      <DialogContent>
        <div className="flex">
          <p>
            {" "}
            <FormattedMessage id="dashboard.chart.param8" />
          </p>
          <CustomSwitch
            name="connectNulls"
            handleChange={localHandleChangeConfig}
            selected={config.connectNulls}
          />
        </div>
        <div className="flex">
          <p>
            {" "}
            <FormattedMessage id="dashboard.chart.param9" />
          </p>
          <CustomSwitch
            name="yAxisZoom"
            handleChange={localHandleChangeConfig}
            selected={config.yAxisZoom}
          />
        </div>
        <div className="flex">
          <p>
            {" "}
            <FormattedMessage id="dashboard.chart.param10" />
          </p>
          <CustomSwitch
            name="autoScale"
            handleChange={localHandleChangeConfig}
            selected={config.autoScale}
          />
        </div>
        <div>
          <p className="font-medium">
            {" "}
            <FormattedMessage id="dashboard.chart.param11" />
          </p>
          <div className="flex gap-1">{toolBoxOptionsList}</div>
        </div>
        <div className="mt-3">
          <p className="font-medium">
            {" "}
            <FormattedMessage id="dashboard.chart.param12" />
          </p>
          <p className="text-xs text-grey-light mb-1 ml-2">
            <FormattedMessage id="dashboard.chart.param13" />
          </p>
          <div className="flex gap-1">{markpointOptionsList}</div>
        </div>
        <div className="mt-3">
          <p className="font-medium">Yaxis config</p>
          <div className="flex items-center ml-2 mt-2">
            <p className="font-medium text-sm mr-3">
              {" "}
              <FormattedMessage id="dashboard.chart.param14" />
            </p>
            <div className="flex  items-center">
              <p className="text-xs text-grey-light">
                {" "}
                <FormattedMessage id="dashboard.chart.param15" />
              </p>
              <CustomSwitch
                name="yaxisBoundaryGapShow"
                handleChange={handleChangeConfigYaxisShow}
                selected={config.yaxis.boundaryGap.show}
              />
              <p className="text-xs text-grey-light">active</p>
            </div>
          </div>
          <p className="text-xs text-grey-light -mt-3 mb-2 ml-4 ">
            <FormattedMessage id="dashboard.chart.param16" />
          </p>
          {config.yaxis.boundaryGap.show && (
            <>
              <input
                className="ml-2 border-1 border-primary border-opacity-20 rounded-8px px-2 py-1 focus:outline-none"
                name="yaxisBoundaryGapValue"
                value={config.yaxis.boundaryGap.value}
                onChange={handleChangeConfigYaxisValue}
              />
              <p className="text-xs text-grey-light ml-4 ">
                <FormattedMessage id="dashboard.chart.param17" />
              </p>
            </>
          )}
          <div className="flex items-center ml-2 mt-2">
            <p className="font-medium text-sm mr-3">
              {" "}
              <FormattedMessage id="dashboard.chart.param18" />
            </p>
            <div className="flex  items-center">
              <p className="text-xs text-grey-light">
                {" "}
                <FormattedMessage id="dashboard.chart.param19" />
              </p>
              <CustomSwitch
                name="yaxisMaxShow"
                handleChange={handleChangeConfigYaxisShow}
                selected={config.yaxis.max.show}
              />
              <p className="text-xs text-grey-light">
                {" "}
                <FormattedMessage id="dashboard.chart.param20" />
              </p>
            </div>
          </div>
          {!config.yaxis.max.show && (
            <input
              className="ml-2 border-1 border-primary border-opacity-20 rounded-8px px-2 py-1 focus:outline-none"
              name="yaxisMaxValue"
              value={config.yaxis.max.value}
              onChange={handleChangeConfigYaxisValue}
            />
          )}
          <div className="flex items-center ml-2 mt-2">
            <p className="font-medium text-sm mr-3">
              {" "}
              <FormattedMessage id="dashboard.chart.param21" />
            </p>
            <div className="flex  items-center">
              <p className="text-xs text-grey-light">
                {" "}
                <FormattedMessage id="dashboard.chart.param22" />
              </p>
              <CustomSwitch
                name="yaxisMinShow"
                handleChange={handleChangeConfigYaxisShow}
                selected={config.yaxis.min.show}
              />
              <p className="text-xs text-grey-light">auto</p>
            </div>
          </div>
          {!config.yaxis.min.show && (
            <input
              className="ml-2 border-1 border-primary border-opacity-20 rounded-8px px-2 py-1 focus:outline-none"
              name="yaxisMinValue"
              value={config.yaxis.min.value}
              onChange={handleChangeConfigYaxisValue}
            />
          )}
        </div>
        <div className="mt-3">
          <p className="font-medium">Type</p>
          <p className="text-xs text-grey-light mb-1 ml-2">
            <FormattedMessage id="dashboard.chart.param23" />
          </p>
          <div className="flex gap-1 mt-2">{chartTypesList}</div>
        </div>
      </DialogContent>
      <DialogActions className="flex gap-1">
        <SkeletonGreybgButton
          handleOnClick={() => {
            setopen(false);
          }}
          id="close"
          selected={false}
        >
          <p>
            {" "}
            <FormattedMessage id="dashboard.chart.param24" />
          </p>
        </SkeletonGreybgButton>
      </DialogActions>
    </Dialog>
  );
}

function ChartConfigSection({ setOption, realtime, config, setconfig }) {
  const [open, setopen] = useState(false);

  function handleChangeConfig(name, value) {
    switch (name) {
      case "connectNulls":
        handleOnConnectNulls(value);
        break;
      case "yAxisZoom":
        handleOnYAxisClick(value);
        break;
      case "autoScale":
        handleAutoScale(value);
        break;
      //yaxis config
      case "yaxisBoundaryGapShow":
        handleYAxisBoundaryGapShow(value);
        break;
      case "yaxisBoundaryGapValue":
        handleYAxisBoundaryGapValue(value);
        break;
      case "yaxisMaxShow":
        handleYAxisMaxShow(value);
        break;
      case "yaxisMaxValue":
        handleYAxisMaxValue(value);
        break;
      case "yaxisMinShow":
        handleYAxisMinShow(value);
        break;
      case "yaxisMinValue":
        handleYAxisMinValue(value);
        break;
      //toolbox options
      case "saveAsImage":
        onToolboxSaveAsImage(value);
        break;
      case "dataZoom":
        onToolboxDataZoom(value);
        break;
      case "magicType":
        onToolboxMagicType(value);
        break;
      case "dataView":
        onToolboxDataView(value);
        break;
      //markpoint options
      case "min":
        handleOnClickMarkpoint(name, value);
        break;
      case "max":
        handleOnClickMarkpoint(name, value);
        break;
      case "average":
        handleOnClickMarkpoint(name, value);
        break;
      //chart type
      case "line":
        handleSetChartType(name);
        break;
      case "scatter":
        handleSetChartType(name);
        break;
      case "bar":
        handleSetChartType(name);
        break;
      default:
      //do nothing
    }
  }

  function handleSetChartType(name) {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.option.series.map((serie) => (serie.type = name));
      newOption.notMerge = false;
      return newOption;
    });
  }

  function handleYAxisMaxValue(value) {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.option.yAxis.max = value;
      newOption.notMerge = false;
      return newOption;
    });
  }

  function handleYAxisMinValue(value) {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.option.yAxis.min = value;
      newOption.notMerge = false;
      return newOption;
    });
  }

  function handleYAxisMaxShow(value) {
    if (!value) {
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        newOption.option.yAxis.max = "dataMax";
        newOption.notMerge = false;
        return newOption;
      });
    } else {
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        delete newOption.option.yAxis.max;
        newOption.notMerge = true;
        return newOption;
      });
    }
  }

  function handleYAxisMinShow(value) {
    if (!value) {
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        newOption.option.yAxis.min = "dataMin";
        newOption.notMerge = false;
        return newOption;
      });
    } else {
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        delete newOption.option.yAxis.min;
        newOption.notMerge = true;
        return newOption;
      });
    }
  }

  function handleYAxisBoundaryGapShow(value) {
    if (value) {
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        newOption.option.yAxis.boundaryGap = ["20%", "20%"];
        newOption.notMerge = false;
        return newOption;
      });
    } else {
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        delete newOption.option.yAxis.boundaryGap;
        newOption.notMerge = true;
        return newOption;
      });
    }
  }

  function handleYAxisBoundaryGapValue(value) {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.option.yAxis.boundaryGap = [value + "%", value + "%"];
      newOption.notMerge = true;
      return newOption;
    });
  }

  function handleOnConnectNulls(value) {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.option.series[0].connectNulls = value;
      newOption.notMerge = false;
      return newOption;
    });
  }

  function handleOnYAxisClick(value) {
    if (!realtime) {
      if (value) {
        //show yaxis datazoom
        setOption((prev) => {
          const newOption = cloneDeep(prev);
          newOption.option.grid.right = 45;
          newOption.option.dataZoom.push({
            type: "inside",
            id: "insidey",
            yAxisIndex: 0,
            zoomOnMouseWheel: false,
            moveOnMouseMove: true,
            moveOnMouseWheel: true,
            start: 0,
            end: 100,
          });
          newOption.option.dataZoom.push({
            type: "slider",
            id: "slidery",
            yAxisIndex: 0,
            zoomLock: false,
            showDetail: true,
            realtime: true, // maybe disable on large dataset to avoid rendering issue
            fillerColor: "rgba(47,69,84,0.25)",
            dataBackground: {
              lineStyle: { color: "#3D80FB", width: 2, opacity: 1 },
              areaStyle: { color: "#3D80FB", opacity: 0.15 },
            },
          });
          newOption.notMerge = false;
          return newOption;
        });
      } else {
        //hide yaxis datazoom
        setOption((prev) => {
          const newOption = cloneDeep(prev);
          newOption.option.grid.right = 10;
          let xZoom = newOption.option.dataZoom.filter((elem) => {
            return elem.id !== "insidey" && elem.id !== "slidery";
          });
          newOption.option.dataZoom = [...xZoom];
          newOption.notMerge = true;
          return newOption;
        });
      }
    }
  }

  function handleAutoScale(value) {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.option.yAxis.scale = value;
      newOption.notMerge = false;
      return newOption;
    });
  }

  function handleOnClickMarkpoint(id, value) {
    if (value) {
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        newOption.option.toolbox.show = true;
        if (!newOption.option.series[0]["markPoint"]) {
          newOption.option.series[0].markPoint = { data: [] };
        }
        newOption.option.series[0].markPoint.data.push({
          type: id,
          name: id,
          label: {
            formatter: function (name) {
              return name.value.toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              });
            },
          },
        });
        newOption.notMerge = false;
        return newOption;
      });
    } else {
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        newOption.option.toolbox.show = false;
        newOption.option.series[0].markPoint.data =
          newOption.option.series[0].markPoint.data.filter((elem) => {
            return elem.type !== id;
          });
        newOption.notMerge = true;
        return newOption;
      });
    }
  }

  function onToolboxDataView(value) {
    if (value) {
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        newOption.option.toolbox.show = true;
        newOption.option.toolbox.feature.dataView = { readOnly: false };
        newOption.notMerge = false;
        return newOption;
      });
    } else {
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        newOption.option.toolbox.show = false;
        delete newOption.option.toolbox.feature.dataView;
        newOption.notMerge = true;
        return newOption;
      });
    }
  }

  function onToolboxMagicType(value) {
    if (value) {
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        newOption.option.toolbox.show = true;
        newOption.option.toolbox.feature.magicType = {
          show: true,
          type: ["line", "bar"],
        };
        newOption.notMerge = false;
        return newOption;
      });
    } else {
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        newOption.option.toolbox.show = false;
        delete newOption.option.toolbox.feature.magicType;
        newOption.notMerge = true;
        return newOption;
      });
    }
  }

  function onToolboxSaveAsImage(value) {
    if (value) {
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        newOption.option.toolbox.show = true;
        newOption.option.toolbox.feature.saveAsImage = {};
        newOption.notMerge = false;
        return newOption;
      });
    } else {
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        newOption.option.toolbox.show = false;
        delete newOption.option.toolbox.feature.saveAsImage;
        newOption.notMerge = true;
        return newOption;
      });
    }
  }

  function onToolboxDataZoom(value) {
    if (value) {
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        newOption.option.toolbox.show = true;
        newOption.option.toolbox.feature.dataZoom = {};
        newOption.notMerge = false;
        return newOption;
      });
    } else {
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        newOption.option.toolbox.show = false;
        delete newOption.option.toolbox.feature.dataZoom;
        newOption.notMerge = true;
        return newOption;
      });
    }
  }

  return (
    <>
      <button
        onClick={() => setopen(true)}
        className=" focus:outline-none h-full px-2 flex text-primary flex items-center justify-center border-1 border-primary border-opacity-15 rounded-8px"
      >
        <GearSkeletonIcon className="h-3.5 w-3.5" />
      </button>
      <ConfigDialog
        setopen={setopen}
        open={open}
        config={config}
        setconfig={setconfig}
        handleChangeConfig={handleChangeConfig}
      />
    </>
  );
}

function DownloadSection({ rangeprop }) {
  const [open, setopen] = useState(false);
  return (
    <>
      <SkeletonSquareGreybgButton
        id="id"
        handleOnClick={() => setopen(true)}
        selected={false}
      >
        <div className="flex gap-1 items-center">
          <DownloadFileIcon className="h-3.5 w-3.5" />
          <p>
            <FormattedMessage id="dashboard.chart.param5" />
          </p>
        </div>
      </SkeletonSquareGreybgButton>
      <DownloadDialog setopen={setopen} open={open} rangeprop={rangeprop} />
    </>
  );
}

function DownloadDialog({ open, setopen, rangeprop }) {
  const [selectedCadence, setselectedCadence] = useState("10minutes");
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);
  const [selectedVariables, setselectedVariables] = useState([]);
  const [error, seterror] = useState(false);
  const [range, setrange] = useState(rangeprop);
  const [selecteddataType, setselecteddataType] = useState("raw data");
  const [data, setdata] = useState(null);
  const [loading, setloading] = useState(false);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [anions, cations, physico_chemicalParams, technicalParams] =
    useSearchParameters(
      currentSelectedCard ? currentSelectedCard.riverlab : null
    );

  useEffect(() => {
    if (currentSelectedCard) {
      setselectedVariables([currentSelectedCard.element]);
    }
  }, [currentSelectedCard]);

  useEffect(() => {
    setrange(rangeprop);
  }, [rangeprop]);

  function handleAddVariable(elem) {
    setdata(null);
    if (!selectedVariables.includes(elem)) {
      setselectedVariables((prev) => {
        let temp = Array.from(prev);
        temp.push(elem);
        return temp;
      });
    } else {
      if (selectedVariables.length > 1) {
        let temp = selectedVariables.filter((item) => {
          return item !== elem;
        });
        setselectedVariables(temp);
      } else {
        seterror(true);
      }
    }
  }

  const CedencesList = cadencesNames.map((name) => {
    return (
      <FilledGreybgButton
        key={name}
        id={name}
        text={intl.formatMessage({ id: name })}
        handleOnClick={() => {
          setselectedCadence(name);
          setdata(null);
        }}
        selected={name === selectedCadence ? true : false}
      />
    );
  });

  const dataTypeList = dataTypesNames.map((type) => {
    return (
      <FilledGreybgButton
        key={type}
        id={type}
        text={intl.formatMessage({ id: type })}
        handleOnClick={() => {
          setselecteddataType(type);
          setdata(null);
        }}
        selected={type === selecteddataType ? true : false}
      />
    );
  });
  const anionsList = anions
    ? anions.map((elem) => {
        return (
          <li
            key={elem.id}
            className="flex items-center flex-col gap-1 pr-2 text-center mb-1 text-grey-light font-normal hover:text-secondary hover:font-semibold hover:border-none transition ease-in-out duration-150"
          >
            <ElemPanel
              elem={elem}
              selected={selectedVariables.includes(elem.id) ? true : false}
              onclick={() => handleAddVariable(elem.id)}
            >
              {elem.formule_chimique.element}
              <sub>{elem.formule_chimique.indice}</sub>
              <sup className="z-0">{elem.formule_chimique.exposant}</sup>
            </ElemPanel>
            <p className="text-xs text-normal truncate w-14 font-medium">
              {elem.label}
            </p>
          </li>
        );
      })
    : [];

  const cationsList = cations
    ? cations.map((elem) => {
        return (
          <li
            key={elem.id}
            className="flex items-center flex-col gap-1 pr-2 text-center mb-1 text-grey-light font-normal hover:text-secondary hover:font-semibold hover:border-none transition ease-in-out duration-150"
          >
            <ElemPanel
              elem={elem}
              selected={selectedVariables.includes(elem.id) ? true : false}
              onclick={() => handleAddVariable(elem.id)}
            >
              {elem.formule_chimique.element}
              <sub>{elem.formule_chimique.indice}</sub>
              <sup className="z-0">{elem.formule_chimique.exposant}</sup>
            </ElemPanel>
            <p className="text-xs text-normal truncate w-14 font-medium">
              {elem.label}
            </p>
          </li>
        );
      })
    : [];

  const physico_chemicalParamsList = physico_chemicalParams
    ? physico_chemicalParams.map((elem) => {
        let paths = elem.svg.map((path, i) => {
          return <path key={i} d={path} />;
        });
        return (
          <li
            className="flex items-center flex-col gap-1 pr-2 text-center mb-1 text-grey-light font-normal hover:text-secondary hover:font-semibold hover:border-none transition ease-in-out duration-150"
            key={elem.id}
          >
            <ElemPanel
              elem={elem}
              selected={selectedVariables.includes(elem.id) ? true : false}
              onclick={() => handleAddVariable(elem.id)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 25"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="w-auto h-auto"
              >
                {paths}
              </svg>
            </ElemPanel>
            <p className="text-xs text-normal truncate w-14 font-medium">
              {elem.label}
            </p>
          </li>
        );
      })
    : [];

  const technicalParamsList = technicalParams
    ? technicalParams.map((elem) => {
        let paths = elem.svg.map((path, i) => {
          return <path key={i} d={path} />;
        });
        return (
          <li
            className="flex items-center flex-col gap-1 pr-2 text-center  mb-1 text-grey-light font-normal hover:text-secondary hover:font-semibold hover:border-none transition ease-in-out duration-150"
            key={elem.id}
          >
            <ElemPanel
              elem={elem}
              selected={selectedVariables.includes(elem.id) ? true : false}
              onclick={() => handleAddVariable(elem.id)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 25"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="w-auto h-auto"
              >
                {paths}
              </svg>
            </ElemPanel>
            <p className="text-xs text-normal truncate w-12 font-medium">
              {elem.label}
            </p>
          </li>
        );
      })
    : [];

  function handleDateInputChange(newValue, name) {
    setdata(null);
    setrange((prev) => {
      let test = cloneDeep(prev);
      //if user chooses a start date bigger than the end date moove end date
      if (name === "startDate" && moment(newValue).isAfter(test.endDate)) {
        test.endDate = newValue;
      }
      test[name] = newValue;
      return test;
    });
  }

  function getData() {
    const payload = {
      sampling_rate: selectedCadence,
      column_list: selectedVariables,
      date_start: range.startDate.startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      date_end: range.endDate.endOf("day").format("YYYY-MM-DD HH:mm:ss"),
    };
    setloading(true);
    axios
      .post(
        process.env.REACT_APP_API_URL +
          `/observatories/${currentSelectedCard.riverlab}/dataset${
            selecteddataType === "validated data" ? "/qualify" : ""
          } `,
        payload
      )
      .then((res) => {
        setdata(res.data);
        setloading(false);
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(
          intl.formatMessage({
            id: "dashboard.annex.param19",
          }),
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }
        );
      });
  }

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth={true}
      open={open}
      onClose={() => {
        setdata(null);
        setopen(false);
      }}
    >
      <DialogTitle className="border-b-1 border-light-grey-border border-opacity-20 flex gap-1 items-center">
        <DownloadFileIcon className="h-5 w-5 text-primary" />{" "}
        {intl.formatMessage({ id: "Télécharger les données de" })}{" "}
        {currentSelectedCard && (
          <p className="text-primary">{currentSelectedCard.riverlab}</p>
        )}
      </DialogTitle>
      <DialogContent
        className="divide-y divide-primary divide-opacity-15 p-0"
        sx={{ padding: 0 }}
      >
        <div className="px-8 py-4">
          <p className="text-lg text-dark-text font-medium mb-2">
            <FormattedMessage id="dashboard.annex.param16" />
          </p>
          <div className="flex gap-1">{dataTypeList}</div>
        </div>
        <div className="px-8 py-4">
          <p className="text-lg text-dark-text font-medium mb-2">
            <FormattedMessage id="protocol.SecondView.param37" />
          </p>
          <div className="flex gap-1">{CedencesList}</div>
        </div>
        <div className="px-8 py-4">
          <div className="mb-2">
            <p className="text-lg text-dark-text font-medium">
              <FormattedMessage id="protocol.SecondView.param38" />
            </p>
            {error && (
              <p className="text-xs text-error ">
                <FormattedMessage id="dashboard.annex.param15" />
              </p>
            )}
          </div>
          <div>
            <div className="mb-2">
              <p className="text-xs font-medium text-grey-light mb-2">
                anions :
              </p>
              <ul className="flex flex-wrap ml-2">{anionsList}</ul>
            </div>
            <div className="mb-2">
              <p className="text-xs font-medium text-grey-light mb-2">
                cations :
              </p>
              <ul className="flex flex-wrap">{cationsList}</ul>
            </div>
            <div className="mb-2">
              <p className="text-xs font-medium text-grey-light mb-2">
                pcparams :
              </p>
              <ul className="flex flex-wrap">{physico_chemicalParamsList}</ul>
            </div>
            <div className="my-2">
              <p className="text-xs font-medium text-grey-light mb-2">
                tparams :
              </p>
              <ul className="flex flex-wrap">{technicalParamsList}</ul>
            </div>
          </div>
        </div>
        <div className="px-8 py-4">
          <p className="text-lg text-dark-text font-medium mb-2">
            <FormattedMessage id="protocol.SecondView.param39" />
          </p>
          <div className="flex gap-1 items-center justify-center">
            <p className="text-primary text-md font-semibold">
              <FormattedMessage id="De" />
            </p>
            <LocalizationProvider dateAdapter={MomentDateAdapter}>
              <DesktopDatePicker
                inputFormat="YYYY-MM-DD"
                label="Custom input"
                value={range.startDate}
                onChange={(newValue) => {
                  handleDateInputChange(newValue, "startDate");
                }}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <div className="flex items-center">
                    <CssTextField
                      className="w-52 h-12"
                      variant="outlined"
                      ref={inputRef}
                      {...inputProps}
                      InputProps={{
                        endAdornment: InputProps?.endAdornment,
                      }}
                    />
                  </div>
                )}
              />
            </LocalizationProvider>
            <p className="text-primary text-md mx-4 font-semibold">
              <FormattedMessage id="protocol.firstView.param11" />
            </p>
            <LocalizationProvider dateAdapter={MomentDateAdapter}>
              <DesktopDatePicker
                inputFormat="YYYY-MM-DD"
                minDate={range.startDate}
                label="Custom input"
                value={range.endDate}
                onChange={(newValue) => {
                  handleDateInputChange(newValue, "endDate");
                }}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <div className="flex items-center">
                    <CssTextField
                      className="w-52 h-12"
                      variant="outlined"
                      ref={inputRef}
                      {...inputProps}
                      InputProps={{
                        endAdornment: InputProps?.endAdornment,
                      }}
                    />
                  </div>
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="px-8 py-4">
          {!data && !loading && (
            <FullWidthSecondaryButton handleOnClick={getData}>
              {intl.formatMessage({ id: "get data from DataBase" })}
            </FullWidthSecondaryButton>
          )}
          {loading && (
            <div>
              <LinearProgress />
              <p className="font-semibold flex justify-center">
                {intl.formatMessage({ id: "Getting data drom database" })} ...
              </p>
              <p className="text-secondary text-xs flex justify-center">
                {intl.formatMessage({
                  id: "The process may take a few seconds",
                })}
              </p>
            </div>
          )}
          {data && !loading && (
            <div>
              <div className="flex gap-1 justify-center items-center mb-2">
                <p className="font-semibold flex justify-center">
                  {intl.formatMessage({
                    id: "le processus peut prendre quelques secondes",
                  })}
                </p>
                <span className="ml-2 animate-bounce text-green">
                  <DownloadIcon />
                </span>
              </div>
              <CSVLink
                filename={`${
                  currentSelectedCard.riverlab
                }Data(${range.startDate.format(
                  "YYYY-MM-DD"
                )}to${range.endDate.format("YYYY-MM-DD")}).csv`}
                data={data}
                className="bg-success rounded-8px text-white py-2 flex justify-center items-center gap-2"
              >
                <DownloadFileIcon className="h-4 w-4" />
                Download me
              </CSVLink>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions className="flex gap-1">
        <SkeletonGreybgButton
          id="close"
          selected={false}
          handleOnClick={() => {
            setdata(null);
            setopen(false);
          }}
        >
          {intl.formatMessage({ id: "annuler" })}
        </SkeletonGreybgButton>
      </DialogActions>
    </Dialog>
  );
}

function CustomSelect({ chosenValue, handleChange }) {
  const intl = useIntl();
  return (
    <Select
      id="grouped-select"
      label="Grouping"
      value={chosenValue}
      onChange={handleChange}
      className="mb-2"
      input={<SimpleMenuStyles />}
    >
      <ListSubheader>
        <FormattedMessage id="dashboard.chart.param25" />
      </ListSubheader>
      <MenuItem
        value={intl.formatMessage({
          id: "dashboard.chart.param26",
        })}
      >
        <FormattedMessage id="dashboard.chart.param26" />
      </MenuItem>
      <MenuItem
        value={intl.formatMessage({
          id: "dashboard.chart.param27",
        })}
      >
        <FormattedMessage id="dashboard.chart.param27" />
      </MenuItem>
      <ListSubheader>
        <FormattedMessage id="dashboard.chart.param28" />
      </ListSubheader>
      <MenuItem
        value={intl.formatMessage({
          id: "dashboard.chart.param29",
        })}
      >
        <FormattedMessage id="dashboard.chart.param29" />
      </MenuItem>
      <MenuItem
        value={intl.formatMessage({
          id: "dashboard.chart.param30",
        })}
      >
        <FormattedMessage id="dashboard.chart.param30" />
      </MenuItem>
      <MenuItem
        value={intl.formatMessage({
          id: "dashboard.chart.param31",
        })}
      >
        <FormattedMessage id="dashboard.chart.param31" />
      </MenuItem>
      <MenuItem
        value={intl.formatMessage({
          id: "dashboard.chart.param32",
        })}
      >
        <FormattedMessage id="dashboard.chart.param32" />
      </MenuItem>
      <MenuItem
        value={intl.formatMessage({
          id: "dashboard.chart.param33",
        })}
      >
        <FormattedMessage id="dashboard.chart.param33" />
      </MenuItem>
      <MenuItem
        value={intl.formatMessage({
          id: "dashboard.chart.param34",
        })}
      >
        <FormattedMessage id="dashboard.chart.param34" />
      </MenuItem>
      <Divider />
      <MenuItem
        value={intl.formatMessage({
          id: "dashboard.chart.param35",
        })}
      >
        <FormattedMessage id="dashboard.chart.param35" />
      </MenuItem>
      <Divider />
      <MenuItem
        value={intl.formatMessage({
          id: "dashboard.chart.param36",
        })}
      >
        <FormattedMessage id="dashboard.chart.param36" />
      </MenuItem>
      <Divider />
      <MenuItem
        value={intl.formatMessage({
          id: "dashboard.chart.param37",
        })}
      >
        <FormattedMessage id="dashboard.chart.param37" />
      </MenuItem>
    </Select>
  );
}

//Shared
function DateSelectionButton({ text, handleClick }) {
  return (
    <button
      className="text-primary bg-primary bg-opacity-8 border-1 border-primary font-semibold p-1 flex rounded-8px items-center focus:outline-none"
      onClick={handleClick}
    >
      <CalendarIcon className="text-primary mr-1 h-4 w-4" />
      {text}
      <ArrowDownIcon className="text-primary ml-1" />
    </button>
  );
}

//  ANNEX SECTION COMPONENTS
function AnnexSection() {
  const [showNotifs, setShowNotifs] = useState(true);
  const [selectedFilteringTags, setselectedFilteringTags] = useState(null);
  const [selectedFilteringPostType, setSelectedFilteringPostType] = useState({
    signalement: false,
    intervention: false,
  });

  return (
    <div className="mx-2 md:mx-0 h-full flex flex-col">
      <AddPostSection showNotifs={showNotifs} />
      <FeedSectionParameters
        setShowNotifs={setShowNotifs}
        showNotifs={showNotifs}
        setselectedFilteringTags={setselectedFilteringTags}
        selectedFilteringTags={selectedFilteringTags}
        selectedFilteringPostType={selectedFilteringPostType}
        setSelectedFilteringPostType={setSelectedFilteringPostType}
      />
      <FeedSection
        showNotifs={showNotifs}
        selectedFilteringTags={selectedFilteringTags}
        selectedFilteringPostType={selectedFilteringPostType}
      />
    </div>
  );
}

function FeedSectionParameters({
  setShowNotifs,
  showNotifs,
  selectedFilteringTags,
  setselectedFilteringTags,
  selectedFilteringPostType,
  setSelectedFilteringPostType,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const intl = useIntl();

  function handleNtifs(name, checked) {
    setShowNotifs(checked);
  }

  function handleFilterWithTags(tag) {
    setSelectedFilteringPostType((prev) => {
      let copy = cloneDeep(prev);
      copy[tag] = !prev[tag];
      return copy;
    });
  }

  // handle menu click
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event) => {
    const tag = event.currentTarget.value;
    setselectedFilteringTags((prev) => {
      if (!prev) {
        prev = [];
      }
      let copy = cloneDeep(prev);
      if (copy.includes(tag)) {
        copy = copy.filter((elem) => {
          return elem !== tag;
        });
      } else {
        copy.push(tag);
      }
      if (copy.length === 0) {
        copy = null;
      }
      return copy;
    });
  };

  return (
    <div className="flex justify-between py-2 ">
      <div className="flex gap-1">
        <FilledGreybgButton
          selected={selectedFilteringPostType.signalement}
          text={
            intl
              .formatMessage({ id: "dashboard.annex.param5" })
              .charAt(0)
              .toUpperCase() +
            intl.formatMessage({ id: "dashboard.annex.param5" }).slice(1)
          }
          id="id"
          handleOnClick={() => {
            handleFilterWithTags("signalement");
          }}
        />
        <FilledGreybgButton
          selected={selectedFilteringPostType.intervention}
          text="intervention"
          id="id"
          handleOnClick={() => {
            handleFilterWithTags("intervention");
          }}
        />
        <button
          className="text-xs font-medium flex items-center gap-1 rounded-8px border-1 border-primary px-2 focus:outline-none"
          onClick={handleClick}
        >
          <p>
            {intl
              .formatMessage({ id: "plus de filtres" })
              .charAt(0)
              .toUpperCase() +
              intl.formatMessage({ id: "plus de filtres" }).slice(1)}
          </p>
          <ArrowDownIcon className="text-primary" />
        </button>

        <CustomMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
          <div className="p-2">
            {tagsNames.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox
                  checked={
                    selectedFilteringTags &&
                    selectedFilteringTags.indexOf(name) > -1
                      ? true
                      : false
                  }
                  value={name}
                  onChange={handleMenuItemClick}
                />
                <p className="text-sm">{intl.formatMessage({ id: name })}</p>
              </MenuItem>
            ))}
          </div>
        </CustomMenu>
      </div>
      <div className="flex items-center">
        <p className="text-primary font-medium text-sm">Notifications</p>
        <CustomSwitch
          selected={showNotifs}
          name="test"
          handleChange={handleNtifs}
        />
      </div>
    </div>
  );
}

function AddPostSection({ showNotifs }) {
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const AddPostDialogIsOpen = useSelector((state) => state.AddPostDialogIsOpen);

  const handleClickOpen = () => {
    dispatch(setAddPostDialogOpen(true));
  };

  const handleClose = () => {
    dispatch(setAddPostDialogOpen(false));
  };

  return (
    <div className="w-full h-16 p-2 bg-white rounded-lg shadow-medium">
      <div className="w-full h-full flex items-center">
        <img
          className="h-10 w-10 mr-3 rounded-full object-cover"
          src="./assets/postlogo.jpeg"
          alt=""
        />
        <button
          className="flex flex-grow justify-start text-grey-light text-sm py-1 border-b 
                    border-grey-border focus:outline-none focus:shadow-outline hover:bg-gray-200"
          onClick={() => {
            handleClickOpen(true);
          }}
        >
          {currentSelectedCard && user && (
            <p>
              <FormattedMessage
                id="dashboard.annex.param1"
                values={{
                  name: user.given_name,
                  riverlab: currentSelectedCard.riverlab,
                }}
              />
            </p>
          )}
        </button>
        {AddPostDialogIsOpen && (
          <AddPostDialog
            showNotifs={showNotifs}
            open={AddPostDialogIsOpen}
            handleClose={handleClose}
          />
        )}
      </div>
    </div>
  );
}

function SelectedPostCrown({ selectedDate }) {
  return (
    <div className="flex my-5">
      <div className="border-t-2 text-grey-light flex-grow"></div>
      <p className="text-xs text-grey-light text-center -mt-3 flex-grow-0 mx-2">
        Closeset date to {selectedDate}
      </p>
      <div className="border-t-2 text-grey-light flex-grow"></div>
    </div>
  );
}

function FeedSection({
  showNotifs,
  selectedFilteringTags,
  selectedFilteringPostType,
}) {
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);
  const dashboardChartSelectedDate = useSelector(
    (state) => state.dashboardChartSelectedDate
  );
  const newPostsList = useSelector((state) => state.posts);
  const [finalPostsList, setFinalPostsList] = useState(null);
  const [postsList, setpostsList] = useState(null);
  const userPreferencesInfo = useSelector((state) => state.userPreferences);

  const observer = useRef();
  const [closestDate, setClosestDate] = useState(null);
  const socket = useSelector((state) => state.socket);
  const registered = useSelector((state) => state.registered);
  const dispatch = useDispatch();
  const [seenPostsLength, setSeenPostsLength] = useState(10);
  const [loading, setLoading] = useState(false);
  const postRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const lastPostElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setSeenPostsLength((prev) => prev + 10);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  function SearchPosts() {
    dispatch(getPosts(null));
    setLoading(true);
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/observatories/${currentSelectedCard?.riverlab}/posts?include_notifications=true&include_calibrations=true`
      )
      .then((res) => {
        setLoading(false);
        dispatch(getPosts(Object.values(res.data)));
      })
      .catch((err) => {
        enqueueSnackbar(
          intl.formatMessage({
            id: "dashboard.annex.param20",
          }),
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }
        );
      });
  }

  //clear the date before unmount
  useEffect(() => {
    if (newPostsList) {
      let temp = newPostsList;
      if (!showNotifs) {
        let filteredPostsList = temp.filter((elem) => {
          return elem.author !== undefined;
        });
        temp = filteredPostsList;
      }
      if (selectedFilteringPostType.signalement) {
        let filteredPostsListByTypes = temp.filter((elem) => {
          return elem.signalement;
        });
        temp = filteredPostsListByTypes;
      }
      if (selectedFilteringPostType.intervention) {
        let filteredPostsListByTypes = temp.filter((elem) => {
          return elem.intervention;
        });
        temp = filteredPostsListByTypes;
      }
      if (selectedFilteringTags) {
        let filteredPostsList = temp.filter((elem) => {
          let bool = false;
          for (let i = 0; i < selectedFilteringTags.length; i++) {
            if (elem.tags && elem.tags.includes(selectedFilteringTags[i])) {
              bool = true;
              break;
            }
          }
          return bool;
        });
        temp = filteredPostsList;
      }
      setFinalPostsList(temp);
    }
  }, [
    showNotifs,
    selectedFilteringTags,
    selectedFilteringPostType,
    newPostsList,
  ]);

  //clear the date before unmount
  useEffect(() => {
    return () => dispatch(setDashboardChartSelectedDate(null));
  }, []);

  useEffect(() => {
    currentSelectedCard && SearchPosts();
  }, [currentSelectedCard?.riverlab]);

  //initial posts displayed
  useEffect(() => {
    finalPostsList
      ? setpostsList(finalPostsList.slice(0, seenPostsLength))
      : setpostsList(null);
  }, [finalPostsList]);

  useEffect(() => {
    finalPostsList && setpostsList(finalPostsList.slice(0, seenPostsLength));
  }, [seenPostsLength]);

  //register to listen to incomming new posts
  useEffect(() => {
    registered &&
      postsList &&
      postsList.length !== 0 &&
      registered &&
      postsList &&
      postsList.length !== 0 &&
      socket.on("posts", (res) => {
        let postsListCopy = [...postsList];
        postsListCopy.unshift(res);
        dispatch(getPosts(postsListCopy));
      });
    return () => {
      registered && postsList && socket.removeAllListeners("posts");
    };
  }, [postsList, registered]);

  //join posts room
  useEffect(() => {
    registered && socket.emit("join", "posts", () => {});
    return () => {
      registered && socket.emit("leave", "posts", () => {});
    };
  }, [registered]);

  //notify ws of the selected card you are on
  useEffect(() => {
    if (currentSelectedCard && registered) {
      socket.emit("changeactiveelement", {
        riverlab: currentSelectedCard.riverlab,
        element: currentSelectedCard.element,
      });
    }
  }, [currentSelectedCard, registered]);

  function findClosest(selectedDate, source) {
    const closest = source.reduce((a, b) => {
      return Math.abs(moment(a.timedate) - moment(selectedDate)) <
        Math.abs(moment(b.timedate) - moment(selectedDate))
        ? a
        : b;
    });
    return closest;
  }

  useEffect(() => {
    if (dashboardChartSelectedDate) {
      const closest = findClosest(
        dashboardChartSelectedDate,
        postsList.filter((p) => p.timedate !== "NaT")
      );
      setClosestDate(closest.timedate);
    }
  }, [dashboardChartSelectedDate]);

  useEffect(() => {
    closestDate &&
      postRef.current &&
      postRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  }, [closestDate]);

  useEffect(() => {
    let scrollTop = document.getElementById("AnnexSection").scrollTop;
    if (scrollTop !== 0) {
      document.getElementById("AnnexSection").scrollTop = 0;
    }
  }, [currentSelectedCard?.riverlab]);

  const handleSeeOnChart = (date) => {
    dispatch(
      setDashboardFeedSelectedDate(
        applyUTCtoDate(date, userPreferencesInfo?.time)
      )
    );
  };

  const domPostsList = postsList
    ? postsList.map((post, idx) => {
        let postrender;
        switch (post.type) {
          case "post": {
            if (postsList.length !== idx + 1) {
              postrender = (
                <PostCard
                  postRef={postRef}
                  lastPostElementRef={null}
                  {...post}
                  key={post.post_id}
                  closestDate={closestDate}
                />
              );
            } else {
              postrender = (
                <PostCard
                  postRef={postRef}
                  lastPostElementRef={lastPostElementRef}
                  {...post}
                  key={post.post_id}
                  closestDate={closestDate}
                />
              );
            }
            break;
          }
          case "notification": {
            switch (post.tags[0]) {
              case "info": {
                if (postsList.length !== idx + 1) {
                  postrender = (
                    <div
                      ref={post.timedate === closestDate ? postRef : null}
                      key={post.post_id}
                    >
                      {closestDate === post.timedate && (
                        <SelectedPostCrown
                          selectedDate={dashboardChartSelectedDate}
                        />
                      )}
                      <div ref={null} className="w-full  rounded-8px my-2">
                        <div className="flex justify-between p-4 h-full w-full bg-primary bg-opacity-8 rounded-8px ">
                          <div className="flex">
                            <div className="text-white h-8 w-8 bg-primary rounded-full flex items-center justify-center mr-2">
                              <InfoFilledIcon className="h-5 w-5" />
                            </div>
                            <div>
                              <p className="text-xs text-grey-light">
                                {moment(
                                  applyUTCtoDate(
                                    post.timedate,
                                    userPreferencesInfo?.time
                                  )
                                ).format("lll")}
                              </p>
                              <p className="text-xs font-semibold">
                                {post.content}
                              </p>
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              handleSeeOnChart(post.timedate);
                            }}
                            className="flex items-center text-primary text-xs font-semibold focus:outline-none"
                          >
                            <ShowChartIcon className="h-4 w-4 mr-1" />
                            <p>
                              {" "}
                              <FormattedMessage id="dashboard.annex.param8" />
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  postrender = (
                    <div
                      ref={post.timedate === closestDate ? postRef : null}
                      key={post.post_id}
                    >
                      {closestDate === post.timedate && (
                        <SelectedPostCrown
                          selectedDate={dashboardChartSelectedDate}
                        />
                      )}
                      <div
                        ref={lastPostElementRef}
                        className="w-full bg-white rounded-8px my-2"
                      >
                        <div className="flex justify-between p-4 h-full w-full bg-primary bg-opacity-8 rounded-8px ">
                          <div className="flex">
                            <div className="text-white h-8 w-8 bg-primary rounded-full flex items-center justify-center mr-2">
                              <InfoFilledIcon className="h-5 w-5" />
                            </div>
                            <div>
                              <p className="text-xs text-grey-light">
                                {moment(
                                  applyUTCtoDate(
                                    post.timedate,
                                    userPreferencesInfo?.time
                                  )
                                ).format("lll")}
                              </p>
                              <p className="text-xs font-semibold">
                                {post.content}
                              </p>
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              handleSeeOnChart(post.timedate);
                            }}
                            className="flex items-center text-primary text-xs font-semibold focus:outline-none"
                          >
                            <ShowChartIcon className="h-4 w-4 mr-1" />
                            <p>
                              {" "}
                              <FormattedMessage id="dashboard.annex.param8" />
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                }
                break;
              }
              case "success": {
                if (postsList.length !== idx + 1) {
                  postrender = (
                    <div
                      ref={post.timedate === closestDate ? postRef : null}
                      key={post.post_id}
                    >
                      {closestDate === post.timedate && (
                        <SelectedPostCrown
                          selectedDate={dashboardChartSelectedDate}
                        />
                      )}
                      <div
                        ref={null}
                        className="w-full bg-white rounded-8px my-2"
                      >
                        <div className="flex justify-between p-4 h-full w-full bg-success bg-opacity-8 rounded-8px ">
                          <div className="flex">
                            <div className="text-white h-8 w-8 bg-sucess rounded-full flex items-center justify-center mr-2">
                              <CheckFilledIcon className="h-5 w-5" />
                            </div>
                            <div>
                              <p className="text-xs text-grey-light">
                                {moment(
                                  applyUTCtoDate(
                                    post.timedate,
                                    userPreferencesInfo?.time
                                  )
                                ).format("lll")}
                              </p>
                              <p className="text-xs font-semibold">
                                {post.content}
                              </p>
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              handleSeeOnChart(post.timedate);
                            }}
                            className="flex items-center text-success text-xs font-semibold focus:outline-none"
                          >
                            <ShowChartIcon className="h-4 w-4 mr-1" />
                            <p>
                              {" "}
                              <FormattedMessage id="dashboard.annex.param8" />
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  postrender = (
                    <div
                      ref={post.timedate === closestDate ? postRef : null}
                      key={post.post_id}
                    >
                      {closestDate === post.timedate && (
                        <SelectedPostCrown
                          selectedDate={dashboardChartSelectedDate}
                        />
                      )}
                      <div
                        ref={lastPostElementRef}
                        className="w-full bg-white rounded-8px my-2"
                      >
                        <div className="flex justify-between p-4 h-full w-full bg-success bg-opacity-8 rounded-8px ">
                          <div className="flex">
                            <div className="text-white h-8 w-8 bg-sucess rounded-full flex items-center justify-center mr-2">
                              <CheckFilledIcon className="h-5 w-5" />
                            </div>
                            <div>
                              <p className="text-xs text-grey-light">
                                {moment(
                                  applyUTCtoDate(
                                    post.timedate,
                                    userPreferencesInfo?.time
                                  )
                                ).format("lll")}
                              </p>
                              <p className="text-xs font-semibold">
                                {post.content}
                              </p>
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              handleSeeOnChart(post.timedate);
                            }}
                            className="flex items-center text-success text-xs font-semibold focus:outline-none"
                          >
                            <ShowChartIcon className="h-4 w-4 mr-1" />
                            <p>
                              {" "}
                              <FormattedMessage id="dashboard.annex.param8" />
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                }
                break;
              }
              case "warning": {
                if (postsList.length !== idx + 1) {
                  postrender = (
                    <div
                      ref={post.timedate === closestDate ? postRef : null}
                      key={post.post_id}
                    >
                      {closestDate === post.timedate && (
                        <SelectedPostCrown
                          selectedDate={dashboardChartSelectedDate}
                        />
                      )}
                      <div
                        ref={null}
                        className="w-full bg-white rounded-8px my-2"
                      >
                        <div className="flex justify-between p-4 h-full w-full bg-warning bg-opacity-8 rounded-8px ">
                          <div className="flex">
                            <div className="text-white h-8 w-8 bg-warning rounded-full flex items-center justify-center mr-2">
                              <WarningFilledIcon className="h-5 w-5" />
                            </div>
                            <div>
                              <p className="text-xs text-grey-light">
                                {moment(
                                  applyUTCtoDate(
                                    post.timedate,
                                    userPreferencesInfo?.time
                                  )
                                ).format("lll")}
                              </p>
                              <p className="text-xs font-semibold">
                                {post.content}
                              </p>
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              handleSeeOnChart(post.timedate);
                            }}
                            className="flex items-center text-primary text-xs font-semibold focus:outline-none text-warning"
                          >
                            <ShowChartIcon className="h-4 w-4 mr-1" />
                            <p>
                              {" "}
                              <FormattedMessage id="dashboard.annex.param8" />
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  postrender = (
                    <div
                      ref={post.timedate === closestDate ? postRef : null}
                      id={post.timedate}
                      key={post.post_id}
                    >
                      {closestDate === post.timedate && (
                        <SelectedPostCrown
                          selectedDate={dashboardChartSelectedDate}
                        />
                      )}
                      <div
                        ref={lastPostElementRef}
                        className="w-full bg-white rounded-8px my-2"
                      >
                        <div className="flex justify-between p-4 h-full w-full bg-warning bg-opacity-8 rounded-8px ">
                          <div className="flex">
                            <div className="text-white h-8 w-8 bg-warning rounded-full flex items-center justify-center mr-2">
                              <WarningFilledIcon className="h-5 w-5" />
                            </div>
                            <div>
                              <p className="text-xs text-grey-light">
                                {moment(
                                  applyUTCtoDate(
                                    post.timedate,
                                    userPreferencesInfo?.time
                                  )
                                ).format("lll")}
                              </p>
                              <p className="text-xs font-semibold">
                                {post.content}
                              </p>
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              handleSeeOnChart(post.timedate);
                            }}
                            className="flex items-center text-xs font-semibold focus:outline-none text-warning"
                          >
                            <ShowChartIcon className="h-4 w-4 mr-1" />
                            <p>
                              {" "}
                              <FormattedMessage id="dashboard.annex.param8" />
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                }
                break;
              }
              case "error": {
                if (postsList.length !== idx + 1) {
                  postrender = (
                    <div
                      ref={post.timedate === closestDate ? postRef : null}
                      key={post.post_id}
                    >
                      {closestDate === post.timedate && (
                        <SelectedPostCrown
                          selectedDate={dashboardChartSelectedDate}
                        />
                      )}
                      <div
                        ref={null}
                        className="w-full bg-white rounded-8px my-2"
                      >
                        <div className="flex justify-between p-4 h-full w-full bg-error bg-opacity-8 rounded-8px ">
                          <div className="flex">
                            <div className="text-white h-8 w-8 bg-error rounded-full flex items-center justify-center mr-2">
                              <ErrorFilledIcon className="h-5 w-5" />
                            </div>
                            <div>
                              <p className="text-xs text-grey-light">
                                {moment(
                                  applyUTCtoDate(
                                    post.timedate,
                                    userPreferencesInfo?.time
                                  )
                                ).format("lll")}
                              </p>
                              <p className="text-xs font-semibold">
                                {post.content}
                              </p>
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              handleSeeOnChart(post.timedate);
                            }}
                            className="flex items-center text-error text-xs font-semibold focus:outline-none"
                          >
                            <ShowChartIcon className="h-4 w-4 mr-1" />
                            <p>
                              {" "}
                              <FormattedMessage id="dashboard.annex.param8" />
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  postrender = (
                    <div
                      ref={post.timedate === closestDate ? postRef : null}
                      key={post.post_id}
                    >
                      {closestDate === post.timedate && (
                        <SelectedPostCrown
                          selectedDate={dashboardChartSelectedDate}
                        />
                      )}
                      <div
                        ref={lastPostElementRef}
                        className="w-full bg-white rounded-8px my-2"
                      >
                        <div className="flex justify-between p-4 h-full w-full bg-error bg-opacity-8 rounded-8px ">
                          <div className="flex">
                            <div className="text-white h-8 w-8 bg-error rounded-full flex items-center justify-center mr-2">
                              <ErrorFilledIcon className="h-5 w-5" />
                            </div>
                            <div>
                              <p className="text-xs text-grey-light">
                                {moment(
                                  applyUTCtoDate(
                                    post.timedate,
                                    userPreferencesInfo?.time
                                  )
                                ).format("lll")}
                              </p>
                              <p className="text-xs font-semibold">
                                {post.content}
                              </p>
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              handleSeeOnChart(post.timedate);
                            }}
                            className="flex items-center text-error text-xs font-semibold focus:outline-none"
                          >
                            <ShowChartIcon className="h-4 w-4 mr-1" />
                            <p>
                              {" "}
                              <FormattedMessage id="dashboard.annex.param8" />
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                }
                break;
              }
              default:
              //do nothing
            }
            break;
          }
          case "calibration": {
            if (postsList.length !== idx + 1) {
              postrender = (
                <CalibrationCard
                  calibrationObject={post}
                  index={idx}
                  lastPostElementRef={null}
                />
              );
            } else {
              postrender = (
                <CalibrationCard
                  calibrationObject={post}
                  index={idx}
                  lastPostElementRef={lastPostElementRef}
                />
              );
            }
            break;
          }
          default:
          //do nothing
        }
        return postrender;
      })
    : [];

  if (!postsList) return <WireframeSection />;

  if (postsList.length === 0) {
    return (
      <div className="bg-white rounded-8px flex items-center justify-center flex-grow">
        <div>
          <EmptyPostsIcon />
          <p className="text-grey-lighter font-medium text-base text-center">
            <FormattedMessage id="dashboard.annex.param14" />
          </p>
        </div>
      </div>
    );
  }

  return <>{postsList && domPostsList}</>;
}

function PostActionsSection({ post_id }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleting, setisDeleting] = useState(false);
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);
  const newPostsList = useSelector((state) => state.posts);
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deletePost = () => {
    setisDeleting(true);
    axios
      .delete(
        process.env.REACT_APP_API_URL +
          "/posts/" +
          currentSelectedCard.riverlab +
          "/id/" +
          post_id
      )
      .then((res) => {
        setisDeleting(false);
        let updatedList = newPostsList.filter((post) => {
          return post.post_id !== post_id;
        });
        dispatch(getPosts(updatedList));
        enqueueSnackbar(
          intl.formatMessage({
            id: "dashboard.annex.param17",
          }),
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }
        );
      })
      .catch((err) => {
        enqueueSnackbar(
          intl.formatMessage({
            id: "dashboard.annex.param21",
          }),
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }
        );
      });
  };

  return (
    <div>
      <CirclehoverButton handleOnClick={handleClick} selected={false}>
        <HorizentalDotsIcon className="w-5" />
      </CirclehoverButton>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={deletePost}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText
            primary={<FormattedMessage id="dashboard.annex.param7" />}
          />
        </MenuItem>
        <div className="mt-3">{isDeleting && <LinearProgress />}</div>
      </Menu>
    </div>
  );
}

function AddPostDialog({ showNotifs, open, handleClose }) {
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);
  const user = useSelector((state) => state.user);
  const intl = useIntl();
  const dispatch = useDispatch();
  const [postForm, setpostForm] = useState({
    content: "",
    intervention: true,
    signalement: false,
    errorText: null,
    attachment: [],
    uploadError: null,
    from: moment().subtract(1, "minutes"),
    to: moment(),
  });
  const [InputError, setInputError] = useState({
    helperText: "",
    error: false,
  });
  const [selectedParameters, setSelectedParameters] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [ImagesFilesList, setImagesFilesList] = useState([]);
  const [pdfPreviewCode, setPdfPreviewCode] = useState(null);
  const [PdfFile, setPdfFile] = useState(null);
  const isMobile = useSelector((state) => state.isMobile);
  const [postIsSubmitting, setPostIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const PrimaryItemButtonWithSelected = WithHocSelectableButton(
    PrimaryFullWidthButton,
    BorderPrimaryFullWidthButton,
    "text"
  );

  function handleInputChange(e) {
    setpostForm((prev) => {
      return { ...prev, content: e.target.value };
    });
    if (e.target.value === "") {
      setInputError({ helperText: "This field is required", error: true });
    } else {
      setInputError({ helperText: "", error: false });
    }
  }

  function UploadImagesAndSubmit() {
    if (!InputError.error && postForm.content !== "") {
      setPostIsSubmitting(true);
      if (imagePreviews.length !== 0) {
        let formData = new FormData();
        ImagesFilesList.forEach((element) => {
          formData.append("file", element);
        });
        axios
          .post(process.env.REACT_APP_API_URL + `/posts/image`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            enqueueSnackbar(
              intl.formatMessage({
                id: "dashboard.annex.param18",
              }),
              {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
              }
            );
            handleSubmitPost(res.data.image_ids);
          })
          .catch((error) => {
            enqueueSnackbar(
              intl.formatMessage({
                id: "dashboard.annex.param22",
              }),
              {
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
              }
            );
          });
      } else if (pdfPreviewCode) {
        let formData = new FormData();
        formData.append("file", PdfFile);
        axios
          .post(process.env.REACT_APP_API_URL + `/posts/image`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            enqueueSnackbar(
              intl.formatMessage({
                id: "dashboard.annex.param23",
              }),
              {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
              }
            );
            handleSubmitPost(res.data.image_ids);
          })
          .catch((error) => {
            enqueueSnackbar(
              intl.formatMessage({
                id: "dashboard.annex.param22",
              }),
              {
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
              }
            );
          });
      } else {
        handleSubmitPost([]);
      }
    } else {
      setInputError({ helperText: "This field is required", error: true });
    }
  }

  function handleSubmitPost(attachmentsList) {
    const postEnd = postForm.to._d ?? new Date();
    const postStart = postForm.from._d ?? new Date();
    const newPost = {
      riverlab: currentSelectedCard.riverlab,
      timedate: formateDate(moment(applyUTCtoDate(new Date(), "UTC+0"))),
      post_id: "",
      answers: null,
      attachment: attachmentsList,
      author: user.given_name,
      content: postForm.content,
      intervention: postForm.intervention,
      likes: null,
      post_end: formateDate(moment(applyUTCtoDate(postEnd, "UTC+0"))),
      post_start: formateDate(moment(applyUTCtoDate(postStart, "UTC+0"))),
      signalement: postForm.signalement,
      tags: selectedParameters.length === 0 ? null : selectedParameters,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "/posts", newPost)
      .then((res) => {
        setPostIsSubmitting(false);
        handleCloseDialog();
        axios
          .get(
            process.env.REACT_APP_API_URL +
              `/observatories/${
                currentSelectedCard?.riverlab
              }/posts?include_notifications=${
                showNotifs ? true : false
              }&include_calibrations=true`
          )
          .then((res) => {
            dispatch(getPosts(Object.values(res.data)));
          })
          .catch((err) => {
            console.error("error", err);
          });
        enqueueSnackbar(
          intl.formatMessage({
            id: "dashboard.annex.param24",
          }),
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }
        );
      })
      .catch((err) => {
        console.error("error", err);
        enqueueSnackbar(
          intl.formatMessage({
            id: "dashboard.annex.param25",
          }),
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }
        );
      });

    if (
      (postForm.intervention ||
        postForm.signalement ||
        (postForm.intervention && postForm.signalement)) &&
      (currentSelectedCard.riverlab === "MasCamargues" ||
        currentSelectedCard.riverlab === "MasCamarguesVortex")
    ) {
      axios.post(
        process.env.REACT_APP_FRONTEND_URL + `api/users/sendNotifEmail`,
        {
          postForm: postForm,
        }
      );
    }
  }

  function handleCloseDialog() {
    setpostForm({ content: "", intervention: false, signalement: false });
    handleClose();
  }

  function handleTags(tag) {
    if (selectedParameters.includes(tag)) {
      setSelectedParameters((prev) => prev.filter((e) => e !== tag));
    } else {
      setSelectedParameters((prev) => {
        return [...prev, tag];
      });
    }
  }

  function handleAttachImages(event) {
    if (event.target.files) {
      let encodedImageUrl = Object.values(event.target.files).map((elem) =>
        URL.createObjectURL(elem)
      );
      setImagePreviews(encodedImageUrl);
      setImagesFilesList(Object.values(event.target.files));
    }
  }

  function handleAttachPdfs(event) {
    if (event.target.files) {
      let encodedImageUrl = URL.createObjectURL(event.target.files[0]);
      setPdfPreviewCode(encodedImageUrl);
      setPdfFile(event.target.files[0]);
    }
  }

  function handleDeleteImages() {
    setImagePreviews([]);
    setImagesFilesList([]);
  }

  function handleDeletePdf() {
    setPdfPreviewCode(null);
    setPdfFile(null);
  }

  function handleChange(newValue, propName) {
    setpostForm((prev) => {
      return { ...prev, [propName]: newValue ? newValue : null };
    });
  }

  const tagsList = tagsNames.map((item) => {
    return (
      <FilledGreybgButton
        key={item}
        id=""
        handleOnClick={() => {
          handleTags(item);
        }}
        text={intl.formatMessage({ id: item })}
        selected={selectedParameters.includes(item) ? true : false}
      />
    );
  });
  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      onClose={handleClose}
      maxWidth={"md"}
      fullWidth={true}
      aria-describedby="alert-dialog-description"
      className="relative"
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle
        id="scroll-dialog-title"
        className="border-b-1 border-light-grey-border border-opacity-20"
      >
        <div className="flex justify-between">
          <div className="flex items-center text-xl font-medium text-normal">
            {currentSelectedCard && (
              <p>
                <FormattedMessage id="dashboard.annex.param4" />
              </p>
            )}
          </div>
          <button className="focus:outline-none" onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>
      </DialogTitle>
      <DialogContent>
        {postIsSubmitting && (
          <div className="absolute h-full top-0 bottom-0 right-0 left-0 bg-white bg-opacity-65 z-10"></div>
        )}
        <div className="relative pt-4">
          <div className="flex flex-row gap-2 items-center">
            <img
              className="h-12 w-12  rounded-full object-cover"
              src="./assets/postlogo.jpeg"
              alt=""
            />
            <p className="font-bold text-lg">{user.preferred_username}</p>
          </div>
        </div>
        <div className="divide-y divide-primary divide-opacity-15">
          <div className="flex flex-col gap-2 py-3">
            <p className="font-semibold">
              <FormattedMessage id="Ajouter un type pour le post" />
            </p>
            <div className="flex gap-2">
              <div className="flex-1">
                <PrimaryItemButtonWithSelected
                  text={intl.formatMessage({ id: "dashboard.annex.param5" })}
                  checkSelected={() => {
                    return postForm.signalement;
                  }}
                  onClick={() => {
                    setpostForm((prev) => {
                      return { ...prev, signalement: !prev.signalement };
                    });
                  }}
                />
              </div>
              <div className="flex-1">
                <PrimaryItemButtonWithSelected
                  text="Intervention"
                  checkSelected={() => {
                    return postForm.intervention;
                  }}
                  onClick={() => {
                    setpostForm((prev) => {
                      return { ...prev, intervention: !prev.intervention };
                    });
                  }}
                />
              </div>
            </div>
          </div>
          {(postForm.intervention || postForm.signalement) && (
            <div className="flex flex-col gap-2 py-3">
              <p className="font-semibold">
                <FormattedMessage id="Ajouter un intervalle de temps pour l'intervention" />
              </p>
              <div className="flex gap-2 items-center">
                <DateFormSection
                  from={postForm.from}
                  to={postForm.to}
                  handleChange={handleChange}
                />
              </div>
            </div>
          )}
          <div className="flex flex-col gap-2 py-3">
            <p className="font-semibold">
              <FormattedMessage id="Ajouter une description" />*
            </p>
            <TextField
              onChange={handleInputChange}
              className="w-full"
              id="standard-basic"
              variant="outlined"
              multiline
              minRows={3}
              maxRows={10}
              placeholder={intl.formatMessage(
                { id: "dashboard.annex.param1" },
                {
                  name: user.given_name,
                  riverlab: currentSelectedCard.riverlab,
                }
              )}
            />
          </div>
          <div className="flex flex-col gap-2 py-3">
            <p className="font-semibold">
              <FormattedMessage id="Ajouter des tags" />
            </p>
            <p className="text-xs text-grey-lighter">
              <FormattedMessage id="Ajouter des paramétres pour identifier vos publications plus facilement !" />
            </p>
            <div className="flex flex-wrap gap-1 mt-1">{tagsList}</div>
          </div>
        </div>
        <div className="flex flex-col gap-2 py-5">
          {imagePreviews && imagePreviews.length !== 0 && (
            <div className="relative border-1 border-light-grey-border border-opacity-20 rounded-8px overflow-hidden">
              <DialogImagePreviwsSection imagePreviews={imagePreviews} />
              <div className="absolute top-0 right-0 mr-2 mt-2 flex gap-2">
                <button
                  className="bg-black bg-opacity-65 rounded-full p-3 text-white"
                  onClick={handleDeleteImages}
                >
                  <CloseIcon className="h-3 w-3" />
                </button>
              </div>
            </div>
          )}
          {pdfPreviewCode && (
            <div className="relative border-1 border-light-grey-border border-opacity-20 rounded-8px overflow-hidden">
              <DialogPdfPreviwsSection pdfPreviewCode={pdfPreviewCode} />
              <div className="absolute top-0 right-0 mr-2 mt-2 flex gap-2">
                <button
                  className="bg-black bg-opacity-65 rounded-full p-3 text-white"
                  onClick={handleDeletePdf}
                >
                  <CloseIcon className="h-3 w-3" />
                </button>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        {postIsSubmitting && (
          <Box sx={{ width: "100%", paddingY: "10px" }}>
            <LinearProgress />
          </Box>
        )}
        {!postIsSubmitting && (
          <div className="mt-2 flex justify-between w-full">
            <div className="flex justify-start items-center">
              <label
                htmlFor="image-upload"
                className={` ${
                  pdfPreviewCode ? "cursor-not-allowed " : "cursor-pointer"
                } flex items-center text-grey-light  rounded-full h-8 px-2`}
              >
                <ImageIcon className="mr-2 h-5 w-5 text-grey-light" />
                <p className="text-grey-light text-xs font-semibold">Photo</p>
              </label>
              <div className="hidden">
                <input
                  id="image-upload"
                  accept="image/png, image/jpeg, image/gif"
                  type="file"
                  multiple="multiple"
                  onChange={handleAttachImages}
                />
              </div>
              <label
                htmlFor="file-upload"
                className={`${
                  imagePreviews.length !== 0
                    ? "cursor-not-allowed "
                    : "cursor-pointer"
                } flex items-center text-grey-light  rounded-full h-8 px-2 cursor-pointer`}
              >
                <FileIcon className="mr-2 h-5 w-5 text-grey-light" />
                <p className="text-grey-light text-xs font-semibold">
                  <FormattedMessage id="Fichier" />
                </p>
              </label>
              <div className="hidden">
                <input
                  id="file-upload"
                  name="files"
                  accept="application/pdf"
                  type="file"
                  onChange={handleAttachPdfs}
                />
              </div>
              {currentSelectedCard && !isMobile && (
                <div className="flex items-center text-white bg-secondary bg-opacity-25  rounded-full h-8 px-3">
                  <SimpleHomeIcon className="mr-2 h-4 w-4 text-secondary" />
                  <p className="text-secondary text-xs font-semibold">
                    {currentSelectedCard.riverlab}
                  </p>
                </div>
              )}
            </div>
            <div className="flex gap-1">
              {!isMobile && (
                <SkeletonGreybgButton
                  id="close"
                  handleOnClick={handleCloseDialog}
                  selected={false}
                >
                  <p>Cancel</p>
                </SkeletonGreybgButton>
              )}
              <FilledGreybgButton
                id="publish"
                text={intl.formatMessage({ id: "Publish the post" })}
                handleOnClick={UploadImagesAndSubmit}
                selected={true}
              />
            </div>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}

function DateFormSection({ from, to, handleChange }) {
  return (
    <>
      <div className="flex-grow h-12">
        <LocalizationProvider dateAdapter={MomentDateAdapter}>
          <DateTimePicker
            inputFormat="YYYY-MM-DD HH:mm:ss"
            label="Custom input"
            value={from}
            onChange={(newValue) => {
              handleChange(newValue, "from");
            }}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <CssTextField
                className="h-full w-full"
                variant="outlined"
                ref={inputRef}
                {...inputProps}
                InputProps={{
                  endAdornment: InputProps?.endAdornment,
                }}
              />
            )}
          />
        </LocalizationProvider>
      </div>
      <ArrowSkeletonComplete className="w-6 text-primary" />
      <div className="flex-grow h-12">
        <LocalizationProvider dateAdapter={MomentDateAdapter}>
          <DateTimePicker
            inputFormat="YYYY-MM-DD HH:mm:ss"
            label="Custom input"
            value={to}
            onChange={(newValue) => {
              handleChange(newValue, "to");
            }}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <CssTextField
                className="h-full w-full"
                variant="outlined"
                ref={inputRef}
                {...inputProps}
                InputProps={{
                  endAdornment: InputProps?.endAdornment,
                }}
              />
            )}
          />
        </LocalizationProvider>
      </div>
    </>
  );
}

function PostCard({
  lastPostElementRef,
  author,
  content,
  timedate,
  signalement,
  intervention,
  post_id,
  closestDate,
  attachment,
  postRef,
  tags,
  post_start,
  post_end,
}) {
  const userPreferencesInfo = useSelector((state) => state.userPreferences);
  const [selectedPost, setSelectedPost] = useState(false);
  const dashboardChartSelectedDate = useSelector(
    (state) => state.dashboardChartSelectedDate
  );
  const dispatch = useDispatch();
  const [attachmentInfos, setAttachmentInfos] = useState([]); //tab of all images src
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const handleSeeOnChart = (date) => {
    dispatch(
      setDashboardFeedSelectedDate(
        applyUTCtoDate(date, userPreferencesInfo?.time)
      )
    );
  };

  useEffect(() => {
    if (attachment && attachment.length !== 0) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            `/posts/${currentSelectedCard.riverlab}/id/${post_id}/attachments`
        )
        .then((res) => {
          setAttachmentInfos(res.data.images);
        })
        .catch((err) => {
          console.error("err", err);
          enqueueSnackbar(
            `Could not get attachement of post_id ${post_id} 😔,please contact tech team to fix the problem !`,
            {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            }
          );
        });
    }
  }, []);

  const tagsList = tags
    ? tags.map((item) => {
        return (
          <div className="text-xs text-primary" key={item}>
            #{intl.formatMessage({ id: item })}
          </div>
        );
      })
    : [];

  return (
    <div ref={timedate === closestDate ? postRef : null}>
      {closestDate === timedate && (
        <SelectedPostCrown selectedDate={dashboardChartSelectedDate} />
        // TODOO CHECK THIS
      )}
      <div
        className={`${
          selectedPost ? " bg-primary bg-opacity-8" : "bg-white"
        } p-4  my-1 rounded-8px`}
        ref={lastPostElementRef}
      >
        <div className="flex justify-between">
          <div className="flex ">
            <img
              className="h-12 w-12 mr-3 rounded-full object-cover"
              src="./assets/postlogo.jpeg"
              alt={author}
            />
            <div>
              <p className="font-normal text-grey-light text-xs">
                {moment(
                  applyUTCtoDate(timedate, userPreferencesInfo?.time)
                ).format("lll")}
              </p>
              <p className="font-semibold text-grey-dark text-base">
                {author ? author : "Not known"}
              </p>
            </div>
          </div>
          <div className="flex items-center">
            {signalement && (
              <Tooltip
                title={<FormattedMessage id="dashboard.annex.param5" />}
                arrow={true}
                placement="top"
              >
                <div className="rounded-8px h-8 w-8 bg-warning-1 flex items-center justify-center mr-2">
                  <WarningFilledIcon className="text-white w-5 h-5" />
                </div>
              </Tooltip>
            )}
            {intervention && (
              <Tooltip title="Intervention" arrow={true} placement="top">
                <div className="rounded-8px h-8 w-8 bg-primary flex items-center justify-center mr-2">
                  <InfoFilledIcon className="text-white w-5 h-5" />
                </div>
              </Tooltip>
            )}
            <Tooltip
              title={<FormattedMessage id="dashboard.annex.param3" />}
              arrow={true}
              placement="top"
            >
              <div>
                <SkeletonSquareGreybgButton
                  selected={false}
                  id="id"
                  handleOnClick={() => {
                    handleSeeOnChart(timedate);
                  }}
                >
                  <ShowChartIcon className="h-5 w-5" />
                </SkeletonSquareGreybgButton>
              </div>
            </Tooltip>
            <PostActionsSection post_id={post_id} />
          </div>
        </div>
        {post_start && post_end && intervention && (
          <>
            <div className=" flex gap-2 text-xs  text-primary items-center justify-center">
              <p>
                <FormattedMessage id="De" /> :
                <span className="text-grey-light">
                  {applyUTCtoDate(post_start, userPreferencesInfo?.time)}
                </span>
              </p>
              <ArrowSkeletonComplete className="w-4" />
              <p>
                <FormattedMessage id="A" /> :
                <span className="text-grey-light">
                  {applyUTCtoDate(post_end, userPreferencesInfo?.time)}
                </span>
              </p>
            </div>
            <Divider />
          </>
        )}
        <div className="my-3 ml-2">
          <ShowMoreLes content={content} />
        </div>
        {attachment &&
          attachment.length !== 0 &&
          attachmentInfos &&
          attachmentInfos.length === 0 && (
            <div className="w-full h-72 relative border-1 border-light-grey-border border-opacity-20 rounded-8px flex flex-col items-center justify-center gap-4">
              <LandscapeIcon className="h-full w-full animate-pulse" />
            </div>
          )}
        {attachmentInfos &&
          attachmentInfos.length !== 0 &&
          attachmentInfos[0]["content-type"].includes("image") && (
            <PostImagePreviwsSection attachmentInfos={attachmentInfos} />
          )}
        {attachmentInfos &&
          attachmentInfos.length !== 0 &&
          attachmentInfos[0]["content-type"].includes("application/pdf") && (
            <DialogPdfPreviwsSection
              pdfPreviewCode={
                "data:application/pdf;base64," + attachmentInfos[0].content
              }
            />
          )}
        <div className="flex gap-1">{tagsList}</div>
      </div>
    </div>
  );
}

function ShowMoreLes({ content }) {
  const [expanded, setexpanded] = useState(false);
  const [truncated, settruncated] = useState(false);

  function handleTruncate(trun) {
    if (truncated !== trun) {
      settruncated(trun);
    }
  }

  function toggleLines(event) {
    event.preventDefault();
    setexpanded(!expanded);
  }

  return (
    <>
      <Truncate
        lines={!expanded && 5}
        ellipsis={
          <span>
            ...{" "}
            <a href="#" onClick={toggleLines}>
              <div>
                <span className="text-secondary">
                  {" "}
                  <FormattedMessage id="dashboard.chart.param38" />
                </span>
              </div>
            </a>
          </span>
        }
        onTruncate={handleTruncate}
      >
        {content.split("\n").map((line, i, arr) => {
          const linee = <span key={i}>{line}</span>;
          if (i === arr.length - 1) {
            return linee;
          } else {
            return [linee, <br key={i + "br"} />];
          }
        })}
      </Truncate>
      {!truncated && expanded && (
        <a href="#" onClick={toggleLines}>
          <div>
            <span className="text-secondary">
              {" "}
              <FormattedMessage id="dashboard.chart.param39" />
            </span>
          </div>
        </a>
      )}
    </>
  );
}

function PostImagePreviwsSection({ attachmentInfos }) {
  const [attachmentURL, setAttachmentURL] = useState([]);
  const [extend, setExtend] = useState({ state: false, idx: 0 });

  useEffect(() => {
    let temp = [];
    attachmentInfos.forEach((elem) => {
      temp.push("data:image/png;base64," + elem.content);
    });
    setAttachmentURL(temp);
  }, []);

  switch (attachmentURL.length) {
    case 1:
      return (
        <div className="flex justify-center">
          {attachmentURL.map((elem, idx) => {
            return (
              <button
                key={elem}
                onClick={() => {
                  setExtend({ state: true, idx: idx });
                }}
              >
                <img
                  className="object-cover w-full "
                  src={elem}
                  style={{ maxHeight: "70vh", minHeight: "20vh" }}
                />
              </button>
            );
          })}
          {extend.state && (
            <ImageExtendSection
              extend={extend.state}
              idx={extend.idx}
              attachmentInfos={attachmentURL}
              handleClose={() => setExtend({ state: false, idx: 0 })}
            />
          )}
        </div>
      );

    case 2:
      return (
        <div>
          <div className="grid grid-cols-2 gap-2 w-full">
            {attachmentURL.map((elem, idx) => {
              return (
                <button
                  key={elem}
                  onClick={() => {
                    setExtend({ state: true, idx: idx });
                  }}
                >
                  <img
                    className="object-cover w-full h-full"
                    src={elem}
                    style={{ maxHeight: "40vh", minHeight: "20vh" }}
                  />
                </button>
              );
            })}
          </div>
          {extend.state && (
            <ImageExtendSection
              extend={extend.state}
              idx={extend.idx}
              attachmentInfos={attachmentURL}
              handleClose={() => setExtend({ state: false, idx: 0 })}
            />
          )}
        </div>
      );

    case 3:
      return (
        <div>
          <div className="grid grid-cols-2 gap-2 w-full">
            {attachmentURL.map((elem, idx) => {
              return (
                <button
                  className={`${idx === 0 ? "col-span-full" : null}`}
                  key={elem}
                  onClick={() => {
                    setExtend({ state: true, idx: idx });
                  }}
                >
                  <img
                    className="object-cover w-full h-full"
                    src={elem}
                    style={{ maxHeight: "40vh", minHeight: "20vh" }}
                  />
                </button>
              );
            })}
          </div>
          {extend.state && (
            <ImageExtendSection
              extend={extend.state}
              idx={extend.idx}
              attachmentInfos={attachmentURL}
              handleClose={() => setExtend({ state: false, idx: 0 })}
            />
          )}
        </div>
      );

    default:
      return (
        <div>
          <div className="grid grid-cols-3 gap-2 w-full">
            {attachmentURL.map((elem, idx) => {
              if (idx === 0) {
                return (
                  <button
                    className="col-span-full"
                    key={elem}
                    onClick={() => {
                      setExtend({ state: true, idx: idx });
                    }}
                  >
                    <img
                      className="object-cover w-full "
                      src={elem}
                      style={{ maxHeight: "40vh", minHeight: "20vh" }}
                    />
                  </button>
                );
              } else if (idx > 0 && idx < 3) {
                return (
                  <button
                    key={elem}
                    onClick={() => {
                      setExtend({ state: true, idx: idx });
                    }}
                  >
                    <img
                      className="object-cover w-full h-full"
                      src={elem}
                      style={{ maxHeight: "40vh", minHeight: "20vh" }}
                    />
                  </button>
                );
              } else if (idx === 3 && attachmentURL.length === 4) {
                return (
                  <button
                    key={elem}
                    onClick={() => {
                      setExtend({ state: true, idx: idx });
                    }}
                  >
                    <img
                      className="object-cover w-full h-full"
                      src={elem}
                      style={{ maxHeight: "40vh", minHeight: "20vh" }}
                    />
                  </button>
                );
              } else if (idx === 3 && attachmentURL.length > 4) {
                return (
                  <button
                    key={elem}
                    onClick={() => {
                      setExtend({ state: true, idx: idx });
                    }}
                  >
                    <div key={elem} className="relative">
                      <div className="absolute top-0 right-0 left-0 bottom-0 bg-black bg-opacity-65 text-white text-4xl flex items-center justify-center text-bold ">
                        <AddIcon className="h-5 w-5 mr-2" />
                        <p>{Math.abs(attachmentURL.length - 4)}</p>
                      </div>
                      <img
                        className="object-cover w-full h-full"
                        src={elem}
                        style={{ maxHeight: "40vh", minHeight: "20vh" }}
                      />
                    </div>
                  </button>
                );
              } else {
                return null;
              }
            })}
          </div>
          {extend.state && (
            <ImageExtendSection
              extend={extend.state}
              idx={extend.idx}
              attachmentInfos={attachmentURL}
              handleClose={() => setExtend(false)}
            />
          )}
        </div>
      );
  }
}

function ImageExtendSection({ attachmentInfos, extend, handleClose, idx }) {
  const [currentSelectedImage, setcurrentSelectedImage] = useState(idx);
  return (
    <Dialog
      open={extend}
      onClose={handleClose}
      maxWidth={"lg"}
      fullWidth={true}
      aria-describedby="alert-dialog-description"
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      className="relative flex items-center justify-center px-10"
    >
      {currentSelectedImage !== 0 && (
        <div className="absolute left-0 top-0 bottom-0 w-10 text-white flex items-center justify-center transform rotate-180">
          <button
            className="focus:outline-none"
            onClick={() => {
              setcurrentSelectedImage((prev) => prev - 1);
            }}
          >
            <ArrowRightIcon className="w-7 h-7"></ArrowRightIcon>
          </button>
        </div>
      )}
      {currentSelectedImage !== attachmentInfos.length - 1 && (
        <div className="absolute right-0 top-0 bottom-0 w-10 text-white  flex items-center justify-center">
          <button
            className="focus:outline-none"
            onClick={() => {
              setcurrentSelectedImage((prev) => prev + 1);
            }}
          >
            <ArrowRightIcon className="w-7 h-7"></ArrowRightIcon>
          </button>
        </div>
      )}
      <img
        src={attachmentInfos[currentSelectedImage]}
        style={{ maxHeight: "100vh", minHeight: "20vh" }}
      />
    </Dialog>
  );
}

function DialogPdfPreviwsSection({ pdfPreviewCode }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [bottomSectionstyle, setBottomSectionstyle] = useState("none");
  const [timeoutId, setTimeoutId] = useState(null);
  const [full, setfull] = useState(false);
  const [convertedImages, setconvertedImages] = useState([]);
  PDFJS.disableWorker = true;

  useEffect(() => {
    PDFJS.getDocument(pdfPreviewCode).promise.then((pdf) => {
      let copy = [];
      setNumPages(pdf.numPages);
      for (let i = 1; i < pdf.numPages + 1; i++) {
        pdf.getPage(i).then(function getPageHelloWorld(page) {
          var scale = 1;
          var viewport = page.getViewport({ scale });
          var canvas = document.createElement("canvas");
          var context = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          page
            .render({ canvasContext: context, viewport: viewport })
            .promise.then(function () {
              let imageUrl = canvas.toDataURL("image/png");
              copy.push(imageUrl);
              if (i === pdf.numPages) {
                setconvertedImages(copy);
              }
            });
        });
      }
    });

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (full && screenfull.isEnabled) {
      screenfull.toggle(document.getElementById("full"));
      sessionStorage.setItem("pdfBase64", pdfPreviewCode);
    }
    screenfull.on("change", () => {
      if (full && !screenfull.isFullscreen) {
        setfull(false);
      }
    });

    return () => clearTimeout(timeoutId);
  }, [full]);

  return (
    <>
      {!full && convertedImages.length === 0 && (
        <div className="h-96 w-full rounded-8px border-1 border-light-grey-border border-opacity-20 flex items-center justify-center">
          <CircularProgress />
        </div>
      )}
      {!full && convertedImages.length !== 0 && (
        <div
          onMouseOver={(e) => {
            timeoutId && clearTimeout(timeoutId);
            setBottomSectionstyle("block");
            let id = setTimeout(() => {
              setBottomSectionstyle("none");
            }, 4000);
            setTimeoutId(id);
          }}
          className="relative flex justify-center rounded-8px border-1 border-light-grey-border border-opacity-20 overflow-hidden"
        >
          <div className="shadow-md">
            <img className="bg-cover" src={convertedImages[pageNumber - 1]} />
          </div>
          {pageNumber !== 1 && (
            <div className="absolute left-0 top-0 bottom-0 w-10 flex items-center ml-2">
              <button
                className="bg-black bg-opacity-65 rounded-full p-3 text-white focus:outline-none"
                onClick={() => {
                  setPageNumber((prev) => {
                    return prev - 1;
                  });
                }}
              >
                <ArrowRightIcon className="h-3 w-3 transform rotate-180" />
              </button>
            </div>
          )}
          {pageNumber !== numPages && (
            <div className="absolute right-0 top-0 bottom-0 w-10 flex items-center mr-2">
              <button
                className="bg-black bg-opacity-65 rounded-full p-3 text-white focus:outline-none"
                onClick={() => {
                  setPageNumber((prev) => {
                    return prev + 1;
                  });
                }}
              >
                <ArrowRightIcon className="h-3 w-3 " />
              </button>
            </div>
          )}
          {numPages && (
            <div
              style={{
                transition: "all 1s linear",
                display: bottomSectionstyle,
              }}
            >
              <div className="absolute bottom-0 right-0 left-0 h-10 bg-black bg-opacity-65 flex p-2">
                <div className="flex-none h-full text-white">
                  {pageNumber}/{numPages}
                </div>
                <div className="flex-grow h-full w-full flex justify-center items-center px-4 relative">
                  <div className="h-1 w-full bg-black bg-opacity-8 relative flex">
                    <div
                      className="bg-white h-full"
                      style={{
                        width: `${(100 / numPages) * pageNumber}%`,
                        transition: "all 1s",
                      }}
                    ></div>
                    <div
                      className="absolute left-0 top-0 bottom-0 h-3 w-3 rounded-full bg-white my-auto"
                      style={{
                        marginLeft: `${(100 / numPages) * pageNumber}%`,
                        transition: "all 1s",
                      }}
                    ></div>
                  </div>
                </div>
                <button className="text-white mr-1">
                  <a
                    className="flex gap-1  items-center"
                    download="file.pdf"
                    href={pdfPreviewCode}
                  >
                    <DownloadFileIcon className=" rounded-8px h-5 w-5" />
                  </a>
                </button>
                {
                  <button
                    className="flex-none h-full flex justify-center items-center relative text-white"
                    onClick={() => {
                      setfull(true);
                    }}
                  >
                    <ExtendIcon />
                    <ExtendIcon className="absolute right-0 top-0 bottom-0 transform rotate-90" />
                  </button>
                }
              </div>
            </div>
          )}
        </div>
      )}
      {full && (
        <div id="full" className="h-full w-full">
          <iframe
            id="pdfViewer"
            src="lib/web/viewer.html"
            width="100%"
            height="100%"
          ></iframe>
        </div>
      )}
    </>
  );
}

function DialogImagePreviwsSection({ imagePreviews }) {
  switch (imagePreviews.length) {
    case 1:
      return imagePreviews.map((elem, idx) => {
        return (
          <div className="w-full">
            <img
              key={elem}
              className="object-cover w-full "
              src={elem}
              style={{ maxHeight: "40vh", minHeight: "20vh" }}
            />
          </div>
        );
      });
    case 2:
      return (
        <div className="grid grid-cols-2 gap-2 w-full">
          {imagePreviews.map((elem) => {
            return (
              <img
                key={elem}
                className="object-cover w-full h-full"
                src={elem}
                style={{ maxHeight: "40vh", minHeight: "20vh" }}
              />
            );
          })}
        </div>
      );
    case 3:
      return (
        <div className="grid grid-cols-2 gap-2 w-full">
          {imagePreviews.map((elem, idx) => {
            return (
              <img
                key={elem}
                className={`${
                  idx === 0 ? "col-span-full" : null
                } object-cover w-full h-full`}
                src={elem}
                style={{ maxHeight: "40vh", minHeight: "20vh" }}
              />
            );
          })}
        </div>
      );
    default:
      return (
        <div className="grid grid-cols-3 gap-2 w-full">
          {imagePreviews.map((elem, idx) => {
            if (idx === 0) {
              return (
                <img
                  key={elem}
                  className="object-cover w-full col-span-full"
                  src={elem}
                  style={{ maxHeight: "40vh", minHeight: "20vh" }}
                />
              );
            } else if (idx > 0 && idx < 3) {
              return (
                <img
                  key={elem}
                  className="object-cover w-full h-full"
                  src={elem}
                  style={{ maxHeight: "40vh", minHeight: "20vh" }}
                />
              );
            } else if (idx === 3 && imagePreviews.length === 4) {
              return (
                <img
                  key={elem}
                  className="object-cover w-full h-full"
                  src={elem}
                  style={{ maxHeight: "40vh", minHeight: "20vh" }}
                />
              );
            } else if (idx === 3 && imagePreviews.length > 4) {
              return (
                <div key={elem} className="relative">
                  <div className="absolute top-0 right-0 left-0 bottom-0 bg-black bg-opacity-65 text-white text-4xl flex items-center justify-center text-bold ">
                    <AddIcon className="h-5 w-5 mr-2" />
                    <p>{Math.abs(imagePreviews.length - 4)}</p>
                  </div>
                  <img
                    className="object-cover w-full h-full"
                    src={elem}
                    style={{ maxHeight: "40vh", minHeight: "20vh" }}
                  />
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      );
  }
}

function CalibrationCard({ calibrationObject, index, lastPostElementRef }) {
  const userPreferencesInfo = useSelector((state) => state.userPreferences);
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div
      className="rounded-8px p-2 my-2"
      style={{ backgroundColor: "#3D80FB" }}
      ref={lastPostElementRef}
    >
      <div className="mb-1">
        <p className="flex justify-center text-white font-medium">
          <ClapIcon className="h-6.5 w-6.5 mr-1" />
          <FormattedMessage id="dashboard.annex.param13" /> {index}
        </p>
        <p className="flex justify-center text-white text-xs">
          <FormattedMessage id="dashboard.annedx.param12" />
          {moment(
            applyUTCtoDate(
              calibrationObject.timedate,
              userPreferencesInfo?.time
            )
          ).format("lll")}
        </p>
      </div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <div className="flex items-center">
            <TestTubeIcon className="h-5 w-5 mr-1" />
            <p className="text-primary font-medium">
              Blanks({calibrationObject.blanks.length})
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <>
            {calibrationObject.blanks.length !== 0 &&
              calibrationObject.blanks.map((iteration, i) => {
                return (
                  <div className="my-2">
                    <p className="font-medium mb-1 text-xs flex justify-center">
                      Injection n° {i} :
                    </p>
                    <div className="flex flex-wrap gap-1 ">
                      {iteration.element_list.map((elem) => {
                        return (
                          <div className="p-2 text-primary font-medium text-xs bg-primary bg-opacity-8 p-1 rounded-8px">
                            {elem.element_name} {elem.measured}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            {calibrationObject.blanks.length === 0 && (
              <p className="flex">
                <FormattedMessage id="dashboard.annex.param11" />
                <BofEmogyIcon />
              </p>
            )}
          </>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <div className="flex items-center">
            <TestTubeIcon className="h-5 w-5 mr-1" />
            <p className="text-primary font-medium ">
              <FormattedMessage id="dashboard.annex.param10" />(
              {calibrationObject.standards.length})
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <>
            {calibrationObject.standards.length !== 0 &&
              calibrationObject.standards.map((iteration, i) => {
                return (
                  <div className="my-2">
                    <p className="font-medium mb-1 text-xs flex justify-center">
                      Injection n° {i} :
                    </p>
                    <div className="flex flex-wrap gap-1">
                      {iteration.element_list.map((elem) => {
                        return (
                          <div className="p-2 text-primary font-medium text-xs bg-primary bg-opacity-8 p-1 rounded-8px">
                            {elem.element_name} {elem.measured}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            {calibrationObject.standards.length === 0 && (
              <p className="flex">
                <FormattedMessage id="dashboard.annex.param9" />
                <BofEmogyIcon />
              </p>
            )}
          </>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default injectIntl(DashboardPage);
