import { FormattedMessage, useIntl } from "react-intl";
import cloneDeep from "lodash.clonedeep";
import Switch from "@mui/material/Switch";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "./Accordion/Accordion";
import ConfigTab from "./ConfigTab/ConfigTab";
import { ReactComponent as LegendIcon } from "../../../icons/legend.svg";

export default function LegendSection({
  expanded,
  handleChangeAccordion,
  legend,
  setOption,
}) {
  const intl = useIntl();
  const checkDefaultVerticalAlignement = () => {
    if (legend.top === "top" || legend.top === "haut") {
      legend.top = intl.formatMessage({
        id: "extraplot.annex.param31",
      });
      return legend.top;
    } else if (legend.top === "middle" || legend.top === "milieu") {
      legend.top = intl.formatMessage({
        id: "extraplot.annex.param32",
      });
      return legend.top;
    } else if (legend.top === "bottom" || legend.top === "bas") {
      legend.top = intl.formatMessage({
        id: "extraplot.annex.param33",
      });
      return legend.top;
    }
  };
  const checkDefaultHorizentalAlignement = () => {
    if (legend.left === "left" || legend.left === "gauche") {
      legend.left = intl.formatMessage({
        id: "extraplot.annex.param34",
      });
      return legend.left;
    } else if (legend.left === "center" || legend.left === "centre") {
      legend.left = intl.formatMessage({
        id: "extraplot.annex.param35",
      });
      return legend.left;
    } else if (legend.left === "right" || legend.left === "droite") {
      legend.left = intl.formatMessage({
        id: "extraplot.annex.param36",
      });
      return legend.left;
    }
  };

  function handleUpdate(newValue, name) {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.option.legend = {
        ...newOption.option.legend,
        [name]: newValue,
      };
      return newOption;
    });
  }

  const handleChange = (event) => {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.option.legend = {
        ...newOption.option.legend,
        [event.target.name]: event.target.checked,
      };
      return newOption;
    });
  };
  return (
    <Accordion
      expanded={expanded.has("panel5")}
      onChange={handleChangeAccordion("panel5")}
      backgroundColor="white"
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="flex items-center">
          <p className="text-primary font-medium flex gap-2 items-center">
            <LegendIcon className="h-7 w-7" />
            <p className="text-normal">
              <FormattedMessage id="extraplot.annex.param13" />
            </p>
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="flex flex-col justify-center gap-1">
          <div className="mb-2 flex gap-1 justify-end items-center">
            <p className="font-medium text-xs">Show :</p>
            <Switch
              checked={legend.show}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
              name="show"
            />
          </div>
          {legend.show && (
            <>
              <div className="mb-2">
                <p className="font-medium text-xs">
                  <FormattedMessage id="extraplot.annex.param15" />
                </p>
                <ConfigTab
                  items={[
                    intl.formatMessage({
                      id: "extraplot.annex.param31",
                    }),
                    intl.formatMessage({
                      id: "extraplot.annex.param32",
                    }),
                    intl.formatMessage({
                      id: "extraplot.annex.param33",
                    }),
                  ]}
                  color="primary"
                  name="top"
                  selectedValue={checkDefaultVerticalAlignement()}
                  handleOnClick={(ev) => {
                    handleUpdate(ev.target.value, ev.target.name);
                  }}
                />
              </div>
              <div className="mb-2">
                <p className="font-medium text-xs">
                  <FormattedMessage id="extraplot.annex.param16" />
                </p>
                <ConfigTab
                  items={[
                    intl.formatMessage({
                      id: "extraplot.annex.param34",
                    }),
                    intl.formatMessage({
                      id: "extraplot.annex.param35",
                    }),
                    intl.formatMessage({
                      id: "extraplot.annex.param36",
                    }),
                  ]}
                  color="primary"
                  name="left"
                  selectedValue={checkDefaultHorizentalAlignement()}
                  handleOnClick={(ev) => {
                    handleUpdate(ev.target.value, ev.target.name);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
