import styled from "styled-components";
import { Dialog } from "@mui/material";
import { Col, Row } from "react-flexbox-grid";
import { AiOutlineCheck } from "react-icons/ai";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";

export const HiddenScrollBar = styled(Dialog)`
  overflow: hidden;
`;

export const RedButton = styled.button`
  background-color: red;
  &:hover {
    background: #cc1e1e;
  }
`;

export const StyledSpan = styled.span`
  width: 9px;
  height: 9px;
  margin-right: 5px;
`;

export const StyleFlexWrapDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const OverFlowAutoDiv = styled.div`
  overflow: auto;
`;

export const SpanUnderEmail = styled.span`
    height: 60px;
    width: 308px;
    margin-top: -10px;
    box-shadow: 0px 0px 7px rgb(13 20 65 / 50%)
  }}
`;
export const RedText = styled.p`
  color: red;
`;
export const DivWithBorderRadius = styled.div`
  height: 26px;
  border-radius: 54px;
  padding: 20px;
  width: 90%;
`;
export const StyledCol = styled(Col)`
  margin-left: -7px;
  gap: 10px;
`;

export const StyledDiv = styled.div`
  height: 50px;
  width: 308px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCheckButton = styled(AiOutlineCheck)`
  width: 25px;
  height: 22px;
`;

export const StyledRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const GreenButton = styled.button`
  background: #176a2a;
  width: 39px;
  height: 16px;
  border-radius: 3px;
  padding: 2px 5px 2px 5px;
  font-weight: 700;
  font-size: 10px;
  font-family: Roboto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
`;

export const BlueButton = styled.button`
  background: #2975ff;
  width: 39px;
  height: 16px;
  border-radius: 3px;
  padding: 2px 5px 2px 5px;
  font-weight: 700;
  font-size: 10px;
  font-family: Roboto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
`;

export const PurpleButton = styled.button`
  background: #7963fe;
  width: 39px;
  height: 16px;
  border-radius: 3px;
  padding: 2px 5px 2px 5px;
  font-weight: 700;
  font-size: 10px;
  font-family: Roboto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
`;
export const DivWithGap = styled.div`
  display: flex;
  gap: 10px;
`;
export const StyledTableContainer = styled(TableContainer)`
  width: 90%;
  margin-left: 30px;
  margin: auto;
`;

export const RowWithGap = styled(Row)`
  display: flex;
  align-items: center;
  gap: 15px;
`;
export const StyledText = styled.h1`
  color: rgba(0, 48, 79, 1);
  font-weight: 700;
  font-size: 25px;
`;
export const StyledParagraph = styled.p`
  font-weight: 400;
  font-size: 15px;
`;

export const StyledPaper = styled(Paper)`
  height: 100%;
`;

export const GrayNormalText = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: #8488a5;
  font-family: Roboto;
`;

export const GrayBoldText = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: ${(props) => (props.openListOfteamsEl ? "#2975FF" : "#343A40")};
  border: ${(props) =>
    props.openListOfteamsEl ? "1px solid #2975FF" : "transparent"};
  font-family: Satoshi;
  height: 24px;
  border-radius: 5px;
  padding: 4px 7px 4px 10px;
  background: ${(props) => (props.openListOfteamsEl ? "#FFFFFF" : "#EBECF3")};
  display: flex;
  justify-content: justify-between;
  align-items: center;
  gap: 10px;
`;
export const ColWithRowDirection = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  margin-left: 80px;
  padding-top: 16px;
`;

export const HeaderText = styled.p`
  font-family: Satoshi;
  font-weight: 500;
  font-size: 12px;
  color: #8488a5;
`;

export const BlackNormalText = styled.p`
  font-family: Satoshi;
  font-weight: 400;
  font-size: 14px;
  color: #0d1441;
`;

export const GraySmallText = styled.p`
  font-family: Satoshi;
  font-weight: 400;
  font-size: 12px;
  color: #8488a5;
`;

export const BlackBoldTextSatoshiFont = styled.p`
  font-family: Satoshi;
  font-weight: 700;
  font-size: 20px;
  color: #0d1441;
`;

export const StyledImage = styled.img`
  height: calc(100vh - 65px);
  transform: translate(8px, 0);
`;

export const InviteButton = styled.button`
  width: 113px;
  background: #2975ff;
  height: 32px;
  padding: 0 34px;
  font-family: Roboto;
  border-radius: 5px;
  font-weight: 700;
  font-size: 13px;
  color: #ffffff;
  border: 1px solid #2975ff;
  &:hover {
    background: #17469c;
  }
  &:focus {
    outline: unset;
  }
`;

export const CancelWithHover = styled.button`
    width: 113px;
    height: 32px;
    border-radius: 5px;
    padding: 10px 34px 10px 34px;
    background: #FFFFFF;
    color: #2975FF;
    font-family: Roboto;
    font-weight: 700,
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #EBECF3;
    },
`;
export const SmallFontCancelWithHover = styled.button`
    width: 113px;
    height: 32px;
    border-radius: 5px;
    padding: 10px 34px 10px 34px;
    background: #FFFFFF;
    color: #2975FF;
    font-family: Roboto;
    font-weight: 700,
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #EBECF3;
    },
`;
export const SmallCancelWithHover = styled.button`
    width: 60px;
    height: 32px;
    border-radius: 5px;
    padding: 10px 34px 10px 34px;
    background: #FFFFFF;
    color: #2975FF;
    font-family: Roboto;
    font-weight: 700,
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #EBECF3;
    },
`;

export const SendBlueButton = styled.button`
  width: 113px;
  height: 32px;
  border-radius: 5px;
  padding: 10px 34px 10px 34px;
  background: #2975ff;
  color: #FFFFFF;
  font-family: Roboto;
  font-weight: 700,
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: #17469C;
  },
`;
