import React, { useState } from "react";
import cloneDeep from "lodash.clonedeep";
import { ReactComponent as MarkpointIcon } from "../../../../icons/markpoints.svg";
import { ReactComponent as AddIcon } from "../../../../icons/add.svg";
import { SkeletonGreybgButton } from "../../../../Shared/Butttons";
import MarkpointConfig from "./MarkpointConfig";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "./../Accordion/Accordion";
import { DEFAULT_MARKPOINT_CONFIG } from "../../Const";

export default function MarkPointSection({
  seriesConfig,
  setchartConfigg,
  setOption,
}) {
  const [expandedMarkpoint, setExpandedMarkpoint] = useState("");

  const handleChangeAccordionMarkpoint = (panel) => (event, newExpanded) => {
    setExpandedMarkpoint(newExpanded ? panel : false);
  };

  const markpointList = seriesConfig.markPoint.data.map((markpoint) => {
    return (
      <MarkpointConfig
        key={markpoint.id}
        markpointConfig={markpoint}
        serieid={seriesConfig.id}
        setchartConfigg={setchartConfigg}
        setOption={setOption}
      />
    );
  });

  function handleAddNewMarkpoint(serieid) {
    const id = Math.floor(Math.random() * 10000);
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.option.series.map((serie) => {
        if (serie.id === serieid) {
          serie.markPoint.data = [
            ...serie.markPoint.data,
            { ...DEFAULT_MARKPOINT_CONFIG, id: id },
          ];
        }
        return serie;
      });
      return newOption;
    });
  }

  return (
    <Accordion
      expanded={expandedMarkpoint === "markpoint"}
      onChange={handleChangeAccordionMarkpoint("markpoint")}
      backgroundColor="rgba(142, 155, 187, 0.08)"
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="flex items-center">
          <p className="text-primary font-medium flex gap-2 items-center">
            <MarkpointIcon />
            MarkPoints
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {markpointList}
        <SkeletonGreybgButton
          id="close"
          handleOnClick={() => {
            handleAddNewMarkpoint(seriesConfig.id);
          }}
          selected={false}
        >
          <div className="flex gap-2 justify-center items-center">
            <AddIcon className="h-3 w-3" />
            new Markpoint
          </div>
        </SkeletonGreybgButton>
      </AccordionDetails>
    </Accordion>
  );
}
