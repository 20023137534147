import React, { useState } from "react";
import cloneDeep from "lodash.clonedeep";
import { ReactComponent as ChartIcon } from "../../../icons/show-chart.svg";

import MarkLineSection from "./MarkLine/MarkLineSection";
import MarkPointSection from "./MarkPoint/MarkPointSection";
import SymbolStyleSection from "./SymbolStyleSection/SymbolStyleSection";
import LineStyleSection from "./LineStyleSection/LineStyleSection";
import ConfigTab from "./ConfigTab/ConfigTab";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "./Accordion/Accordion";

export default function SeriesConfig({ seriesConfig, setOption }) {
  const [expanded, setExpanded] = useState("");

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function handleUpdate(id, newValue, name) {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.option.series.map((elem) => {
        if (elem.id === id) {
          elem[name] = newValue;
        }
        return elem;
      });
      return newOption;
    });
  }

  return (
    <Accordion
      expanded={expanded === "series"}
      onChange={handleChangeAccordion("series")}
      backgroundColor="white"
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="flex items-center">
          <p className="font-medium flex gap-2">
            <ChartIcon className="text-primary" />
            Series config
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <div className="mb-2">
            <p className="font-medium text-xs">Type :</p>
            <ConfigTab
              items={["line", "scatter"]}
              color="primary"
              name="type"
              selectedValue={seriesConfig.type}
              handleOnClick={(ev) => {
                handleUpdate(seriesConfig.id, ev.target.value, ev.target.name);
              }}
            />
          </div>
          <LineStyleSection seriesConfig={seriesConfig} setOption={setOption} />
          <SymbolStyleSection
            seriesConfig={seriesConfig}
            setOption={setOption}
          />
          <MarkPointSection seriesConfig={seriesConfig} setOption={setOption} />
          <MarkLineSection seriesConfig={seriesConfig} setOption={setOption} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
