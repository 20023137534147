import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Row } from "react-flexbox-grid";
import {
  BlackBoldText,
  GreyText,
  SmallGreyText,
  DivWithMargin,
  ColWithPadding,
  StyledImage,
} from "./Profile.style";
import { createPicture } from "../UsersManagement/Const";
import { capitalizeFirstLetter } from "../../utils/char";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import { StyledTableCell } from "../Alerts/Alerts.style";
import { stationToUpperCase } from "../../utils/riverlabs";

const Profile = () => {
  const isMobile = useSelector((state) => state.isMobile);
  const userRoles = useSelector((state) => state.userRoles);
  const numberTeams = userRoles && Object.keys(userRoles).length;
  const marginOfImage = (419 + 41.3 * numberTeams).toString() + "px";
  const user = useSelector((state) => state.user);
  const renderColor = (role) => {
    var color;
    role === "admin"
      ? (color = "#176A2A")
      : role === "writer"
      ? (color = "#2975FF")
      : (color = "#7963FE");
    return color;
  };

  return (
    <div className={`${!isMobile && "ml-16"} bg-white w-full pb-2 pt-2`}>
      <div className="flex justify-between border-b-1 border-primary border-opacity-20 p-2">
        <BlackBoldText style={{ fontFamily: "Satoshi", marginLeft: "16px" }}>
          <FormattedMessage id="My profile" />
        </BlackBoldText>
      </div>
      <div className="overflow-hidden w-full">
        <Row>
          <ColWithPadding>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <DivWithMargin>
                {user && createPicture(user.given_name, 78, 78)}
              </DivWithMargin>
              <BlackBoldText>
                {user &&
                  capitalizeFirstLetter(user.given_name) +
                    " " +
                    capitalizeFirstLetter(user.family_name)}
              </BlackBoldText>
              <GreyText>{user && user.email}</GreyText>
            </div>
            {userRoles && (
              <TableContainer>
                <Table
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: "1px solid transparent",
                    },
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: "0px" }}
                        align="center"
                      >
                        <SmallGreyText>
                          <FormattedMessage id="team" />
                        </SmallGreyText>
                      </TableCell>
                      <TableCell style={{ paddingBottom: "0px" }} align="left">
                        <SmallGreyText>
                          <FormattedMessage id="role" />
                        </SmallGreyText>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(userRoles).map(([riverlab, role]) => (
                      <>
                        <TableRow>
                          <StyledTableCell align="left">
                            {stationToUpperCase(riverlab)}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {
                              <button
                                style={{
                                  background: renderColor(role),
                                  width: "50px",
                                  height: "20px",
                                  fontWeight: 700,
                                  fontSize: "12px",
                                  padding: "2px 5px",
                                  color: "white",
                                  borderRadius: "3px",
                                  fontFamily: "Satoshi",
                                }}
                              >
                                {capitalizeFirstLetter(role)}
                              </button>
                            }
                          </StyledTableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </ColWithPadding>
        </Row>
        <Row style={{ marginLeft: "0px", marginTop: "-23px" }}>
          <StyledImage
            alt=""
            src="./assets/profile.png"
            style={{
              height: `calc(100vh - ${marginOfImage})`,
            }}
          />
        </Row>
      </div>
    </div>
  );
};

export default Profile;
