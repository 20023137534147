import React, { useState, useEffect } from "react";
import { CustomForm } from "../../../../Shared/Forms/forms";
import { useSearchRiverlabs } from "../../../../hooks/useSearchRiverlabs";
import { useSearchParameters } from "../../../../hooks/useSearchParameters";
import { WithHocSelectableButton } from "../../../../Shared/Buttons/HOCS";
import { PrimaryItemButton, GreyItemButton } from "../../../../Shared/Butttons";
import { RegularListTest } from "../../Const";

export default function Test({
  config,
  setconfig,
  handleAddNewCouple,
  setopen,
  graphType,
  option,
}) {
  const obsList = useSearchRiverlabs();
  const [selectedObs, setselectedObs] = useState(null);
  const [
    anions,
    cations,
    physico_chemicalParams,
    technicalParams,
    meteoParams,
  ] = useSearchParameters(selectedObs);
  const PrimaryItemButtonWithSelected = WithHocSelectableButton(
    PrimaryItemButton,
    GreyItemButton,
    "text"
  );

  useEffect(() => {
    if (obsList) {
      setselectedObs(obsList[0]);
    }
  }, [obsList]);

  function handleObsChange(e) {
    setselectedObs(e.target.value);
  }

  function checkObsSelected(item) {
    if (selectedObs && selectedObs === item) {
      return true;
    } else {
      return false;
    }
  }

  function onClick(e) {
    switch (graphType) {
      case "Scatter": {
        if (!config.x) {
          setconfig((prev) => {
            return {
              ...prev,
              x: { element: e.target.value, observatory: selectedObs },
            };
          });
        } else {
          handleAddNewCouple(
            {
              ...config,
              y: { element: e.target.value, observatory: selectedObs },
            },
            option
          );
          setopen(false);
          setconfig({ x: null, y: null });
        }
        break;
      }
      case "Timeseries": {
        handleAddNewCouple(
          {
            ...config,
            y: { element: e.target.value, observatory: selectedObs },
          },
          option
        );
        setopen(false);
        setconfig({ x: null, y: null });
        break;
      }
      default:
      //do nothing
    }
  }

  return (
    <div>
      <CustomForm>
        <RegularListTest
          items={obsList}
          itemComponenet={PrimaryItemButtonWithSelected}
          ressourceName="item"
          //rest of props are forwarded to the item component
          checkSelected={checkObsSelected}
          onClick={handleObsChange}
        />

        {anions
          ? anions.map((elem) => {
              if (
                config.x &&
                config.x.element === elem.id &&
                config.x.observatory === selectedObs
              ) {
                return (
                  <button
                    key={elem.id + selectedObs}
                    className="bg-success text-white p-2 rounded-8px font-semibold text-sm focus:outline-none"
                    value={elem.id}
                    onClick={onClick}
                  >
                    {elem.label}
                  </button>
                );
              } else if (
                config.y &&
                config.y.element === elem.id &&
                config.y.observatory === selectedObs
              ) {
                return (
                  <button
                    key={elem.id + selectedObs}
                    className="bg-primary text-white p-2 rounded-8px font-semibold text-sm focus:outline-none"
                    value={elem.id}
                    onClick={onClick}
                  >
                    {elem.label}
                  </button>
                );
              } else {
                return (
                  <button
                    key={elem.id + selectedObs}
                    className="bg-app-bg p-2 rounded-8px font-semibold text-sm focus:outline-none"
                    value={elem.id}
                    onClick={onClick}
                  >
                    {elem.label}
                  </button>
                );
              }
            })
          : []}
        {cations
          ? cations.map((elem) => {
              if (
                config.x &&
                config.x.element === elem.id &&
                config.x.observatory === selectedObs
              ) {
                return (
                  <button
                    key={elem.id + selectedObs}
                    className="bg-success text-white p-2 rounded-8px font-semibold text-sm focus:outline-none"
                    value={elem.id}
                    onClick={onClick}
                  >
                    {elem.label}
                  </button>
                );
              } else if (
                config.y &&
                config.y.element === elem.id &&
                config.y.observatory === selectedObs
              ) {
                return (
                  <button
                    key={elem.id + selectedObs}
                    className="bg-primary text-white p-2 rounded-8px font-semibold text-sm focus:outline-none"
                    value={elem.label}
                    onClick={onClick}
                  >
                    {elem.id}
                  </button>
                );
              } else {
                return (
                  <button
                    key={elem.id + selectedObs}
                    className="bg-app-bg p-2 rounded-8px font-semibold text-sm focus:outline-none"
                    value={elem.id}
                    onClick={onClick}
                  >
                    {elem.label}
                  </button>
                );
              }
            })
          : []}
        {physico_chemicalParams
          ? physico_chemicalParams.map((elem) => {
              if (
                config.x &&
                config.x.element === elem.id &&
                config.x.observatory === selectedObs
              ) {
                return (
                  <button
                    key={elem.id + selectedObs}
                    className="bg-success text-white p-2 rounded-8px font-semibold text-sm focus:outline-none"
                    value={elem.id}
                    onClick={onClick}
                  >
                    {elem.label}
                  </button>
                );
              } else if (
                config.y &&
                config.y.element === elem.id &&
                config.y.observatory === selectedObs
              ) {
                return (
                  <button
                    key={elem.id + selectedObs}
                    className="bg-primary text-white p-2 rounded-8px font-semibold text-sm focus:outline-none"
                    value={elem.id}
                    onClick={onClick}
                  >
                    {elem.label}
                  </button>
                );
              } else {
                return (
                  <button
                    key={elem.id + selectedObs}
                    className="bg-app-bg p-2 rounded-8px font-semibold text-sm focus:outline-none"
                    value={elem.id}
                    onClick={onClick}
                  >
                    {elem.label}
                  </button>
                );
              }
            })
          : []}
        {technicalParams
          ? technicalParams.map((elem) => {
              if (
                config.x &&
                config.x.element === elem.id &&
                config.x.observatory === selectedObs
              ) {
                return (
                  <button
                    key={elem.id + selectedObs}
                    className="bg-success text-white p-2 rounded-8px font-semibold text-sm focus:outline-none"
                    value={elem.id}
                    onClick={onClick}
                  >
                    {elem.label}
                  </button>
                );
              } else if (
                config.y &&
                config.y.element === elem.id &&
                config.y.observatory === selectedObs
              ) {
                return (
                  <button
                    key={elem.id + selectedObs}
                    className="bg-primary text-white p-2 rounded-8px font-semibold text-sm focus:outline-none"
                    value={elem.id}
                    onClick={onClick}
                  >
                    {elem.label}
                  </button>
                );
              } else {
                return (
                  <button
                    key={elem.id + selectedObs}
                    className="bg-app-bg p-2 rounded-8px font-semibold text-sm focus:outline-none"
                    value={elem.id}
                    onClick={onClick}
                  >
                    {elem.label}
                  </button>
                );
              }
            })
          : []}

        {meteoParams
          ? meteoParams.map((elem) => {
              if (
                config.x &&
                config.x.element === elem.id &&
                config.x.observatory === selectedObs
              ) {
                return (
                  <button
                    key={elem.id + selectedObs}
                    className="bg-success text-white p-2 rounded-8px font-semibold text-sm focus:outline-none"
                    value={elem.id}
                    onClick={onClick}
                  >
                    {elem.label}
                  </button>
                );
              } else if (
                config.y &&
                config.y.element === elem.id &&
                config.y.observatory === selectedObs
              ) {
                return (
                  <button
                    key={elem.id + selectedObs}
                    className="bg-primary text-white p-2 rounded-8px font-semibold text-sm focus:outline-none"
                    value={elem.label}
                    onClick={onClick}
                  >
                    {elem.id}
                  </button>
                );
              } else {
                return (
                  <button
                    key={elem.id + selectedObs}
                    className="bg-app-bg p-2 rounded-8px font-semibold text-sm focus:outline-none"
                    value={elem.id}
                    onClick={onClick}
                  >
                    {elem.label}
                  </button>
                );
              }
            })
          : []}
      </CustomForm>
    </div>
  );
}
