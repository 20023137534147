export const timeZones = [
  { "UTC+0": "GMT, WET" },
  { "UTC+1": "BMT, CET" },
  { "UTC+2": "CAT, CEST, EET" },
  { "UTC+3": "EAT, EEST, MSK" },
  { "UTC+4": "GET, AMT, AZT" },
  { "UTC+5": "MVT, PKT, TJT" },
  { "UTC+6": "ALMT, BST, IOT" },
  { "UTC+7": "WIB, ICT, CXT" },
  { "UTC+8": "AWST, CST, HKT" },
  { "UTC+9": "AWDT, JST , KST" },
  { "UTC+10": "AEST, CHUT, DDUT" },
  { "UTC+11": "AEDT, KOST" },
  { "UTC+12": "ANAT, GILT, NFDT" },
  { "UTC+13": "NZDT, PHOT, TOT" },
  { "UTC+14": "LINT, TOST" },
  { "UTC-1": "AZOT, CVT, EGT" },
  { "UTC-2": "FNT, GST" },
  { "UTC-3": "ART, BRT, PYST" },
  { "UTC-4": "AMT, AST, GYT" },
  { "UTC-5": "ACT, COT, CST" },
  { "UTC-6": "EAST, CST" },
  { "UTC-7": "MST, PDT" },
  { "UTC-8": "AKDT, PST" },
  { "UTC-9": "AKST, GAMT" },
  { "UTC-10": "CKT, HST, TAHT" },
  { "UTC-11": "NUT, SST" },
];
