import { FormattedMessage } from "react-intl";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../Accordion/Accordion";
import { ReactComponent as ConfigIcon } from "../../../../icons/gear-skeleton.svg";
import ThemeConfig from "./ThemeConfig";
import GridConfig from "./GridConfig";
import ToolboxConfig from "./ToolboxConfig";

export default function GeneralConfig({
  expanded,
  handleChangeAccordion,
  setOption,
  option,
}) {
  return (
    <Accordion
      expanded={expanded.has("panel6")}
      onChange={handleChangeAccordion("panel6")}
      backgroundColor="white"
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="flex items-center">
          <p className="text-primary font-medium flex gap-2 items-center">
            <ConfigIcon className="h-5 w-5" />
            <span className="text-normal">
              {" "}
              <FormattedMessage id="extraplot.annex.param17" />
            </span>
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <ThemeConfig themeConfig={option.theme} setOption={setOption} />
        <GridConfig gridConfig={option.option.grid} setOption={setOption} />
        <ToolboxConfig
          setOption={setOption}
          toolboxConfig={option.option.toolbox}
        />
      </AccordionDetails>
    </Accordion>
  );
}
