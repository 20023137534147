import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import axios from "axios";
import cloneDeep from "lodash.clonedeep";
import { ReactComponent as ArrowSkeletonComplete } from "../../../icons/arrow-skeleton-complete.svg";
import { ReactComponent as SaveIcon } from "../../../icons/save.svg";
import AnnexSectionHeader from "./AnnexSectionHeader";
import GraphTypeSection from "./GraphTypeSection";
import ChooseCoupleSection from "./ChooseCoupleSection";
import PanelOptionSection from "./PanelOptionSection";
import LegendSection from "./LegendSection";
import GeneralConfig from "./GeneralConfig/GeneralConfig";
import { GRAPH_TYPES } from "../Const";
import ChooseTimeSection from "./ChooseTimeSection/ChooseTimeSection";

export default function AnnexSection({
  echartRef,
  option,
  setOption,
  handleAddNewCouple,
  handleSaveChart,
}) {
  const [expanded, setExpanded] = useState(new Set(["panel4"]));

  function updateAllData(date_start, date_end) {
    if (option.couples.length !== 0) {
      option.couples.forEach((elem) => {
        updateData(elem, date_start, date_end);
      });
    }
  }

  function updateData(item, date_start, date_end) {
    const payload = !item.x ? { x1: item.y } : { x1: item.x, y1: item.y };
    const url = !item.x
      ? `/dataset/timeseries/from/${date_start}/to/${date_end}`
      : `/dataset/from/${date_start}/to/${date_end}`;
    axios
      .post(process.env.REACT_APP_API_URL + url, payload)
      .then((res) => {
        setOption((prev) => {
          const series = prev.option.series.map((serie) => {
            if (serie.id === item.id) {
              return { ...serie, data: !item.x ? res.data : res.data[0] };
            } else {
              return { ...serie };
            }
          });
          return {
            ...prev,
            option: { ...prev.option, series: series },
            notMerge: true,
          };
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    if (newExpanded) {
      setExpanded((prev) => {
        prev.add(panel);
        return new Set([...prev]);
      });
    } else {
      setExpanded((prev) => {
        prev.delete(panel);
        return new Set([...prev]);
      });
    }
  };

  function handleDeleteCouple(toDeleteId) {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.notMerge = true;
      let toBeDeletedIndex = null;
      //revaluate yaxis
      newOption.option.yAxis = newOption.option.yAxis.filter((yaxis, index) => {
        return yaxis.id !== toDeleteId;
      });
      newOption.option.yAxis = newOption.option.yAxis.map((yaxis, index) => {
        yaxis.position = index % 2 === 0 ? "left" : "right";
        yaxis.offset = index > 1 ? 50 * Math.floor(index / 2) : 0;
        return yaxis;
      });
      //revaluate xaxis
      if (option.graph_type !== "timeseries") {
        newOption.option.xAxis = newOption.option.xAxis.filter(
          (xaxis, index) => {
            return xaxis.id !== toDeleteId;
          }
        );
        newOption.option.xAxis = newOption.option.xAxis.map((xaxis, index) => {
          xaxis.position = index % 2 === 0 ? "bottom" : "top";
          xaxis.offset = index > 1 ? 50 * Math.floor(index / 2) : 0;
          return xaxis;
        });
      }
      if (
        option.graph_type === "timeseries" &&
        newOption.option.yAxis.length === 0
      ) {
        newOption.option.xAxis = [];
      }
      //revaluate series
      newOption.option.series = newOption.option.series.filter(
        (serie, index) => {
          if (serie.id === toDeleteId) {
            toBeDeletedIndex = index;
          }
          return serie.id !== toDeleteId;
        }
      );
      newOption.option.series = newOption.option.series.map((elem, index) => {
        if (index >= toBeDeletedIndex) {
          if (option.graph_type !== "timeseries") {
            elem.xAxisIndex = index;
          }
          elem.yAxisIndex = index;
        }
        return elem;
      });
      newOption.couples = newOption.couples.filter((couple) => {
        return couple.id !== toDeleteId;
      });
      const numberOfAxis = newOption.option.yAxis.length;
      //revaluate the grid
      newOption.option.grid.right =
        40 + (numberOfAxis > 2 ? 50 * (Math.floor(numberOfAxis / 2) - 1) : 0);
      newOption.option.grid.left =
        40 + (numberOfAxis > 2 ? 50 * (Math.ceil(numberOfAxis / 2) - 1) : 0);
      if (option.graph_type !== "timeseries") {
        newOption.option.grid.top =
          40 + (numberOfAxis > 2 ? 50 * (Math.floor(numberOfAxis / 2) - 1) : 0);
        newOption.option.grid.bottom =
          65 + (numberOfAxis > 2 ? 50 * (Math.ceil(numberOfAxis / 2) - 1) : 0);
      }
      return newOption;
    });
  }

  return (
    <div className="h-full w-full relative flex flex-col border-1 border-light-grey-border border-opacity-20 pb-2 px-1 overflow-y-scroll">
      <AnnexSectionHeader
        setOption={setOption}
        setExpanded={setExpanded}
        expanded={expanded}
      />
      <div className="flex-grow">
        {/*chose graph type*/}
        <GraphTypeSection
          expanded={expanded}
          handleChangeAccordion={handleChangeAccordion}
          graphType={GRAPH_TYPES[option.graph_type]}
          setOption={setOption}
        />
        {/*chose time*/}
        <ChooseTimeSection
          expanded={expanded}
          handleChangeAccordion={handleChangeAccordion}
          date_start={option.date_start}
          date_end={option.date_end}
          updateAllData={updateAllData}
          setOption={setOption}
        />
        {/*chose couples*/}
        <ChooseCoupleSection
          echartRef={echartRef}
          expanded={expanded}
          handleChangeAccordion={handleChangeAccordion}
          couples={option.couples}
          handleAddNewCouple={handleAddNewCouple}
          handleDeleteCouple={handleDeleteCouple}
          setOption={setOption}
          option={option}
        />
        <PanelOptionSection
          setOption={setOption}
          option={option}
          expanded={expanded}
          handleChangeAccordion={handleChangeAccordion}
        />
        <LegendSection
          expanded={expanded}
          handleChangeAccordion={handleChangeAccordion}
          legend={option.option.legend}
          setOption={setOption}
        />
        <GeneralConfig
          expanded={expanded}
          handleChangeAccordion={handleChangeAccordion}
          setOption={setOption}
          option={option}
        />
      </div>
      <div className="flex justify-between mt-2">
        <Link to="/extraPlot">
          <div className="bg-primary text-white font-medium rounded-8px flex gap-2 p-2">
            <ArrowSkeletonComplete className="transform -rotate-180" />{" "}
            <FormattedMessage id="extraplot.annex.param26" />
          </div>
        </Link>
        <button
          className="bg-secondary text-white p-y2 px-6 rounded-8px font-medium flex gap-2 items-center"
          onClick={handleSaveChart}
        >
          <SaveIcon /> <FormattedMessage id="extraplot.annex.param27" />
        </button>
      </div>
    </div>
  );
}
