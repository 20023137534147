import React, { useState } from "react";
import cloneDeep from "lodash.clonedeep";
import TextField from "@mui/material/TextField";
import { ReactComponent as SegmentIcon } from "../../../../icons/segment.svg";
import ConfigTab from "../ConfigTab/ConfigTab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "./../Accordion/Accordion";

export default function LineStyleSection({ seriesConfig, setOption }) {
  const [expandedLineStyle, setExpandedLineStyle] = useState("");

  const handleChangeAccordionLineStyle = (panel) => (event, newExpanded) => {
    setExpandedLineStyle(newExpanded ? panel : false);
  };

  function handleLineStyleUpdate(id, newValue, name) {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.option.series.map((elem) => {
        if (elem.id === id) {
          elem.lineStyle[name] = newValue;
        }
        return elem;
      });
      return newOption;
    });
  }

  function handleUpdate(id, newValue, name) {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.option.series.map((elem) => {
        if (elem.id === id) {
          elem[name] = newValue;
        }
        return elem;
      });
      return newOption;
    });
  }

  return (
    <Accordion
      expanded={expandedLineStyle === "LineStyle"}
      onChange={handleChangeAccordionLineStyle("LineStyle")}
      backgroundColor="rgba(142, 155, 187, 0.08)"
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="flex items-center">
          <p className="font-medium flex gap-2 items-center text-primary">
            <SegmentIcon className="text-primary" />
            LineStyle
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="mb-2">
          <p className="font-medium text-xs">Line type :</p>
          <ConfigTab
            items={["solid", "dashed", "dotted"]}
            color="primary"
            name="type"
            selectedValue={seriesConfig.lineStyle.type}
            handleOnClick={(ev) => {
              handleLineStyleUpdate(
                seriesConfig.id,
                ev.target.value,
                ev.target.name
              );
            }}
          />
        </div>
        <div className="mb-2">
          <p className="font-medium text-xs">Width :</p>
          <TextField
            onChange={(ev) => {
              handleLineStyleUpdate(
                seriesConfig.id,
                ev.target.value,
                ev.target.name
              );
            }}
            name="width"
            value={seriesConfig.lineStyle.width}
            className="w-full"
            id="standard-basic"
            variant="outlined"
            placeholder="Line width"
          />
        </div>
        <div className="mb-2">
          <p className="font-medium text-xs">Opacity :</p>
          <TextField
            onChange={(ev) => {
              handleLineStyleUpdate(
                seriesConfig.id,
                ev.target.value,
                ev.target.name
              );
            }}
            name="opacity"
            value={seriesConfig.lineStyle.opacity}
            className="w-full"
            id="standard-basic"
            variant="outlined"
            placeholder="Line opacity"
          />
        </div>
        <div className="mb-2">
          <p className="font-medium text-xs">Smooth:</p>
          <ConfigTab
            items={["true", "false"]}
            color="primary"
            name="smooth"
            selectedValue={seriesConfig.smooth.toString()}
            handleOnClick={(ev) => {
              handleUpdate(
                seriesConfig.id,
                ev.target.value === "true" ? true : false,
                ev.target.name
              );
            }}
          />
        </div>
        <div className="mb-2">
          <p className="font-medium text-xs">Step:</p>
          <ConfigTab
            items={["none", "start", "middle", "end"]}
            color="primary"
            name="step"
            selectedValue={
              seriesConfig.step === false ? "none" : seriesConfig.step
            }
            handleOnClick={(ev) => {
              handleUpdate(
                seriesConfig.id,
                ev.target.value === "none" ? false : ev.target.value,
                ev.target.name
              );
            }}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
