import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import cloneDeep from "lodash.clonedeep";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "./../Accordion/Accordion";

import { ReactComponent as ColorizeIcon } from "../../../../icons/colorize.svg";
import { DEFAULT_DARK_THEMES, DEFAULT_LIGHT_THEMES } from "../../Const";

export default function ThemeConfig({ themeConfig, setOption }) {
  const [expanded, setExpanded] = useState(new Set([]));

  const darkThemelist = DEFAULT_DARK_THEMES.map((theme) => {
    return (
      <ThemeComponent
        key={theme.themeName}
        theme={theme}
        setOption={setOption}
        selectedTheme={themeConfig}
      />
    );
  });

  const lightThemelist = DEFAULT_LIGHT_THEMES.map((theme) => {
    return (
      <ThemeComponent
        key={theme.themeName}
        theme={theme}
        setOption={setOption}
        selectedTheme={themeConfig}
      />
    );
  });

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    if (newExpanded) {
      setExpanded((prev) => {
        prev.add(panel);
        return new Set([...prev]);
      });
    } else {
      setExpanded((prev) => {
        prev.delete(panel);
        return new Set([...prev]);
      });
    }
  };

  return (
    <Accordion
      expanded={expanded.has("themeConfig")}
      onChange={handleChangeAccordion("themeConfig")}
      backgroundColor="rgba(142, 155, 187, 0.08)"
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="flex items-center">
          <p className="text-primary font-medium flex gap-2">
            <ColorizeIcon />
            <span>
              <FormattedMessage id="extraplot.annex.param18" />
            </span>
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="mb-2">
          <p className="font-medium text-xs">Light themes:</p>
          <div className="flex flex-wrap gap-2">{lightThemelist}</div>
          <p className="font-medium text-xs">Dark themes:</p>
          <div className="flex flex-wrap gap-2">{darkThemelist}</div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

function ThemeComponent({ theme, setOption, selectedTheme }) {
  const list = theme.theme.color.slice(0, 6).map((color) => {
    return (
      <div
        key={color}
        title={theme.themeName}
        className="h-5 w-5"
        style={{ backgroundColor: color }}
      ></div>
    );
  });

  function onThemeClick(theme) {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.theme = theme.themeName;
      return newOption;
    });
  }

  return (
    <button
      className={`${
        selectedTheme && selectedTheme === theme.themeName
          ? "border-2"
          : "border-opacity-25 border-1"
      } focus:outline-none rounded-8px  border-primary p-2 flex gap-1`}
      style={{ backgroundColor: theme.theme.backgroundColor }}
      onClick={() => {
        onThemeClick(theme);
      }}
    >
      {list}
    </button>
  );
}
