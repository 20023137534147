/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import ReactECharts from "echarts-for-react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import { setGraphsOptions } from "../../Redux/actions";
import { ReactComponent as PencilIcon } from "../../icons/pencil.svg";
import { ReactComponent as DeleteIcon } from "../../icons/delete.svg";
import { ReactComponent as AddIcon } from "../../icons/add.svg";
import { useIntl } from "react-intl";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { applyUTCtoDate } from "../../utils/date";

export default function ExtraPlotDashboard() {
  const [graphs, setGraphs] = useState(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.user);
  const intl = useIntl();
  const userPreferencesInfo = useSelector((state) => state.userPreferences);

  //Save graphs data to redux store
  useEffect(() => {
    if (graphs && graphs.length !== 0) {
      dispatch(setGraphsOptions(graphs));
    }
  }, [graphs]);

  //get graphs and graphs data
  useEffect(() => {
    if (user) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            `/user/${user.preferred_username}/graph`
        )
        .then((res) => {
          setGraphs(res.data);
          res.data.forEach((graph1) => {
            graph1.content.series.forEach((serie1) => {
              //reconstruct couple from serie id
              const item = { x: {}, y: {}, id: serie1.id, color: null };
              const [x, y] = serie1.id.split("/");
              if (x === "time") {
                item.x = null;
              } else {
                const [element, observatory] = x.split("-");
                item.x.element = element;
                item.x.observatory = observatory;
              }
              const [element, observatory] = y.split("-");
              item.y.element = element;
              item.y.observatory = observatory;
              //populate couples
              setGraphs((prev) => {
                return prev.map((graph) => {
                  if (graph.graph_id === graph1.graph_id) {
                    if (graph.couples) {
                      return { ...graph, couples: [...graph.couples, item] };
                    } else {
                      return { ...graph, couples: [item] };
                    }
                  }
                  return graph;
                });
              });
              //get couple data
              const payload = payloadAccordingToChartType(
                graph1.graph_type,
                item
              );
              const url = urlAccordingToChartType(
                graph1.graph_type,
                graph1.date_start,
                graph1.date_end
              );
              axios
                .post(process.env.REACT_APP_API_URL + url, payload)
                .then((res) => {
                  setGraphs((prev) => {
                    return [
                      ...prev.map((graph2) => {
                        if (graph2.graph_id === graph1.graph_id) {
                          let newSeries = graph2.content.series.map(
                            (serie2) => {
                              if (serie1.id === serie2.id) {
                                return {
                                  ...serie2,
                                  data:
                                    graph1.graph_type === "scatter"
                                      ? res.data[0]
                                      : res.data,
                                };
                              } else {
                                return { ...serie2 };
                              }
                            }
                          );
                          return {
                            ...graph2,
                            content: { ...graph2.content, series: newSeries },
                          };
                        } else {
                          return { ...graph2 };
                        }
                      }),
                    ];
                  });
                })
                .catch((err) => {
                  console.error(err);
                });
            });
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [user]);

  function handleDeleteGraph(graph_id) {
    axios
      .delete(
        process.env.REACT_APP_API_URL +
          `/user/${user.preferred_username}/graph/id/${graph_id}`
      )
      .then((res) => {
        setGraphs((prev) => {
          return prev.filter((graph) => {
            return graph.graph_id !== graph_id;
          });
        });
        enqueueSnackbar(
          intl.formatMessage({
            id: "extraplot.annex.param41",
          }),
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }
        );
      })
      .catch((err) => {
        console.error("err", err);
        enqueueSnackbar(
          intl.formatMessage({
            id: "extraplot.annex.param40",
          }),
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }
        );
      });
  }
  const [open, setOpen] = useState(false);

  function onDeleteButtonClicked(open, setOpen, graphId) {
    const handleClose = () => {
      setOpen(false);
    };
    return (
      <Dialog
        maxWidth={"xs"}
        fullWidth={true}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>
          <p className="text-primary font-medium text-sm">
            <FormattedMessage id="extraplot.param4" />
          </p>
        </DialogTitle>
        <DialogActions>
          <button
            onClick={() => {
              setOpen(false);
              handleDeleteGraph(graphId);
            }}
            className="text-primary bg-transparent-blue-15% py-2 px-1 mr-1 focus:outline-none rounded-8px font-medium"
          >
            <p>
              <FormattedMessage id="extraplot.param5" />
            </p>
          </button>
          <button
            onClick={handleClose}
            className="bg-grey-text text-white py-2 px-1 focus:outline-none rounded-8px font-medium"
          >
            <FormattedMessage id="extraplot.param6" />
          </button>
        </DialogActions>
      </Dialog>
    );
  }
  const [graphIdToDelete, setGraphIdToDelete] = useState();
  const graphList = graphs
    ? graphs.map((graph) => {
        return (
          <div key={graph.graph_id} className="bg-white rounded-8px p-2">
            <div className="flex justify-between mb-2">
              <div>
                <p className="font-medium text-primary">
                  <FormattedMessage id="extraplot.annex.param28" />{" "}
                  <span className="text-grey-light">
                    {applyUTCtoDate(graph.timedate, userPreferencesInfo?.time)}
                  </span>
                </p>
              </div>
              <div className="flex gap-2">
                <Link
                  to={{
                    pathname: "/graphForm/" + graph.graph_id,
                  }}
                  className="text-grey-light h-5 w-5 hover:text-primary"
                >
                  <div>
                    <PencilIcon />
                  </div>
                </Link>
                <button
                  onClick={() => {
                    setOpen(true);
                    setGraphIdToDelete(graph.graph_id);
                  }}
                  className="text-grey-light h-5 w-5 hover:text-primary focus:outline-none"
                >
                  <DeleteIcon />
                </button>
                {open && onDeleteButtonClicked(open, setOpen, graphIdToDelete)}
              </div>
            </div>
            <div className="h-80">
              <ChartComponent
                option={{
                  option: { ...graph.content },
                  theme: graph.theme,
                  notMerge: true,
                }}
              />
            </div>
          </div>
        );
      })
    : [];

  return (
    <div className="bg-app-bg h-full w-full ml-16 pb-2 px-2">
      <div className="flex justify-between border-b-1 border-primary border-opacity-20 p-2">
        <p className="font-medium text-2xl text-primary">
          Hello {user && user.given_name} 👋
        </p>
        <Link
          to={{
            pathname: "/graphForm/" + 0,
          }}
          className="bg-primary text-white p-2 font-medium rounded-8px flex"
        >
          <div className="flex gap-2 items-center">
            <AddIcon />
            <FormattedMessage id="extraplot.param3" />
          </div>
        </Link>
      </div>
      {graphs && graphs.length !== 0 && (
        <div className="grid gap-2 grid-cols-3 mt-2">{graphList}</div>
      )}
      {graphs && graphs.length === 0 && (
        <div className="flex items-center justify-center h-full w-full">
          <div className="text-center">
            <p className="text-xl text-primary font-medium">
              <FormattedMessage id="extraplot.param1" />
            </p>
            <p className="text-grey-light font-medium">
              <FormattedMessage id="extraplot.param2" />
            </p>
          </div>
        </div>
      )}
      {!graphs && (
        <div className="flex gap-2 items-center justify-center h-full w-full">
          <CircularProgress size={40} />
          <p className="text-xl text-primary font-medium">Loading ...</p>
        </div>
      )}
    </div>
  );
}

export function payloadAccordingToChartType(chartType, item) {
  switch (chartType) {
    case "timeseries":
      return { x1: item.y };
    case "scatter":
      return { x1: item.x, y1: item.y };
    default:
      return { x1: item.x, y1: item.y };
  }
}

export function urlAccordingToChartType(chartType, date_start, date_end) {
  switch (chartType) {
    case "timeseries":
      return `/dataset/timeseries/from/${date_start}/to/${date_end}`;
    case "scatter":
      return `/dataset/from/${date_start}/to/${date_end}`;
    default:
      return `/dataset/from/${date_start}/to/${date_end}`;
  }
}

function ChartComponent({ option, echartRef }) {
  return (
    <ReactECharts
      ref={echartRef}
      option={option.option}
      style={{ height: "100%", width: "100%" }}
      notMerge={option.notMerge}
      theme={option.theme}
    />
  );
}
