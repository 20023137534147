import { Menu, MenuItem, TableCell } from "@mui/material";
import styled from "styled-components";
import { Col } from "react-flexbox-grid";
import withStyles from "@mui/styles/withStyles";
import TextField from "@mui/material/TextField";
import { ValidityForValue } from "./Alerts.service";

export const StyledButton = styled.button`
  width: 131px;
  background: ${(props) =>
    props.disabled ? "rgba(0, 0, 0, 0.12)" : "#2975ff"};
  height: 32px;
  border-radius: 5px;
  padding: 10px, 34px, 10px, 34px;
  font-weight: 700;
  font-size: 12px;
  color: ${(props) => (props.disabled ? "rgba(0, 0, 0, 0.26)" : "#ffffff")};
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  &:hover {
    background: ${(props) => (props.disabled ? "#EBECF3" : "#17469C")};
  }
`;

export const BlackNormalText = styled.p`
  font-weight: 500;
  font-size: 20px;
  color: #0d1441;
`;

export const BlackTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #0d1441;
`;
export const DivWithBlackTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #0d1441;
`;

export const BlackMediumText = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: #343a40;
`;

export const BlackMediumTextWithMargin = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: #343a40;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const SmalBlackText = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: #0d1441;
`;
export const DivWithBoldBlackText = styled.div`
  font-weight: 500;
  font-size: 20px;
  color: #0d1441;
`;

export const SmallBlueText = styled.p`
  font-weight: 300;
  font-size: 12px;
  color: #2975ff;
`;

export const GreyText = styled.p`
  font-weight: 300;
  font-size: 10px;
  color: #8488a5;
`;

export const StyledMenuItem = styled(MenuItem)`
  height: 60px;
  width: 399px;
`;

export const ModifAlertMenuItem = styled(MenuItem)`
  height: 30px;
  width: 232px;
`;

export const ModifMenuItem = styled(MenuItem)`
  height: 48px;
  width: 300px;
`;

export const GreySmallText = styled.p`
  font-weight: 300;
  font-size: 12px;
  color: #8488a5;
  margin-left: 10px;
  margin-top: 1;
`;

export const StyledDiv = styled.div`
  height: 48px;
  width: 383px;
  border: 1px solid #ebecf3;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  &:hover {
    border: 1px solid #2975ff;
  }
`;

export const BlueBorder = styled.div`
  height: 48px;
  width: 383px;
  border: 1px solid #2975ff;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
`;

export const BlueSmallText = styled.p`
  font-weight: 300;
  font-size: 12px;
  color: #2975ff;
  margin-left: 10px;
  margin-top: 1;
`;

export const BlueButton = styled.button`
  width: 136px;
  height: 32px;
  border-radius: 5px;
  padding: 4px 34px 4px 34px;
  background: #2975ff;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  &:hover {
    background: #17469c;
  }
`;

export const DarkGreyText = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: #8488a5;
  margin: 11px, 17px;
`;

export const BlackBoldText = styled.p`
  font-weight: 500;
  font-size: 19px;
  color: #343a40;
  padding: 5px 0px 5px 0px;
  margin-left: 8px;
`;

export const BlackSmallText = styled.p`
  font-weight: 300;
  font-size: 14px;
  color: #343a40;
  padding: 12px 0px 12px 0px;
  margin-left: 8px;
`;

export const ButtonWithBlueTextWhiteBackground = styled.button`
  width: 131px;
  height: 32px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  color: #2975ff;
  &:hover {
    background: #ebecf3;
  }
`;

export const StyledMenu = styled(Menu)`
  max-height: 280px;
`;

export const GreyDiv = styled.div`
  height: 60px;
  width: 399px;
  border: 1px solid #ebecf3;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
`;

export const NormalDarkText = styled.p`
  font-weight: 300;
  font-size: 12px;
  colot: #343a40;
  margin-left: 10px;
`;

export const BlackText = styled.span`
  font-size: 12px;
  color: #343a40;
  font-weight: 300;
`;

export const BlueNormalText = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: #2975ff;
`;

export const BlueNormalTextWithPadding = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: #2975ff;
  padding-top: 1;
`;

export const NormalGrayText = styled.p`
  font-weight: 500;
  font-size: 10px;
  color: #8488a5;
`;
export const GrayText = styled.p`
  font-weight: 300;
  font-size: 12px;
  color: #8488a5;
`;

export const SmallGrayText = styled.p`
  font-weight: 300;
  font-size: 10px;
  color: #8488a5;
`;

export const DivWithProps = styled.div`
  width: 800px;
  height: 46px;
  border: ${(props) =>
    props.empty ? "1px solid #ebecf3" : "1px solid #2975ff"};
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  &:hover {
    border: 1px solid #2975ff;
  }
`;

export const WhiteButtonWithBlueText = styled.button`
  width: 865px;
  height: 46px;
  border-radius: 5px;
  padding: 8px 78px 8px 16px;
  border: 1px solid #2975ff;
  font-weight: 700;
  font-size: 14px;
  color: #2975ff;
  margin-top: 15px;
  &:hover {
    background: #ebecf3;
  }
`;

export const GrayInsideDiv = styled.div`
  width: 53px;
  height: 48px;
  border-radius: 3p;
  background: #ebecf3;
  border: 1px solid #ebecf3;
  margin-left: 10px;
`;

export const DivWithShadow = styled.div`
  width: 800px;
  cursor: pointer;
  border-radius: 2px;
  background: #ffffff;
  box-shadow: 5px 5px 40px 5px rgba(0, 0, 0, 0.2);
`;

export const DivWithPadding = styled.div`
  padding: 20px;
`;

export const ColWithProps = styled(Col)`
  padding-top: 30px;
  margin-right: ${(props) => (props.between ? "135px" : "-15px")};
`;

export const RedButton = styled.button`
  background: #f04545;
  width: 124px;
  height: 32px;
  border-radius: 5px;
  padding: 10px, 34px, 10px, 34px;
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;
  &:hover {
    background: #cc1e1e;
  }
`;

export const StyledBlackBoldText = styled.p`
  color: #343a40;
  font-weight: 700;
  font-size: 17px;
`;

export const HeaderText = styled.p`
  color: #8488a5;
  font-weight: 500;
  font-size: 12px;
  font-family: Satoshi;
`;

export const BlueGrayText = styled.p`
  font-weight: 500;
  font-size: 10px;
  margin-left: 20px;
  color: ${(props) => (props.activateAlerte ? "#2975FF" : "#8488a5")};
`;

const TextFieldWithStyles = withStyles({
  root: {
    background: "#ebecf3",
    border: "1px solid #ebecf3",
    "& ::placeholder": {
      fontWeight: 500,
      fontSize: "14px",
      color: "#8488a5",
    },
    "& .MuiInputBase-root": {
      width: "53px",
      height: "48px",
    },
    "& .MuiOutlinedInput-root": {
      borderColor: "#f5f5f5",
      "& fieldset": {
        borderColor: "#f5f5f5",
      },
      "&:hover fieldset": {
        borderColor: "#2c5aff",
      },
    },
  },
})(TextField);

export const StyledTextField = styled(TextFieldWithStyles)`
  & .MuiInputBase-colorPrimary {
    color: ${(props) =>
      props.validity === ValidityForValue.VALID
        ? "#2975FF"
        : props.validity === ValidityForValue.INVALID
        ? "#F04545"
        : "#8488A5"};
  }
`;

export const ErrorText = styled.div`
  color: #f04545;
  font-size: 12px;
  font-weight: 300;
`;

export const StyledTableCell = styled(TableCell)`
  padding: 10px;
`;

export const StyledBigText = styled.p`
  font-family: Satoshi;
  font-weight: 700;
  font-size: 25px;
  color: #0d1441;
`;

export const StyledParagraph = styled.p`
  font-family: Roboto;
  font-weight: 400;
  font-size: 14px;
  color: #0d1441;
`;

export const StyledImage = styled.img`
  height: calc(100vh - 0px);
  transform: translate(0px, -0px);
`;

export const DivWithoutHover = styled.div`
  height: 48px;
  width: 383px;
  border: 1px solid #ebecf3;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
`;
