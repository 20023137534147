export const getRandomColor = (name) => {
  var color = "#";
  if (getInitials(name) === "A") {
    color += "2975FF";
  } else if (getInitials(name) === "B") {
    color += "17469C";
  } else if (getInitials(name) === "C") {
    color += "F04545";
  } else if (getInitials(name) === "D") {
    color += "CC1E1E";
  } else if (getInitials(name) === "E") {
    color += "176A2A";
  } else if (getInitials(name) === "F") {
    color += "7963FE";
  } else if (getInitials(name) === "G") {
    color += "50D86E";
  } else if (getInitials(name) === "H") {
    color += "FFC90B";
  } else if (getInitials(name) === "I") {
    color += "FC610A";
  } else if (getInitials(name) === "J") {
    color += "FC0A61";
  } else if (getInitials(name) === "K") {
    color += "3A0AFC";
  } else if (getInitials(name) === "L") {
    color += "128FD6";
  } else if (getInitials(name) === "M") {
    color += "73BD15";
  } else if (getInitials(name) === "N") {
    color += "2975FF";
  } else if (getInitials(name) === "O") {
    color += "17469C";
  } else if (getInitials(name) === "P") {
    color += "F04545";
  } else if (getInitials(name) === "Q") {
    color += "CC1E1E";
  } else if (getInitials(name) === "R") {
    color += "176A2A";
  } else if (getInitials(name) === "S") {
    color += "7963FE";
  } else if (getInitials(name) === "T") {
    color += "50D86E";
  } else if (getInitials(name) === "U") {
    color += "FFC90B";
  } else if (getInitials(name) === "V") {
    color += "FC610A";
  } else if (getInitials(name) === "W") {
    color += "FC0A61";
  } else if (getInitials(name) === "X") {
    color += "3A0AFC";
  } else if (getInitials(name) === "Y") {
    color += "73BD15";
  } else if (getInitials(name) === "Z") {
    color += "2975FF";
  }
  return color;
};

const getInitials = (name) => {
  if (!name) {
    return "";
  }
  let initials;
  const nameSplit = name.split(" ");
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else return;

  return initials.toUpperCase();
};

export const createImageFromInitials = (size, name, color) => {
  if (name == null) return;
  name = getInitials(name);

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = canvas.height = size;

  context.fillStyle = "#ffffff";
  context.fillRect(0, 0, size, size);

  context.fillStyle = `${color}50`;
  context.fillRect(0, 0, size, size);

  context.fillStyle = color;
  context.textBaseline = "middle";
  context.textAlign = "center";
  context.font = `${size / 2}px Satoshi`;
  context.fillText(name, size / 2, size / 2);

  return canvas.toDataURL();
};

// Helper to validate email based on regex
const EMAIL_REGEX =
  /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

export const validateEmail = (email) => {
  if (
    typeof email === "string" &&
    email.length > 5 &&
    email.length < 61 &&
    EMAIL_REGEX.test(email)
  ) {
    return email.toLowerCase();
  } else {
    return false;
  }
};
