import React, { useState, useEffect, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Row, Col } from "react-flexbox-grid";

import { useSnackbar } from "notistack";
import axios from "axios";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Radio from "@mui/material/Radio";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Dialog, Menu } from "@mui/material";
import { MenuItem } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import { ReactComponent as DropDownIcon } from "../../icons/sort-arrow-down.svg";
import { ReactComponent as DropUpIcon } from "../../icons/sort-arrow-up.svg";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import { validateEmail } from "../../components/utils";

import { ReactComponent as TickIcon } from "../../icons/tick.svg";
import { ReactComponent as MoreIcon } from "../../icons/more.svg";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { ReactComponent as LitleBlueCloseIcon } from "../../icons/little-close-blue.svg";
import { ReactComponent as GrayCloseIcon } from "../../icons/gray-close.svg";
import { ReactComponent as CheckBlueIcon } from "../../icons/check-blue.svg";
import { ReactComponent as FlechIcon } from "../../icons/flèche.svg";
import { useSearchRiverlabs } from "./../../hooks/useSearchRiverlabs";
import { getAllUsers } from "./UserManagement.service";

import {
  RedButton,
  StyledSpan,
  StyleFlexWrapDiv,
  HiddenScrollBar,
  OverFlowAutoDiv,
  SpanUnderEmail,
  RedText,
  DivWithBorderRadius,
  StyledCol,
  StyledDiv,
  StyledCheckButton,
  StyledRow,
  GreenButton,
  BlueButton,
  PurpleButton,
  DivWithGap,
  RowWithGap,
  StyledText,
  StyledParagraph,
  GrayNormalText,
  GrayBoldText,
  ColWithRowDirection,
  HeaderText,
  GraySmallText,
  BlackNormalText,
  BlackBoldTextSatoshiFont,
  StyledImage,
  StyledTableContainer,
  InviteButton,
  CancelWithHover,
  SmallCancelWithHover,
  SendBlueButton,
  SmallFontCancelWithHover,
} from "./UsersManagement.style";
import { createPicture, StyledTextField } from "./Const";
import { isReaderOnly } from "./../../utils/roles";
import { useMemo } from "react";
import { capitalizeFirstLetter } from "../../utils/char";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import {
  formatRiverlabForBackend,
  stationToUpperCase,
} from "../../utils/riverlabs";

export default function UsersManagement() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openListOfteamsEl, setOpenListOfteamsEl] = useState(null);
  const [inviteNewUserAnchor, setInviteNewUserAnchor] = useState(null);
  const [emailToInviteWithOpenModalIndex, setEmailToInviteWithOpenModalIndex] =
    useState(0);
  const [chosenRiverIndex, setChosenRiverIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalUser, setOpenModalUser] = useState(null);
  const isMobile = useSelector((state) => state.isMobile);
  const connectedRiverlab = useSearchRiverlabs();
  const [chosenRiver, setChosenRiver] = useState("");
  const [chosenRiversToInvite, setChosenRiversToInvite] = useState([]);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState("");
  const [validEmails, setValidEmails] = useState([]);
  const [validEmailsRoles, setValidEmailsRoles] = useState([]);
  const [invalidEmailError, setInvalidEmailError] = useState(false);
  const [openListOfTeams, setOpenListOfTeams] = useState(false);
  const [chosenRole, setChosenRole] = useState("writer");
  const [usersToInviteInformations, setUsersToInviteInformations] = useState(
    []
  );
  const userRoles = useSelector((state) => state.userRoles);
  const user = useSelector((state) => state.user);
  const connectedUserEmail = user && user.email;
  const language = useSelector((state) => state.language);

  const userName = user && user.given_name;
  const senderEmail = user && user.email;
  const handleClick = (event, user) => {
    setAnchorEl(event.currentTarget);
    setOpenModalUser(user);
  };
  const checkConnectedUserRoleByRiver = (river) => {
    return userRoles[river];
  };

  const { enqueueSnackbar } = useSnackbar();

  const intl = useIntl();

  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  const fetchUsers = useCallback(async () => {
    const river = formatRiverlabForBackend(chosenRiver);
    const registeredUsers = await axios
      .get(process.env.REACT_APP_FRONTEND_URL + `/api/users/team/${river}`)
      .then((resp) => resp.data);
    const invitedUsers = await axios
      .get(
        process.env.REACT_APP_FRONTEND_URL + `/api/users/invited-users/${river}`
      )
      .then((resp) => resp.data);
    setUsers([...registeredUsers, ...invitedUsers]);
  }, [chosenRiver]);
  useEffect(() => {
    if (chosenRiver === "all") {
      setChosenRiver("");
      setChosenRiverIndex(null);
      getAllUsers(connectedRiverlab, setUsers);
    } else {
      fetchUsers();
    }
  }, [chosenRiver, fetchUsers, connectedRiverlab]);

  useEffect(() => {
    if (connectedRiverlab.length > 0) {
      setChosenRiver("all");
      setChosenRiversToInvite([connectedRiverlab[0]]);
    }
  }, [connectedRiverlab]);

  useEffect(() => {
    setChosenRiversToInvite([chosenRiver]);
  }, [chosenRiver]);

  const handleInviteUsers = () => {
    axios
      .post(process.env.REACT_APP_FRONTEND_URL + "/api/users/invite", {
        emails: validEmails,
        role: chosenRole,
        teams: chosenRiversToInvite
          .filter((river) => river !== "")
          .map((station) => formatRiverlabForBackend(station)),
        senderName: userName,
        senderEmail: senderEmail,
        language: language,
      })
      .then(() => {
        if (chosenRiver !== "") {
          fetchUsers();
        } else getAllUsers(connectedRiverlab, setUsers);
      });

    enqueueSnackbar(
      intl.formatMessage({ id: "Invitation sent successfully" }),
      {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      }
    );

    setInvalidEmailError(false);
    setOpen(false);
    setMessage("");
    setValidEmails([]);
    setValidEmailsRoles([]);
  };

  const modifyUserRole = (newRole) => {
    const usersWithUpdatedRoles = users.map((currentUser) => {
      if (currentUser.email === openModalUser.email) {
        const updatedUser = {
          ...currentUser,
          role: newRole,
        };
        axios.put(
          process.env.REACT_APP_FRONTEND_URL + "/api/users/modify/user",
          {
            email: currentUser.email,
            role: newRole,
            team: currentUser.team,
          }
        );
        enqueueSnackbar(
          intl.formatMessage({ id: "Role updated successfully" }),
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }
        );
        setOpenModalUser(updatedUser);
        setAnchorEl(null);
        return updatedUser;
      }
      return currentUser;
    });
    setUsers(usersWithUpdatedRoles);
  };

  const accessDetails = (role) => {
    if (role === "admin") {
      return intl.formatMessage({ id: "usermanagement.param7" });
    } else if (role === "writer") {
      return intl.formatMessage({ id: "usermanagement.param8" });
    } else if (role === "reader") {
      return intl.formatMessage({ id: "usermanagement.param9" });
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const renderRemoveClicked = () => {
    const handleClose = () => {
      setOpenModal(false);
      setAnchorEl(null);
    };

    const handleDeleteUser = async () => {
      await axios.delete(
        process.env.REACT_APP_FRONTEND_URL + "/api/users/delete/user",
        {
          headers: {},
          data: {
            email: openModalUser.email,
            team: openModalUser.team,
          },
        }
      );
      enqueueSnackbar(intl.formatMessage({ id: "User deleted successfully" }), {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      setOpenModal(false);
      setAnchorEl(null);
      if (chosenRiver !== "") {
        fetchUsers();
      } else getAllUsers(connectedRiverlab, setUsers);
    };

    return (
      <Dialog
        maxWidth={"xs"}
        fullWidth={true}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setAnchorEl(null);
        }}
      >
        <DialogTitle style={{ padding: "15px" }}>
          <div className="flex items-center relative">
            <p
              style={{
                fontFamily: "Satoshi",
                fontWeight: 700,
                fontSize: "16px",
                color: "#0D1441",
              }}
            >
              <FormattedMessage id="usermanagement.param19" />
            </p>
            <button
              className="absolute right-0 top-1"
              onClick={() => {
                setOpenModal(false);
                setAnchorEl(null);
              }}
            >
              <CloseIcon className="text-primary h-3 w-3 " />
            </button>
          </div>
        </DialogTitle>
        <Divider />
        <div className="flex flex-col gap-2 py-3 ml-4">
          <p
            style={{
              fontWeight: 400,
              fontSize: "14px",
              color: "#0D1441",
              fontFamily: "Roboto",
              paddingTop: "15px",
              paddingBottom: "20px",
            }}
          >
            {
              <span style={{ fontWeight: 700 }}>
                {capitalizeFirstLetter(
                  openModalUser.firstName ||
                    intl.formatMessage({ id: "this user" })
                )}
              </span>
            }
            <FormattedMessage id="usermanagement.param20" />
          </p>
        </div>
        <DialogActions style={{ paddingTop: "0px", paddingBottom: "15px" }}>
          <CancelWithHover onClick={handleClose}>
            <FormattedMessage id="usermanagement.param17" />
          </CancelWithHover>
          <RedButton
            onClick={handleDeleteUser}
            className="text-white py-2 px-1 focus:outline-none rounded-8px font-medium"
            style={{
              width: "124px",
              height: "32px",
              borderRadius: "5px",
              padding: "10px 34px 10px 34px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Roboto",
            }}
          >
            <FormattedMessage id="usermanagement.param21" />
          </RedButton>
        </DialogActions>
      </Dialog>
    );
  };
  const renderCustomizeStatus = (user) => {
    const status = user.username ? "Accepté" : "En attente";
    return (
      <StyleFlexWrapDiv>
        <div>
          <StyledSpan
            className={`${
              status === "En attente" ? "bg-warning" : "bg-success"
            } 
            inline-block rounded-full border border-white`}
          ></StyledSpan>
        </div>{" "}
        <div>
          <p>{status}</p>
        </div>
      </StyleFlexWrapDiv>
    );
  };

  const RadioButtonClicked = (event) => {
    setChosenRole(event.target.value);
  };

  const userToInviteWithWriterRole = useMemo(
    () =>
      usersToInviteInformations.find((user) =>
        Object.values(user).some((userRoles) =>
          Object.values(userRoles).some(
            (role) => role === "writer" || role === "admin"
          )
        )
      ),
    [usersToInviteInformations]
  );
  const userToInviteWithAdminRole = useMemo(
    () =>
      usersToInviteInformations.find((user) =>
        Object.values(user).some((userRoles) =>
          Object.values(userRoles).some((role) => role === "admin")
        )
      ),
    [usersToInviteInformations]
  );

  const usersToInviteRiverlabs = usersToInviteInformations.flatMap(
    (userToInvite) =>
      Object.values(userToInvite).flatMap((userToInviteRoleByTeam) =>
        Object.keys(userToInviteRoleByTeam)
      )
  );

  useEffect(() => {
    if (
      userToInviteWithAdminRole ||
      userToInviteWithWriterRole ||
      isReaderOnly(userRoles)
    ) {
      setChosenRole("reader");
    }
  }, [userToInviteWithAdminRole, userToInviteWithWriterRole, userRoles]);

  const renderInviteButtonClicked = () => {
    const handleClose = () => {
      setInvalidEmailError(false);
      setOpen(false);
      setMessage("");
      setValidEmails([]);
      setValidEmailsRoles([]);
    };

    const setRoleForEmailToInvite = (role) => {
      setValidEmailsRoles((prevValidEmailsRoles) => {
        prevValidEmailsRoles[emailToInviteWithOpenModalIndex] = role;
        return [...prevValidEmailsRoles];
      });
      setInviteNewUserAnchor(null);
    };

    const onEmailAddToInvite = async () => {
      const updatedValidEmails = [...validEmails, message];
      setValidEmails(updatedValidEmails);
      setValidEmailsRoles((prevValidEmailsRoles) => [
        ...prevValidEmailsRoles,
        "writer",
      ]);
      setInvalidEmailError(false);
      setMessage("");
      const usersToInviteToInviteData = await Promise.all(
        updatedValidEmails.map((emailToInvite) =>
          axios
            .get(
              process.env.REACT_APP_FRONTEND_URL +
                `/api/users/user/${emailToInvite} `
            )
            .then((resp) => ({ [emailToInvite]: resp.data }))
        )
      );
      setUsersToInviteInformations(usersToInviteToInviteData);
    };
    return (
      <HiddenScrollBar
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "350px",
              height: "100%",
              maxHeight: "532px",
            },
          },
        }}
        fullWidth={true}
        open={open}
        onClose={() => {
          setOpen(false);
          setMessage("");
          setValidEmails([]);
          setValidEmailsRoles([]);
        }}
      >
        <DialogTitle style={{ padding: "15px" }}>
          <div className="flex items-center relative">
            <p
              style={{
                fontFamily: "Satoshi",
                fontWeight: 700,
                fontSize: "16px",
                color: "#0D1441",
              }}
            >
              <FormattedMessage id="usermanagement.param14" />
            </p>
            <button
              className="absolute right-0 top-1"
              onClick={() => {
                setOpen(false);
                setMessage("");
                setValidEmails([]);
                setValidEmailsRoles([]);
                setInvalidEmailError(false);
              }}
            >
              <GrayCloseIcon className="text-primary h-3 w-3 " />
            </button>
          </div>
        </DialogTitle>
        <Divider />
        <OverFlowAutoDiv className="flex flex-col gap-2 py-3 ml-4 no-scrollbar">
          <p
            style={{
              fontWeight: 700,
              fontSize: "14px",
              color: "#343A40",
              fontFamily: "Roboto",
            }}
          >
            <FormattedMessage id="usermanagement.param15" />
          </p>
          <StyledTextField
            className="bg-opacity-3"
            style={{
              fontFamily: "Roboto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "308px",
            }}
            variant="outlined"
            placeholder={intl.formatMessage({
              id: "usermanagement.param16",
            })}
            value={message}
            onChange={handleChange}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
                if (validateEmail(message)) {
                  onEmailAddToInvite();
                } else {
                  setInvalidEmailError(true);
                }
              }
            }}
          />
          {validateEmail(message) && (
            <SpanUnderEmail className="flex justify-between items-center p-4 border-1 border-primary border-opacity-15 w-full">
              <p
                style={{
                  color: "#2975FF",
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  fontSize: "14px",
                }}
              >
                {message}
              </p>
              <SmallCancelWithHover onClick={onEmailAddToInvite}>
                <FormattedMessage id="usermanagement.param6" />
              </SmallCancelWithHover>
            </SpanUnderEmail>
          )}
          {invalidEmailError && !validateEmail(message) && (
            <RedText>
              <FormattedMessage id="usermanagement.param18" />
            </RedText>
          )}
          {validEmails.map((emailToInvite) => (
            <DivWithBorderRadius className="flex justify-between items-center p-4 border-1 hover:border-primary">
              <Row className="flex justify-between w-full">
                <StyledCol className="mr-2 flex">
                  {createPicture(emailToInvite, 30, 30)}
                  <p
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#0D1441",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {emailToInvite}
                  </p>
                </StyledCol>
                <button
                  onClick={() => {
                    //To do Update the list of emails
                    setValidEmails(
                      validEmails.filter(
                        (validEmail) => validEmail !== emailToInvite
                      )
                    );
                    setUsersToInviteInformations((prev) =>
                      prev.filter(
                        (userRolesByEmail) =>
                          Object.keys(userRolesByEmail).indexOf(
                            emailToInvite
                          ) === -1
                      )
                    );
                  }}
                >
                  <CloseIcon className="text-primary h-3 w-3" />
                </button>
              </Row>
            </DivWithBorderRadius>
          ))}
          {validEmails.length > 0 && (
            <>
              <p
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 700,
                  fontSize: "12px",
                  color: "#343A40",
                }}
              >
                <FormattedMessage id="team" />
              </p>
              {connectedRiverlab.map(
                (riverLab) =>
                  !usersToInviteRiverlabs.includes(
                    formatRiverlabForBackend(riverLab)
                  ) && (
                    <StyledDiv
                      style={{
                        justifyContent: "space-between",
                        border: `${
                          chosenRiversToInvite.indexOf(riverLab) !== -1
                            ? "#2975FF"
                            : "#EBECF3"
                        } 1px solid`,
                      }}
                      className="flex justify-between cursor-pointer items-center p-4 rounded-5px border-1 hover:border-primary"
                      onClick={() => {
                        if (chosenRiversToInvite.indexOf(riverLab) === -1) {
                          setChosenRiversToInvite(
                            (prevChosenRiversToInviten) => [
                              ...prevChosenRiversToInviten,
                              riverLab,
                            ]
                          );
                        } else if (chosenRiversToInvite.length > 1) {
                          setChosenRiversToInvite((prevChosenRiversToInviten) =>
                            prevChosenRiversToInviten.filter(
                              (currentRiverLab) => currentRiverLab !== riverLab
                            )
                          );
                        }
                      }}
                    >
                      <p
                        style={{
                          color:
                            chosenRiversToInvite.indexOf(riverLab) !== -1
                              ? "#2975FF"
                              : "#8488A5",

                          fontFamily: "Roboto",
                          fontWeight: 700,
                          fontSize: "12px",
                        }}
                      >
                        {riverLab}
                      </p>
                      {chosenRiversToInvite.indexOf(riverLab) !== -1 && (
                        <StyledCheckButton className="text-primary" />
                      )}
                    </StyledDiv>
                  )
              )}
            </>
          )}
          {validEmails.length > 0 && (
            <>
              <p
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 700,
                  fontSize: "12px",
                  color: "#343A40",
                }}
              >
                <FormattedMessage id="role" />
              </p>
              {!isReaderOnly(userRoles) && (
                <>
                  {!(
                    chosenRiversToInvite
                      .filter((river) => river !== "")
                      .map((station) =>
                        station !== "Guilin"
                          ? userRoles[station] === "admin"
                          : userRoles["guilin"] === "admin"
                      )
                      .filter((result) => result === false).length !== 0
                  ) && (
                    <StyledDiv
                      style={{
                        border: `${
                          chosenRole === "admin" ? "#2975FF" : "#EBECF3"
                        } 1px solid `,
                      }}
                      className="flex justify-between items-center p-4 border-opacity-15 rounded-5px hover:border-primary cursor-pointer"
                      onClick={() => setChosenRole("admin")}
                    >
                      <StyledRow>
                        <DivWithGap>
                          <Col
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <GreenButton className="text-white focus:outline-none font-semibold rounded-5px">
                              <FormattedMessage id="usermanagement.param10" />
                            </GreenButton>
                          </Col>
                          <Col
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <p
                              style={{
                                fontFamily: "Roboto",
                                fontWeight: 400,
                                fontSize: "10px",
                                color: "#8488A5",
                              }}
                            >
                              <FormattedMessage
                                id="usermanagement.param23"
                                values={{ br: <br /> }}
                              />
                            </p>
                          </Col>
                        </DivWithGap>
                        <Col>
                          <Radio
                            checked={chosenRole === "admin"}
                            onChange={RadioButtonClicked}
                            value="admin"
                            name="radio-buttons"
                          />
                        </Col>
                      </StyledRow>
                    </StyledDiv>
                  )}
                  {(!(
                    chosenRiversToInvite
                      .filter((river) => river !== "")
                      .map((station) =>
                        station !== "Guilin"
                          ? userRoles[station] === "admin"
                          : userRoles["guilin"] === "admin"
                      )
                      .filter((result) => result === false).length !== 0
                  ) ||
                    !(
                      chosenRiversToInvite
                        .filter((river) => river !== "")
                        .map((station) =>
                          station !== "Guilin"
                            ? userRoles[station] === "writer"
                            : userRoles["guilin"] === "writer"
                        )
                        .filter((result) => result === false).length !== 0
                    )) && (
                    <StyledDiv
                      style={{
                        border: `${
                          chosenRole === "writer" ? "#2975FF" : "#EBECF3"
                        } 1px solid `,
                      }}
                      className="flex justify-between items-center p-4 border-opacity-15 rounded-5px hover:border-primary cursor-pointer"
                      onClick={() => setChosenRole("writer")}
                    >
                      <StyledRow>
                        <DivWithGap>
                          <Col
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <BlueButton className="text-white focus:outline-none font-semibold rounded-5px">
                              <FormattedMessage id="usermanagement.param11" />
                            </BlueButton>
                          </Col>
                          <Col
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <p
                              style={{
                                fontFamily: "Roboto",
                                fontWeight: 400,
                                fontSize: "10px",
                                color: "#8488A5",
                              }}
                            >
                              <FormattedMessage
                                id="usermanagement.param24"
                                values={{ br: <br /> }}
                              />
                            </p>
                          </Col>
                        </DivWithGap>
                        <Col>
                          <Radio
                            checked={chosenRole === "writer"}
                            onChange={RadioButtonClicked}
                            value="writer"
                            name="radio-buttons"
                          />
                        </Col>
                      </StyledRow>
                    </StyledDiv>
                  )}
                </>
              )}

              <StyledDiv
                style={{
                  border: `${
                    chosenRole === "reader" ? "#2975FF" : "#EBECF3"
                  } 1px solid `,
                }}
                className="flex justify-between items-center p-4 border-opacity-15 rounded-5px hover:border-primary cursor-pointer"
                onClick={() => setChosenRole("reader")}
              >
                <StyledRow>
                  <DivWithGap>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <PurpleButton>
                        <FormattedMessage id="usermanagement.param12" />
                      </PurpleButton>
                    </Col>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: 400,
                          fontSize: "10px",
                          color: "#8488A5",
                        }}
                      >
                        <FormattedMessage
                          id="usermanagement.param25"
                          values={{ br: <br /> }}
                        />
                      </p>
                    </Col>
                  </DivWithGap>
                  <Col>
                    <Radio
                      checked={
                        chosenRole === "reader" ||
                        (isReaderOnly(userRoles) && setChosenRole("reader"))
                      }
                      onChange={RadioButtonClicked}
                      value="reader"
                      name="radio-buttons"
                    />
                  </Col>
                </StyledRow>
              </StyledDiv>
            </>
          )}
        </OverFlowAutoDiv>
        {validEmails.length > 0 && <Divider />}
        <DialogActions>
          {validEmails.length > 0 ? (
            <>
              <CancelWithHover onClick={handleClose}>
                <FormattedMessage id="usermanagement.param17" />
              </CancelWithHover>
              <SendBlueButton onClick={handleInviteUsers}>
                <FormattedMessage id="envoyer" />
              </SendBlueButton>
            </>
          ) : (
            <SmallFontCancelWithHover
              style={{
                position: `${!validateEmail(message) && "relative"}`,
                top: `${!validateEmail(message) ? "315px" : "255px"}`,
              }}
              onClick={handleClose}
            >
              <FormattedMessage id="usermanagement.param17" />
            </SmallFontCancelWithHover>
          )}
        </DialogActions>
        <Menu
          anchorEl={inviteNewUserAnchor}
          open={Boolean(inviteNewUserAnchor)}
          onClose={() => {
            setInviteNewUserAnchor(null);
            setEmailToInviteWithOpenModalIndex(0);
          }}
        >
          <StyledMenuItem onClick={() => setRoleForEmailToInvite("admin")}>
            <ListItemText>
              <Row>
                <Col>
                  <button
                    style={{
                      background: "#176A2A",
                      width: "70px",
                    }}
                    className="text-white py-2 px-1 focus:outline-none font-bold rounded-5px"
                  >
                    <FormattedMessage id="usermanagement.param10" />
                  </button>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="text-sm text-grey-light ml-2 mt-2 mr-2">
                    <FormattedMessage id="usermanagement.param23" />
                  </p>
                </Col>
              </Row>
            </ListItemText>
            {validEmailsRoles[emailToInviteWithOpenModalIndex] === "admin" && (
              <TickIcon claassName="font-bold" />
            )}
          </StyledMenuItem>
          <StyledMenuItem onClick={() => setRoleForEmailToInvite("writer")}>
            <ListItemText>
              <Row>
                <Col>
                  <button
                    style={{
                      background: "#2975FF",
                      width: "70px",
                    }}
                    className="text-white py-2 px-1 focus:outline-none font-bold rounded-5px "
                  >
                    <FormattedMessage id="usermanagement.param11" />
                  </button>
                </Col>
                <Col>
                  <p className="text-sm text-grey-light ml-2 mt-2 mr-2">
                    <FormattedMessage
                      id="usermanagement.param24"
                      values={{ br: <br /> }}
                    />
                  </p>
                </Col>
              </Row>
            </ListItemText>
            {validEmailsRoles[emailToInviteWithOpenModalIndex] === "writer" && (
              <TickIcon />
            )}
          </StyledMenuItem>
          <StyledMenuItem onClick={() => setRoleForEmailToInvite("reader")}>
            <ListItemText>
              <Row>
                <Col>
                  <button
                    style={{
                      background: "#7963FE",
                      width: "70px",
                    }}
                    className="text-white py-2 px-1 focus:outline-none font-bold rounded-5px"
                  >
                    <FormattedMessage id="usermanagement.param12" />
                  </button>
                </Col>
                <Col>
                  <p className="text-sm text-grey-light ml-2 mt-2 mr-4">
                    <FormattedMessage
                      id="usermanagement.param25"
                      values={{ br: <br /> }}
                    />
                  </p>
                </Col>
              </Row>
            </ListItemText>
            {validEmailsRoles[emailToInviteWithOpenModalIndex] === "reader" && (
              <TickIcon />
            )}
          </StyledMenuItem>
        </Menu>
      </HiddenScrollBar>
    );
  };
  const renderChooseTeam = () => {
    return (
      <ColWithRowDirection>
        <GrayNormalText>
          <FormattedMessage id="Team" />
        </GrayNormalText>
        {chosenRiver !== "" && chosenRiver !== "all" && (
          <GrayBoldText
            openListOfteamsEl={true}
            style={{
              cursor: "pointer",
            }}
          >
            {chosenRiver}
            <LitleBlueCloseIcon
              onClick={() => {
                setChosenRiver("");
                setChosenRiverIndex(null);
                getAllUsers(connectedRiverlab, setUsers);
              }}
            />
          </GrayBoldText>
        )}
        {(chosenRiver === "" || chosenRiver === "all") && (
          <>
            {connectedRiverlab.length > 1 && openListOfteamsEl ? (
              <GrayBoldText
                openListOfteamsEl={openListOfteamsEl}
                style={{
                  cursor: "pointer",
                }}
              >
                <FormattedMessage id="Select a team" />
                <DropUpIcon style={{ cursor: "pointer", marginTop: "-5px" }} />
              </GrayBoldText>
            ) : (
              <GrayBoldText
                openListOfteamsEl={openListOfteamsEl}
                style={{
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  setOpenListOfteamsEl(event.currentTarget);
                  setOpenListOfTeams(true);
                }}
              >
                <FormattedMessage id="Select a team" />
                <DropDownIcon
                  style={{ cursor: "pointer", marginTop: "-5px" }}
                />
              </GrayBoldText>
            )}
            {openListOfTeams && (
              <>
                <Menu
                  id="simple-menu"
                  anchorEl={openListOfteamsEl}
                  keepMounted
                  open={Boolean(openListOfteamsEl)}
                  onClose={() => {
                    setOpenListOfTeams(false);
                    setOpenListOfteamsEl(null);
                  }}
                  sx={{
                    "& .MuiMenu-paper": {
                      boxShadow: "0px 4px 10px 0px rgba(19, 30, 100, 0.26)",
                    },
                  }}
                >
                  {connectedRiverlab.map((riverlab, index) => (
                    <StyledMenuItem
                      style={{
                        width: "205px",
                        height: "40px",
                        fontFamily: "Roboto",
                      }}
                    >
                      <ListItemText
                        onClick={() => {
                          setChosenRiverIndex(index);
                          setChosenRiver(riverlab);
                          setOpenListOfTeams(false);
                          setOpenListOfteamsEl(null);
                        }}
                      >
                        <BlackNormalText>{riverlab}</BlackNormalText>
                      </ListItemText>
                      {index === chosenRiverIndex && <CheckBlueIcon />}
                    </StyledMenuItem>
                  ))}
                </Menu>
              </>
            )}
          </>
        )}
      </ColWithRowDirection>
    );
  };
  const renderColor = (role) => {
    var color;
    role === "admin"
      ? (color = "#176A2A")
      : role === "writer"
      ? (color = "#2975FF")
      : (color = "#7963FE");
    return color;
  };

  const useStyles = makeStyles({
    table: {
      "& tbody tr:last-child td": {
        border: 0,
      },
      "& tbody tr:nth-child(2n+1) td:last-child": {
        borderRight: "1px solid #EBECF3",
      },
      "& tbody tr:nth-child(2n+1) td:first-child": {
        borderLeft: "1px solid #EBECF3",
      },
      "& tbody tr:nth-child(2n) td": {
        paddingTop: "3px !important",
        paddingBottom: "3px !important",
      },
      "& tbody tr:nth-child(2n+1) td": {
        paddingTop: "10px !important",
        paddingBottom: "10px !important",
        background: "white",
      },
    },
  });
  const classes = useStyles();

  return (
    <div className={`${!isMobile && "ml-16"} w-full h-full`}>
      <div
        className={`${
          users.length === 0 ? "justify-end" : "justify-between"
        } flex border-b-1 border-primary border-opacity-20 p-2 bg-white`}
        style={{
          padding: "16px",
          position: "sticky",
          top: "0px",
          zIndex: 1,
        }}
      >
        {users.length !== 0 && (
          <BlackBoldTextSatoshiFont>
            <FormattedMessage id="usermanagement.param5" />
          </BlackBoldTextSatoshiFont>
        )}
        <InviteButton onClick={() => setOpen(true)}>
          <FormattedMessage id="usermanagement.param6" />
        </InviteButton>
        {open && renderInviteButtonClicked(open, setOpen)}
      </div>
      {!users && (
        <div className="text-3xl text-primary-3 flex flex-col items-center justify-center h-full">
          <FormattedMessage id="dashboard.chart.param44" />
          <p className="text-dark text-lg block mb-3">
            <FormattedMessage id="dashboard.chart.param43" />
          </p>
          <CircularProgress />
        </div>
      )}
      <div>
        {users.length !== 0 && <div>{renderChooseTeam()}</div>}
        {users.length > 0 && (
          <StyledTableContainer>
            <Table
              className={classes.table}
              sx={{
                minWidth: 650,
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "1px solid #EBECF3",
                },
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <Col style={{ marginLeft: 10, marginRight: 12 }}>
                        <HeaderText>
                          <FormattedMessage id="usermanagement.param1" />
                        </HeaderText>
                      </Col>
                      <Col>
                        <FlechIcon />
                      </Col>
                    </Row>
                  </TableCell>
                  <TableCell align="left">
                    <HeaderText>
                      <FormattedMessage id="usermanagement.param2" />
                    </HeaderText>
                  </TableCell>
                  <TableCell align="left">
                    <HeaderText>
                      <FormattedMessage id="usermanagement.param3" />
                    </HeaderText>
                  </TableCell>
                  <TableCell align="left">
                    <HeaderText>
                      <FormattedMessage id="Team" />
                    </HeaderText>
                  </TableCell>
                  <TableCell align="left">
                    <HeaderText>
                      <FormattedMessage id="usermanagement.param4" />
                    </HeaderText>
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user, indx) => (
                  <>
                    <TableRow key={indx}>
                      <TableCell align="left">
                        <RowWithGap>
                          {user.firstName && user.lastName ? (
                            <>
                              <Col>{createPicture(user.firstName, 30, 30)}</Col>
                              <Col align="left">
                                <BlackNormalText>
                                  {capitalizeFirstLetter(user.firstName) +
                                    " " +
                                    capitalizeFirstLetter(user.lastName)}
                                </BlackNormalText>

                                <GraySmallText>{user.email}</GraySmallText>
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col>{createPicture(user.email, 30, 30)}</Col>
                              <Col align="left">
                                <BlackNormalText>{user.email}</BlackNormalText>
                                <GraySmallText>{user.email}</GraySmallText>
                              </Col>
                            </>
                          )}
                        </RowWithGap>
                      </TableCell>
                      <TableCell align="left">
                        {
                          <button
                            style={{
                              background: renderColor(user.role),
                              width: "46px",
                              height: "20px",
                              fontWeight: 700,
                              fontSize: "12px",
                              padding: "2px 5px",
                              color: "white",
                              borderRadius: "3px",
                              display: "flex",
                              justifyContent: "center",
                              fontFamily: "Satoshi",
                            }}
                          >
                            {capitalizeFirstLetter(user.role)}
                          </button>
                        }
                      </TableCell>
                      <TableCell align="left">
                        <BlackNormalText
                          style={{ fontSize: "12px", fontWeight: 500 }}
                        >
                          {accessDetails(user.role)}
                        </BlackNormalText>
                      </TableCell>
                      <TableCell align="left">
                        <BlackNormalText
                          style={{ fontSize: "12px", fontWeight: 500 }}
                        >
                          {stationToUpperCase(user.team)}
                        </BlackNormalText>
                      </TableCell>
                      <TableCell align="left">
                        <BlackNormalText
                          style={{ fontSize: "12px", fontWeight: 500 }}
                        >
                          {renderCustomizeStatus(user)}
                        </BlackNormalText>
                      </TableCell>
                      <TableCell align="left">
                        {(checkConnectedUserRoleByRiver(
                          formatRiverlabForBackend(user.team)
                        ) === "admin" ||
                          (checkConnectedUserRoleByRiver(
                            formatRiverlabForBackend(user.team)
                          ) === "writer" &&
                            user.role !== "admin")) &&
                          connectedUserEmail !== user.email && (
                            <>
                              <div
                                onClick={(event) => {
                                  handleClick(event, user);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <MoreIcon />
                              </div>
                            </>
                          )}
                      </TableCell>
                    </TableRow>
                    <TableRow key={indx}>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </>
                ))}
                {openModalUser && (
                  <Menu
                    sx={{
                      "& .MuiMenu-paper": {
                        boxShadow: "0px 0px 7px 0px rgb(41, 117, 255, 0.31)",
                      },
                    }}
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {
                      <>
                        {userRoles[openModalUser.team] === "admin" && (
                          <StyledMenuItem
                            style={{ width: "200px", height: "40px" }}
                          >
                            <ListItemText
                              onClick={() => modifyUserRole("admin")}
                            >
                              <button
                                align="center"
                                style={{
                                  background: "#176A2A",
                                  width: "45px",
                                  height: "20px",
                                  fontWeight: 700,
                                  fontSize: "12px",
                                  padding: "2px 5px",
                                  color: "white",
                                  borderRadius: "3px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontFamily: "Roboto",
                                }}
                              >
                                <FormattedMessage id="usermanagement.param10" />
                              </button>
                            </ListItemText>

                            <Typography variant="body2" color="text.secondary">
                              {openModalUser?.role === "admin" && <TickIcon />}
                            </Typography>
                          </StyledMenuItem>
                        )}
                        {(userRoles[openModalUser.team] === "admin" ||
                          userRoles[openModalUser.team] === "writer") && (
                          <StyledMenuItem>
                            <ListItemText
                              onClick={() => modifyUserRole("writer")}
                            >
                              <button
                                align="center"
                                style={{
                                  background: "#2975FF",
                                  width: "45px",
                                  height: "20px",
                                  fontWeight: 700,
                                  fontSize: "12px",
                                  padding: "2px 5px",
                                  color: "white",
                                  borderRadius: "3px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontFamily: "Roboto",
                                }}
                              >
                                <FormattedMessage id="usermanagement.param11" />
                              </button>
                            </ListItemText>
                            <Typography variant="body2" color="text.secondary">
                              {openModalUser?.role === "writer" && <TickIcon />}
                            </Typography>
                          </StyledMenuItem>
                        )}
                        {
                          <StyledMenuItem>
                            <ListItemText
                              onClick={() => modifyUserRole("reader")}
                            >
                              <button
                                align="center"
                                style={{
                                  background: "#7963FE",
                                  width: "45px",
                                  height: "20px",
                                  fontWeight: 700,
                                  fontSize: "12px",
                                  padding: "2px 5px",
                                  color: "white",
                                  borderRadius: "3px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontFamily: "Roboto",
                                }}
                              >
                                <FormattedMessage id="usermanagement.param12" />
                              </button>
                            </ListItemText>
                            <Typography variant="body2" color="text.secondary">
                              {openModalUser?.role === "reader" && <TickIcon />}
                            </Typography>
                          </StyledMenuItem>
                        }
                        <Divider />
                      </>
                    }
                    <StyledMenuItem
                      style={{
                        color: "#F04545",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "5px",
                        fontFamily: "Satoshi",
                      }}
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      <FormattedMessage id="usermanagement.param13" />
                    </StyledMenuItem>
                  </Menu>
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        )}
      </div>
      {users.length === 0 && (
        <Paper>
          <Row
            className="flex justify-between"
            style={{
              marginRight: "8px",
            }}
          >
            <Col>
              <Row>{renderChooseTeam()}</Row>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "Column",
                  marginLeft: "30%",
                  marginTop: "40%",
                }}
              >
                <StyledText
                  align="left"
                  style={{ fontFamily: "Satoshi", marginBottom: "15px" }}
                >
                  <FormattedMessage
                    id="Invitez des collaborateurs à rejoindre Extralab"
                    values={{ br: <br /> }}
                  />
                </StyledText>
                <StyledParagraph
                  align="left"
                  style={{ fontFamily: "Roboto", marginBottom: "15px" }}
                >
                  <FormattedMessage
                    id="Your colleagues will receive an email that will allow them to access the application according to the role you assign them."
                    values={{ br: <br /> }}
                  />
                </StyledParagraph>
                <InviteButton onClick={() => setOpen(true)}>
                  <FormattedMessage id="usermanagement.param6" />
                </InviteButton>
              </Row>
            </Col>
            <Col>
              <StyledImage alt="" src="./assets/user-management.png" />
            </Col>
          </Row>
        </Paper>
      )}
      {openModal && renderRemoveClicked()}
    </div>
  );
}

const StyledMenuItem = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "#EBECF3",
    },
  },
})(MenuItem);
