import { GreyText } from "../Signup.style";

function StrengthMeter({ poorPassword, passwordError }) {
  return (
    <>
      <ul className="list-group list-group-horizontal">
        {poorPassword === true ? (
          <li
            className="list-group-item bg-danger col-4"
            style={{ padding: "1px 0px" }}
          ></li>
        ) : (
          ""
        )}
      </ul>
      <GreyText>{passwordError}</GreyText>
    </>
  );
}
export default StrengthMeter;
