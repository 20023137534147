import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "./../Accordion/Accordion";
import { ReactComponent as ToolsIcon } from "../../../../icons/tools.svg";
import { ReactComponent as ZoomIcon } from "../../../../icons/zoom.svg";
import { ReactComponent as FileIcon } from "../../../../icons/file.svg";
import { ReactComponent as ImageIcon } from "../../../../icons/image.svg";
import { ReactComponent as RestoreIcon } from "../../../../icons/restore.svg";

export default function ToolboxConfig({ setOption, toolboxConfig }) {
  const [expanded, setExpanded] = useState(new Set([]));

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    if (newExpanded) {
      setExpanded((prev) => {
        prev.add(panel);
        return new Set([...prev]);
      });
    } else {
      setExpanded((prev) => {
        prev.delete(panel);
        return new Set([...prev]);
      });
    }
  };

  function handleShowToolbox(event) {
    if (event.target.checked) {
      setOption((prev) => {
        return {
          ...prev,
          option: {
            ...prev.option,
            toolbox: {
              ...prev.option.toolbox,
              feature: {
                ...prev.option.toolbox.feature,
                [event.target.name]: {},
              },
            },
          },
        };
      });
    } else {
      setOption((prev) => {
        let test = { ...prev.option.toolbox.feature };
        delete test[event.target.name];
        return {
          ...prev,
          notMerge: true,
          option: {
            ...prev.option,
            toolbox: { ...prev.option.toolbox, feature: test },
          },
        };
      });
    }
  }

  return (
    <Accordion
      expanded={expanded.has("toolBox")}
      onChange={handleChangeAccordion("toolBox")}
      backgroundColor="rgba(142, 155, 187, 0.08)"
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="flex items-center">
          <p className="text-primary font-medium flex gap-2">
            <ToolsIcon />
            Toolbox config
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="mb-2 px-2">
          <div className="flex gap-2 items-center">
            <p className="font-medium flex gap-2">
              <ZoomIcon className="text-primary" /> DataZoom :
            </p>
            <Switch
              checked={Object.keys(toolboxConfig.feature).includes("dataZoom")}
              onChange={handleShowToolbox}
              inputProps={{ "aria-label": "controlled" }}
              name="dataZoom"
            />
          </div>
          <div className="flex gap-2 items-center">
            <p className="font-medium flex gap-2">
              <FileIcon className="text-primary" /> DataView :
            </p>
            <Switch
              checked={Object.keys(toolboxConfig.feature).includes("dataView")}
              onChange={handleShowToolbox}
              inputProps={{ "aria-label": "controlled" }}
              name="dataView"
            />
          </div>
          <div className="flex gap-2 items-center">
            <p className="font-medium flex gap-2">
              <ImageIcon className="text-primary" />
              SaveAsImage :
            </p>
            <Switch
              checked={Object.keys(toolboxConfig.feature).includes(
                "saveAsImage"
              )}
              onChange={handleShowToolbox}
              inputProps={{ "aria-label": "controlled" }}
              name="saveAsImage"
            />
          </div>
          <div className="flex gap-2 items-center">
            <p className="font-medium flex gap-2">
              <RestoreIcon className="text-primary" />
              Restore :
            </p>
            <Switch
              checked={Object.keys(toolboxConfig.feature).includes("restore")}
              onChange={handleShowToolbox}
              inputProps={{ "aria-label": "controlled" }}
              name="restore"
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
