import styled from "styled-components";
import FormLabel from "@mui/material/FormLabel";
import { AiOutlineCheck } from "react-icons/ai";
import { ListItem } from "@mui/material";

export const StyledText = styled.p`
  font-weight: 700;
  font-size: 20px;
  color: #343a40;
  font-family: Satoshi;
`;

export const BlueButton = styled.button`
  width: 113px;
  height: 32px;
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  border-radius: 5px;
  padding: 10px, 34px, 10px, 34px;
  background: #2975ff;
  :hover {
    background: #17469c;
  }
`;

export const BlackMediumText = styled.p`
  color: #0d1441;
  font-weight: 700;
  font-size: 12px;
`;

export const StyledLabel = styled(FormLabel)`
  color: #0d1441;
  font-weight: 700;
  font-size: 12px;
`;

export const StyledRadioButtonText = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: #0d1441;
  font-family: Roboto;
`;
export const StyledRadioBlueText = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: #2975ff;
`;

export const StyledDiv = styled.div`
  height: 46px;
  width: 413px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #ebecf3;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
  margin-left: 16px;
  cursor: pointer;
  padding: 11px;
`;

export const StyledCheckButton = styled(AiOutlineCheck)`
  width: 25px;
  height: 22px;
`;
export const StyledDarkParagraphe = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: #0d1441;
  font-family: Roboto;
`;

export const StyledGreyParagraphe = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: #8488a5;
  font-family: Roboto;
`;

export const CancelButton = styled.button`
  width: 113px;
  height: 32px;
  font-weight: 700;
  font-size: 12px;
  border-radius: 5px;
  padding: 10px, 34px, 10px, 34px;
  color: #2975ff;
  :hover {
    background: #ebecf3;
  }
`;

export const LargeStyledDarkParagraphe = styled(ListItem)`
  width: 300px;
  font-weight: 400;
  font-size: 12px;
  color: #0d1441;
  margin-left: -17px;
`;

export const BlueBorderButton = styled.button`
  background: #ffffff;
  color: #2975ff;
  font-weight: 500;
  font-size: 11px;
  width: 69px;
  height: 23px;
  border-radius: 5px;
  padding: 2px 16px 2px 16px;
  border: 1px solid #2975ff;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background: #ebecf3;
  }
`;

export const GreyButton = styled.button`
  background: #eff0f3;
  font-weight: 500;
  font-size: 11px;
  width: 69px;
  height: 23px;
  border-radius: 5px;
  padding: 4px 24px 6px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    color: #2975ff;
  }
`;

export const StyledDarkParagrapheWithProps = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => (props.chosen ? "#2975FF" : "#0d1441")};
  font-family: Roboto;
`;

export const StyledGreyParagrapheWithProps = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: ${(props) => (props.chosen ? "#2975FF" : "#0d1441")};
  font-family: Roboto;
`;
