import styled from "styled-components";
import { Col } from "react-flexbox-grid";

export const BlackBoldText = styled.p`
  font-weight: 700;
  font-size: 25px;
  color: #0d1441;
  margin-bottom: 10px;
  font-family: Satoshi;
`;

export const MediumText = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: #343a40;
  margin-bottom: 10px;
  font-family: Roboto;
`;

export const GreyText = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: #8488a5;
  font-family: Roboto;
`;

export const BlueButton = styled.button`
  width: 172px;
  height: 32px;
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  border-radius: 5px;
  padding: 10px 34px 10px 34px;
  background: #2975ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  :hover {
    background: #17469c;
  }
`;

export const CenterColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px;
`;

export const StyledImage = styled.img`
  height: calc(100vh - 63px);
  transform: translate(8px, 0);
`;
