import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CustomMenu } from "../../../Shared/Menues/CustomMenu";
import { ReactComponent as UndoIcon } from "../../../icons/undo.svg";
import { ReactComponent as ConfigIcon } from "../../../icons/gear-skeleton.svg";
import { CirclehoverButton } from "../../../Shared/Butttons";
import { ReactComponent as HorizentalDotsIcon } from "../../../icons/horizental-dots.svg";
import { ReactComponent as ArrowDownLineIcon } from "../../../icons/arrow-down-s-line.svg";
import { DEFAULT_OPTION } from "../Const";

export default function AnnexSectionHeader({
  setOption,
  setExpanded,
  expanded,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const MenuOptions = [
    { icon: UndoIcon, text: "Reset", onClick: resetGraph },
    { icon: CloseAllButton, text: "Close All", onClick: closeAll },
    { icon: OpenAllButton, text: "Open All", onClick: openAll },
  ];

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    MenuOptions[index].onClick();
    setAnchorEl(null);
  };

  function resetGraph() {
    setOption({ ...DEFAULT_OPTION, notMerge: true });
  }

  function closeAll() {
    setExpanded(new Set([]));
  }
  function openAll() {
    setExpanded(
      new Set(["panel1", "panel2", "panel3", "panel4", "panel5", "panel6"])
    );
  }

  return (
    <div className="flex border-b-1 border-light-grey-border border-opacity-20 justify-between items-center my-1 h-12">
      <p className="font-medium flex gap-2 items-center pl-3 text-xl">
        <ConfigIcon className="h-5 w-5" />
        <FormattedMessage id="extraplot.annex.param1" />
      </p>
      <div>
        <CirclehoverButton handleOnClick={handleClickListItem} selected={false}>
          <HorizentalDotsIcon className="w-5" />
        </CirclehoverButton>
      </div>
      <CustomMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        {MenuOptions.map((option, index) => (
          <MenuItem
            key={option.text}
            onClick={(event) => handleMenuItemClick(event, index)}
            disabled={
              (index === 1 && expanded.size === 0) ||
              (index === 2 && expanded.size === 4)
            }
          >
            <ListItemIcon>
              <option.icon />
            </ListItemIcon>
            <ListItemText>{option.text}</ListItemText>
          </MenuItem>
        ))}
      </CustomMenu>
    </div>
  );
}

function CloseAllButton() {
  return (
    <div>
      <ArrowDownLineIcon className="h-5 w-5" />
      <ArrowDownLineIcon
        className="h-5 w-5 -mt-3"
        style={{ transform: "rotate(180deg)" }}
      />
    </div>
  );
}

function OpenAllButton() {
  return (
    <div>
      <ArrowDownLineIcon
        className="h-5 w-5"
        style={{ transform: "rotate(180deg)" }}
      />
      <ArrowDownLineIcon className="h-5 w-5 -mt-3" />
    </div>
  );
}
