import React, { useState } from "react";
import Modal from "./sharedComponents/Modal/modal";
import { useSelector } from "react-redux";
import "react-multi-carousel/lib/styles.css";
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Sidebar from "./Shared/sidebar";
import "./styles/dropdown.css";
import "moment/min/locales";
import ProtocolPage from "./Pages/ProtocolPage/ProtocolPage";
import MonitoringPage from "./Pages/MonitoringPage/MonitoringPage";
import DashboardPage from "./Pages/DashboardPage/DashboardPage";
import ExtraPlotPage from "./Pages/ExtraPlotPage/FinalExtraplot";
import ExtraCodePage from "./Pages/ExtraCodePage/ExtraCodePage";
import ExtraPlotDashboard from "./Pages/ExtraPlotPage/ExtraPlotDashboard";
import UsersManagement from "./Pages/UsersManagement/UsersManagement.component";
import { isReaderOnly } from "./utils/roles";
import Contact from "./Pages/Contact/Contact.component";
import Profile from "./Pages/Profile/Profile.component";
import Alerts from "./Pages/Alerts/Alerts.component";

export default function Layout() {
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const userRoles = useSelector((state) => state.userRoles);

  return (
    <div className="h-screen w-full md:flex relative">
      <Sidebar />
      {isOpen && <Modal isOpen={isOpen} setIsOpen={setIsOpen} />}
      <Switch>
        <Route path="/dashboard" exact component={DashboardPage} />
        <Redirect path="/" exact to="/dashboard" />
        <Route path="/extraPlot" component={ExtraPlotDashboard} />
        {user &&
          (user.groups.includes("/monticello") ||
            user.groups.includes("/demo")) && (
            <Route path="/monitoring" component={MonitoringPage} />
          )}
        <Route path="/users-management" component={UsersManagement} />
        <Route path="/contact" component={Contact} />
        <Route path="/profile" component={Profile} />
        <Route path="/alerts" component={Alerts} />
        {!isReaderOnly(userRoles) && (
          <>
            <Route path="/protocole" component={ProtocolPage} />
            <Route path="/extraCode" component={ExtraCodePage} />
            <Route path="/graphForm/:id" component={ExtraPlotPage} />
          </>
        )}
        <Redirect path="/*" exact to="/dashboard" />
      </Switch>
    </div>
  );
}
