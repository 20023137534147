import axios from "axios";
export const METRICS = Object.freeze({
  Fluorures: "Fluorures",
  Chlorures: "Chlorures",
  Bromures: "Bromures",
  Sulfates: "Sulfates",
  Phosphates: "Phosphates",
  Nitrates: "Nitrates",
  Nitrites: "Nitrites",
  Lithium: "Lithium",
  Sodium: "Sodium",
  Potassium: "Potassium",
  Magnesium: "Magnesium",
  Calcium: "Calcium",
  temperature: "temperature",
  pH: "pH",
  Conductivite: "Conductivite",
  Turbidite: "Turbidite",
  pressure: "pressure",
  O2: "O2",
  debit: "debit",
});

export const ValidityForValue = Object.freeze({
  NEUTRAL: "NEUTRAL",
  VALID: "VALID",
  INVALID: "INVALID",
});

const isValidValueForMetric = (value, metric) => {
  const parsedValue = Number(value);
  if (isNaN(parsedValue))
    return {
      validity: ValidityForValue.INVALID,
      error: "Veuillez saisir une valeur numérique.",
    };

  if (metric === METRICS.pH) {
    if (0 <= parsedValue && parsedValue <= 14)
      return { validity: ValidityForValue.VALID };
    return {
      validity: ValidityForValue.INVALID,
      error: "Veuillez saisir une valeur comprise entre 0 et 14.",
    };
  }
  if (metric === METRICS.temperature)
    return { validity: ValidityForValue.VALID };

  return 0 <= parsedValue
    ? { validity: ValidityForValue.VALID }
    : {
        validity: ValidityForValue.INVALID,
        error: "Veuillez saisir une valeur positive ou nulle.",
      };
};

const getValueValidityForMetric = (value, metric, ruleType, numberType) => {
  if (value === "" || ruleType === null)
    return { validity: ValidityForValue.NEUTRAL };
  if (numberType === "decimal")
    return Number(value) < 0
      ? {
          validity: ValidityForValue.INVALID,
          error: "Un décimal ne peut avoir qu'une valeur positive ou nulle.",
        }
      : { validity: ValidityForValue.VALID };

  return isValidValueForMetric(value, metric);
};

export const getUnityAndDecimalValidityForMetric = (
  values,
  metric,
  ruleType,
  numberType
) => {
  if (values[numberType] !== "" && isNaN(Number(values[numberType])))
    return {
      validity: ValidityForValue.INVALID,
      error: "Veuillez saisir une valeur numérique.",
    };

  const { unity, decimal } = values;
  const unityValidity = getValueValidityForMetric(
    unity,
    metric,
    ruleType,
    "unity"
  );
  if (numberType === "unity") return unityValidity;

  if (unityValidity.validity === ValidityForValue.INVALID)
    return { validity: ValidityForValue.INVALID, error: unityValidity.error };

  return getValueValidityForMetric(decimal, metric, ruleType, "decimal");
};

export const isRuleValid = (values, metric) => {
  const valuesWithValidity = values.map(({ unity, decimal }) => ({
    unityValidity: getValueValidityForMetric(unity, metric, "", "unity"),
    decimalValidity: getValueValidityForMetric(decimal, metric, "", "decimal"),
  }));
  const isValid = valuesWithValidity
    .map(
      ({ unityValidity, decimalValidity }) =>
        unityValidity.validity === ValidityForValue.VALID &&
        decimalValidity.validity === ValidityForValue.VALID
    )
    .every((isValid) => isValid);

  if (isValid) {
    return { isValid, error: "" };
  }

  const valuesWithError = valuesWithValidity.find(
    (valuesValidity) =>
      valuesValidity.unityValidity.validity === ValidityForValue.INVALID ||
      valuesValidity.decimalValidity.validity === ValidityForValue.INVALID
  );

  if (!valuesWithError) {
    // isValid = false, but no error => at least one is neutral
    return { isValid, error: "" };
  }

  const error =
    valuesWithError.unityValidity.validity === ValidityForValue.INVALID
      ? valuesWithError.unityValidity.error
      : valuesWithError.decimalValidity.error;

  return { isValid, error };
};

export const handleGetRealTimeData = async (station, param) =>
  await axios
    .get(
      process.env.REACT_APP_API_URL +
        `/observatories/${station}/elements/${param}/realtime`
    )
    .then((resp) => {
      if (resp.data.value === "no data") {
        return resp.data.value;
      }
      return resp.data.value + " " + resp.data.unit;
    });

export const getId = async (station, param) =>
  await axios
    .get(
      process.env.REACT_APP_API_URL +
        `/observatories/${station}/realtime/gauges`
    )
    .then((resp) =>
      resp.data.data
        .filter((data) => data.label === param)
        .map((data) => data.element)
    );

export const getUnitForSetting = (metric, station) => {
  if (metric === METRICS.Conductivite) return "µS/cm";
  if (metric === METRICS.pressure) return "bar";
  if (metric === METRICS.O2) return "mg/L";
  if (metric === METRICS.temperature) return "°C";
  if (metric === METRICS.debit) return "L/h";
  if (metric === METRICS.Turbidite) return "FNU";
  if (metric === METRICS.pH) return "";
  return station === "monticello" ? "mmol/L" : "µmol/L";
};

export const updateAlertApi = (alert, alertId) =>
  axios.put(
    process.env.REACT_APP_FRONTEND_URL + `/api/alerts/modify/${alertId}`,
    {
      chosenStation: alert.chosenStation,
      chosenSetting: alert.chosenSetting,
      chosenId: alert.chosenId,
      rules: alert.rules,
      withWhatsApp: alert.withWhatsApp,
      withEmail: alert.withEmail,
      chosenRecipients: alert.chosenRecipients,
      userEmail: alert.userEmail,
      activateAlerts: alert.activateAlerts,
      activateNotifs: alert.activateNotifs,
    }
  );

export const checkIsAlert = (alertRules, realTimeValue) => {
  const alertRulesParsed = alertRules.flatMap(({ values }) =>
    values.map(({ unity, decimal }) => parseFloat(`${unity}.${decimal}`))
  );
  const alertType = alertRules.flatMap((alert) => alert.type);
  if (alertType.length === 1) {
    if (
      alertType[0] === "Value greater than ..." ||
      alertType[0] === "Valeur supérieure à ..."
    ) {
      return realTimeValue > alertRulesParsed[0];
    } else if (
      alertType[0] === "Valeur inférieure à ..." ||
      alertType[0] === "Value lower than ..."
    ) {
      return realTimeValue < alertRulesParsed[0];
    }
  } else {
    const max = Math.max.apply(null, alertRulesParsed);
    const min = Math.min.apply(Math, alertRulesParsed);
    return realTimeValue < max && realTimeValue > min;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
};
