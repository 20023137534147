import React, { useState} from "react";

export default function ParameterPanel({ children, label, type, id }) {
  const [isSelected, setSelected] = useState(false);

  return (
    <button
      key={id}
      className={`${
        isSelected
          ? "bg-transparent-orange text-primary-3"
          : "bg-grey-panel border border-border-panel text-grey-light"
      }  rounded-full h-12 w-12 flex items-center justify-center mr-3 mb-3 focus:outline-none`}
      onClick={() => {
        setSelected(!isSelected);
      }}
    >
      {children}
    </button>
  );
}
