import React from "react";
import { arc } from "d3-shape";
import { scaleLinear } from "d3-scale";
import { useIntl } from "react-intl";
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@mui/styles/withStyles";
import {
  DivWithBlackTitle,
  DivWithBoldBlackText,
  NormalGrayText,
} from "../Pages/Alerts/Alerts.style";
import { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { getId } from "../Pages/Alerts/Alerts.service";

const Gauge = React.memo(({ value, label, units, station, alertRules }) => {
  const [isAlert, setIsAlert] = useState(false);
  const intl = useIntl();
  const alertRulesParsed = alertRules.flatMap(({ values }) =>
    values.map(({ unity, decimal }) => parseFloat(`${unity}.${decimal}`))
  );

  const [infoClicked, setInfoClicked] = useState(
    Array(alertRulesParsed.length).fill(false)
  );
  const [values, setValues] = useState();

  const getMetricsMinMaxValue = useCallback(
    async (label) => {
      const res = await axios
        .get(process.env.REACT_APP_API_URL + `/observatories/${station}/gauges`)
        .then((resp) => resp.data.gauges);
      setValues(res[label]);
    },
    [station]
  );
  const checkAlert = useCallback(async () => {
    const alertType = alertRules.flatMap((alert) => alert.type);
    if (alertType.length === 1) {
      if (
        alertType[0] === "Value greater than ..." ||
        alertType[0] === "Valeur supérieure à ..."
      ) {
        if (value > alertRulesParsed[0]) setIsAlert(true);
        else setIsAlert(false);
      } else if (
        alertType[0] === "Valeur inférieure à ..." ||
        alertType[0] === "Value lower than ..."
      ) {
        if (value < alertRulesParsed[0]) setIsAlert(true);
        else setIsAlert(false);
      }
    } else {
      const max = Math.max.apply(null, alertRulesParsed);
      const min = Math.min.apply(Math, alertRulesParsed);
      if (value < max && value > min) setIsAlert(true);
      else setIsAlert(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertRules, alertRulesParsed, value]);

  useEffect(() => {
    checkAlert();
  }, [checkAlert, value]);

  useEffect(async () => {
    const paramId = await getId(station, label);
    getMetricsMinMaxValue(paramId);
  }, [getMetricsMinMaxValue, station, label]);

  const min = (values && values.min) ?? 0;

  const max = (values && values.max) ?? 1000;

  const backgroundArc = arc()
    .innerRadius(0.8)
    .outerRadius(1)
    .startAngle(-Math.PI / 2)
    .endAngle(Math.PI / 2)
    .cornerRadius(1)();
  const percentScale = scaleLinear().domain([min, max]).range([0, 1]);
  const percent = percentScale(value);
  const angleScale = scaleLinear()
    .domain([0, 1])
    .range([-Math.PI / 2, Math.PI / 2])
    .clamp(true);
  const angle = angleScale(percent);

  const filledArc = arc()
    .innerRadius(0.8)
    .outerRadius(1)
    .startAngle(-Math.PI / 2)
    .endAngle(angle)
    .cornerRadius(1)();

  const alertRulesCordinates = alertRulesParsed.map((ruleValue) => {
    const rulePercent = percentScale(ruleValue);
    const ruleAngle = angleScale(rulePercent);
    const x = Math.sin(ruleAngle);
    const y = -Math.cos(ruleAngle);
    return { x, y, ruleAngle };
  });

  const alertImageUrl = (ruleAngle, indx) => {
    let imageUrl = "";
    if (!infoClicked[indx]) {
      if (Math.sign(ruleAngle) < 0) {
        isAlert
          ? (imageUrl = "https://i.postimg.cc/1XB8cB45/AlertOn.png")
          : (imageUrl = "https://i.postimg.cc/GmXjBnjs/image.png");
      } else {
        isAlert
          ? (imageUrl = "https://i.postimg.cc/1XB8cB45/AlertOn.png")
          : (imageUrl = "https://i.postimg.cc/BvdHDSPt/image.png");
      }
    } else {
      if (Math.sign(ruleAngle) < 0) {
        isAlert
          ? (imageUrl =
              "https://i.postimg.cc/XvGY4Ggn/info-On-Right-Alert-On.png")
          : (imageUrl =
              "https://i.postimg.cc/X7wcLXM1/Info-On-Left-Alert-Off.png");
      } else {
        isAlert
          ? (imageUrl =
              "https://i.postimg.cc/XvGY4Ggn/info-On-Right-Alert-On.png")
          : (imageUrl =
              "https://i.postimg.cc/mZS71J7K/info-On-Right-Alert-Off.png");
      }
    }
    return imageUrl;
  };

  return (
    <div className="flex" style={{ flexDirection: "column" }}>
      <svg
        style={{ overflow: "visible" }}
        width="186px"
        height="94px"
        viewBox={[-1, -1, 2, 1].join(" ")}
      >
        <path d={backgroundArc} fill="#EBECF3" />
        <path d={filledArc} fill={isAlert ? "#FC610A" : "#50D86E"} />
        {alertRulesCordinates.map(({ x, y, ruleAngle }, indx) => (
          <LightTooltip
            placement={Math.sign(ruleAngle) < 0 ? "bottom-start" : "right-end"}
            title={intl.formatMessage(
              {
                id: "Alert threshold at",
              },
              { valeur: alertRulesParsed[indx], unité: units }
            )}
          >
            <a
              onMouseEnter={() =>
                setInfoClicked((prevInfo) =>
                  prevInfo.map((clicked, InfoIndx) =>
                    InfoIndx === indx ? !clicked : clicked
                  )
                )
              }
              onMouseLeave={() =>
                setInfoClicked((prevInfo) =>
                  prevInfo.map((clicked, InfoIndx) =>
                    InfoIndx === indx ? !clicked : clicked
                  )
                )
              }
            >
              <image
                x={x - (Math.sign(ruleAngle) < 0 ? 0.3 : 0)}
                y={y - (Math.sign(ruleAngle) < 0 ? 0.2 : 0.3)}
                width="0.3"
                height={0.3}
                href={alertImageUrl(ruleAngle, indx)}
              />
            </a>
          </LightTooltip>
        ))}
      </svg>

      <NormalGrayText style={{ marginLeft: "2%", fontFamily: "Roboto" }}>
        {min}
      </NormalGrayText>
      <NormalGrayText
        style={{
          marginLeft: "74%",
          marginTop: "-7%",
          fontFamily: "Roboto",
        }}
      >
        {max}
      </NormalGrayText>
      <div
        className="flex justify-center items-center"
        style={{
          flexDirection: "column",
          marginRight: "15%",
          marginTop: "-30%",
        }}
      >
        {value === "undefined" ? (
          <DivWithBoldBlackText
            style={{ fontSize: "16px", fontFamily: "Satoshi" }}
          >
            No data
          </DivWithBoldBlackText>
        ) : (
          <DivWithBoldBlackText
            style={{ fontSize: "16px", fontFamily: "Satoshi" }}
          >
            {value + " " + units}
          </DivWithBoldBlackText>
        )}

        {!!label && (
          <DivWithBlackTitle
            style={{
              marginTop: "12%",
              fontFamily: "Satoshi",
            }}
          >
            {intl.formatMessage({ id: label })}
          </DivWithBlackTitle>
        )}
      </div>
    </div>
  );
});

export default Gauge;

export const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "white",
    color: "#2975FF",
    boxShadow: "0px 0px 7px 0px rgb(41, 117, 255, 0.31)",
    maxWidth: 253,
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Roboto",
  },
}))(Tooltip);
