import "./styles/main.css";
import Layout from "./Layaout";
import { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { SnackbarProvider } from "notistack";
import io from "socket.io-client";
import { IntlProvider } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import messages from "./i18n/messages";
import {
  setUser,
  setKcObj,
  setSocket,
  setUserRegisteredWs,
  setIsMobile,
  setToken,
  setUserRoles,
  setUserPreferences,
  setLanguage,
} from "./Redux/actions";
import axios from "axios";
import Keycloak from "keycloak-js";
import { ReactComponent as ReactLogo } from "./icons/extralab-logo.svg";
import LinearProgress from "@mui/material/LinearProgress";
import { useMediaQuery } from "react-responsive";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { StylesProvider } from "@material-ui/core";
import theme1 from "./Themes/theme1";
import theme2 from "./Themes/theme2";
import theme3 from "./Themes/theme3";
import theme4 from "./Themes/theme4";
import theme5 from "./Themes/theme5";
import theme6 from "./Themes/theme6";
import theme7 from "./Themes/theme7";
import theme8 from "./Themes/theme8";
import theme9 from "./Themes/theme9";
import theme10 from "./Themes/theme10";
import theme11 from "./Themes/theme11";
import theme12 from "./Themes/theme12";
import theme13 from "./Themes/theme13";
import * as echarts from "echarts";
import { LOCALES } from "./i18n/locales";
import { stationToRiverlab } from "./utils/riverlabs";
import { setCurrentSelectedElement } from "./Redux/actions";

const DEFAULT_DARK_THEMES = [theme1, theme5, theme10, theme11];
const DEFAULT_LIGHT_THEMES = [
  theme2,
  theme3,
  theme4,
  theme6,
  theme7,
  theme8,
  theme9,
  theme12,
  theme13,
];

function App() {
  const [keycloak, setKeycloak] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const language = useSelector((state) => state.language);
  const userPreferencesInfo = useSelector((state) => state.userPreferences);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const theme = createTheme({
    palette: {
      primary: {
        main: "#2C5AFF",
        light: "#2C5AFF",
        dark: "#2C5AFF",
        contrastText: "#000000",
      },
      secondary: {
        main: "#FF7715",
        light: "#FF7715",
        dark: "#FF7715",
        contrastText: "#000000",
      },
    },
  });

  dispatch(setIsMobile(useMediaQuery({ maxWidth: 767 })));

  useEffect(() => {
    user &&
      axios
        .get(
          process.env.REACT_APP_API_URL +
            `/user/${user.preferred_username}/elements/realtime`
        )
        .then((res) => {
          dispatch(setCurrentSelectedElement(res.data.data[0]));
        })
        .catch((err) => console.error(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    DEFAULT_DARK_THEMES.forEach((theme) => {
      echarts.registerTheme(theme.themeName, theme.theme);
    });
    DEFAULT_LIGHT_THEMES.forEach((theme) => {
      echarts.registerTheme(theme.themeName, theme.theme);
    });
  }, []);

  useEffect(() => {
    const kc = Keycloak("/keycloak.json");
    kc.init({ onLoad: "login-required" }).then((authenticated) => {
      dispatch(setToken(kc.token));
      dispatch(setKcObj(kc));
      setKeycloak(kc);
      setAuthenticated(authenticated);
      kc.loadUserInfo()
        .then((userInfo) => {
          dispatch(setUser(userInfo));
        })
        .catch((e) => {
          console.log("error catched", e);
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserRolesByTeam = async (connectedUserEmail) =>
    axios
      .get(
        process.env.REACT_APP_FRONTEND_URL +
          `/api/users/user/${connectedUserEmail}`
      )
      .then(({ data: rolesByStation }) => {
        const rolesByRiverlab = Object.keys(rolesByStation).reduce(
          (acc, station) => {
            const riverlab = stationToRiverlab(station);
            acc[riverlab] = rolesByStation[station];
            return acc;
          },
          {}
        );
        dispatch(setUserRoles(rolesByRiverlab));
      });

  const getUserPreferences = async (connectedUserEmail) =>
    axios
      .get(
        process.env.REACT_APP_FRONTEND_URL +
          `/api/users/getUserPreferences/${connectedUserEmail}`
      )
      .then(({ data }) => dispatch(setUserPreferences(data)));
  useEffect(() => {
    if (user) {
      let socket = null;
      //socket = openSocket.connect(process.env.REACT_APP_SOCKET_API);
      socket = io(process.env.REACT_APP_SOCKET_API);
      socket.on("connect", () => {
        dispatch(setSocket(socket));
        socket.emit("register", { username: user.preferred_username }, () => {
          dispatch(setUserRegisteredWs(true));
        });
      });
      socket.on("disconnect", () => {
        dispatch(setUserRegisteredWs(false));
      });
      const connectedUserEmail = user.email;
      getUserRolesByTeam(connectedUserEmail);
      getUserPreferences(connectedUserEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  !userPreferencesInfo && dispatch(setLanguage(LOCALES["ENGLISH"]));
  userPreferencesInfo &&
    (userPreferencesInfo.language === "Français" ||
    userPreferencesInfo.language === "French"
      ? dispatch(setLanguage(LOCALES["FRENSH"]))
      : dispatch(setLanguage(LOCALES["ENGLISH"])));

  if (keycloak) {
    if (authenticated) {
      return (
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            <IntlProvider locale={language} messages={messages[language]}>
              <Router>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <SnackbarProvider maxSnack={3}>
                    <div className="App">
                      <Layout />
                    </div>
                  </SnackbarProvider>
                </MuiPickersUtilsProvider>
              </Router>
            </IntlProvider>
          </ThemeProvider>
        </StylesProvider>
      );
    } else {
      return (
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            <div className="w-screen h-screen bg-app-bg flex items-center justify-center">
              <div className="flex flex-col">
                <div className="flex justify-center ">
                  <ReactLogo className="h-28 w-28 md:w-80 md:h-80 " />
                </div>
                <LinearProgress />
                <p className="text-center text-normal font-bold text-xl md:text-6xl">
                  EXTRALAB PLATFORM
                </p>
              </div>
            </div>
          </ThemeProvider>
        </StylesProvider>
      );
    }
  }
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className="w-screen h-screen bg-app-bg flex items-center justify-center">
          <div className="flex flex-col">
            <div className="flex justify-center">
              <ReactLogo className="h-28 w-28 md:w-80 md:h-80" />
            </div>
            <div>
              <LinearProgress />
            </div>
            <p className="text-center text-normal font-bold text-xl md:text-6xl">
              EXTRALAB PLATFORM
            </p>
          </div>
        </div>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
