import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent } from "@mui/material";

export default function DialogContainer({ children, open, onClose, maxWidth }) {
  const [title, content, actions] = children;
  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth} fullWidth={true}>
      <DialogTitle className="border-b-1 border-light-grey-border border-opacity-20 w-full">
        {title}
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions className="border-t-1 border-light-grey-border border-opacity-20 w-full">
        {actions}
      </DialogActions>
    </Dialog>
  );
}
