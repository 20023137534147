/* eslint-disable import/no-anonymous-default-export */
import { LOCALES } from "../locales";
export default {
  [LOCALES.FRENSH]: {
    "My preferences": "Mes préférences",
    "Do you have a question": "Vous avez une question ?",
    "Contact us by e-mail or phone":
      "Contactez-nous par e-mail ou par téléphone.",
    Contact: "Contact",
    "See my profile": "Voir mon profil",
    Phone: "Téléphone",
    "Send e-mail": "Envoyer un e-mail",
    Logout: "Déconnexion",
    "My profile": "Mon profil",
    "addcard.param1": "Ajoutez ou supprimez vos cartes préférées d'ici 😉",
    "addcard.param2": "réinitialiser",
    "sidebar.param1": "Se déconnecter",
    "sidebar.param2": "Gestion de l'équipe",
    "sidebar.param3": "Mes paramétres",
    "usermanagement.param1": "Nom",
    "usermanagement.param2": "Rôle",
    "usermanagement.param3": "Détails des accès",
    "usermanagement.param4": "Statut",
    "usermanagement.param5": "Membres de l’équipe",
    "usermanagement.param6": "Inviter",
    "usermanagement.param7": "Tous les modules + Droits de gestion d’équipe",
    "usermanagement.param8":
      "Tous les modules + Peut inviter/suppr. des readers et writers",
    "usermanagement.param9":
      "Dashboard + Fil d’actualité + Peut inviter/suppr. des readers",
    "usermanagement.param10": "Admin",
    "usermanagement.param11": "Writer",
    "usermanagement.param12": "Reader",
    "usermanagement.param13": "Retirer de l'équipe",
    "usermanagement.param14": "Inviter des collaborateurs",
    "usermanagement.param15": "Adresse e-mail",
    "usermanagement.param16": "Ajouter des collaborateurs",
    "usermanagement.param17": "Annuler",
    "usermanagement.param18": "Email est invalide",
    "usermanagement.param19": "Retirer le collaborateur",
    "usermanagement.param20": " n’aura plus accès à la plateforme Extralab",
    "usermanagement.param21": "Retirer",
    "usermanagement.param22": "Accès",
    "usermanagement.param23":
      "Accès à tous les modules {br} Tous les droits de gestion d’équipe",
    "usermanagement.param24":
      "Accès à tous modules {br} Peut inviter/suppr. des readers et writers",
    "usermanagement.param25":
      "Accès au Dashboard + Fil d’actualité {br} Peut inviter/suppr. des readers",
    role: "Rôle",
    envoyer: "Envoyer",
    "Select Riverlab": "Sélectionnez le Riverlab {riverlab}",
    "You cannot acceed to the Protocol panel for the Riverlab of the metric you have selected, select a Riverlab chip to acceed to its Protocol panel.":
      "Vous ne pouvez pas accéder au panneau de Protocole {br} pour le Riverlab de la métrique que vous avez sélectionnée, {br} sélectionnez une puce {riverlab} pour accéder à son {br} panneau de protocole.",
    "You cannot acceed to the Monitoring panel for the Riverlab of the metric you have selected, select a Riverlab chip to acceed to its Monitoring panel.":
      "Vous ne pouvez pas accéder au panneau de Pilotage {br} pour le Riverlab de la métrique que vous avez sélectionnée, {br} sélectionnez une puce {riverlab} pour accéder à son {br} panneau de pilotage.",
    "Cet utilisateur est Admin dans une autre équipe":
      "Cet utilisateur est Admin dans une autre équipe, vous ne pouvez l’inviter dans une nouvelle équipe qu’en tant que Reader.",
    "est Admin dans une autre équipe":
      "est Admin dans une autre équipe, vous ne pouvez l’inviter dans une nouvelle équipe qu’en tant que Reader. Pour inviter des Admins et Writers, créez une nouvelle invitation.",
    "Invitation sent successfully": "Invitation envoyé avec succès 🥳",
    "User deleted successfully": "Utilisateur supprimé avec succès",
    "Role updated successfully": "Rôle Rôle mis à jour avec succès",
    "Invitez des collaborateurs à rejoindre Extralab":
      "Invitez des collaborateurs {br} à rejoindre Extralab !",
    "Your colleagues will receive an email that will allow them to access the application according to the role you assign them.":
      "Vos collaborateurs recevront un e-mail qui leur permettra {br} d’accéder à l’application selon le rôle que vous leur {br} attribuez.",
    "Toutes les équipes": "Toutes les équipes",
    "settings.param1": "Langues",
    "settings.param2": "Fuseau horaire",
    "settings.param3": "Fuseau",
    "settings.param4": "Enregistrer",
    "settings.param5": "Unités de mesure",
    "automate.chart.tab2": "Graphique",
    "automate.annex.tab1": "Pompe",
    "automate.annex.tab2": "Vanne",
    "automate.annex.tab3": "Décolmatage",
    "automate.annex.tab4": "Eau filtrée",
    "automate.annex.tab5": "Lumiére",
    "automate.annex.pump.param1": "Paramétres avancées",
    "automate.annex.pump.param2": "Sélecteur marche / arrêt moteur",
    "automate.annex.pump.param3": "Mode manuel",
    "automate.annex.pump.param4": "Mode automatique",
    "automate.annex.pump.param5": "Consigne vitesse pompe",
    "automate.annex.pump.param6": "Mesure débit pompe",
    "automate.annex.pump.param7": "Parametre proportionalité",
    "automate.annex.pump.param8": "Paramètre intégrateur",
    "automate.annex.pump.param9": "Paramètre dérivateur",
    "automate.annex.infomessage1": "Mode automatique désactivé",
    "automate.annex.infomessage2": "Mode manuel désactivé",
    "automate.annex.infomessage3":
      "Vous n'êtes pas authorisé pour accéder à cette section !",
    "automate.annex.valve.param1": "Consigne ouverture vanne",
    "automate.annex.valve.param2": "Mesure pression vanne",
    "automate.annex.Unclogging.param1": "Timer décolmatage",
    "automate.annex.Unclogging.param2": "Prochain décolmatage dans",
    "automate.annex.Unclogging.param3": "Lancer un nouveau cycle",
    "automate.annex.Unclogging.param4": "Paramétres pompe décolmatage",
    "automate.annex.Unclogging.param5": "Durée décolmatage pompe",
    "automate.annex.Unclogging.param6": "Paramétres vanne décolmatage",
    "automate.annex.Unclogging.param7": "Durée ouverture vanne",
    "automate.annex.Unclogging.param8": "Paramétres bac d'analyse",
    "automate.annex.Unclogging.param9": "Durée ouverture bac",
    "automate.annex.FilteredWater.param1":
      "Timer nettoyage du barreau du filtre",
    "automate.annex.FilteredWater.param2": "Prochain nettoyage dans",
    "automate.annex.FilteredWater.param3": "Paramétres nettoyage",
    "automate.annex.FilteredWater.param4": "Etape",
    "automate.annex.FilteredWater.param5": "Temps fermeture vanne",
    "automate.annex.FilteredWater.param6": "Temps activation ultrason",
    "automate.annex.FilteredWater.param7": "Temps ouverture vanne d'air",
    "automate.annex.FilteredWater.param8": "Temps d'équilibre",
    "automate.annex.Light.param1": "Lumiére intérieure spot",
    "automate.annex.Light.param2": "Lumiére intérieur RL 215",
    "automate.annex.Light.param3": "Lumiére extérieure",
    "dashboard.chart.param4": "Interventions",
    "dashboard.chart.param5": "Télécharger",
    "dashboard.chart.param6": "Dernier point",
    "dashboard.chart.param7": "Paramètres du graphique",
    "dashboard.chart.param8": "Connecter les nuls :",
    "dashboard.chart.param9": "Afficher zoom de données yaxis :",
    "dashboard.chart.param10": "AutoScale :",
    "dashboard.chart.param11": "Options de la boîte à outils",
    "dashboard.chart.param12": "Markpoint options",
    "dashboard.chart.param13":
      "NB : les nombres ici sont formatés avec un maximum de 2 chiffres après la virgule décimale",
    "dashboard.chart.param14": "Écart limite:",
    "dashboard.chart.param15": "désactivé",
    "dashboard.chart.param16":
      "NB : si le min et le max sont définis manuellement, ce champ sera ignoré.",
    "dashboard.chart.param17": "NB : cette valeur est convertie en %.",
    "dashboard.chart.param18": "Valeur max:",
    "dashboard.chart.param19": "manuel:",
    "dashboard.chart.param20": "auto",
    "dashboard.chart.param21": "Valeur min:",
    "dashboard.chart.param22": "manuel",
    "dashboard.chart.param23":
      "NB : les options de dispersion et de barre peuvent ne pas être idéales pour les grandes fenêtres de données. Veuillez en choisir de plus petites pour voir le résultat.",
    "dashboard.chart.param24": "Fermé",
    "dashboard.chart.param25": "Dates relatives",
    "dashboard.chart.param26": "Hier",
    "dashboard.chart.param27": "Aujourd'hui",
    "dashboard.chart.param28": "Périodes relatives",
    "dashboard.chart.param29": "cette semaine",
    "dashboard.chart.param30": "la semaine derniére",
    "dashboard.chart.param31": "ce mois",
    "dashboard.chart.param32": "le mois dernier",
    "dashboard.chart.param33": "cette année",
    "dashboard.chart.param34": "l'année derniére",
    "dashboard.chart.param35": "Personaliser la période",
    "dashboard.chart.param36": "Toutes les données",
    "dashboard.chart.param37": "Directe",
    "dashboard.chart.param38": "Montrer plus",
    "dashboard.chart.param39": "Montrer moins",
    "dashboard.chart.param40": "Séries valide",
    "dashboard.chart.param41": "Séries brutes",
    "dashboard.chart.param42": "Séries invalides",
    "dashboard.chart.param43": "S'il vous plaît, attendez 🧐",
    "dashboard.chart.param44": "Chargement des données...",
    "dashboard.chart.param45": "DIRECTE",
    "dashboard.chart.param46": " à ",
    "dashboard.chart.param47": "Aucune donnée disponible 😔",
    "dashboard.annex.param1": "Hey {name}, quoi de neuf à {riverlab} ? 🤓",
    "dashboard.annex.param3": "Voir sur la courbe",
    "dashboard.annex.param4": "Créer une publication",
    "dashboard.annex.param5": "signalement",
    "dashboard.annex.param7": "Supprimer ce poste",
    "dashboard.annex.param8": "Voir sur le graphique",
    "dashboard.annex.param9":
      "Désolé, aucune norme trouvée pour cet étalonnage ",
    "dashboard.annex.param10": "Normes",
    "dashboard.annex.param11":
      "Désolé, aucun blanc trouvé pour cet étalonnage ",
    "dashboard.annedx.param12": "Crée le ",
    "dashboard.annex.param13": " Nouvelle calibration n°",
    "dashboard.annex.param14": "Désolé, aucun poste trouvé 🤓",
    "dashboard.annex.param15": "vous devez sélectionner au moins une variable",
    "dashboard.annex.param16": "Type de données",
    "dashboard.annex.param17": "Poste supprimé avec succès 🥳",
    "dashboard.annex.param18": "Image téléchargée avec succès 🥳 !",
    "dashboard.annex.param19":
      "Impossible d'obtenir les données de la base de données 😔, Veuillez actualiser ou contacter l'équipe technique !",
    "dashboard.annex.param20":
      "Impossible d'obtenir des postes 😔, Veuillez actualiser ou contacter l'équipe technique !",
    "dashboard.annex.param21":
      "Impossible de supprimer le poste 😔, Veuillez contacter l'équipe technique !",
    "dashboard.annex.param22":
      "Impossible de télécharger l'image 😔, réessayez ou contactez l'équipe technique !",
    "dashboard.annex.param23": "Image téléchargée avec succès 🥳 !",
    "dashboard.annex.param24": "Poste ajouté avec succès 🥳",
    "dashboard.annex.param25":
      "Impossible d'ajouter un poste 😔, Veuillez contacter l'équipe technique !",
    "dashboard.annex.param26":
      "Impossible d'obtenir la pièce jointe de post_id {post_id}😔, Veuillez contacter l'équipe technique pour résoudre le problème!",
    "protocol.firstView.param1": "Protocole",
    "protocol.firstView.param3": "Nouveau protocole",
    "protocol.firstView.param4": "Créer un nouveau protocole",
    "protocol.firstView.param5": "Copier un protocole existant",
    "protocol.firstView.param6": "Protocole en direct",
    "protocol.firstView.param7": "Protocole activé",
    "protocol.firstView.param8": "Protocole désactivé",
    "protocol.firstView.param9": "Crée par",
    "protocol.firstView.param10": "Appliqué du",
    "protocol.firstView.param11": "au",
    "protocol.firstView.param13": "Protocole à ",
    "protocol.firstView.param14": "Appliquer ce protocole",
    "protocol.firstView.param16": "A propos de ce protocole",
    "protocol.firstView.param17": "Nom",
    "protocol.firstView.param18": "observatoire",
    "protocol.firstView.param19": "date de début",
    "protocol.firstView.param20": "date de fin",
    "protocol.firstView.param21": "Trouver un protocole",
    "protocol.firstView.param22":
      "Pourquoi créez vous ce nouveau protocole ? 🤩",
    "protocol.firstView.param23": "Titre du nouveau protocole",
    "protocol.firstView.param24": "Vue d'ensemble",
    "protocol.firstView.param25": "Vue par variable",
    "protocol.SecondView.param17":
      "Créer des règles pour constituer votre protocole petit à petit",
    "protocol.SecondView.param18": "Créer votre première règle",
    "protocol.SecondView.param19": "Nouvelle régle",
    "protocol.SecondView.param20": "Crée une régle",
    "protocol.SecondView.param21": "Appliquer sur les données",
    annuler: "annuler",
    "protocol.SecondView.param23": "Enregistrer",
    "protocol.SecondView.param24":
      "Un protocole est constitué de plusieurs règles",
    "protocol.SecondView.param25": "Modifier la régle",
    "protocol.SecondView.param26": "Régle",
    "protocol.SecondView.param27": "Consigne",
    "protocol.SecondView.param28":
      "Pour créer vos règles, sélectionnez des paramètres, puis créer vos consignes !",
    "protocol.SecondView.param29": "Choisir un laboratoire",
    "protocol.SecondView.param30": "Paramétres à filtrer",
    "protocol.SecondView.param31": "PARAMETRES PHYSICO-CHIMIQUES",
    "protocol.SecondView.param32": "PARAMETRES TECHNIQUES",
    "protocol.SecondView.param33": "Création des consignes",
    "protocol.SecondView.param34": "Ajouter une consigne",
    "protocol.SecondView.param35": "Télécharger en csv",
    "protocol.SecondView.param36": "Télécharger vos données de",
    "protocol.SecondView.param37": "Choisir une Cadence",
    "protocol.SecondView.param38": "Choisir des variables",
    "protocol.SecondView.param39": "Choisir un intervalle",
    "protocol.SecondView.param40": "Filtrer",
    "protocol.SecondView.param41":
      "Lancez le process de filtration de data en cliquant sur le bouton ci dessus pour pouvoir la télécharger !",
    "protocol.SecondView.param42":
      "Veuiller noter que le process peut prendre quelques secondes",
    "protocol.SecondView.param43":
      "Votre data filtrée est prête à être téléchargée",
    "protocol.SecondView.param44":
      "Les modifications ont bien été enregistrées!",
    "protocol.SeconView.param45": "Tous les protocoles",
    "protocol.SecondView.param46":
      "Les modifications en cours seront suppriméss , cette action est irréversible.",
    "protocol.SecondView.param47": "Continuer",
    "protocol.SecondView.param48": "Annuler",
    "extraplot.param1": "Aucun graphique trouvé😔",
    "extraplot.param2":
      "Veuillez ajouter un nouveau en utilisant le bouton du haut !",
    "extraplot.param3": "Ajouter un nouveau graphique",
    "extraplot.param4":
      "Êtes-vous sûr de vouloir supprimer définitivement ce graphique",
    "extraplot.param5": "Supprimer",
    "extraplot.param6": "annuler",
    "extraplot.annex.param1": "Configuration graphique",
    "extraplot.annex.param2": "Couples de données",
    "extraplot.annex.param3": "nouveau couple",
    "extraplot.annex.param4": "Choisissez vos données de graphique",
    "extraplot.annex.param5": "Par défaut, le temps est choisie comme",
    "extraplot.annex.param6": "pour",
    "extraplot.annex.param7": "Veuillez choisir votre",
    "extraplot.annex.param8": "variable à ajouter sur le graphique",
    "extraplot.annex.param9": "fermé",
    "extraplot.annex.param10": "Options de titre",
    "extraplot.annex.param11": "Titre du texte",
    "extraplot.annex.param12": "Sous-titre du texte",
    "extraplot.annex.param13": "Configuration légende",
    "extraplot.annex.param14": "Show",
    "extraplot.annex.param15": "Alignement vertical :",
    "extraplot.annex.param16": "Alignement horizontal :",
    "extraplot.annex.param17": "Configuration générale",
    "extraplot.annex.param18": "Configuration du thème",
    "extraplot.annex.param19": "Light themes:",
    "extraplot.annex.param20": "Dark themes:",
    "extraplot.annex.param21": "Configuration grid",
    "extraplot.annex.param22": "Espace gauche : ",
    "extraplot.annex.param23": "Espace droite : ",
    "extraplot.annex.param24": "Espace haut : ",
    "extraplot.annex.param25": "Espace bas : ",
    "extraplot.annex.param26": "Retour",
    "extraplot.annex.param27": "Enregistrer graphique",
    "extraplot.annex.param28": "Créé le :",
    "extraplot.annex.param29": "titre du graphique",
    "extraplot.annex.param30": "Sous-titre du graphique",
    "extraplot.annex.param31": "haut",
    "extraplot.annex.param32": "milieu",
    "extraplot.annex.param33": "bas",
    "extraplot.annex.param34": "gauche",
    "extraplot.annex.param35": "centre",
    "extraplot.annex.param36": "droite",
    "extraplot.annex.param37": "Échec de l'enregistrement des mises à jour",
    "extraplot.annex.param38":
      "Un nouveau graphique est ajouté, Veuillez revenir au tableau de bord extraplot pour le trouver !",
    "extraplot.annex.param39": "Mises à jour des graphiques enregistrées",
    "extraplot.annex.param40":
      "Impossible de supprimer le graphique, veuillez réessayer !",
    "extraplot.annex.param41": "Le graphique a été supprimé",
    "cardssection.param1": "En direct",
    //cations
    Lithium: "Lithium",
    Sodium: "Sodium",
    Potassium: "Potassium",
    Magnesium: "Magnesium",
    Calcium: "Calcium",
    Strontium: "Strontium",
    Ammonium: "Ammonium",
    Silicium: "Silicium",
    //Anions
    Fluorures: "Fluorures",
    Chlorures: "Chlorures",
    Bromures: "Bromures",
    Sulfates: "Sulphates",
    Phosphates: "Phosphates",
    Nitrates: "Nitrates",
    Nitrites: "Nitrites",
    //pc parameters
    "Temperature Bac Noir": "Température Bac Noir",
    pH: "pH",
    Conductivite: "conductivité",
    Turbidite: "Turbidité",
    O2: "O2",
    "Niveau Riviere": "Niveau Rivière",
    COD: "COD",
    COT: "COT",
    Silice: "Silice",
    Durete: "Dureté",
    "Conductivite EF": "Conductivité EF",
    "O2 EF": "O2 EF",
    "pH EF": "pH EF",
    //T parameters
    "Pression Pompe": "Pression Pompe",
    "Consigne Pression Pompe": "Consigne Pression Pompe",
    "Pression EF": "Pression EF",
    "Debit Pompe": "Débit Pompe",
    "Consigne Debit Pompe": "Consigne Débit Pompe",
    "Debit EF": "Débit EF",
    "Debit Riviere": "Débit Rivière",
    "Vitesse Moteur": "Vitesse Moteur",
    "Temperature Exterieure": "Température Extérieure",
    "Temperature Interieure": "Température Intérieure",
    "Ouverture Vanne": "Ouverture Vanne",
    "Consigne Ouverture Vanne": "Consigne Ouverture Vanne",
    "Prochain Nettoyage": "Prochain Nettoyage",
    "Fermeture Vanne": "Fermeture Vanne",
    "Pression Circuit 1": "Pression Circuit 1",
    "Pression Circuit 2": "Pression Circuit 2",
    "Pression Circuit 3": "Pression Circuit 3",
    "Pression Circuit 4": "Pression Circuit 4",
    "Pression Circuit 5": "Pression Circuit 5",
    "Volume Eau 1": "Volume Eau 1",
    "Volume Eau 2": "Volume Eau 2",
    "Volume Eau 3": "Volume Eau 3",
    "Niveau Cuve 1": "Niveau Cuve 1",
    "Niveau Cuve 2": "Niveau Cuve 2",
    "Niveau Cuve 3": "Niveau Cuve 3",
    niveau_cuve_3: "niveau_cuve_3",
    "Chlore Redox": "Chlore Redox",
    "Température Eau": "Température Eau",
    "Conductivité (actuelle)": "Conductivité (actuelle)",
    "Conductivité (spécifique)": "Conductivité (spécifique)",
    "O2 dissous": "O2 dissous",
    "Saturation O2": "Saturation O2",
    "Pression Air": "Pression Air",
    Densité: "Densité",
    "Niveau Rivière": "Niveau Rivière",
    "Potentiel Rédox": "Potentiel Rédox",
    PaO2: "PaO2",
    "Pression Eau": "Pression Eau",
    "Total Solides Dissous": "Total Solides Dissous",
    Turbidité: "Turbidité",
    "Niveau Batterie": "Niveau Batterie",
    "Voltage Externe": "Voltage Externe",
    "Debit Eau Brute": "Debit Eau Brute",
    "Température Bac Noir": "Température Bac Noir",
    Conductivité: "Conductivité",
    "Pression Eau Filtrée": "Pression Eau Filtrée",
    "Débit Eau Filtrée": "Débit Eau Filtrée",
    reset: "réinitialiser",
    réinitialiser: "réinitialiser",
    MasCamargues: "MasCamargues",
    "Pression avant filtres": "Pression avant filtres",
    "Pression entre filtres": "Pression entre filtres",
    "Pression après filtres": "Pression après filtres",
    "Pression avant charbon actif": "Pression avant charbon actif",
    "Pression alimentation": "Pression alimentation",
    "Eau alimentation": "Eau alimentation",
    "Eau relevage": "Eau relevage",
    "Eau adoucie": "Eau adoucie",
    "Eau alimentation inst.": "Eau alimentation inst.",
    "Eau relevage inst.": "Eau relevage inst.",
    "Eau adoucie inst.": "Eau adoucie inst.",
    R2Lavage: "R2Lavage",
    "Volume Eau 1 Direct": "Volume Eau 1 Direct",
    "Volume Eau 2 Direct": "Volume Eau 2 Direct",
    "Volume Eau 3 Direct": "Volume Eau 3 Direct",
    RLDemo1: "RLDemo1",
    "Conductivité Eau Filtrée": "Conductivité Eau Filtrée",
    "Température Extérieure": "Température Extérieure",

    //Automate
    "Etat Lumiere Exterieure": "Etat Lumiére extérieure",
    "Etat Lumiere Interieure": "Etat Lumiére Intérieure",
    "Pompe Marche": "Etat de la pompe",
    timedate: "timedate",
    "Vanne Marche": "Etat de la vanne",
    "Etat Lumiere RL215": "Etat Lumiere RL215",
    Fichier: "Fichier",
    "Ajouter des tags": "Ajouter des tags",
    "Ajouter des paramétres pour identifier vos publications plus facilement !":
      "Ajouter des paramétres pour identifier vos publications plus facilement !",
    Arsenic: "Arsenic",
    Mercure: "Mercure",
    //others
    "paramètres météo": "PARAMÈTRES MÉTÉO",
    "plus de filtres": "plus de filtres",
    panne: "panne",
    pompe: "pompe",
    "eau brut": "eau brut",
    "eau filtrée": "eau filtrée",
    sondes: "sondes",
    analyseurs: "analyseurs",
    "chromato-ionique": "chromato-ionique",
    "data connexion": "data connexion",
    riviére: "riviére",
    "raw data": "data brut",
    "validated data": "data validée",
    "Télécharger les données de": "Télécharger les données de",
    "get data from DataBase": "obtenir les données de la base",
    minute: "1 minute",
    "10minutes": "10 minutes",
    hour: "heure",
    day: "jour",
    all: "tout",
    "Getting data drom database": "obtension des données de la base",
    "The process may take a few seconds":
      "Le processus peut prendre quelques secondes",
    "le processus peut prendre quelques secondes":
      "Le processus peut prendre quelques secondes",
    onduleur: "onduleur",
    Onduleur: "onduleur",
    "alarme TOC": "alarme TOC",
    "Alarme TOC": "alarme TOC",
    "Max Acide": "Max Acide",
    "Max Max Acide": "Max Max Acide",
    "Max Base": "Max Base",
    "Cde M/A Durete": "Cde M/A Durete",
    RESET: "RESET",
    Orgeval: "Orgeval",
    Naizin: "Naizin",
    Aubure: "Aubure",
    Monticello: "Monticello",
    paramétres: "paramétres",
    enregistrer: "enregistrer",
    Zinc: "Zinc",
    "Ajouter un type pour le post": "Ajouter un type pour le post",
    "Ajouter un intervalle de temps pour l'intervention":
      "Ajouter un intervalle de temps pour l'intervention",
    "Ajouter une description": "Ajouter une description",
    De: "De",
    A: "A",
    "Frequence Pompe": "Frequence Pompe",
    Gauges: "Gauges",
    "Timer Decolmatage": "Timer Decolmatage",
    Français: "Français",
    French: "Français",
    Anglais: "Anglais",
    Alerts: "Alertes",
    "Create an alert": "Créer une alerte",
    "new alert": "Nouvelle alerte",
    metric: "Métrique",
    "Trigger rules": "RÈGLES DE DÉCLENCHEMENT",
    rule: "Règle",
    "Value greater than": "Valeur supérieure à ...",
    "Value lower than": "Valeur inférieure à ...",
    "Value between": "Valeur comprise entre ...",
    setting: "Paramètre",
    Temperature: "Température",
    "Choose a condition type": "Choisissez un type de condition",
    units: "unités",
    decimals: "décimales",
    and: "et",
    "create the alert": "Créer l’alerte",
    validate: "Valider",
    "add a rule": "Ajouter une règle",
    "NOTIFICATION SETTINGS": "PARAMÈTRES DE NOTIFICATIONS",
    "notification in news feed": "Notification dans le fil d’actualité",
    "by WhatsApp message or e-mail": "Par message WhatsApp ou e-mail",
    "add a recipient": "Ajouter un destinataire",
    "recipient n°1": "Destinataire n°1",
    recipients: "Destinataires",
    "select recipients": "Sélectionnez des destinataires",
    "select all collaborators": "Sélectionner tous les collaborateurs",
    "whole team": "Toute l’équipe {station}",
    "edit recipients": "Modifier les destinatires",
    "Add recipients": "Ajouter des destinataires",
    "notify by Whatsapp message": "Prévenir par message Whatsapp",
    "notify by e-mail": "Prévenir par e-mail",
    next: "Suivant",
    "Message d’alerte dans Extralab": "Message d’alerte dans Extralab",
    "message in Extralab Whatsapp": "Message dans le Whatsapp d’Extralab",
    edit: "Modifier",
    delete: "Supprimer",
    temperature: "Température",
    pressure: "Pression",
    debit: "Débit",
    "cancel alert creation": "Annuler la création d’alerte",
    "your changes will not be saved.":
      "Vos modifications ne seront pas enregistrées.",
    leave: "Quitter",
    "alert created!": "Alerte créée !",

    "your alert has been saved, to modify it, go to “My alerts”.":
      "Votre alerte a bien été enregistrée, pour la modifier rendez-vous dans “Mes alertes”.",
    "An alert triggers a message in the News Feed for all collaborators. Each employee can individually silence an alert.":
      "Une alerte déclenche un message dans le fil d’actualité pour tous les collaborateurs. Chaque collaborateur peut individuellement mettre en silence une alerte.",
    "Alert message in the Riverlab Whatsapp group":
      "Message d’alerte dans le groupe Whatsapp du Riverlab",
    "Send emails to selected recipients":
      "Envoyer des e-mail à des destinataires sélectionnés",
    "my alerts": "Mes alertes",
    "current value": "Valeur courante",
    "activate alert": "Activer l’alerte",
    mute: "Mettre en silence",
    "Veuillez saisir une valeur numérique.":
      "Veuillez saisir une valeur numérique.",
    "Veuillez saisir une valeur comprise entre 0 et 14.":
      "Veuillez saisir une valeur comprise entre 0 et 14.",
    "Veuillez saisir une valeur positive ou nulle.":
      "Veuillez saisir une valeur positive ou nulle.",
    "Un décimal ne peut avoir qu'une valeur positive ou nulle.":
      "Un décimal ne peut avoir qu'une valeur positive ou nulle.",
    "See the details": "Voir les détails",
    Alert: "Alerte",
    Today: "Aujourd’hui à ",
    "Active alert": "Alerte active",
    "Trigger history": "Historique des déclenchements",
    "Delete alert for all collaborators":
      "Supprimer l’alerte pour tous les collaborateurs",
    "Please note that this alert will be deleted for all employees. Triggers will no longer be recorded. Note that an alert can also be silenced.":
      "Attention, cette alerte sera supprimée pour l’ensemble des collaborateurs. {br} Les déclenchements ne seront plus enregistrés. {br} Sachez qu’une alerte peut aussi être mise en silence.",
    "please note that the alert will be activated for all employees.":
      "Attention, l’alerte sera activée pour à l’ensemble des collaborateurs.",
    activate: "Activer",
    "desactivate the alert": "Désactiver l’alerte",
    "please note that the alert will be deactivated for all employees. Triggers will no longer be recorded.":
      "Attention, l’alerte sera désactivée pour à l’ensemble des collaborateurs. {br} Les déclenchements ne seront plus enregistrés.",
    desactivate: "Désactiver",
    NOTIFICATIONS: "NOTIFICATIONS",
    "This alert has not been triggered": "Cette alerte n'a pas été déclanchée",
    "this user": "Cet utilisateur",
    "You don't have the right to delete users with this role":
      "Vous n'avez pas le droit de supprimer des utilisateurs avec cet rôle",
    "Create your first alert!": "Créez votre première alerte !",
    "Be informed in real time of the evolution of your chronicles. Notify your collaborators by e-mail or Whatsapp.":
      "Soyez informé en temps réel de l’évolution de vos chroniques. {br}  Notifiez vos collaborateurs par e-mail ou par Whatsapp.",
    "Team management": "Gestion d’équipe",
    Team: "Équipe",
    Station: "Station",
    "Select a team": "Sélectionner une équipe",
    "Activez et désactivez l’alerte pour l’ensemble des collaborateurs. Vous pouvez aussi mettre l’alerte en silence afin de couper vos notifications.":
      "Activez et désactivez l’alerte pour l’ensemble des collaborateurs. Vous pouvez aussi mettre l’alerte en silence afin de couper vos notifications.",
    battery: "battery",
    chlore: "chlore",
    "Alert threshold at": "Seuil de déclenchement d'alerte à {valeur} {unité}.",
    "Index UV": "Index UV",
    Salinité: "Salinité",
    Resistivité: "Resistivité",
    "Température Air": "Température Air",
    Guilin: "Guilin",
    MasCamarguesVortex: "MasCamarguesVortex",
    Visibilité: "Visibilité",
    "Coucher du soleil": "Coucher du soleil",
    "Lever du soleil": "Lever du soleil",
    "Vitesse du vent": "Vitesse du vent",
    "Vitesse rafale": "Vitesse rafale",
    "Direction vent": "Direction vent",
    "Temp. ressentie": "Temp. ressentie",
    "Point de rosée": "Point de rosée",
    Humidité: "Humidité",
    "Vitesse de Surface": "Vitesse de Surface",
    "Température Intérieure": "Température Intérieure",
    "pH Eau Filtrée": "pH Eau Filtrée",
    "O2 dissous Eau Filtrée": "O2 dissous Eau Filtrée",
    "Dureté/Alcalinité": "Dureté/Alcalinité",
    "Air pressure": "Pression Air",
    "Valve position": "Position valve",
    "Room temperature": "Température ambiante",
    "Pump engine speed": "Vitesse moteur pompe",
    "Filtered water flow": "Débit d'eau filtrée",
    "Water flow": "Débit eau",
    "Water pressure": "Pression eau",
    "Dissolved O2": "O2 dissous",
    Conductivity: "Conductivité",
    "Water temperature": "température eau",
    "Commande Analyseur Dureté": "Ordered Analyseur Dureté",
    "Niveau 2 Bac Base": "Niveau 2 Bac Base",
    "Niveau 1 Bac Base": "Niveau 1 Bac Base",
    "Niveau 1 Bac Acide": "Niveau 1 Bac Acide",
    "Niveau 2 Bac Acide": "Niveau 2 Bac Acide",
    "Alarme Bac COT": "Alarme Bac COT",
    "Statut de l'onduleur": "Statut de l'onduleur",
    "Fréquence du Moteur": "Fréquence du Moteur",
    Intervention: "Intervention",
    Report: "Report",
    signalement: "signalement",
    team: "Team",
    English: "Anglais",
    "Publish the post": "Publier le message",
  },
};
