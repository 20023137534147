import { FormattedMessage, useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "./Accordion/Accordion";
import { ReactComponent as TitleIcon } from "../../../icons/title.svg";
import { ReactComponent as LeftTextIcon } from "../../../icons/left-text.svg";
import { ReactComponent as RightTextIcon } from "../../../icons/right-text.svg";
import { ReactComponent as CenterTextIcon } from "../../../icons/center-text.svg";

export default function PanelOptionSection({
  setOption,
  option,
  expanded,
  handleChangeAccordion,
}) {
  const intl = useIntl();

  function handleTitleChange(name, value) {
    setOption((prev) => {
      return {
        ...prev,
        notMerge: false,
        option: {
          ...prev.option,
          title: { ...prev.option.title, [name]: value },
        },
      };
    });
  }

  return (
    <Accordion
      expanded={expanded.has("panel2")}
      onChange={handleChangeAccordion("panel2")}
      backgroundColor="white"
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <p className="text-primary font-medium flex gap-2">
          <TitleIcon />
          <span className="text-normal">
            {" "}
            <FormattedMessage id="extraplot.annex.param10" />
          </span>
        </p>
      </AccordionSummary>
      <AccordionDetails>
        <div className="flex flex-col gap-1 justify-center items-center">
          <div className="w-full">
            <p className="text-xs font-medium">
              <FormattedMessage id="extraplot.annex.param11" />
            </p>
            <TextField
              onChange={(event) =>
                handleTitleChange(event.target.name, event.target.value)
              }
              name="text"
              value={option.option.title.text}
              className="w-full"
              id="standard-basic"
              variant="outlined"
              placeholder={intl.formatMessage({
                id: "extraplot.annex.param29",
              })}
            />
          </div>
          <div className="w-full">
            <p className="text-xs font-medium">Position</p>
            <div className="inline-block">
              <div className="py-2 px-4 rounded-8px flex gap-2 items-center border-1 border-primary border-opacity-20">
                <button
                  name="left"
                  value="left"
                  onClick={() => handleTitleChange("left", "left")}
                  className={`${
                    option.option.title.left === "left"
                      ? "text-white bg-primary"
                      : "text-primary"
                  } p-1 focus:outline-none rounded-8px`}
                >
                  <LeftTextIcon />
                </button>
                <button
                  name="left"
                  value="right"
                  onClick={() => handleTitleChange("left", "right")}
                  className={`${
                    option.option.title.left === "right"
                      ? "text-white bg-primary"
                      : "text-primary"
                  } p-1 focus:outline-none rounded-8px`}
                >
                  <RightTextIcon />
                </button>
                <button
                  name="left"
                  value="center"
                  onClick={() => handleTitleChange("left", "center")}
                  className={`${
                    option.option.title.left === "center"
                      ? "text-white bg-primary"
                      : "text-primary"
                  } p-2 focus:outline-none rounded-8px`}
                >
                  <CenterTextIcon />
                </button>
              </div>
            </div>
          </div>
          <div className="w-full">
            <p className="text-xs font-medium">
              <FormattedMessage id="extraplot.annex.param12" />{" "}
            </p>
            <TextField
              onChange={(event) =>
                handleTitleChange(event.target.name, event.target.value)
              }
              name="subtext"
              value={option.option.title.subtext}
              className="w-full"
              id="standard-basic"
              variant="outlined"
              placeholder={intl.formatMessage({
                id: "extraplot.annex.param30",
              })}
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
