import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPosts } from "../../Redux/actions";
import { deselectParam } from "../../Redux/actions";
import ParameterPanel from "./ParameterPanel";
import axios from "axios";
import moment from "moment";

export default function Modal({ setIsOpen }) {
  const dispatch = useDispatch();
  const [content, setContent] = useState("");
  const [isSignalement, setIsSignalement] = useState(false);
  const [isIntervention, setIsIntervention] = useState(false);
  const user = useSelector((state) => state.user).preferred_username;
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);

  const [isShownParams, setIsShownParams] = useState(false);

  function handleChange(e) {
    setContent(e.target.value);
    auto_grow(e);
  }

  useEffect(() => {}, [content]);

  const handleSubmit = () => {
    let postDate = moment().toISOString();
    //console.log("date in submit",postDate)
    const newPost = {
      riverlab: currentSelectedCard.riverlab,
      timedate: postDate.slice(0, 10) + " " + postDate.slice(11, 19),
      post_id: Math.floor(Math.random() * 1000).toString(),
      answers: null,
      attachment: "",
      author: user,
      content: content,
      intervention: isIntervention,
      likes: null,
      post_end: postDate.slice(0, 10) + " " + postDate.slice(11, 19),
      post_start: postDate.slice(0, 10) + " " + postDate.slice(11, 19),
      signalement: isSignalement,
      tags: null,
    };
    console.log("new post", newPost);
    axios
      .post(process.env.REACT_APP_API_URL + "/posts", newPost)
      .then((res) => {
        console.log("new post", res.data);
        axios
          .get(
            process.env.REACT_APP_API_URL +
              `/observatories/${currentSelectedCard.riverlab}/posts/latest?quantity=100`
          )
          .then((res) => {
            dispatch(getPosts(Object.values(res.data)));
            setIsOpen(false);
          })
          .catch((err) => console.log("Error!" + err));
      })
      .catch((err) => console.log("error", err));
  };

  //Calculate the height of the text area
  let lasHeight = "h-8";
  let newHeight = "h-8";
  function auto_grow(element) {
    element.target.classList.remove(`h-${lasHeight}`);
    //console.log("element scrollHeight", element.target.scrollHeight)
    let calc = Math.round(element.target.scrollHeight / 16 / 0.25);
    //Adapt to tailwind classes
    if (calc < 16) {
      newHeight = calc;
    } else {
      newHeight = calc % 4 == 0 ? calc : calc + 2;
    }
    element.target.classList.add(`h-${newHeight}`);
    lasHeight = newHeight;
    //console.log("element class", 'h-'+ newHeight)
    //console.log("element classList after add", element.target.classList)
  }

  return (
    <div className="fixed w-full h-full pin z-50 overflow-visible bg-smoke-light flex">
      <div className=" bg-white w-full max-w-xl m-auto flex-col  rounded-lg max-h-full overflow-auto">
        <div className="font-semibold p-3 flex justify-between">
          Créer une publication
          <button
            className="focus:outline-none"
            onClick={(e) => {
              setIsOpen(false);
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.3 5.70997C17.91 5.31997 17.28 5.31997 16.89 5.70997L12 10.59L7.11 5.69997C6.72 5.30997 6.09 5.30997 5.7 5.69997C5.31 6.08997 5.31 6.71997 5.7 7.10997L10.59 12L5.7 16.89C5.31 17.28 5.31 17.91 5.7 18.3C6.09 18.69 6.72 18.69 7.11 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10997C18.68 6.72997 18.68 6.08997 18.3 5.70997Z"
                fill="#8E9BBB"
              />
            </svg>
          </button>
        </div>

        <div className="pb-4 pt-5 px-6 relative">
          <div className="w-full ">
            <div className="flex items-center">
              <img
                className="h-16 w-16 mr-3 rounded-full object-cover"
                src=".assets/postlogo.jpeg"
                alt=""
              />
              <textarea
                className="max-h-64 min-h-8 h-8 w-11/12 overflow-hidden text-grey-light text-md pb-2 border-b border-border-panel resize-none outline-none focus:shadow-outline focus:bg-grey-100"
                rows="1"
                placeholder="Rédiger votre message ici..."
                onChange={handleChange}
              ></textarea>
            </div>

            <div className="ml-20 mb-4 mt-2">
              <FilterButton
                label="Signalement"
                id="1"
                setIsSignalement={setIsSignalement}
                setIsIntervention={setIsIntervention}
              />
              <FilterButton
                label="Intervention"
                id="2"
                setIsSignalement={setIsSignalement}
                setIsIntervention={setIsIntervention}
              />
            </div>

            <div
              className={`${
                !isShownParams ? "h-14 " : " h-auto "
              }  transition-all duration-75 ease-in-out`}
            >
              <div className="flex mb-8">
                <div className="flex-grow">
                  <p className="font-semibold">Paramètres</p>
                  <p className="text-xs text-grey-lighter">
                    Ajouter des paramétres pour identifier vos publications plus
                    facilement !
                  </p>
                </div>
                <button
                  className={`${
                    !isShownParams ? "hidden" : "block"
                  } flex items-center justify-center focus:outline-none`}
                  onClick={() => {
                    setIsShownParams(!isShownParams);
                  }}
                >
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.11953 6.71043L5.99953 2.83043L9.87953 6.71043C10.2695 7.10043 10.8995 7.10043 11.2895 6.71043C11.6795 6.32043 11.6795 5.69043 11.2895 5.30043L6.69953 0.710429C6.30953 0.320429 5.67953 0.320429 5.28953 0.710429L0.699533 5.30043C0.309532 5.69043 0.309532 6.32043 0.699533 6.71043C1.08953 7.09043 1.72953 7.10043 2.11953 6.71043Z"
                      fill="#343A40"
                    />
                  </svg>
                </button>
                <button
                  className={`${
                    isShownParams ? "hidden" : "block"
                  } flex items-center justify-center focus:outline-none`}
                  onClick={() => {
                    setIsShownParams(!isShownParams);
                  }}
                >
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.88047 1.28957L6.00047 5.16957L2.12047 1.28957C1.73047 0.89957 1.10047 0.89957 0.710469 1.28957C0.320469 1.67957 0.320469 2.30957 0.710469 2.69957L5.30047 7.28957C5.69047 7.67957 6.32047 7.67957 6.71047 7.28957L11.3005 2.69957C11.6905 2.30957 11.6905 1.67957 11.3005 1.28957C10.9105 0.90957 10.2705 0.89957 9.88047 1.28957Z"
                      fill="#343A40"
                    />
                  </svg>
                </button>
              </div>
              <ParamSection isShownParams={isShownParams} />
            </div>

            <div className="bottom-0  w-full flex justify-end mt-6">
              <button
                className="bg-primary-3 text-white px-7 py-2.5 rounded-lg text-xs mr-2 focus:outline-none"
                onClick={handleSubmit}
              >
                Publier ce message
              </button>
              <button
                className="bg-grey-buttons text-normal px-7 py-2.5 rounded-lg text-xs focus:outline-none"
                onClick={(e) => {
                  setIsOpen(false);
                }}
              >
                Annuler
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SelectedParamSection() {
  const SelectedParamsList = useSelector((state) => state.SelectedParams);
  const dispatch = useDispatch();
  const paramList = SelectedParamsList.map((param) => (
    <button className="flex justify-between bg-transparent-blue-8% text-primary rounded-md text-xs px-4 py-2 mr-2 mb-1 items-center ">
      <div className="mr-2 font-medium">{param}</div>

      <a
        className="h-3 w-3 focus:outline-none"
        onClick={() => {
          dispatch(deselectParam(param));
        }}
      >
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.2005 3.80714C11.9405 3.54714 11.5205 3.54714 11.2605 3.80714L8.00047 7.06047L4.74047 3.80047C4.48047 3.54047 4.06047 3.54047 3.80047 3.80047C3.54047 4.06047 3.54047 4.48047 3.80047 4.74047L7.06047 8.00047L3.80047 11.2605C3.54047 11.5205 3.54047 11.9405 3.80047 12.2005C4.06047 12.4605 4.48047 12.4605 4.74047 12.2005L8.00047 8.94047L11.2605 12.2005C11.5205 12.4605 11.9405 12.4605 12.2005 12.2005C12.4605 11.9405 12.4605 11.5205 12.2005 11.2605L8.94047 8.00047L12.2005 4.74047C12.4538 4.48714 12.4538 4.06047 12.2005 3.80714Z"
            fill="#3D80FB"
          />
        </svg>
      </a>
    </button>
  ));
  return <div className=" flex flex-wrap py-4 px-4">{paramList}</div>;
}

function ParamSection({ isShownParams }) {
  const [anions, setAnions] = useState([]);
  const [cations, setCations] = useState([]);
  const [parametres_physico_chimiques, setParametres_physico_chimiques] =
    useState([]);
  const [parametres_techniques, setParametres_techniques] = useState([]);
  const [parametres_meteo, setParametres_meteo] = useState([]);
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);
  useEffect(() => {
    if (currentSelectedCard.riverlab == "test") {
      axios
        .get("/api/parameters")
        .then((res) => {
          const anionsCopy = [...res.data.parameters[0].anions];
          const cationsCopy = [...res.data.parameters[0].cations];
          const parametres_physico_chimiques_copy = [
            ...res.data.parameters[0].parametres_physico_chimiques,
          ];
          const parametres_techniques_copy = [
            ...res.data.parameters[0].parametres_techniques,
          ];
          const parametres_meteo_copy = [
            ...res.data.parameters[0].parametres_meteo,
          ];

          setAnions(anionsCopy);
          setCations(cationsCopy);
          setParametres_physico_chimiques(parametres_physico_chimiques_copy);
          setParametres_techniques(parametres_techniques_copy);
          setParametres_meteo(parametres_meteo_copy);
        })
        .catch((err) => console.log("Error!"));
    } else {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            `/observatories/${currentSelectedCard.riverlab}/elements`
        )
        .then((res) => {
          //console.log("anions : ", res.data.anions);
          setAnions(res.data.anions);
          setCations(res.data.cations);
          setParametres_physico_chimiques(
            res.data.parametres_physico_chimiques
          );
          setParametres_techniques(res.data.parametres_techniques);
          setParametres_meteo(res.data.parametres_meteo);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  return (
    <div className={`${!isShownParams ? " hidden " : " block "} `}>
      <ElemtsSection anions={anions} cations={cations} />
      <ParametersSection
        parametres_physico_chimiques={parametres_physico_chimiques}
        parametres_techniques={parametres_techniques}
        parametres_meteo={parametres_meteo}
      />
      <SelectedParamSection />
    </div>
  );
}
function ElemtsSection({ anions, cations }) {
  const anionsList = anions.map((anion) => {
    return (
      <ParameterPanel key={anion.id} label={anion.label} type="parameter">
        <p className="text-xs font-bold">
          {anion.formule_chimique.element}
          <sub>{anion.formule_chimique.indice}</sub>
          <sup>{anion.formule_chimique.exposant}</sup>
        </p>
      </ParameterPanel>
    );
  });

  const cationsList = cations.map((cation) => {
    //console.log("cation", cation);
    return (
      <ParameterPanel key={cation.id} label={cation.label} type="parameter">
        <p className="text-xs font-bold">
          {cation.formule_chimique.element}
          <sub>{cation.formule_chimique.indice}</sub>
          <sup>{cation.formule_chimique.exposant}</sup>
        </p>
      </ParameterPanel>
    );
  });

  return (
    <div>
      <p className="font-semibold text-xs mb-2">anions</p>
      <div className="flex flex-wrap">
        {anionsList}
        <AddParamButton />
      </div>
      <p className="font-semibold text-xs mb-2">Cations</p>
      <div className="flex flex-wrap border-b border-border-panel">
        {cationsList}
        <AddParamButton />
      </div>
    </div>
  );
}

function AddParamButton() {
  return (
    <div className="bg-transparent-blue-8% text-primary   rounded-full h-12 w-12 flex items-center justify-center ml-4 mb-4">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 8L8 8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13L6 8L1 8C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6L6 6L6 1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6L13 6C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z"
          fill="#3D80FB"
        />
      </svg>
    </div>
  );
}

function ParametersSection({
  parametres_physico_chimiques,
  parametres_techniques,
  parametres_meteo,
}) {
  // const cations = [... params.cations];
  //const parametres_physico_chimiques = [... params.parametres_physico_chimiques];
  //const parametres_techniques = [... params.parametres_techniques]

  const parametres_physico_chimiques_List = parametres_physico_chimiques.map(
    (param) => {
      let paths = param.svg.map((path, i) => {
        return <path key={i} d={path} />;
      });
      return (
        <ParameterPanel key={param.id} type="parameter">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
          >
            {paths}
          </svg>
        </ParameterPanel>
      );
    }
  );

  const parametres_techniques_List = parametres_techniques.map((param) => {
    let paths = param.svg.map((path, i) => {
      return <path key={i} d={path} />;
    });
    return (
      <ParameterPanel key={param.id} type="parameter">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
        >
          {paths}
        </svg>
      </ParameterPanel>
    );
  });

  const parametres_meteo_List = parametres_meteo.map((param) => {
    let paths = param.svg.map((path, i) => {
      return <path key={i} d={path} />;
    });
    return (
      <ParameterPanel key={param.id} type="parameter">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
        >
          {paths}
        </svg>
      </ParameterPanel>
    );
  });

  return (
    <div>
      <p className="font-semibold text-xs mb-2">param phys</p>
      <div className="flex flex-wrap mt-4 ">
        {parametres_physico_chimiques_List}
        <AddParamButton />
      </div>
      <p className="font-semibold text-xs mb-2">param phys</p>
      <div className="flex flex-wrap mt-4 ">
        {parametres_techniques_List}
        <AddParamButton />
      </div>
      <p className="font-semibold text-xs mb-2">param phys</p>
      <div className="flex flex-wrap mt-4 ">
        {parametres_meteo_List}
        <AddParamButton />
      </div>
    </div>
  );
}

function FilterButton({ label, id, setIsSignalement, setIsIntervention }) {
  const [isSelected, setSelected] = useState(false);

  useEffect(() => {
    if (label === "Signalement") {
      setIsSignalement(isSelected);
    } else {
      setIsIntervention(isSelected);
    }
  }, [isSelected, label, setIsIntervention, setIsSignalement]);
  return (
    <button
      value={id}
      className={`${
        isSelected ? "bg-dark text-white" : "bg-grey-buttons text-grey-dark "
      } text-xs px-3.5 border-none py-2 mr-2 rounded-lg    font-medium resize-none focus:outline-none `}
      onClick={() => {
        setSelected(!isSelected);
      }}
    >
      <div className="flex">{label}</div>
    </button>
  );
}
