import { createReducer } from "@reduxjs/toolkit";
import { LOCALES } from "../i18n/locales";
import {
  TOGGLE_MODAL,
  GET_POSTS,
  SELECT_PARAMS,
  DESELECT_PARAM,
  CURRENT_SELECTED_CARD,
  CURRENT_SELECTED_PREFERRED_OBS,
  CURRENT_SELECTED_PREFERRED_PARAMETER,
  CURRENT_SELECTED_PREFERRED_ELEMENT,
  NEW_USER,
  SET_PROTOCOL_START_DATE,
  SET_PROTOCOL_END_DATE,
  SET_DASHBOARD_CHART_SELECTED_DATE,
  SET_DASHBOARD_FEED_SELECTED_DATE,
  SET_LANGUAGE,
  SET_KC,
  SET_SOCKET,
  SET_USER_REGISTERED_WS,
  IS_MOBILE,
  USER_ROLES,
  USER_PREFERENCES,
  ADD_POST_DIALOG_OPEN,
  SET_SELECTED_TAB,
} from "./actions";

const initialState = {
  user: null,
  showModal: false,
  posts: null,
  SelectedParams: [],
  SelectedParameters: [],
  SelectedElements: [],
  currentSelectedCard: null,
  currentSelectedPreferredObs: null,
  currentSelectedPreferredElem: null,
  currentSelectedPreferredParam: null,
  //new
  protocolStart_date: null,
  protocolEnd_date: null,
  dashboardChartSelectedDate: null,
  dashboardFeedSelectedDate: null,
  kc: null,
  socket: null,
  registered: false,
  isMobile: null,
  AddPostDialogIsOpen: false,
  selectedTab: 0,
  token: null,
  preferredParams: null,
  prefferedCards: null,
  chartVars: null,
  toDeleteSerie: null,
  chartConfig: null,
  interventionDates: null,
  graphs: null,
};

export const extraReducer = createReducer(initialState, {
  ["setGraphsOptions"]: (state, action) => {
    state.graphs = action.graphs;
  },

  ["setInterventionDates"]: (state, action) => {
    state.interventionDates = action.interventionDates;
  },

  ["setExtraPlotChartConfig"]: (state, action) => {
    state.chartConfig = action.chartConfig;
  },

  ["deletechartSerie"]: (state, action) => {
    state.toDeleteSerie = action.toDeleteSerie;
  },

  ["setchartVars"]: (state, action) => {
    state.chartVars = action.vars;
  },

  ["setPrefferedCards"]: (state, action) => {
    state.preferredCards = action.preferredCards;
  },

  ["setPrefferedParams"]: (state, action) => {
    state.preferredParams = action.preferredParams;
  },

  ["setToken"]: (state, action) => {
    state.token = action.token;
  },

  [ADD_POST_DIALOG_OPEN]: (state, action) => {
    state.AddPostDialogIsOpen = action.isOpen;
  },

  [SET_SELECTED_TAB]: (state, action) => {
    state.selectedTab = action.index;
  },

  [IS_MOBILE]: (state, action) => {
    state.isMobile = action.isMobile;
  },
  [USER_ROLES]: (state, action) => {
    state.userRoles = action.userRoles;
  },
  [USER_PREFERENCES]: (state, action) => {
    state.userPreferences = action.userPreferences;
  },
  [SET_USER_REGISTERED_WS]: (state, action) => {
    state.registered = action.registered;
  },

  [SET_SOCKET]: (state, action) => {
    state.socket = action.socket;
  },

  [SET_KC]: (state, action) => {
    state.kc = action.kc;
  },

  [SET_LANGUAGE]: (state, action) => {
    state.language = action.language;
  },

  [SET_DASHBOARD_FEED_SELECTED_DATE]: (state, action) => {
    state.dashboardFeedSelectedDate = action.date;
  },

  [SET_DASHBOARD_CHART_SELECTED_DATE]: (state, action) => {
    state.dashboardChartSelectedDate = action.date;
  },

  [SET_PROTOCOL_END_DATE]: (state, action) => {
    state.protocolEnd_date = action.end_date;
  },

  //new
  [SET_PROTOCOL_START_DATE]: (state, action) => {
    state.protocolStart_date = action.start_date;
  },

  [NEW_USER]: (state, action) => {
    state.user = action.user;
  },

  [TOGGLE_MODAL]: (state, action) => {
    state.showModal = !state.showModal;
  },

  [GET_POSTS]: (state, action) => {
    state.posts = action.posts;
  },

  [SELECT_PARAMS]: (state, action) => {
    let copyParams = state.SelectedParams;
    let copyParameters = state.SelectedParameters;
    let copyElements = state.SelectedElements;
    if (action.isSelected) {
      if (state.SelectedParams.includes(action.label)) {
        return;
      }
      // TO BE ADDED
      if (action.itemType === "parameter") {
        copyParameters = [...state.SelectedParameters, action.id];
      } else if (action.itemType === "element") {
        copyElements = [...state.SelectedElements, action.id];
      }
      copyParams = [...state.SelectedParams, action.label];
    } else {
      // TO BE REMOVED
      copyParams = state.SelectedParams.filter((e) => e !== action.label);
      copyParameters = state.SelectedParameters.filter((e) => e !== action.id);
      copyElements = state.SelectedElements.filter((e) => e !== action.id);
    }
    state.SelectedParams = copyParams;
    state.SelectedParameters = copyParameters;
    state.SelectedElements = copyElements;
  },

  [DESELECT_PARAM]: (state, action) => {
    let copyParams = [];
    copyParams = state.SelectedParams.filter((e) => e != action.label);
    state.SelectedParams = copyParams;
  },

  [CURRENT_SELECTED_CARD]: (state, action) => {
    state.currentSelectedCard = action.currentSelectedCard;
  },

  [CURRENT_SELECTED_PREFERRED_OBS]: (state, action) => {
    state.currentSelectedPreferredObs = action.currentSelectedPreferredObs;
  },

  [CURRENT_SELECTED_PREFERRED_ELEMENT]: (state, action) => {
    state.currentSelectedPreferredElem = action.currentSelectedPreferredElem;
  },

  [CURRENT_SELECTED_PREFERRED_PARAMETER]: (state, action) => {
    state.currentSelectedPreferredParam = action.currentSelectedPreferredParam;
  },
});
