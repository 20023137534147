/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { styled } from "@mui/system";
import { useParams } from "react-router-dom";
import { useSearchRiverlabs } from "../../hooks/useSearchRiverlabs";
import cloneDeep from "lodash.clonedeep";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import AnnexSection from "../../components/ExtraPlot/Annex/AnnexSection";
import ChartSection from "../../components/ExtraPlot/Chart/ChartSection";
import {
  payloadAccordingToChartType,
  urlAccordingToChartType,
} from "./ExtraPlotDashboard";
import {
  DEFAULT_OPTION,
  DEFAULT_SERIE_OPTION,
  DEFAULT_XAXIS_CONFIG,
  DEFAULT_YAXIS_CONFIG,
} from "./Const";

export default function ExtraPlot() {
  const [option, setOption] = useState(null);
  const echartRef = useRef(null);
  const observatoriesList = useSearchRiverlabs();
  const { id } = useParams();
  const graphs = useSelector((state) => state.graphs);
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.user);
  const intl = useIntl();
  function handleSaveChart() {
    const newSeries = {
      series: option.option.series.map((serie) => {
        return { ...serie, data: [] };
      }),
    };
    const newChart = {
      graph_id: option.graph_id,
      timedate: option.timedate,
      content: { ...option.option, ...newSeries },
      author: user.preferred_username,
      graph_type: option.graph_type,
      date_start: option.date_start,
      date_end: option.date_end,
      theme: option.theme,
    };
    axios
      .post(
        process.env.REACT_APP_API_URL + "/graph",
        JSON.parse(JSON.stringify(newChart, null, 4))
      )
      .then((res) => {
        enqueueSnackbar(
          option.graph_id === ""
            ? intl.formatMessage({
                id: "extraplot.annex.param38",
              })
            : intl.formatMessage({
                id: "extraplot.annex.param39",
              }),
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }
        );
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          intl.formatMessage({
            id: "extraplot.annex.param37",
          }),
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }
        );
      });
  }

  //generic add new couple to global config
  function handleAddNewCouple(newCouple, option) {
    const id =
      (newCouple.x
        ? newCouple.x.element + "-" + newCouple.x.observatory
        : "time") +
      "/" +
      newCouple.y.element +
      "-" +
      newCouple.y.observatory +
      "/" +
      Math.floor(Math.random() * 10000);
    getData(
      option.graph_type,
      option.date_start,
      option.date_end,
      newCouple,
      false,
      id
    );
  }

  function getData(graph_type, date_start, date_end, item, notMerge, id) {
    const payload = payloadAccordingToChartType(graph_type, item);
    const url = urlAccordingToChartType(graph_type, date_start, date_end);
    axios.post(process.env.REACT_APP_API_URL + url, payload).then((res) => {
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        newOption.couples.push({ ...item, id: id });
        let yAxisLength = prev.option.yAxis.length;
        let xAxisLength = prev.option.xAxis.length;
        newOption.notMerge = notMerge;
        newOption.option.grid.right =
          newOption.option.grid.right +
          (!(yAxisLength % 2 === 0) && yAxisLength > 1 ? 50 : 0);
        newOption.option.grid.left =
          newOption.option.grid.left +
          (yAxisLength % 2 === 0 && yAxisLength > 1 ? 50 : 0);
        newOption.option.grid.bottom =
          newOption.option.grid.bottom +
          (xAxisLength % 2 === 0 && xAxisLength > 1 ? 50 : 0);
        newOption.option.grid.top =
          newOption.option.grid.top +
          (!(xAxisLength % 2 === 0) && xAxisLength > 1 ? 50 : 0);
        //add xaxis
        if (prev.graph_type === "timeseries") {
          if (xAxisLength === 0) {
            newOption.option.xAxis = [{ ...DEFAULT_XAXIS_CONFIG, id: id }];
          }
        } else {
          const newXAxis = { ...DEFAULT_XAXIS_CONFIG };
          newXAxis.id = id;
          newXAxis.name = item.x.element + "-" + item.x.observatory;
          newXAxis.type = "value";
          newXAxis.position = xAxisLength % 2 === 0 ? "bottom" : "top";
          newXAxis.offset =
            xAxisLength > 1 ? 50 * Math.floor(xAxisLength / 2) : 0;
          newOption.option.xAxis.push(newXAxis);
        }
        //add yaxis
        newOption.option.yAxis.push({
          ...DEFAULT_YAXIS_CONFIG,
          id: id,
          name: item.y.element + "-" + item.y.observatory,
          position: yAxisLength % 2 === 0 ? "left" : "right",
          offset: yAxisLength > 1 ? 50 * Math.floor(yAxisLength / 2) : 0,
        });
        //set series
        newOption.option.series.push({
          ...DEFAULT_SERIE_OPTION,
          id: id,
          name: id,
          type: graph_type === "scatter" ? "scatter" : "line",
          xAxisIndex: !item.x ? 0 : xAxisLength,
          yAxisIndex: yAxisLength,
          data: !item.x ? res.data : res.data[0],
        });
        return newOption;
      });
    });
  }

  //load data (add new chart case)
  useEffect(() => {
    if (
      Number(id) === 0 &&
      observatoriesList &&
      observatoriesList.length !== 0
    ) {
      setOption(DEFAULT_OPTION);
      const initCouples = observatoriesList.map((elem, i) => {
        return {
          x: null,
          y: { element: "Conductivite", observatory: elem },
          id: "",
        };
      });
      initCouples.forEach((elem, i) => {
        handleAddNewCouple(elem, DEFAULT_OPTION);
      });
    }
  }, [observatoriesList, id]);

  //load data from redux (add new chart case)
  useEffect(() => {
    if (graphs && Number(id) !== 0) {
      const graph = graphs.find((graph) => graph.graph_id === id);
      const newOption = {
        option: graph.content,
        graph_id: graph.graph_id,
        timedate: graph.timedate,
        date_start: graph.date_start,
        date_end: graph.date_end,
        theme: graph.theme,
        notMerge: false,
        graph_type: graph.graph_type,
        couples: graph.couples,
      };
      setOption(newOption);
    }
  }, [graphs, id]);

  return (
    <>
      {option && (
        <ScreenContainer>
          <SplitScreenComponent>
            <Pane weight={4}>
              <ChartSection option={option} echartRef={echartRef} />
            </Pane>
            <Pane weight={2}>
              <AnnexSection
                echartRef={echartRef}
                handleAddNewCouple={handleAddNewCouple}
                setOption={setOption}
                option={option}
                handleSaveChart={handleSaveChart}
              />
            </Pane>
          </SplitScreenComponent>
        </ScreenContainer>
      )}
      {!option && <div>Loading</div>}
    </>
  );
}

const Pane = styled("div")((props) => ({ flex: props.weight }));

const ScreenContainer = styled("div")({
  width: "100%",
  height: "100%",
  marginLeft: "64px",
});

export function SplitScreenComponent({ children }) {
  const [leftComponent, rightComponent] = children;
  return (
    <Container>
      {leftComponent}
      {rightComponent}
    </Container>
  );
}

const Container = styled("div")({
  display: "flex",
  height: "100%",
});
