const transformTemperature = (unit, value, preferenceTemperatureUnit) => {
  if (preferenceTemperatureUnit !== unit) {
    if (preferenceTemperatureUnit === "°F" && unit === "°C") {
      value = (value * 9) / 5 + 32;
    }
    if (preferenceTemperatureUnit === "°C" && unit === "°F") {
      value = ((value - 32) * 5) / 9;
    }
    return value;
  }
  return value;
};

const transformFlow = (unit, value, preferenceFlowUnit) => {
  if (preferenceFlowUnit !== unit) {
    if (preferenceFlowUnit === "m³/h" && unit === "L/h") {
      value = value * 1000;
    }
    if (preferenceFlowUnit === "L/h" && unit === "m³/h") {
      value = value / 1000;
    }
    return value;
  }
  return value;
};

const transformConcentration = (
  unit,
  value,
  preferenceConcentrationUnit,
  param
) => {
  const massMolaireValue = parseInt(
    massMolaire
      .flatMap((value) => value[param])
      .filter((result) => result !== undefined)[0]
  );
  if (preferenceConcentrationUnit !== unit) {
    if (preferenceConcentrationUnit === "µmol/L" && unit === "mmol/L") {
      return value * 1000;
    }
    if (preferenceConcentrationUnit === "µmol/L" && unit === "ppb") {
      return value / massMolaireValue;
    }
    if (preferenceConcentrationUnit === "µmol/L" && unit === "ppm") {
      return (value * 1000) / massMolaireValue;
    }
    if (preferenceConcentrationUnit === "mmol/L" && unit === "µmol/L") {
      return value / 1000;
    }
    if (preferenceConcentrationUnit === "mmol/L" && unit === "ppb") {
      return (value * 1000) / massMolaireValue;
    }
    if (preferenceConcentrationUnit === "mmol/L" && unit === "ppm") {
      return value / massMolaireValue;
    }
    if (preferenceConcentrationUnit === "ppb" && unit === "µmol/L") {
      return value * massMolaireValue;
    }
    if (preferenceConcentrationUnit === "ppb" && unit === "mmol/L") {
      return value * 1000 * massMolaireValue;
    }

    if (preferenceConcentrationUnit === "ppb" && unit === "ppm") {
      return value * 1000;
    }
    if (preferenceConcentrationUnit === "ppm" && unit === "µmol/L") {
      return value * 1000 * massMolaireValue;
    }
    if (preferenceConcentrationUnit === "ppm" && unit === "mmol/L") {
      return value * massMolaireValue;
    }
    if (preferenceConcentrationUnit === "ppm" && unit === "ppb") {
      return value * 0.001;
    }
    return value;
  } else return value;
};

export const transformValueWithePreferncesUnit = (
  unit,
  value,
  preferenceUnit,
  param
) => {
  if (
    (unit === "°F" || unit === "°C") &&
    value !== "no rt data" &&
    value !== "no data"
  )
    return transformTemperature(unit, value, preferenceUnit);
  else if (
    (unit === "m³/h" || unit === "L/h") &&
    value !== "no rt data" &&
    value !== "no data"
  )
    return transformFlow(unit, value, preferenceUnit);
  else if (
    (unit === "ppm" ||
      unit === "ppb" ||
      unit === "mmol/L" ||
      unit === "µmol/L") &&
    value !== "no rt data" &&
    value !== "no data"
  ) {
    return transformConcentration(unit, value, preferenceUnit, param);
  } else return value;
};

export const transformUnit = (userPreferencesInfo, unit) => {
  if (unit === "°F" || unit === "°C") return userPreferencesInfo?.temperature;
  else if (unit === "m³/h" || unit === "L/h") return userPreferencesInfo?.flow;
  else if (
    unit === "ppm" ||
    unit === "ppb" ||
    unit === "mmol/L" ||
    unit === "µmol/L"
  )
    return userPreferencesInfo?.concentration;
  else return unit;
};

const massMolaire = [
  { Fluorures: "20,01" },
  { Chlorures: "35,453" },
  { Bromures: "159,81" },
  { Sulfates: "96,06" },
  { Phosphates: "94,9714" },
  { Nitrates: "62,0049" },
  { Nitrites: "46,005" },
  {
    Lithium: "7",
  },
  { Sodium: "22.9892" },
  { Potassium: "39,10" },
  { Magnesium: "24.3039" },
  { Calcium: "40.0769" },
  { Strontium: "87.6189" },
  { Ammonium: "18,04" },
  { Arsenic: "74,92159" },
  { Mercury: "342.61" },
  { Zinc: "65.409" },
];
