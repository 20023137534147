import styled from "styled-components";
import { Row } from "react-flexbox-grid";

export const StyledText = styled.h1`
  color: #2975ff;
  font-weight: 700;
  font-size: 25px;
`;

export const StyledLabel = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(46, 56, 73, 1);
  font-size: 18px;
  font-weight: 600;
  margin: 5px;
`;

export const StyledRow = styled(Row)`
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
  text-align: center;
`;
export const BlackSpan = styled.span`
  color: #343a40;
  margin-right: 5px;
`;
export const BlueText = styled.p`
  color: #2975ff;
`;

export const StyledInputButton = styled.input`
  font-weight: 700;
  font-size: 12px;
  width: 113px;
  cursor: pointer;
  border-radius: 5px;
  height: 32px;
  margin-top: 5px;
`;

export const StyledGreyText = styled.p`
  color: rgba(107, 124, 165, 1);
  font-size: 16px;
  font-weight: 400;
`;

export const RedText = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: red;
`;
export const GreyText = styled.p`
  color: #8488a5;
  font-weight: 400;
  font-size: 10px;
`;

export const StyledImage = styled.img`
  height: calc(100vh);
  transform: translate(15px, -33px);
`;
