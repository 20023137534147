import React, { useState, useEffect,useRef, useCallback } from "react";
import Switch from '@mui/material/Switch';

//Shared component
export function CustomSwitch ({handleChange,name,selected,...otherProps}) {

    const localhandleChange = (event) => {
        handleChange(name,event.target.checked)
    }

    return (
        <Switch {...otherProps} checked={selected} onChange={localhandleChange} disableRipple={true}  />  
    )
}