import React, { useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as ArrowRightIcon } from "../../../icons/arrow-right.svg";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AnnexSection from "./AnnexSection/AnnexSection";
import ChartSection from "./ChartSection/ChartSection";

export default function SecondView({
  setfirstView,
  selectedProtocol,
  setProtocols,
}) {
  const applyProtocol_ref = useRef(null);
  const applyProtocolWithoutSave_ref = useRef(null);
  const verifyChangesExist_ref = useRef(null);
  const [confirmSave, setconfirmSave] = useState(false);

  const handleClose = () => {
    setconfirmSave(false);
  };

  const handleOpen = () => {
    if (verifyChangesExist_ref.current()) {
      setfirstView(true);
    } else {
      setconfirmSave(true);
    }
  };

  return (
    <div className="mx-2 h-full flex flex-col">
      <div className="bg-white rounded-8px w-full text-xs flex items-center py-2 px-4">
        <button
          className="font-normal text-grey-text focus:outline-none"
          onClick={handleOpen}
        >
          <FormattedMessage id="protocol.SeconView.param45" />
        </button>
        <ArrowRightIcon className="mx-3" />
        <p className="text-secondary font-medium">{selectedProtocol.name}</p>
      </div>
      <div
        className="mt-2 grid grid-cols-3 gap-2"
        style={{ height: "calc(100% - 50px)" }}
      >
        <div className="col-span-2 bg-white mr-2 rounded-8px p-3 ">
          <ChartSection
            applyProtocol_ref={applyProtocol_ref}
            protocolId={selectedProtocol.protocol_id}
            observatory={selectedProtocol.observatory}
            applyProtocolWithoutSave_ref={applyProtocolWithoutSave_ref}
          />
        </div>
        <div className="col-span-1 bg-white rounded-8px overflow-y-auto">
          <AnnexSection
            selectedProtocol={selectedProtocol}
            setProtocols={setProtocols}
            applyProtocol_ref={applyProtocol_ref}
            applyProtocolWithoutSave_ref={applyProtocolWithoutSave_ref}
            verifyChangesExist_ref={verifyChangesExist_ref}
          />
        </div>
      </div>
      <Dialog
        open={confirmSave}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Etes vous sure ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormattedMessage id="protocol.SecondView.param46" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setfirstView(true);
              handleClose();
            }}
            className="text-primary bg-transparent-blue-15% py-2 px-1 mr-1 focus:outline-none rounded-8px font-medium"
          >
            <p>
              {" "}
              <FormattedMessage id="protocol.SecondView.param47" />
            </p>
          </button>
          <button
            onClick={handleClose}
            className="bg-grey-text text-white py-2 px-1 focus:outline-none rounded-8px font-medium"
          >
            <FormattedMessage id="protocol.SecondView.param48" />
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
