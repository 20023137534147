import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Slider from "@mui/material/Slider";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "./../Accordion/Accordion";
import { ReactComponent as GridIcon } from "../../../../icons/grid.svg";

export default function GridConfig({ gridConfig, setOption }) {
  const [expanded, setExpanded] = useState(new Set([]));

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    if (newExpanded) {
      setExpanded((prev) => {
        prev.add(panel);
        return new Set([...prev]);
      });
    } else {
      setExpanded((prev) => {
        prev.delete(panel);
        return new Set([...prev]);
      });
    }
  };

  const handleSliderChange = (event, newValue) => {
    setOption((prev) => {
      return {
        ...prev,
        option: {
          ...prev.option,
          grid: { ...prev.option.grid, [event.target.name]: newValue },
        },
      };
    });
  };

  return (
    <Accordion
      expanded={expanded.has("gridConfig")}
      onChange={handleChangeAccordion("gridConfig")}
      backgroundColor="rgba(142, 155, 187, 0.08)"
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="flex items-center">
          <p className="text-primary font-medium flex gap-2">
            <GridIcon /> <FormattedMessage id="extraplot.annex.param21" />
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="mb-2 px-3">
          <div className="flex gap-6 my-2">
            <p className="font-medium">
              <FormattedMessage id="extraplot.annex.param22" />
            </p>
            <div className="flex-grow">
              <Slider
                size="small"
                max={200}
                value={gridConfig.left}
                onChange={handleSliderChange}
                aria-labelledby="input-slider"
                name="left"
              />
            </div>
            <p className="text-primary font-medium">{gridConfig.left}</p>
          </div>
          <div className="flex gap-2 my-2">
            <p className="font-medium">
              <FormattedMessage id="extraplot.annex.param23" />
            </p>
            <div className="flex-grow">
              <Slider
                size="small"
                max={200}
                value={gridConfig.right}
                onChange={handleSliderChange}
                aria-labelledby="input-slider"
                name="right"
              />
            </div>
            <p className="text-primary font-medium">{gridConfig.right}</p>
          </div>
          <div className="flex gap-2 my-2">
            <p className="font-medium">
              <FormattedMessage id="extraplot.annex.param24" />
            </p>
            <div className="flex-grow">
              <Slider
                size="small"
                max={200}
                value={gridConfig.top}
                onChange={handleSliderChange}
                aria-labelledby="input-slider"
                name="top"
              />
            </div>
            <p className="text-primary font-medium">{gridConfig.top}</p>
          </div>
          <div className="flex gap-2 my-2">
            <p className="font-medium">
              <FormattedMessage id="extraplot.annex.param25" />
            </p>
            <div className="flex-grow">
              <Slider
                size="small"
                max={200}
                value={gridConfig.bottom}
                onChange={handleSliderChange}
                aria-labelledby="input-slider"
                name="bottom"
              />
            </div>
            <p className="text-primary font-medium">{gridConfig.bottom}</p>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
