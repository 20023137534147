import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import Tooltip from "@material-ui/core/Tooltip";
import {
  BlackMediumText,
  BlackNormalText,
  GreyText,
  SmalBlackText,
  StyledButton,
  StyledMenuItem,
  GreySmallText,
  StyledDiv,
  BlueSmallText,
  BlueBorder,
  SmallBlueText,
  BlackBoldText,
  BlackSmallText,
  ButtonWithBlueTextWhiteBackground,
  StyledMenu,
  SmallGrayText,
  DivWithProps,
  WhiteButtonWithBlueText,
  GrayInsideDiv,
  DarkGreyText,
  BlackMediumTextWithMargin,
  BlueNormalTextWithPadding,
  BlackText,
  DivWithPadding,
  ModifMenuItem,
  ColWithProps,
  RedButton,
  BlueButton,
  StyledBlackBoldText,
  HeaderText,
  BlueGrayText,
  StyledTextField,
  ErrorText,
  StyledTableCell,
  ModifAlertMenuItem,
  BlackTitle,
  GrayText,
  StyledBigText,
  StyledParagraph,
  StyledImage,
} from "./Alerts.style";
import { ReactComponent as MoreIcon } from "../../icons/more.svg";
import { ReactComponent as AlertOnIcon } from "../../icons/alertOn.svg";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { ReactComponent as GrayClose } from "../../icons/grayClose.svg";
import { ReactComponent as TrashIcon } from "../../icons/trash.svg";
import { ReactComponent as GridViewIcon } from "../../icons/gridView.svg";
import { ReactComponent as BlueGridView } from "../../icons/blueGridView.svg";
import { ReactComponent as BlueLineViewIcon } from "../../icons/blueLineView.svg";
import { ReactComponent as LineViewIcon } from "../../icons/lineView.svg";
import { ReactComponent as FlechIcon } from "../../icons/flèche.svg";
import { ReactComponent as MuteIcon } from "../../icons/mute.svg";
import { ReactComponent as DropDownIcon } from "../../icons/dropDown.svg";
import { ReactComponent as DropUpIcon } from "../../icons/dropUp.svg";
import { ReactComponent as HorizontalMoreIcon } from "../../icons/horizontalMore.svg";
import { ReactComponent as InfoBulleOnIcon } from "../../icons/infobull_on.svg";
import { ReactComponent as InfoBulleOffIcon } from "../../icons/infobull_off.svg";
import { ReactComponent as HeartBeatChart } from "../../icons/heartbeat-chart.svg";
import DialogActions from "@mui/material/DialogActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import CircularProgress from "@mui/material/CircularProgress";
import withStyles from "@mui/styles/withStyles";
import * as qs from "qs";
import { format } from "date-fns";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  ListItemText,
  Menu,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Skeleton from "@mui/material/Skeleton";

import {
  BlackBoldTextSatoshiFont,
  OverFlowAutoDiv,
  StyledCheckButton,
  StyledCol,
} from "../UsersManagement/UsersManagement.style";
import { useSearchRiverlabs } from "../../hooks/useSearchRiverlabs";
import { Col, Row } from "react-flexbox-grid";
import axios from "axios";
import { createPicture } from "../UsersManagement/Const";
import { capitalizeFirstLetter } from "../../utils/char";
import { isReaderOnly } from "./../../utils/roles";

import {
  getUnityAndDecimalValidityForMetric,
  isRuleValid,
  handleGetRealTimeData,
  getUnitForSetting,
  updateAlertApi,
  getId,
  checkIsAlert,
} from "./Alerts.service";
import _ from "lodash";
import Gauge from "../../components/Gauge";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import {
  formatRiverlabForBackend,
  stationToUpperCase,
} from "../../utils/riverlabs";
import { applyUTCtoDate } from "../../utils/date";

const Alerts = React.memo(() => {
  const [chosenStation, setChosenStation] = useState("");
  const [chosenSetting, setChosenSetting] = useState("");
  const userPreferencesInfo = useSelector((state) => state.userPreferences);
  /**
   * format of rules array of
   * {
   *   type: (between | less | greate),
   *   values: array of {unity: number, decimal: number}
   *   validated: boolean,
   * }
   */
  const [rules, setRules] = useState([]);
  const userRoles = useSelector((state) => state.userRoles);
  const updateRuleInCreateView = (ruleIdx, updatedRule) =>
    setRules((oldRules) =>
      oldRules.map((oldRule, oldRuleIdx) => {
        if (oldRuleIdx !== ruleIdx) return oldRule;
        return updatedRule;
      })
    );

  const updateChosenAlertToModify = (updatedChosenAlertToModify) => {
    setIsChosenAlertToModifModified(true);
    setChosenAlertToModif(updatedChosenAlertToModify);
  };

  const updateChosenAlertsToModifyRule = (ruleIdx, updatedRule) => {
    const updatedRules = chosenAlertToModif.rules.map((oldRule, oldRuleIdx) => {
      if (oldRuleIdx !== ruleIdx) return oldRule;
      return updatedRule;
    });
    updateChosenAlertToModify({ ...chosenAlertToModif, rules: updatedRules });
  };
  const [triggerHistory, setTriggerHistory] = useState();
  const alertsHistoryTriggers = async (alertId) => {
    const historyTriggersResponse = await axios.get(
      process.env.REACT_APP_API_URL + `/alert/id/${alertId}/pasttriggers`
    );
    setTriggerHistory(historyTriggersResponse?.data);
  };

  const isMobile = useSelector((state) => state.isMobile);

  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openTeams = Boolean(anchorEl);
  const [alertToDeactivate, setAlertToDeactivate] = useState(null);
  const [anchorModifAlert, setAnchorModifAlert] = useState(null);
  const openModifAlert = Boolean(anchorModifAlert);

  const [anchorElRecipient, setAnchorElRecipient] = useState(null);
  const [listView, setListView] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [deleteButtonClicked, setDeleteButtonClicked] = useState(false);
  const [seeDetailsButtonClicked, setSeeDetailsButtonClicked] = useState(false);
  const [editAlertButtonClicked, setEditAlertButtonClicked] = useState(false);

  const closeEditAlert = () => {
    setChosenAlertToModif(null);
    setEditAlertButtonClicked(false);
    setIsChosenAlertToModifModified(false);
    handleGetAlerts();
  };

  const addRecipientRef = useRef();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!!anchorElRecipient && addRecipientRef.current) {
      addRecipientRef.current.scrollIntoView();
    }
    isReaderOnly(userRoles);
  }, [anchorElRecipient, userRoles]);

  const [chosenRecipients, setChosenRecipients] = useState([]);

  const [anchorVariableEl, setAnchorVariableEl] = useState(null);
  const openVaraiables = Boolean(anchorVariableEl);

  const [anchorRuleEl, setAnchorRuleEl] = useState(null);
  const openRules = Boolean(anchorRuleEl);
  const [anchorModifRuleEl, setAnchorModifRuleEl] = useState(null);
  const [ruleToModifyIdx, setRuleToModifyIdx] = useState();
  const openModifRules = Boolean(anchorModifRuleEl);

  const [users, setUsers] = useState([]);
  const intl = useIntl();
  const [withWhatsApp, setWithWhatsApp] = useState(false);
  const [withEmail, setWithEmail] = useState(false);

  const [openCancel, setOpenCancel] = useState(false);

  const handleClickCancelOpen = () => {
    setOpenCancel(true);
  };

  const [openCreate, setOpenCreate] = useState(false);

  const handleClickCreateOpen = () => {
    setOpenCreate(true);
  };

  const [unit, setUnit] = useState("");

  const handleChangeUnity1 = (ruleIdx, event, forModifyView = false) => {
    let values = null;
    const rulesToUse = forModifyView ? chosenAlertToModif.rules : rules;
    if (rulesToUse[ruleIdx].values.length > 1) {
      values = [
        {
          unity: event.target.value,
          decimal: rulesToUse[ruleIdx].values[0].decimal,
        },
        rulesToUse[ruleIdx].values[1],
      ];
    } else {
      values = [
        {
          unity: event.target.value,
          decimal: rulesToUse[ruleIdx].values[0].decimal,
        },
      ];
    }
    if (forModifyView) {
      updateChosenAlertsToModifyRule(ruleIdx, {
        ...rulesToUse[ruleIdx],
        values,
      });
    } else {
      updateRuleInCreateView(ruleIdx, {
        ...rulesToUse[ruleIdx],
        values,
      });
    }
  };
  const handleChangeUnity2 = (ruleIdx, event, forModifyView = false) => {
    const rulesToUse = forModifyView ? chosenAlertToModif.rules : rules;
    const updatedRule = {
      ...rulesToUse[ruleIdx],
      values: [
        rulesToUse[ruleIdx].values[0],
        {
          unity: event.target.value,
          decimal: rulesToUse[ruleIdx].values[1].decimal,
        },
      ],
    };
    if (forModifyView) {
      updateChosenAlertsToModifyRule(ruleIdx, updatedRule);
    } else {
      updateRuleInCreateView(ruleIdx, updatedRule);
    }
  };
  const handleChangeDecimal1 = (ruleIdx, event, forModifyView = false) => {
    const rulesToUse = forModifyView ? chosenAlertToModif.rules : rules;
    let values = null;
    if (rulesToUse[ruleIdx].values.length > 1) {
      values = [
        {
          decimal: event.target.value,
          unity: rulesToUse[ruleIdx].values[0].unity,
        },
        rulesToUse[ruleIdx].values[1],
      ];
    } else {
      values = [
        {
          decimal: event.target.value,
          unity: rulesToUse[ruleIdx].values[0].unity,
        },
      ];
    }
    if (forModifyView) {
      updateChosenAlertsToModifyRule(ruleIdx, {
        ...rulesToUse[ruleIdx],
        values,
      });
    } else {
      updateRuleInCreateView(ruleIdx, {
        ...rulesToUse[ruleIdx],
        values,
      });
    }
  };

  const handleChangeDecimal2 = (ruleIdx, event, forModifyView = false) => {
    const rulesToUse = forModifyView ? chosenAlertToModif.rules : rules;
    const updatedRule = {
      ...rulesToUse[ruleIdx],
      values: [
        rulesToUse[ruleIdx].values[0],
        {
          decimal: event.target.value,
          unity: rulesToUse[ruleIdx].values[1].unity,
        },
      ],
    };
    if (forModifyView) {
      updateChosenAlertsToModifyRule(ruleIdx, updatedRule);
    } else {
      updateRuleInCreateView(ruleIdx, updatedRule);
    }
  };

  const handleRuleClick = (event) => {
    setAnchorRuleEl(event.currentTarget);
  };
  const handleRuleClose = () => {
    setAnchorRuleEl(null);
  };

  const handleModifRuleClick = (event, ruleIdx) => {
    setAnchorModifRuleEl(event.currentTarget);
    setRuleToModifyIdx(ruleIdx);
  };
  const handleModifRuleClose = () => {
    setAnchorModifRuleEl(null);
  };
  const handleVariableClick = (event) => {
    setAnchorVariableEl(event.currentTarget);
  };
  const handleVariableClose = () => {
    setAnchorVariableEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseRecipient = () => {
    setAnchorElRecipient(null);
  };
  const handleCloseClicked = () => {
    setOpen(false);
    setChosenSetting("");
    setChosenStation("");
    setRules([]);
    setWithEmail(false);
    setWithWhatsApp(false);
  };
  const connectedRiverlab = useSearchRiverlabs();
  const [alerts, setAlerts] = useState([]);
  const [infoClicked, setInfoClicked] = useState(
    Array(alerts.length).fill(false)
  );

  const [alertsRealTimeValues, setAlertsRealTimeValues] = useState([]);

  const getAlertsRealTimeData = useMemo(async () => {
    const ids = await Promise.all(
      alerts.map((alert) => getId(alert.chosenStation[0], alert.chosenSetting))
    );
    const realTimeValues = await Promise.all(
      alerts.map((alert, indx) =>
        handleGetRealTimeData(
          alert.chosenStation[0],
          ids.map((param) => param[0]).filter((metric) => metric !== undefined)[
            indx
          ]
        )
      )
    );
    if (!_.isEqual(realTimeValues, alertsRealTimeValues)) {
      setAlertsRealTimeValues(realTimeValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts, alertsRealTimeValues]);
  useEffect(() => {
    const setIntervalId = setInterval(getAlertsRealTimeData, 3000);
    return () => clearInterval(setIntervalId);
  }, [getAlertsRealTimeData]);

  const [historyClicked, setHistoryClicked] = useState(
    Array(alerts.length).fill(false)
  );

  const [chosenAlertToModif, setChosenAlertToModif] = useState(null);

  const [isChosenAlertToModifModified, setIsChosenAlertToModifModified] =
    useState(false);

  const renderUnit = useCallback(() => {
    setUnit(getUnitForSetting(chosenSetting, chosenStation.toLowerCase()));
  }, [chosenSetting, chosenStation]);

  useEffect(() => {
    setHistoryClicked(Array(alerts.length).fill(false));
    setInfoClicked(Array(alerts.length).fill(false));
  }, [alerts]);

  const fetchUsers = useCallback(async () => {
    let registeredUsers = [];
    const river =
      chosenAlertToModif !== null
        ? formatRiverlabForBackend(chosenAlertToModif.chosenStation[0])
        : formatRiverlabForBackend(chosenStation);
    if (chosenAlertToModif !== null) {
      registeredUsers = await axios
        .get(process.env.REACT_APP_FRONTEND_URL + `/api/users/team/${river}`)
        .then((resp) => resp.data);
    } else {
      registeredUsers = await axios
        .get(process.env.REACT_APP_FRONTEND_URL + `/api/users/team/${river}`)
        .then((resp) => resp.data);
    }
    setUsers(registeredUsers);
  }, [chosenStation, chosenAlertToModif]);

  const riverWithWriterRole =
    userRoles &&
    Object.keys(userRoles)
      .filter(
        (key) => userRoles[key] === "writer" || userRoles[key] === "admin"
      )
      .map((river) => capitalizeFirstLetter(river));
  const handleGetAlerts = useCallback(async () => {
    const createdAlerts =
      connectedRiverlab &&
      (await axios
        .get(process.env.REACT_APP_FRONTEND_URL + `/api/alerts/alert`, {
          params: {
            riverlabs: connectedRiverlab,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        })
        .then((resp) => resp.data));
    setAlerts(createdAlerts);
  }, [connectedRiverlab]);

  useEffect(() => {
    fetchUsers();
    renderUnit();
    handleGetAlerts();
  }, [chosenStation, fetchUsers, chosenSetting, renderUnit, handleGetAlerts]);
  const [riverParams, setRiverParams] = useState([]);
  const [riverParamUnit, setRiverParamUnit] = useState([]);
  const [riverParamIds, setRiverParamIds] = useState();

  const handleGetParams = useCallback(async () => {
    const river =
      chosenAlertToModif !== null
        ? chosenAlertToModif.chosenStation[0]
        : chosenStation;
    const params =
      river &&
      (await axios
        .get(
          process.env.REACT_APP_API_URL +
            `/observatories/${river}/realtime/gauges`
        )
        .then((resp) => resp.data.data.map((param) => param.label)));
    const ids =
      river &&
      (await axios
        .get(
          process.env.REACT_APP_API_URL +
            `/observatories/${river}/realtime/gauges`
        )
        .then((resp) => resp.data.data.map((param) => param.element)));
    setRiverParams(params);
    setRiverParamIds(ids);
  }, [chosenAlertToModif, chosenStation]);

  useEffect(() => {
    handleGetParams();
  }, [chosenAlertToModif, chosenStation, handleGetParams]);

  const handleGetUnit = useCallback(async () => {
    const chosenSettingIndex = riverParams.indexOf(chosenSetting);
    const metricUnit = await axios
      .get(process.env.REACT_APP_API_URL + `/units`)
      .then((res) => res.data.units);
    if (
      Object.keys(metricUnit).length !== 0 &&
      chosenStation !== "" &&
      chosenSetting !== "" &&
      chosenAlertToModif === null
    ) {
      setRiverParamUnit(
        metricUnit[chosenStation][riverParamIds[chosenSettingIndex]]
      );
    } else if (
      chosenAlertToModif !== null &&
      Object.keys(metricUnit).length !== 0 &&
      chosenAlertToModif.chosenStation[0] !== "" &&
      chosenAlertToModif.chosenSetting !== ""
    ) {
      setRiverParamUnit(
        metricUnit[chosenAlertToModif.chosenStation[0]][
          riverParamIds[riverParams.indexOf(chosenAlertToModif.chosenSetting)]
        ]
      );
    }
  }, [
    riverParams,
    chosenSetting,
    chosenStation,
    chosenAlertToModif,
    riverParamIds,
  ]);
  useEffect(() => {
    handleGetUnit();
  }, [chosenStation, chosenSetting, handleGetUnit, chosenAlertToModif]);

  const handleCreateAlert = async () => {
    const alertId = await getId(chosenStation, chosenSetting);

    const userData = await axios
      .get(
        process.env.REACT_APP_FRONTEND_URL +
          `/api/users/getUserInformation/${user.email}`
      )
      .then((res) => res.data.filter((user) => user.team === chosenStation)[0]);

    let newChosenRecipients =
      chosenRecipients.filter(
        (chosenRecipient) => chosenRecipient.email === user.email
      ).length === 0
        ? [...chosenRecipients, userData]
        : chosenRecipients;

    const chosenRecipientsWithMutedParam = newChosenRecipients.map(
      (chosenRecipient) => {
        return { ...chosenRecipient, muted: false };
      }
    );
    user &&
      alertId &&
      (await axios.post(process.env.REACT_APP_FRONTEND_URL + "/api/alerts", {
        chosenStation: chosenStation,
        chosenSetting: chosenSetting,
        chosenId: alertId[0],
        rules: rules,
        withWhatsApp: withWhatsApp,
        withEmail: withEmail,
        chosenRecipients: chosenRecipientsWithMutedParam,
        userEmail: user.email,
      }));
  };
  const renderActivateAlertClicked = () => {
    const onSubmit = () => {
      setAlerts(
        alerts.map((currentAlert) => {
          if (currentAlert._id === alertToDeactivate._id) {
            return {
              ...currentAlert,
              activateAlerts: !currentAlert.activateAlerts,
            };
          }
          return currentAlert;
        })
      );
      updateAlertApi(
        {
          ...alertToDeactivate,
          activateAlerts: !alertToDeactivate.activateAlerts,
        },
        alertToDeactivate._id
      );
      setAlertToDeactivate(null);
    };
    return (
      <Dialog maxWidth={"xs"} fullWidth={true} open>
        <DialogTitle style={{ padding: "20px" }}>
          <div className="flex items-center relative">
            <BlackTitle style={{ fontFamily: "Satoshi" }}>
              <FormattedMessage
                id={
                  !alertToDeactivate.activateAlerts
                    ? "activate alert"
                    : "desactivate the alert"
                }
              />
            </BlackTitle>
            <button
              className="absolute right-0 top-1"
              onClick={() => {
                setAlertToDeactivate(null);
              }}
            >
              <CloseIcon />
            </button>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent
          style={{
            padding: "20px",
          }}
        >
          <SmalBlackText style={{ fontFamily: "Roboto" }}>
            <FormattedMessage
              id={
                !alertToDeactivate.activateAlerts
                  ? "please note that the alert will be activated for all employees."
                  : "please note that the alert will be deactivated for all employees. Triggers will no longer be recorded."
              }
              values={{ br: <br /> }}
            />
          </SmalBlackText>
        </DialogContent>
        <DialogActions style={{ padding: "20px" }}>
          <ButtonWithBlueTextWhiteBackground
            style={{
              fontFamily: "Roboto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => setAlertToDeactivate(null)}
          >
            <FormattedMessage id="usermanagement.param17" />
          </ButtonWithBlueTextWhiteBackground>

          {!alertToDeactivate.activateAlerts ? (
            <StyledButton
              disabled={false}
              onClick={onSubmit}
              style={{
                fontFamily: "Roboto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormattedMessage id={"activate"} />
            </StyledButton>
          ) : (
            <RedButton
              onClick={onSubmit}
              style={{
                fontFamily: "Roboto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "20px",
              }}
            >
              <FormattedMessage id={"desactivate"} />
            </RedButton>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  const renderStationAndSettingsInputs = () => (
    <>
      <Col style={{ marginRight: "16px" }}>
        <BlackMediumText style={{ marginBottom: "15px", fontFamily: "Roboto" }}>
          Station
        </BlackMediumText>
        {chosenStation === "" ? (
          <StyledDiv onClick={handleClick}>
            <GreySmallText style={{ fontFamily: "Roboto" }}>
              Ex : Monticello
            </GreySmallText>
            <KeyboardArrowDownIcon />
          </StyledDiv>
        ) : (
          <StyledDiv onClick={handleClick}>
            <BlueSmallText style={{ fontFamily: "Roboto" }}>
              {chosenStation}
            </BlueSmallText>
            <KeyboardArrowDownIcon />
          </StyledDiv>
        )}
        <Menu anchorEl={anchorEl} open={openTeams} onClose={handleClose}>
          {riverWithWriterRole.map((river, index) => (
            <MenuItemWithHover
              onClick={async () => {
                setAnchorEl(null);
                setChosenStation(river);
              }}
            >
              <ListItemText>
                {riverWithWriterRole[index] === chosenStation ? (
                  <SmallBlueText style={{ fontFamily: "Roboto" }}>
                    {river}
                  </SmallBlueText>
                ) : (
                  <SmalBlackText style={{ fontFamily: "Roboto" }}>
                    {river}
                  </SmalBlackText>
                )}
              </ListItemText>
              {riverWithWriterRole[index] === chosenStation && (
                <Typography variant="body2" color="text.secondary">
                  <StyledCheckButton className="text-primary" />
                </Typography>
              )}
            </MenuItemWithHover>
          ))}
        </Menu>
      </Col>
      <Col>
        <BlackMediumText style={{ marginBottom: "15px", fontFamily: "Roboto" }}>
          <FormattedMessage id="setting" />
        </BlackMediumText>
        <StyledDiv onClick={handleVariableClick}>
          {chosenSetting === "" ? (
            <>
              <GreySmallText style={{ fontFamily: "Roboto" }}>
                Ex : <FormattedMessage id="Temperature" />
              </GreySmallText>
              <KeyboardArrowDownIcon />
            </>
          ) : (
            <BlueSmallText style={{ fontFamily: "Roboto" }}>
              {intl.formatMessage({ id: chosenSetting })}
            </BlueSmallText>
          )}
        </StyledDiv>

        <StyledMenu
          anchorEl={anchorVariableEl}
          open={openVaraiables}
          onClose={handleVariableClose}
        >
          {riverParams &&
            riverParams.map((metric, index) => (
              <MenuItemWithHover
                onClick={() => {
                  setAnchorVariableEl(null);
                  setChosenSetting(metric);
                }}
              >
                <ListItemText>
                  {riverParams[index] === chosenSetting ? (
                    <SmallBlueText style={{ fontFamily: "Roboto" }}>
                      {intl.formatMessage({ id: metric })}
                    </SmallBlueText>
                  ) : (
                    <SmalBlackText style={{ fontFamily: "Roboto" }}>
                      {intl.formatMessage({ id: metric })}
                    </SmalBlackText>
                  )}
                </ListItemText>
                {riverParams[index] === chosenSetting && (
                  <Typography variant="body2" color="text.secondary">
                    <StyledCheckButton className="text-primary" />
                  </Typography>
                )}
              </MenuItemWithHover>
            ))}
        </StyledMenu>
      </Col>
    </>
  );

  const handleDeleteAlert = async () => {
    const alertId = chosenAlertToModif._id;
    await axios.delete(
      process.env.REACT_APP_FRONTEND_URL + `/api/alerts/delete/${alertId}`
    );
    handleGetAlerts();
  };

  const renderDeleteAlertButtonClicked = () => {
    return (
      <Dialog
        maxWidth={"xs"}
        fullWidth={true}
        open={chosenAlertToModif}
        onClose={() => {}}
      >
        <DialogTitle style={{ padding: "15px" }}>
          <div className="flex items-center relative">
            <BlackTitle
              style={{
                fonFamily: "Satoshi",
                fontWeight: 700,
                fontSize: "20px",
              }}
            >
              <FormattedMessage id="Delete alert for all collaborators" />
            </BlackTitle>
            <button
              className="absolute right-0 top-1"
              onClick={() => {
                setChosenAlertToModif(null);
                setDeleteButtonClicked(false);
              }}
            >
              <CloseIcon />
            </button>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <SmalBlackText style={{ fontWeight: 400, fontFamily: "Roboto" }}>
            <FormattedMessage
              id="Please note that this alert will be deleted for all employees. Triggers will no longer be recorded. Note that an alert can also be silenced."
              values={{ br: <br /> }}
            />
          </SmalBlackText>
        </DialogContent>
        <DialogActions style={{ paddingBottom: "15px" }}>
          <ButtonWithBlueTextWhiteBackground
            onClick={() => {
              setChosenAlertToModif(null);
              setDeleteButtonClicked(false);
            }}
          >
            <FormattedMessage id="usermanagement.param17" />
          </ButtonWithBlueTextWhiteBackground>

          <RedButton
            onClick={() => {
              setChosenAlertToModif(null);
              setDeleteButtonClicked(false);
              handleDeleteAlert();
            }}
          >
            <FormattedMessage id="delete" />
          </RedButton>
        </DialogActions>
      </Dialog>
    );
  };

  const renderSeeAlertDetails = () => {
    const alertIndx = alerts
      .map((alert) => alert._id)
      .indexOf(chosenAlertToModif._id);
    return (
      <Dialog maxWidth={"md"} fullWidth={true} open={chosenAlertToModif}>
        <DialogTitle>
          <div className="flex items-center relative">
            <BlackTitle style={{ fontFamily: "Satoshi", fontWeight: 700 }}>
              {intl.formatMessage({ id: "Alert" }) + " " + (alertIndx + 1)}
            </BlackTitle>
            <button
              className="absolute right-0 top-1"
              onClick={() => {
                setChosenAlertToModif(null);
                setSeeDetailsButtonClicked(false);
              }}
            >
              <CloseIcon />
            </button>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent
          className="no-scrollbar"
          style={{ fontFamily: "Roboto" }}
        >
          <OverFlowAutoDiv className="flex flex-col gap-2 py-3 no-scrollbar">
            <Row className="flex flex-col gap-10" style={{ marginLeft: "3px" }}>
              <Col>
                <BlackMediumText style={{ marginBottom: "15px" }}>
                  Station
                </BlackMediumText>
                <StyledDiv>
                  <BlueSmallText>
                    {chosenAlertToModif.chosenStation[0]}
                  </BlueSmallText>
                </StyledDiv>
              </Col>
              <Col>
                <BlackMediumText style={{ marginBottom: "15px" }}>
                  <FormattedMessage id="setting" />
                </BlackMediumText>
                <StyledDiv>
                  <BlueSmallText>
                    {intl.formatMessage({
                      id: chosenAlertToModif.chosenSetting,
                    })}
                  </BlueSmallText>
                </StyledDiv>
              </Col>
              <Divider />
              <Col>
                <GreyText
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <FormattedMessage id="Trigger rules" />
                </GreyText>
              </Col>
              <Col>
                {chosenAlertToModif.rules.map((rule, ruleIdx) => (
                  <>
                    <BlackMediumText
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        fontFamily: "Roboto",
                      }}
                    >
                      {`${intl.formatMessage({ id: "rule" })} n°${ruleIdx + 1}`}
                    </BlackMediumText>
                    <div
                      style={{
                        border: "1px solid #EBECF3",
                        width: "800px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "48px",
                      }}
                    >
                      <div
                        style={{
                          padding: 20,
                          display: "flex",
                          alignItems: "center",
                          fontSize: "12px",
                          color: "#343A40",
                          fontWeight: 300,
                          gap: 10,
                        }}
                      >
                        {`${rule.type
                          .replace(
                            "Valeur",
                            intl.formatMessage({
                              id: chosenAlertToModif.chosenSetting,
                            })
                          )
                          .replace("...", "")}`}
                        <BlueNormalTextWithPadding>
                          {rule.values.length === 1
                            ? rule.values[0].unity +
                              "," +
                              rule.values[0].decimal
                            : rule.values[0].unity +
                              "," +
                              rule.values[0].decimal}
                        </BlueNormalTextWithPadding>
                        {rule.values.length > 1 && (
                          <>
                            <BlackText>{" et "}</BlackText>
                            <BlueNormalTextWithPadding>
                              {rule.values[1].unity +
                                "," +
                                rule.values[1].decimal}
                            </BlueNormalTextWithPadding>
                          </>
                        )}

                        <BlackText>{riverParamUnit}</BlackText>
                      </div>
                    </div>
                  </>
                ))}
              </Col>
              <Divider />
              <Col>
                <GreyText>
                  <FormattedMessage id="NOTIFICATIONS" />
                </GreyText>
                <Row style={{ marginLeft: "2px" }}>
                  <Col style={{ marginTop: "15px" }}>
                    <SmalBlackText>
                      <FormattedMessage id="Message d’alerte dans Extralab" />
                    </SmalBlackText>
                    <SmallGrayText style={{ fontSize: "12px" }}>
                      <FormattedMessage id="An alert triggers a message in the News Feed for all collaborators. Each employee can individually silence an alert." />
                    </SmallGrayText>
                  </Col>
                </Row>
                {chosenAlertToModif.withWhatsApp && (
                  <Row style={{ marginLeft: "2px" }}>
                    <Col style={{ marginTop: "15px" }}>
                      <SmalBlackText>
                        <FormattedMessage id="message in Extralab Whatsapp" />
                      </SmalBlackText>
                      <SmallGrayText style={{ fontSize: "12px" }}>
                        <FormattedMessage id="Alert message in the Riverlab Whatsapp group" />
                      </SmallGrayText>
                    </Col>
                  </Row>
                )}
                {chosenAlertToModif.withEmail && (
                  <>
                    <Row style={{ marginLeft: "2px" }}>
                      <Col style={{ marginTop: "15px" }}>
                        <SmalBlackText>E-mail</SmalBlackText>
                        <SmallGrayText style={{ fontSize: "12px" }}>
                          <FormattedMessage id="Send emails to selected recipients" />
                        </SmallGrayText>
                      </Col>
                    </Row>
                    {chosenAlertToModif.chosenRecipients.map(
                      (chosenRecipient) => (
                        <div
                          className="flex justify-between items-center p-4 hover:border-primary"
                          style={{
                            height: "20px",
                            width: 400,
                            marginLeft: "1%",
                            borderTop: "1px solid #EBECF3",
                            borderBottom: "1px solid #EBECF3",
                            marginBottom: "-5px",
                            marginTop: "15px",
                          }}
                        >
                          <Row className="flex justify-between items-center">
                            <StyledCol
                              className="mr-2"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "350px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: 20,
                                  alignItems: "center",
                                }}
                              >
                                {createPicture(
                                  chosenRecipient.firstName,
                                  25,
                                  25
                                )}
                                <SmalBlackText
                                  style={{ fontSize: 13, fontWeight: 300 }}
                                >
                                  {capitalizeFirstLetter(
                                    chosenRecipient.firstName
                                  ) +
                                    " " +
                                    capitalizeFirstLetter(
                                      chosenRecipient.lastName
                                    )}
                                </SmalBlackText>
                              </div>
                            </StyledCol>
                          </Row>
                        </div>
                      )
                    )}
                  </>
                )}
              </Col>
            </Row>
          </OverFlowAutoDiv>
        </DialogContent>
      </Dialog>
    );
  };

  const renderRuleForAlertToModify = (rule, ruleIdx) => {
    if (rule.validated) {
      return (
        <>
          <BlackMediumText style={{ marginBottom: 5, fontFamily: "Roboto" }}>
            {`${intl.formatMessage({ id: "rule" })} n°${ruleIdx + 1}`}
          </BlackMediumText>
          <div
            style={{
              border: "1px solid #EBECF3",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "48px",
              marginBottom: 15,
            }}
          >
            <div
              style={{
                padding: 20,
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                color: "#343A40",
                fontWeight: 300,
                gap: 10,
              }}
            >
              {`${rule.type
                .replace(
                  "Valeur",
                  intl.formatMessage({
                    id: chosenAlertToModif.chosenSetting,
                  })
                )
                .replace("...", "")}`}
              <BlueNormalTextWithPadding>
                {rule.values.length === 1
                  ? rule.values[0].unity + "," + rule.values[0].decimal
                  : rule.values[0].unity + "," + rule.values[0].decimal}
              </BlueNormalTextWithPadding>
              {rule.values.length > 1 && (
                <>
                  <BlackText>{" et "}</BlackText>
                  <BlueNormalTextWithPadding>
                    {rule.values[1].unity + "," + rule.values[1].decimal}
                  </BlueNormalTextWithPadding>
                </>
              )}

              <BlackText>{riverParamUnit}</BlackText>
            </div>
            <DivWithPadding>
              <MoreIcon
                style={{ cursor: "pointer" }}
                onClick={(event) => handleModifRuleClick(event, ruleIdx)}
              />
            </DivWithPadding>
            <Menu
              anchorEl={anchorModifRuleEl}
              open={openModifRules}
              onClose={handleModifRuleClose}
              style={{ marginLeft: "-19%" }}
            >
              <ModifMenuItemWithHover
                onClick={() => {
                  updateChosenAlertsToModifyRule(ruleToModifyIdx, {
                    ...rule,
                    validated: false,
                  });
                  handleModifRuleClose();
                }}
              >
                <ListItemText>
                  <SmalBlackText style={{ fontFamily: "Roboto" }}>
                    <FormattedMessage id="edit" />
                  </SmalBlackText>
                </ListItemText>
              </ModifMenuItemWithHover>
              <ModifMenuItemWithHover
                onClick={() => {
                  updateChosenAlertToModify({
                    ...chosenAlertToModif,
                    rules: chosenAlertToModif.rules.filter(
                      (oldRule, oldRuleIdx) => oldRuleIdx !== ruleToModifyIdx
                    ),
                  });
                  handleModifRuleClose();
                }}
              >
                <ListItemText>
                  <ErrorText style={{ fontFamily: "Roboto" }}>
                    <FormattedMessage id="delete" />
                  </ErrorText>
                </ListItemText>
              </ModifMenuItemWithHover>
            </Menu>
          </div>
        </>
      );
    }

    return (
      <>
        <BlackMediumTextWithMargin>
          {`${intl.formatMessage({ id: "rule" })} n°${ruleIdx + 1}`}
        </BlackMediumTextWithMargin>
        <>
          <Row
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
              width: "880px",
            }}
          >
            <div style={{ display: "flex" }}>
              <Col
                style={{
                  marginLeft: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {!rule.type ? (
                  <StyledDiv onClick={handleRuleClick}>
                    <GreySmallText style={{ fontFamily: "Roboto" }}>
                      <FormattedMessage id="Choose a condition type" />
                    </GreySmallText>
                    <KeyboardArrowDownIcon />
                  </StyledDiv>
                ) : (
                  <BlueBorder
                    onClick={handleRuleClick}
                    style={{ fontFamily: "Roboto" }}
                  >
                    <BlueSmallText>{rule.type}</BlueSmallText>
                    <KeyboardArrowDownIcon />
                  </BlueBorder>
                )}
              </Col>
              <>
                {rule.type === intl.formatMessage({ id: "Value between" }) && (
                  <>
                    <Row>
                      <Col
                        style={{
                          paddingLeft: "20px",
                          paddingBottom: "21px",
                        }}
                      >
                        <Row
                          style={{
                            paddingLeft: "20px",
                            paddingBottom: "5px",
                          }}
                        >
                          <GreyText style={{ fontFamily: "Roboto" }}>
                            <FormattedMessage id="units" />
                          </GreyText>
                        </Row>
                        <Row>
                          <StyledTextField
                            validity={
                              getUnityAndDecimalValidityForMetric(
                                rule.values[0],
                                chosenSetting,
                                rule.type,
                                "unity"
                              ).validity
                            }
                            onFocus={() => {
                              if (rule.values[0].unity === "00") {
                                handleChangeUnity1(
                                  ruleIdx,
                                  {
                                    target: { value: "" },
                                  },
                                  true
                                );
                              }
                            }}
                            placeholder="00"
                            style={{
                              marginLeft: "10px",
                              fontFamily: "Roboto",
                            }}
                            value={rule.values[0].unity}
                            onChange={(event) =>
                              handleChangeUnity1(ruleIdx, event, true)
                            }
                          />
                        </Row>
                      </Col>
                      <Col
                        style={{
                          marginLeft: "3px",
                          marginTop: "18px",
                        }}
                      >
                        <BlackBoldText style={{ fontFamily: "Roboto" }}>
                          ,
                        </BlackBoldText>
                      </Col>
                      <Col
                        style={{
                          paddingBottom: "21px",
                          paddingLeft: "10px",
                        }}
                      >
                        <Row
                          style={{
                            paddingBottom: "5px",
                            paddingLeft: "5px",
                          }}
                        >
                          <GreyText style={{ fontFamily: "Roboto" }}>
                            <FormattedMessage id="decimals" />
                          </GreyText>
                        </Row>
                        <Row>
                          <StyledTextField
                            style={{ fontFamily: "Roboto" }}
                            validity={
                              getUnityAndDecimalValidityForMetric(
                                rule.values[0],
                                chosenSetting,
                                rule.type,
                                "decimal"
                              ).validity
                            }
                            onFocus={() => {
                              if (rule.values[0].decimal === "00") {
                                handleChangeDecimal1(
                                  ruleIdx,
                                  {
                                    target: { value: "" },
                                  },
                                  true
                                );
                              }
                            }}
                            placeholder="00"
                            value={rule.values[0].decimal}
                            onChange={(event) =>
                              handleChangeDecimal1(ruleIdx, event, true)
                            }
                          />
                        </Row>
                      </Col>
                      <Col
                        style={{
                          marginLeft: "8px",
                          marginTop: "18px",
                        }}
                      >
                        <BlackSmallText style={{ fontFamily: "Roboto" }}>
                          <FormattedMessage id="and" />
                        </BlackSmallText>
                      </Col>
                      <Col
                        style={{
                          paddingBottom: "21px",
                          paddingLeft: "10px",
                        }}
                      >
                        <Row
                          style={{
                            paddingBottom: "5px",
                            paddingLeft: "15px",
                          }}
                        >
                          <GreyText style={{ fontFamily: "Roboto" }}>
                            <FormattedMessage id="units" />
                          </GreyText>
                        </Row>
                        <Row>
                          <StyledTextField
                            validity={
                              getUnityAndDecimalValidityForMetric(
                                rule.values[1],
                                chosenSetting,
                                rule.type,
                                "unity"
                              ).validity
                            }
                            onFocus={() => {
                              if (rule.values[1].unity === "00") {
                                handleChangeUnity2(
                                  ruleIdx,
                                  {
                                    target: { value: "" },
                                  },
                                  true
                                );
                              }
                            }}
                            placeholder="00"
                            style={{
                              marginLeft: "10px",
                              fontFamily: "Roboto",
                            }}
                            value={rule.values[1].unity}
                            onChange={(event) =>
                              handleChangeUnity2(ruleIdx, event, true)
                            }
                          />
                        </Row>
                      </Col>
                      <Col
                        style={{
                          marginLeft: "3px",
                          marginTop: "18px",
                          fontFamily: "Roboto",
                        }}
                      >
                        <BlackBoldText>,</BlackBoldText>
                      </Col>
                      <Col
                        style={{
                          paddingBottom: "21px",
                          paddingLeft: "10px",
                          fontFamily: "Roboto",
                        }}
                      >
                        <Row
                          style={{
                            paddingBottom: "5px",
                            paddingLeft: "5px",
                            fontFamily: "Roboto",
                          }}
                        >
                          <GreyText>
                            <FormattedMessage id="decimals" />
                          </GreyText>
                        </Row>
                        <Row>
                          <StyledTextField
                            validity={
                              getUnityAndDecimalValidityForMetric(
                                rule.values[1],
                                chosenSetting,
                                rule.type,
                                "decimal"
                              ).validity
                            }
                            onFocus={() => {
                              if (rule.values[1].decimal === "00") {
                                handleChangeDecimal2(
                                  ruleIdx,
                                  {
                                    target: { value: "" },
                                  },
                                  true
                                );
                              }
                            }}
                            placeholder="00"
                            value={rule.values[1].decimal}
                            onChange={(event) =>
                              handleChangeDecimal2(ruleIdx, event, true)
                            }
                          />
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
                {rule.type !== intl.formatMessage({ id: "Value between" }) && (
                  <>
                    <Row
                      style={{
                        paddingBottom: "21px",
                        paddingLeft: "20px",
                      }}
                    >
                      <Col style={{ marginLeft: "10px" }}>
                        <Row>
                          <GreyText
                            style={{
                              paddingLeft: "10px",
                              marginBottom: "5px",
                              fontFamily: "Roboto",
                            }}
                          >
                            <FormattedMessage id="units" />
                          </GreyText>
                        </Row>
                        <Row>
                          <StyledTextField
                            validity={
                              getUnityAndDecimalValidityForMetric(
                                rule.values[0],
                                chosenSetting,
                                rule.type,
                                "unity"
                              ).validity
                            }
                            onFocus={() => {
                              if (rule.values[0].unity === "00") {
                                handleChangeUnity1(
                                  ruleIdx,
                                  {
                                    target: { value: "" },
                                  },
                                  true
                                );
                              }
                            }}
                            placeholder="00"
                            value={rule.values[0].unity}
                            onChange={(event) =>
                              handleChangeUnity1(ruleIdx, event, true)
                            }
                          />
                        </Row>
                      </Col>
                      <Col
                        style={{
                          marginLeft: "5px",
                          marginRight: "5px",
                          marginTop: "18px",
                          fontFamily: "Roboto",
                        }}
                      >
                        <BlackBoldText>,</BlackBoldText>
                      </Col>
                      <Col style={{ marginLeft: "8px" }}>
                        <Row>
                          <GreyText
                            style={{
                              marginBottom: "5px",
                              fontFamily: "Roboto",
                            }}
                          >
                            <FormattedMessage id="decimals" />
                          </GreyText>
                        </Row>
                        <Row>
                          <StyledTextField
                            validity={
                              getUnityAndDecimalValidityForMetric(
                                rule.values[0],
                                chosenSetting,
                                rule.type,
                                "decimal"
                              ).validity
                            }
                            onFocus={() => {
                              if (rule.values[0].decimal === "00") {
                                handleChangeDecimal1(
                                  ruleIdx,
                                  {
                                    target: { value: "" },
                                  },
                                  true
                                );
                              }
                            }}
                            placeholder="00"
                            value={rule.values[0].decimal}
                            onChange={(event) =>
                              handleChangeDecimal1(ruleIdx, event, true)
                            }
                          />
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            </div>
            <ColWithProps
              between={
                rule.type !== intl.formatMessage({ id: "Value between" })
              }
            >
              <BlackText style={{ fontFamily: "Roboto" }}>
                {riverParamUnit}
              </BlackText>
            </ColWithProps>
            <Col
              style={{
                padding: 20,
                paddingTop: 25,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 10,
              }}
            >
              <StyledButton
                disabled={
                  !rule.type ||
                  !isRuleValid(rule.values, chosenAlertToModif.chosenSetting)
                    .isValid
                }
                style={{
                  fontSize: 12,
                  fontWeight: 700,
                  width: 70,
                  height: 30,
                  padding: 8,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Roboto",
                }}
                onClick={() => {
                  if (!rule.type) return;
                  updateChosenAlertToModify({
                    ...chosenAlertToModif,
                    rules: chosenAlertToModif.rules.map(
                      (oldRule, oldRuleIdx) => {
                        if (oldRuleIdx !== ruleIdx) return oldRule;
                        return {
                          ...oldRule,
                          validated: true,
                        };
                      }
                    ),
                  });
                }}
              >
                <FormattedMessage id="validate" />
              </StyledButton>

              <TrashIcon
                style={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  updateChosenAlertToModify({
                    ...chosenAlertToModif,
                    rules: chosenAlertToModif.rules.filter(
                      (oldRule, oldRuleIdx) => oldRuleIdx !== ruleIdx
                    ),
                  })
                }
              />
            </Col>
          </Row>
          <Menu
            anchorEl={anchorRuleEl}
            open={openRules}
            onClose={handleRuleClose}
          >
            <MenuItemWithHover
              onClick={() => {
                setAnchorRuleEl(null);
                updateChosenAlertsToModifyRule(ruleIdx, {
                  ...rule,
                  type: intl.formatMessage({
                    id: "Value greater than",
                  }),
                });
              }}
            >
              <ListItemText>
                {rule.type ===
                intl.formatMessage({
                  id: "Value greater than",
                }) ? (
                  <SmallBlueText style={{ fontFamily: "Roboto" }}>
                    <FormattedMessage id="Value greater than" />
                  </SmallBlueText>
                ) : (
                  <SmalBlackText style={{ fontFamily: "Roboto" }}>
                    <FormattedMessage id="Value greater than" />
                  </SmalBlackText>
                )}
              </ListItemText>
              {rule.type ===
                intl.formatMessage({
                  id: "Value greater than",
                }) && (
                <Typography variant="body2" color="text.secondary">
                  <StyledCheckButton className="text-primary" />
                </Typography>
              )}
            </MenuItemWithHover>

            <MenuItemWithHover
              onClick={() => {
                setAnchorRuleEl(null);
                updateChosenAlertsToModifyRule(ruleIdx, {
                  ...rule,
                  type: intl.formatMessage({ id: "Value lower than" }),
                });
              }}
            >
              <ListItemText>
                {rule.type ===
                intl.formatMessage({ id: "Value lower than" }) ? (
                  <SmallBlueText style={{ fontFamily: "Roboto" }}>
                    <FormattedMessage id="Value lower than" />
                  </SmallBlueText>
                ) : (
                  <SmalBlackText style={{ fontFamily: "Roboto" }}>
                    <FormattedMessage id="Value lower than" />
                  </SmalBlackText>
                )}
              </ListItemText>
              {rule.type ===
                intl.formatMessage({
                  id: "Value lower than",
                }) && (
                <Typography variant="body2" color="text.secondary">
                  <StyledCheckButton className="text-primary" />
                </Typography>
              )}
            </MenuItemWithHover>

            <MenuItemWithHover
              onClick={() => {
                setAnchorRuleEl(null);
                updateChosenAlertsToModifyRule(ruleIdx, {
                  ...rule,
                  type: intl.formatMessage({ id: "Value between" }),
                  values:
                    rule.values.length < 2
                      ? [rule.values[0], { unity: "00", decimal: "00" }]
                      : rule.values,
                });
              }}
            >
              <ListItemText>
                {rule.type === intl.formatMessage({ id: "Value between" }) ? (
                  <SmallBlueText style={{ fontFamily: "Roboto" }}>
                    <FormattedMessage id="Value between" />
                  </SmallBlueText>
                ) : (
                  <SmalBlackText style={{ fontFamily: "Roboto" }}>
                    <FormattedMessage id="Value between" />
                  </SmalBlackText>
                )}
              </ListItemText>
              {rule.type === intl.formatMessage({ id: "Value between" }) && (
                <Typography variant="body2" color="text.secondary">
                  <StyledCheckButton className="text-primary" />
                </Typography>
              )}
            </MenuItemWithHover>
          </Menu>
        </>
      </>
    );
  };

  const renderEditAlert = () => {
    const alertIndx = alerts
      .map((alert) => alert._id)
      .indexOf(chosenAlertToModif._id);

    const rulesWithValidity = chosenAlertToModif.rules.map((rule) =>
      isRuleValid(rule.values, chosenAlertToModif.chosenSetting)
    );
    const invalidRuleIndex = rulesWithValidity.findIndex(
      (rule) => !!rule.error
    );

    return (
      <Dialog
        maxWidth={"md"}
        fullWidth={true}
        open={chosenAlertToModif}
        onClose={() => {
          closeEditAlert();
        }}
      >
        <DialogTitle>
          <div className="flex items-center relative">
            <BlackTitle
              style={{
                fontFamily: "Satoshi",
                fontWeight: 700,
                fontSize: "20px",
              }}
            >
              {intl.formatMessage({ id: "edit" }) +
                " " +
                intl.formatMessage({ id: "Alert" }) +
                " " +
                (alertIndx + 1)}
            </BlackTitle>
            <button className="absolute right-0 top-1" onClick={closeEditAlert}>
              <CloseIcon />
            </button>
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent className="no-scrollbar">
          <OverFlowAutoDiv className="flex flex-col gap-2 py-3 no-scrollbar">
            <Row
              className="flex flex-col gap-10"
              style={{ marginLeft: "3px", fontFamily: "Roboto" }}
            >
              <Col>
                <BlackMediumText style={{ marginBottom: "15px" }}>
                  Station
                </BlackMediumText>
                <StyledDiv onClick={handleClick}>
                  <BlueSmallText>
                    {chosenAlertToModif.chosenStation[0]}
                  </BlueSmallText>
                  <KeyboardArrowDownIcon />
                </StyledDiv>
                <Menu
                  anchorEl={anchorEl}
                  open={openTeams}
                  onClose={handleClose}
                >
                  {riverWithWriterRole.map((river, index) => (
                    <MenuItemWithHover
                      onClick={() => {
                        setAnchorEl(null);
                        updateChosenAlertToModify({
                          ...chosenAlertToModif,
                          chosenStation: [river],
                        });
                      }}
                    >
                      <ListItemText>
                        {riverWithWriterRole[index] ===
                        chosenAlertToModif.chosenStation[0] ? (
                          <SmallBlueText style={{ fontFamily: "Roboto" }}>
                            {river}
                          </SmallBlueText>
                        ) : (
                          <SmalBlackText style={{ fontFamily: "Roboto" }}>
                            {river}
                          </SmalBlackText>
                        )}
                      </ListItemText>
                      {riverWithWriterRole[index] ===
                        chosenAlertToModif.chosenStation[0] && (
                        <Typography variant="body2" color="text.secondary">
                          <StyledCheckButton className="text-primary" />
                        </Typography>
                      )}
                    </MenuItemWithHover>
                  ))}
                </Menu>
              </Col>
              <Col>
                <BlackMediumText
                  style={{ marginBottom: "15px", fontFamily: "Roboto" }}
                >
                  <FormattedMessage id="setting" />
                </BlackMediumText>
                <StyledDiv onClick={handleVariableClick}>
                  <BlueSmallText style={{ fontFamily: "Roboto" }}>
                    {intl.formatMessage({
                      id: chosenAlertToModif.chosenSetting,
                    })}
                  </BlueSmallText>
                  <KeyboardArrowDownIcon />
                </StyledDiv>
                <StyledMenu
                  anchorEl={anchorVariableEl}
                  open={openVaraiables}
                  onClose={handleVariableClose}
                >
                  {riverParams &&
                    riverParams.map((metric, index) => (
                      <MenuItemWithHover
                        onClick={async () => {
                          const paramId = await getId(
                            chosenAlertToModif.chosenStation[0],
                            metric
                          );
                          setAnchorVariableEl(null);
                          updateChosenAlertToModify({
                            ...chosenAlertToModif,
                            chosenSetting: metric,
                            chosenId: paramId[0],
                          });
                        }}
                      >
                        <ListItemText>
                          {riverParams[index] ===
                          chosenAlertToModif.chosenSetting ? (
                            <SmallBlueText style={{ fontFamily: "Roboto" }}>
                              {intl.formatMessage({ id: metric })}
                            </SmallBlueText>
                          ) : (
                            <SmalBlackText style={{ fontFamily: "Roboto" }}>
                              {intl.formatMessage({ id: metric })}
                            </SmalBlackText>
                          )}
                        </ListItemText>
                        {riverParams[index] ===
                          chosenAlertToModif.chosenSetting && (
                          <Typography variant="body2" color="text.secondary">
                            <StyledCheckButton className="text-primary" />
                          </Typography>
                        )}
                      </MenuItemWithHover>
                    ))}
                </StyledMenu>
              </Col>
              <Col>
                <GreyText
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    marginTop: "-10%",
                  }}
                >
                  <FormattedMessage id="Trigger rules" />
                  {invalidRuleIndex !== -1 && (
                    <ErrorText
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <div style={{ fontWeight: 300 }}>{`${intl.formatMessage({
                        id: "rule",
                      })} n°${invalidRuleIndex + 1}:`}</div>
                      <div>
                        {intl.formatMessage({
                          id: `${rulesWithValidity[invalidRuleIndex].error}`,
                        })}
                      </div>
                    </ErrorText>
                  )}
                </GreyText>
              </Col>
              <Col>
                {chosenAlertToModif.rules.map((rule, ruleIdx) =>
                  renderRuleForAlertToModify(rule, ruleIdx)
                )}
                <WhiteButtonWithBlueText
                  style={{ width: "800px", fontFamily: "Roboto" }}
                  onClick={() =>
                    updateChosenAlertToModify({
                      ...chosenAlertToModif,
                      rules: [
                        ...chosenAlertToModif.rules,
                        {
                          validated: false,
                          values: [{ unity: "00", decimal: "00" }],
                          type: null,
                        },
                      ],
                    })
                  }
                >
                  <FormattedMessage id="add a rule" />
                </WhiteButtonWithBlueText>
              </Col>
              <Col style={{ marginTop: "-2%", fontFamily: "Roboto" }}>
                <GreyText>
                  <FormattedMessage id="NOTIFICATIONS" />
                </GreyText>
                <Row style={{ marginLeft: "2px" }}>
                  <Col style={{ marginTop: "15px" }}>
                    <SmalBlackText>
                      <FormattedMessage id="Message d’alerte dans Extralab" />
                    </SmalBlackText>
                    <SmallGrayText style={{ fontSize: "12px" }}>
                      <FormattedMessage id="An alert triggers a message in the News Feed for all collaborators. Each employee can individually silence an alert." />
                    </SmallGrayText>
                  </Col>
                </Row>
                <Row style={{ marginLeft: "2px" }}>
                  <Col style={{ marginTop: "15px" }}>
                    <SmalBlackText>
                      <FormattedMessage id="message in Extralab Whatsapp" />
                    </SmalBlackText>
                    <SmallGrayText style={{ fontSize: "12px" }}>
                      <FormattedMessage id="Alert message in the Riverlab Whatsapp group" />
                    </SmallGrayText>
                  </Col>
                  <Col style={{ marginTop: "5px" }}>
                    <ToggleSwitch
                      small
                      id={(Math.random() + 1).toString(36).substring(7)}
                      checked={chosenAlertToModif.withWhatsApp}
                      onChange={() => {
                        updateChosenAlertToModify({
                          ...chosenAlertToModif,
                          withWhatsApp: !chosenAlertToModif.withWhatsApp,
                        });
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginLeft: "2px" }}>
                  <Col style={{ marginTop: "15px" }}>
                    <SmalBlackText>E-mail</SmalBlackText>
                    <SmallGrayText style={{ fontSize: "12px" }}>
                      <FormattedMessage id="Send emails to selected recipients" />
                    </SmallGrayText>
                  </Col>
                  <Col style={{ marginTop: "15px", marginLeft: "18px" }}>
                    <ToggleSwitch
                      small
                      id={(Math.random() + 1).toString(36).substring(7)}
                      checked={chosenAlertToModif.withEmail}
                      onChange={() => {
                        updateChosenAlertToModify({
                          ...chosenAlertToModif,
                          withEmail: !chosenAlertToModif.withEmail,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            {chosenAlertToModif.withEmail &&
              renderAddRecipientsOnModif(chosenAlertToModif)}
          </OverFlowAutoDiv>
        </DialogContent>
        <DialogActions style={{ padding: 20 }}>
          <ButtonWithBlueTextWhiteBackground
            style={{
              fontFamily: "Roboto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={closeEditAlert}
          >
            <FormattedMessage id="usermanagement.param17" />
          </ButtonWithBlueTextWhiteBackground>
          {isChosenAlertToModifModified && (
            <StyledButton
              style={{
                fontFamily: "Roboto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              disabled={
                !chosenAlertToModif.rules.every((rule) => rule.validated)
              }
              onClick={() => {
                updateAlertApi(chosenAlertToModif, chosenAlertToModif._id);
                handleGetAlerts();
                closeEditAlert();
              }}
            >
              <FormattedMessage id="settings.param4" />
            </StyledButton>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  const renderRule = (rule, ruleIdx) => {
    if (rule.validated) {
      return (
        <>
          <BlackMediumTextWithMargin style={{ fontFamily: "Roboto" }}>
            {`${intl.formatMessage({ id: "rule" })} n°${ruleIdx + 1}`}
          </BlackMediumTextWithMargin>
          <div
            style={{
              border: "1px solid #EBECF3",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "48px",
            }}
          >
            <div
              style={{
                padding: 20,
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
                color: "#343A40",
                fontWeight: 300,
                gap: 10,
                fontFamily: "Roboto",
              }}
            >
              {`${rule.type
                .replace("Valeur", intl.formatMessage({ id: chosenSetting }))
                .replace("...", "")}`}
              <BlueNormalTextWithPadding style={{ fontFamily: "Roboto" }}>
                {rule.values.length === 1
                  ? rule.values[0].unity + "," + rule.values[0].decimal
                  : rule.values[0].unity + "," + rule.values[0].decimal}
              </BlueNormalTextWithPadding>
              {rule.values.length > 1 && (
                <>
                  <BlackText style={{ fontFamily: "Roboto" }}>
                    {" et "}
                  </BlackText>
                  <BlueNormalTextWithPadding style={{ fontFamily: "Roboto" }}>
                    {rule.values[1].unity + "," + rule.values[1].decimal}
                  </BlueNormalTextWithPadding>
                </>
              )}

              <BlackText style={{ fontFamily: "Roboto" }}>{unit}</BlackText>
            </div>
            <DivWithPadding>
              <MoreIcon
                style={{ cursor: "pointer" }}
                onClick={(event) => handleModifRuleClick(event, ruleIdx)}
              />
            </DivWithPadding>
          </div>

          <Menu
            anchorEl={anchorModifRuleEl}
            open={openModifRules}
            onClose={handleModifRuleClose}
            style={{ marginLeft: "-10%" }}
          >
            <ModifMenuItemWithHover
              onClick={() => {
                updateRuleInCreateView(ruleToModifyIdx, {
                  ...rule,
                  validated: false,
                });
                handleModifRuleClose();
              }}
            >
              <ListItemText>
                <SmalBlackText style={{ fontFamily: "Roboto" }}>
                  <FormattedMessage id="edit" />
                </SmalBlackText>
              </ListItemText>
            </ModifMenuItemWithHover>
            <ModifMenuItemWithHover
              onClick={() => {
                setRules((oldRules) =>
                  oldRules.filter(
                    (oldRule, oldRuleIdx) => oldRuleIdx !== ruleToModifyIdx
                  )
                );
                handleModifRuleClose();
              }}
            >
              <ListItemText>
                <ErrorText style={{ fontFamily: "Roboto" }}>
                  <FormattedMessage id="delete" />
                </ErrorText>
              </ListItemText>
            </ModifMenuItemWithHover>
          </Menu>
        </>
      );
    }

    return (
      <>
        <BlackMediumTextWithMargin style={{ fontFamily: "Roboto" }}>
          {`${intl.formatMessage({ id: "rule" })} n°${ruleIdx + 1}`}
        </BlackMediumTextWithMargin>
        <>
          <Row
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
              fontFamily: "Roboto",
            }}
          >
            <div style={{ display: "flex" }}>
              <Col
                style={{
                  marginLeft: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {!rule.type ? (
                  <StyledDiv onClick={handleRuleClick}>
                    <GreySmallText>
                      <FormattedMessage id="Choose a condition type" />
                    </GreySmallText>
                    <KeyboardArrowDownIcon />
                  </StyledDiv>
                ) : (
                  <BlueBorder onClick={handleRuleClick}>
                    <BlueSmallText>{rule.type}</BlueSmallText>
                    <KeyboardArrowDownIcon />
                  </BlueBorder>
                )}
              </Col>
              {rule.type === "" ? (
                <Col style={{ marginLeft: "5px" }}>
                  <Row>
                    <Col>
                      <GreyText
                        style={{
                          paddingLeft: "20px",
                          marginBottom: "5px",
                        }}
                      >
                        <FormattedMessage id="units" />
                      </GreyText>
                    </Col>
                    <Col>
                      <GreyText
                        style={{
                          paddingLeft: "40px",
                          marginBottom: "5px",
                        }}
                      >
                        <FormattedMessage id="decimals" />
                      </GreyText>
                    </Col>
                  </Row>
                  <Row style={{ paddingBottom: "21px" }}>
                    <Col>
                      <GrayInsideDiv>
                        <DarkGreyText>00</DarkGreyText>
                      </GrayInsideDiv>
                    </Col>
                    <BlackBoldText>,</BlackBoldText>
                    <Col>
                      <GrayInsideDiv>
                        <DarkGreyText>00</DarkGreyText>
                      </GrayInsideDiv>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <>
                  {rule.type ===
                    intl.formatMessage({ id: "Value between" }) && (
                    <>
                      <Row>
                        <Col
                          style={{
                            paddingLeft: "20px",
                            paddingBottom: "21px",
                          }}
                        >
                          <Row
                            style={{
                              paddingLeft: "20px",
                              paddingBottom: "5px",
                            }}
                          >
                            <GreyText>
                              <FormattedMessage id="units" />
                            </GreyText>
                          </Row>
                          <Row>
                            <StyledTextField
                              validity={
                                getUnityAndDecimalValidityForMetric(
                                  rule.values[0],
                                  chosenSetting,
                                  rule.type,
                                  "unity"
                                ).validity
                              }
                              onFocus={() => {
                                if (rule.values[0].unity === "00") {
                                  handleChangeUnity1(ruleIdx, {
                                    target: { value: "" },
                                  });
                                }
                              }}
                              placeholder="00"
                              style={{ marginLeft: "10px" }}
                              value={rule.values[0].unity}
                              onChange={(event) =>
                                handleChangeUnity1(ruleIdx, event)
                              }
                            />
                          </Row>
                        </Col>
                        <Col
                          style={{
                            marginLeft: "3px",
                            marginTop: "18px",
                          }}
                        >
                          <BlackBoldText>,</BlackBoldText>
                        </Col>
                        <Col
                          style={{
                            paddingBottom: "21px",
                            paddingLeft: "10px",
                          }}
                        >
                          <Row
                            style={{
                              paddingBottom: "5px",
                              paddingLeft: "5px",
                            }}
                          >
                            <GreyText>
                              <FormattedMessage id="decimals" />
                            </GreyText>
                          </Row>
                          <Row>
                            <StyledTextField
                              validity={
                                getUnityAndDecimalValidityForMetric(
                                  rule.values[0],
                                  chosenSetting,
                                  rule.type,
                                  "decimal"
                                ).validity
                              }
                              onFocus={() => {
                                if (rule.values[0].decimal === "00") {
                                  handleChangeDecimal1(ruleIdx, {
                                    target: { value: "" },
                                  });
                                }
                              }}
                              placeholder="00"
                              value={rule.values[0].decimal}
                              onChange={(event) =>
                                handleChangeDecimal1(ruleIdx, event)
                              }
                            />
                          </Row>
                        </Col>
                        <Col
                          style={{
                            marginLeft: "8px",
                            marginTop: "18px",
                          }}
                        >
                          <BlackSmallText>
                            <FormattedMessage id="and" />
                          </BlackSmallText>
                        </Col>
                        <Col
                          style={{
                            paddingBottom: "21px",
                            paddingLeft: "10px",
                          }}
                        >
                          <Row
                            style={{
                              paddingBottom: "5px",
                              paddingLeft: "15px",
                            }}
                          >
                            <GreyText>
                              <FormattedMessage id="units" />
                            </GreyText>
                          </Row>
                          <Row>
                            <StyledTextField
                              validity={
                                getUnityAndDecimalValidityForMetric(
                                  rule.values[1],
                                  chosenSetting,
                                  rule.type,
                                  "unity"
                                ).validity
                              }
                              onFocus={() => {
                                if (rule.values[1].unity === "00") {
                                  handleChangeUnity2(ruleIdx, {
                                    target: { value: "" },
                                  });
                                }
                              }}
                              placeholder="00"
                              style={{ marginLeft: "10px" }}
                              value={rule.values[1].unity}
                              onChange={(event) =>
                                handleChangeUnity2(ruleIdx, event)
                              }
                            />
                          </Row>
                        </Col>
                        <Col
                          style={{
                            marginLeft: "3px",
                            marginTop: "18px",
                          }}
                        >
                          <BlackBoldText>,</BlackBoldText>
                        </Col>
                        <Col
                          style={{
                            paddingBottom: "21px",
                            paddingLeft: "10px",
                          }}
                        >
                          <Row
                            style={{
                              paddingBottom: "5px",
                              paddingLeft: "5px",
                            }}
                          >
                            <GreyText>
                              <FormattedMessage id="decimals" />
                            </GreyText>
                          </Row>
                          <Row>
                            <StyledTextField
                              validity={
                                getUnityAndDecimalValidityForMetric(
                                  rule.values[1],
                                  chosenSetting,
                                  rule.type,
                                  "decimal"
                                ).validity
                              }
                              onFocus={() => {
                                if (rule.values[1].decimal === "00") {
                                  handleChangeDecimal2(ruleIdx, {
                                    target: { value: "" },
                                  });
                                }
                              }}
                              placeholder="00"
                              value={rule.values[1].decimal}
                              onChange={(event) =>
                                handleChangeDecimal2(ruleIdx, event)
                              }
                            />
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}
                  {rule.type !== "" &&
                    rule.type !== intl.formatMessage({ id: "Value between" }) &&
                    chosenSetting !== "" && (
                      <>
                        <Row
                          style={{
                            paddingBottom: "21px",
                            paddingLeft: "20px",
                          }}
                        >
                          <Col style={{ marginLeft: "10px" }}>
                            <Row>
                              <GreyText
                                style={{
                                  paddingLeft: "10px",
                                  marginBottom: "5px",
                                }}
                              >
                                <FormattedMessage id="units" />
                              </GreyText>
                            </Row>
                            <Row>
                              <StyledTextField
                                validity={
                                  getUnityAndDecimalValidityForMetric(
                                    rule.values[0],
                                    chosenSetting,
                                    rule.type,
                                    "unity"
                                  ).validity
                                }
                                onFocus={() => {
                                  if (rule.values[0].unity === "00") {
                                    handleChangeUnity1(ruleIdx, {
                                      target: { value: "" },
                                    });
                                  }
                                }}
                                placeholder="00"
                                value={rule.values[0].unity}
                                onChange={(event) =>
                                  handleChangeUnity1(ruleIdx, event)
                                }
                              />
                            </Row>
                          </Col>
                          <Col
                            style={{
                              marginLeft: "5px",
                              marginRight: "5px",
                              marginTop: "18px",
                            }}
                          >
                            <BlackBoldText>,</BlackBoldText>
                          </Col>
                          <Col style={{ marginLeft: "8px" }}>
                            <Row>
                              <GreyText
                                style={{
                                  marginBottom: "5px",
                                }}
                              >
                                <FormattedMessage id="decimals" />
                              </GreyText>
                            </Row>
                            <Row>
                              <StyledTextField
                                validity={
                                  getUnityAndDecimalValidityForMetric(
                                    rule.values[0],
                                    chosenSetting,
                                    rule.type,
                                    "decimal"
                                  ).validity
                                }
                                onFocus={() => {
                                  if (rule.values[0].decimal === "00") {
                                    handleChangeDecimal1(ruleIdx, {
                                      target: { value: "" },
                                    });
                                  }
                                }}
                                placeholder="00"
                                value={rule.values[0].decimal}
                                onChange={(event) =>
                                  handleChangeDecimal1(ruleIdx, event)
                                }
                              />
                            </Row>
                          </Col>
                        </Row>
                      </>
                    )}
                </>
              )}
            </div>
            <ColWithProps
              between={
                rule.type !== intl.formatMessage({ id: "Value between" })
              }
            >
              <BlackText>{riverParamUnit}</BlackText>
            </ColWithProps>
            <Col
              style={{
                padding: 20,
                paddingTop: 25,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 10,
              }}
            >
              <StyledButton
                disabled={
                  !rule.type || !isRuleValid(rule.values, chosenSetting).isValid
                }
                style={{
                  fontSize: 12,
                  fontWeight: 700,
                  width: 70,
                  height: 30,
                  padding: 8,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Roboto",
                }}
                onClick={() => {
                  if (!rule.type) return;
                  updateRuleInCreateView(ruleIdx, { ...rule, validated: true });
                }}
              >
                <FormattedMessage id="validate" />
              </StyledButton>

              <TrashIcon
                style={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  setRules((oldRules) =>
                    oldRules.filter(
                      (oldRule, oldRuleIdx) => oldRuleIdx !== ruleIdx
                    )
                  )
                }
              />
            </Col>
          </Row>
          <Menu
            anchorEl={anchorRuleEl}
            open={openRules}
            onClose={handleRuleClose}
            style={{ fontFamily: "Roboto" }}
          >
            <MenuItemWithHover
              style={{ fontFamily: "Roboto" }}
              onClick={() => {
                setAnchorRuleEl(null);
                updateRuleInCreateView(ruleIdx, {
                  ...rule,
                  type: intl.formatMessage({
                    id: "Value greater than",
                  }),
                });
              }}
            >
              <ListItemText>
                {rule.type ===
                intl.formatMessage({
                  id: "Value greater than",
                }) ? (
                  <SmallBlueText style={{ fontFamily: "Roboto" }}>
                    <FormattedMessage id="Value greater than" />
                  </SmallBlueText>
                ) : (
                  <SmalBlackText style={{ fontFamily: "Roboto" }}>
                    <FormattedMessage id="Value greater than" />
                  </SmalBlackText>
                )}
              </ListItemText>
              {rule.type ===
                intl.formatMessage({
                  id: "Value greater than",
                }) && (
                <Typography variant="body2" color="text.secondary">
                  <StyledCheckButton className="text-primary" />
                </Typography>
              )}
            </MenuItemWithHover>

            <MenuItemWithHover
              style={{ fontFamily: "Roboto" }}
              onClick={() => {
                setAnchorRuleEl(null);
                updateRuleInCreateView(ruleIdx, {
                  ...rule,
                  type: intl.formatMessage({ id: "Value lower than" }),
                });
              }}
            >
              <ListItemText style={{ fontFamily: "Roboto" }}>
                {rule.type ===
                intl.formatMessage({ id: "Value lower than" }) ? (
                  <SmallBlueText>
                    <FormattedMessage id="Value lower than" />
                  </SmallBlueText>
                ) : (
                  <SmalBlackText style={{ fontFamily: "Roboto" }}>
                    <FormattedMessage id="Value lower than" />
                  </SmalBlackText>
                )}
              </ListItemText>
              {rule.type ===
                intl.formatMessage({
                  id: "Value lower than",
                }) && (
                <Typography variant="body2" color="text.secondary">
                  <StyledCheckButton className="text-primary" />
                </Typography>
              )}
            </MenuItemWithHover>

            <MenuItemWithHover
              style={{ fontFamily: "Roboto" }}
              onClick={() => {
                setAnchorRuleEl(null);
                updateRuleInCreateView(ruleIdx, {
                  ...rule,
                  type: intl.formatMessage({ id: "Value between" }),
                  values:
                    rule.values.length < 2
                      ? [rule.values[0], { unity: "00", decimal: "00" }]
                      : rule.values,
                });
              }}
            >
              <ListItemText style={{ fontFamily: "Roboto" }}>
                {rule.type === intl.formatMessage({ id: "Value between" }) ? (
                  <SmallBlueText>
                    <FormattedMessage id="Value between" />
                  </SmallBlueText>
                ) : (
                  <SmalBlackText style={{ fontFamily: "Roboto" }}>
                    <FormattedMessage id="Value between" />
                  </SmalBlackText>
                )}
              </ListItemText>
              {rule.type === intl.formatMessage({ id: "Value between" }) && (
                <Typography variant="body2" color="text.secondary">
                  <StyledCheckButton className="text-primary" />
                </Typography>
              )}
            </MenuItemWithHover>
          </Menu>
        </>
      </>
    );
  };

  const renderAddRules = () => {
    const rulesWithValidity = rules.map((rule) =>
      isRuleValid(rule.values, chosenSetting)
    );
    const invalidRuleIndex = rulesWithValidity.findIndex(
      (rule) => !!rule.error
    );

    return (
      <Col>
        <GreyText
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            fontFamily: "Roboto",
          }}
        >
          <FormattedMessage id="Trigger rules" />
          {invalidRuleIndex !== -1 && (
            <ErrorText
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                fontFamily: "Roboto",
              }}
            >
              <div
                style={{ fontWeight: 300, fontFamily: "Roboto" }}
              >{`${intl.formatMessage({
                id: "rule",
              })} n°${invalidRuleIndex + 1}:`}</div>
              <div>
                {intl.formatMessage({
                  id: `${rulesWithValidity[invalidRuleIndex].error}`,
                })}
              </div>
            </ErrorText>
          )}
        </GreyText>
        {rules.map((rule, ruleIdx) => renderRule(rule, ruleIdx))}
        <WhiteButtonWithBlueText
          style={{
            fontFamily: "Roboto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() =>
            setRules((oldRules) => [
              ...oldRules,
              {
                validated: false,
                values: [{ unity: "00", decimal: "00" }],
                type: null,
              },
            ])
          }
        >
          <FormattedMessage id="add a rule" />
        </WhiteButtonWithBlueText>
      </Col>
    );
  };

  const renderNotificationSwitchs = () => (
    <Col style={{ fontFamily: "Roboto" }}>
      <GreyText Style={{ marginLeft: "2px" }}>
        <FormattedMessage id="NOTIFICATIONS" />
      </GreyText>
      <Row style={{ marginLeft: "1px" }}>
        <Col style={{ marginTop: "15px" }}>
          <SmalBlackText>
            <FormattedMessage id="Message d’alerte dans Extralab" />
          </SmalBlackText>
          <SmallGrayText style={{ fontSize: "12px" }}>
            <FormattedMessage id="An alert triggers a message in the News Feed for all collaborators. Each employee can individually silence an alert." />
          </SmallGrayText>
        </Col>
      </Row>
      <Row style={{ marginLeft: "1px" }}>
        <Col style={{ marginTop: "15px" }}>
          <SmalBlackText>
            <FormattedMessage id="message in Extralab Whatsapp" />
          </SmalBlackText>
          <SmallGrayText style={{ fontSize: "12px" }}>
            <FormattedMessage id="Alert message in the Riverlab Whatsapp group" />
          </SmallGrayText>
        </Col>
        <Col style={{ marginTop: "5px" }}>
          <ToggleSwitch
            small
            id={(Math.random() + 1).toString(36).substring(7)}
            checked={withWhatsApp}
            value={withWhatsApp}
            onChange={() => setWithWhatsApp((notif) => !notif)}
          />
        </Col>
      </Row>
      <Row style={{ marginLeft: "1px" }}>
        <Col style={{ marginTop: "15px" }}>
          <SmalBlackText>E-mail</SmalBlackText>
          <SmallGrayText style={{ fontSize: "12px" }}>
            <FormattedMessage id="Send emails to selected recipients" />
          </SmallGrayText>
        </Col>
        <Col style={{ marginTop: "15px", marginLeft: "18px" }}>
          <ToggleSwitch
            small
            checked={withEmail}
            id={(Math.random() + 1).toString(36).substring(7)}
            value={withEmail}
            onChange={() => setWithEmail((notif) => !notif)}
          />
        </Col>
      </Row>
    </Col>
  );

  const renderAddRecipientsOnModif = (alert) => {
    return (
      <div
        style={{
          marginTop: "3%",
          padding: "0 15px",
          fontFamily: "Roboto",
        }}
      >
        <Col
          style={{
            marginTop: "-15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Row>
              <Col>
                <DivWithProps
                  style={{ width: 400 }}
                  empty={true}
                  onClick={(event) => {
                    if (!anchorElRecipient) {
                      setAnchorElRecipient(event.currentTarget);
                    }
                  }}
                >
                  <>
                    <GreySmallText>
                      <FormattedMessage id="select recipients" />
                    </GreySmallText>
                    <KeyboardArrowDownIcon />
                  </>
                </DivWithProps>
                {!!anchorElRecipient && (
                  <div
                    style={{
                      width: 400,
                      height: 170,
                    }}
                  />
                )}
              </Col>
            </Row>
            <Menu
              maxHeight={"160px"}
              anchorEl={anchorElRecipient}
              open={!!anchorElRecipient}
              onClose={handleCloseRecipient}
            >
              <div style={{ maxHeight: 160, overflow: "auto" }}>
                {users.map((user, index) => {
                  return (
                    <MenuItemWithHover
                      style={{ width: 400, height: 50 }}
                      onClick={() => {
                        if (
                          alert.chosenRecipients &&
                          alert.chosenRecipients.find(
                            (chosenRecipient) =>
                              chosenRecipient.email === user.email
                          )
                        ) {
                          updateChosenAlertToModify({
                            ...chosenAlertToModif,
                            chosenRecipients: alert.chosenRecipients.filter(
                              (chosenRecipient) =>
                                chosenRecipient.email !== user.email
                            ),
                          });
                        } else {
                          updateChosenAlertToModify({
                            ...chosenAlertToModif,
                            chosenRecipients: [
                              ...alert.chosenRecipients,
                              { ...user, muted: false },
                            ],
                          });
                        }
                      }}
                    >
                      <ListItemText>
                        <Row>
                          <Col>{createPicture(user.firstName, 20, 20)}</Col>
                          <Col style={{ marginLeft: "25px" }}>
                            <Row>
                              {!!chosenAlertToModif.chosenRecipients.find(
                                (chosenRecipient) =>
                                  chosenRecipient.email === user.email
                              ) ? (
                                <SmallBlueText style={{ fontFamily: "Roboto" }}>
                                  {capitalizeFirstLetter(user.firstName) +
                                    " " +
                                    capitalizeFirstLetter(user.lastName)}
                                </SmallBlueText>
                              ) : (
                                <SmalBlackText style={{ fontFamily: "Roboto" }}>
                                  {capitalizeFirstLetter(user.firstName) +
                                    " " +
                                    capitalizeFirstLetter(user.lastName)}
                                </SmalBlackText>
                              )}
                            </Row>
                            <Row>
                              <SmallGrayText style={{ fontFamily: "Roboto" }}>
                                {user.email}
                              </SmallGrayText>
                            </Row>
                          </Col>
                        </Row>
                      </ListItemText>
                      {alert.chosenRecipients.map(
                        (chosenRecipient) =>
                          chosenRecipient.email === user.email && (
                            <Typography variant="body2" color="text.secondary">
                              <StyledCheckButton className="text-primary" />
                            </Typography>
                          )
                      )}
                    </MenuItemWithHover>
                  );
                })}
              </div>
              <div>
                <MenuItemWithHover
                  style={{ width: 400 }}
                  onClick={() => {
                    updateChosenAlertToModify({
                      ...chosenAlertToModif,
                      chosenRecipients:
                        alert.chosenRecipients.length !== users.length
                          ? users
                          : [],
                    });
                  }}
                >
                  <SmallBlueText style={{ fontFamily: "Roboto" }}>
                    <FormattedMessage id="select all collaborators" />
                  </SmallBlueText>
                </MenuItemWithHover>
              </div>
            </Menu>

            {!!anchorElRecipient && (
              <>
                <div
                  style={{
                    width: 400,
                    height: 50,
                  }}
                />
                <span ref={addRecipientRef} />
              </>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
            }}
          >
            {alert.chosenRecipients.map((chosenRecipient) => (
              <div
                className="flex justify-between items-center p-4 hover:border-primary"
                style={{
                  height: "20px",
                  width: 400,
                  marginLeft: "15%",
                  borderTop: "1px solid #EBECF3",
                  borderBottom: "1px solid #EBECF3",
                  marginBottom: "-5px",
                }}
              >
                <Row className="flex justify-between items-center">
                  <StyledCol
                    className="mr-2"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "350px",
                    }}
                  >
                    <div
                      style={{ display: "flex", gap: 20, alignItems: "center" }}
                    >
                      {createPicture(chosenRecipient.firstName, 25, 25)}
                      <SmalBlackText style={{ fontSize: 13, fontWeight: 300 }}>
                        {capitalizeFirstLetter(chosenRecipient.firstName) +
                          " " +
                          capitalizeFirstLetter(chosenRecipient.lastName)}
                      </SmalBlackText>
                    </div>
                  </StyledCol>
                  <button>
                    <GrayClose
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        updateChosenAlertToModify({
                          ...chosenAlertToModif,
                          chosenRecipients: alert.chosenRecipients.filter(
                            (oldChosenRecipent) =>
                              oldChosenRecipent.email !== chosenRecipient.email
                          ),
                        });
                      }}
                    />
                  </button>
                </Row>
              </div>
            ))}
          </div>
        </Col>
      </div>
    );
  };

  const renderAddRecipients = () => {
    return (
      <div style={{ paddingTop: 20, fontFamily: "Roboto" }}>
        <Col
          style={{
            marginTop: "-15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Row>
              <Col style={{ marginLeft: "10px" }}>
                <DivWithProps
                  style={{ width: 400 }}
                  empty={true}
                  onClick={(event) => {
                    if (!anchorElRecipient) {
                      setAnchorElRecipient(event.currentTarget);
                    }
                  }}
                >
                  <>
                    <GreySmallText>
                      <FormattedMessage id="select recipients" />
                    </GreySmallText>
                    <KeyboardArrowDownIcon />
                  </>
                </DivWithProps>
                {!!anchorElRecipient && (
                  <div
                    style={{
                      width: 400,
                      height: 170,
                    }}
                  />
                )}
              </Col>
            </Row>
            <Menu
              maxHeight={"160px"}
              anchorEl={anchorElRecipient}
              open={!!anchorElRecipient}
              onClose={handleCloseRecipient}
              style={{ fontFamily: "Roboto" }}
            >
              <div style={{ maxHeight: 160, overflow: "auto" }}>
                {users.map((user, index) => {
                  return (
                    <MenuItemWithHover
                      style={{ width: 400, height: 50 }}
                      onClick={() => {
                        if (
                          chosenRecipients &&
                          chosenRecipients.find(
                            (chosenRecipient) =>
                              chosenRecipient.email === user.email
                          )
                        ) {
                          setChosenRecipients((prev) =>
                            prev.filter(
                              (chosenRecipient) =>
                                chosenRecipient.email !== user.email
                            )
                          );
                        } else {
                          setChosenRecipients([...chosenRecipients, user]);
                        }
                      }}
                    >
                      <ListItemText>
                        <Row>
                          <Col>{createPicture(user.firstName, 20, 20)}</Col>
                          <Col style={{ marginLeft: "25px" }}>
                            <Row>
                              {!!chosenRecipients.find(
                                (chosenRecipient) =>
                                  chosenRecipient.email === user.email
                              ) ? (
                                <SmallBlueText style={{ fontFamily: "Roboto" }}>
                                  {capitalizeFirstLetter(user.firstName) +
                                    " " +
                                    capitalizeFirstLetter(user.lastName)}
                                </SmallBlueText>
                              ) : (
                                <SmalBlackText style={{ fontFamily: "Roboto" }}>
                                  {capitalizeFirstLetter(user.firstName) +
                                    " " +
                                    capitalizeFirstLetter(user.lastName)}
                                </SmalBlackText>
                              )}
                            </Row>
                            <Row>
                              <SmallGrayText style={{ fontFamily: "Roboto" }}>
                                {user.email}
                              </SmallGrayText>
                            </Row>
                          </Col>
                        </Row>
                      </ListItemText>
                      {chosenRecipients.map(
                        (chosenRecipient) =>
                          users[index] === chosenRecipient && (
                            <Typography variant="body2" color="text.secondary">
                              <StyledCheckButton className="text-primary" />
                            </Typography>
                          )
                      )}
                    </MenuItemWithHover>
                  );
                })}
              </div>
              <div>
                <MenuItemWithHover
                  style={{ width: 400 }}
                  onClick={() => {
                    chosenRecipients.length !== users.length
                      ? setChosenRecipients(users)
                      : setChosenRecipients([]);
                  }}
                >
                  <SmallBlueText style={{ fontFamily: "Roboto" }}>
                    <FormattedMessage id="select all collaborators" />
                  </SmallBlueText>
                </MenuItemWithHover>
              </div>
            </Menu>

            {!!anchorElRecipient && (
              <>
                <div
                  style={{
                    width: 400,
                    height: 50,
                  }}
                />
                <span ref={addRecipientRef} />
              </>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
            }}
          >
            {chosenRecipients.map((chosenRecipient) => (
              <div
                className="flex justify-between items-center p-4 hover:border-primary"
                style={{
                  height: "20px",
                  width: 400,
                  borderTop: "1px solid #EBECF3",
                  borderBottom: "1px solid #EBECF3",
                  marginBottom: "-5px",
                }}
              >
                <Row className="flex justify-between items-center">
                  <StyledCol
                    className="mr-2"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "350px",
                    }}
                  >
                    <div
                      style={{ display: "flex", gap: 20, alignItems: "center" }}
                    >
                      {createPicture(chosenRecipient.firstName, 25, 25)}
                      <SmalBlackText style={{ fontSize: 13, fontWeight: 300 }}>
                        {capitalizeFirstLetter(chosenRecipient.firstName) +
                          " " +
                          capitalizeFirstLetter(chosenRecipient.lastName)}
                      </SmalBlackText>
                    </div>
                  </StyledCol>
                  <button>
                    <GrayClose
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setChosenRecipients((oldChosenRecipents) =>
                          oldChosenRecipents.filter(
                            (oldChosenRecipent) =>
                              oldChosenRecipent.email !== chosenRecipient.email
                          )
                        )
                      }
                    />
                  </button>
                </Row>
              </div>
            ))}
          </div>
        </Col>
      </div>
    );
  };

  const renderModalContent = () => {
    if (!chosenSetting || !chosenStation) {
      return (
        <OverFlowAutoDiv className="flex flex-col gap-2 py-3 ml-4 no-scrollbar">
          <Row className="flex flex-col gap-10" style={{ marginLeft: "5px" }}>
            {renderStationAndSettingsInputs()}
          </Row>
        </OverFlowAutoDiv>
      );
    }

    return (
      <OverFlowAutoDiv className="flex flex-col gap-2 py-3 ml-4 no-scrollbar">
        <Row className="flex flex-col gap-10" style={{ marginLeft: "5px" }}>
          {renderStationAndSettingsInputs()}
          <Divider />
          {renderAddRules()}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              paddingRight: 20,
            }}
          >
            {rules.length !== 0 && renderNotificationSwitchs()}
            {withEmail && renderAddRecipients()}
          </div>
        </Row>
      </OverFlowAutoDiv>
    );
  };

  const renderCreateAlertsButtonClicked = () => {
    return (
      <Dialog
        maxWidth={"md"}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              height: "100%",
              maxHeight: "532px",
            },
          },
        }}
        fullWidth={true}
        open={open}
        onClose={handleCloseClicked}
      >
        <DialogTitle>
          <div className="flex items-center relative">
            <BlackNormalText style={{ fontFamily: "Satoshi", fontWeight: 700 }}>
              <FormattedMessage id="new alert" />
            </BlackNormalText>
            <button
              className="absolute right-0 top-1"
              onClick={() => {
                chosenStation !== "" || chosenSetting !== ""
                  ? handleClickCancelOpen()
                  : setOpen(false);
              }}
            >
              <CloseIcon />
            </button>
          </div>
        </DialogTitle>
        <Divider />
        {renderModalContent()}
        <DialogActions>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 10,
            }}
          >
            <ButtonWithBlueTextWhiteBackground
              style={{
                fontFamily: "Roboto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: `${
                  (!chosenSetting || !chosenStation || rules.length === 0) &&
                  "relative"
                }`,
                top: `${
                  !chosenSetting || !chosenStation
                    ? "300px"
                    : rules.length === 0
                    ? "150px"
                    : "0px"
                }`,
              }}
              onClick={() => {
                chosenStation !== "" || chosenSetting !== ""
                  ? handleClickCancelOpen()
                  : setOpen(false);
              }}
            >
              <FormattedMessage id="usermanagement.param17" />
            </ButtonWithBlueTextWhiteBackground>
            {rules.length > 0 &&
              rules.every((rule) => rule.validated) &&
              chosenSetting &&
              chosenStation && (
                <StyledButton
                  style={{
                    fontFamily: "Roboto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    setOpenCreate(true);
                    setOpen(false);
                    handleCreateAlert();
                  }}
                >
                  <FormattedMessage id="create the alert" />
                </StyledButton>
              )}

            <Dialog
              maxWidth={"xs"}
              fullWidth={true}
              open={openCancel}
              onClose={handleClickCancelOpen}
            >
              <DialogTitle>
                <div className="flex items-center relative">
                  <BlackNormalText style={{ fontFamily: "Satoshi" }}>
                    <FormattedMessage id="cancel alert creation" />
                  </BlackNormalText>
                  <button
                    className="absolute right-0 top-1"
                    onClick={() => {
                      setOpenCancel(false);
                    }}
                  >
                    <GrayClose />
                  </button>
                </div>
              </DialogTitle>
              <Divider />
              <DialogContent>
                <SmalBlackText style={{ fontFamily: "Roboto" }}>
                  <FormattedMessage id="your changes will not be saved." />
                </SmalBlackText>
              </DialogContent>
              <DialogActions>
                <ButtonWithBlueTextWhiteBackground
                  style={{ fontFamily: "Roboto" }}
                  onClick={() => {
                    setOpenCancel(false);
                  }}
                >
                  <FormattedMessage id="usermanagement.param17" />
                </ButtonWithBlueTextWhiteBackground>

                <RedButton
                  style={{ fontFamily: "Roboto" }}
                  onClick={() => {
                    setOpenCancel(false);
                    setChosenRecipients([]);
                    setChosenStation("");
                    setChosenSetting("");
                    setRules([]);
                    setWithEmail(false);
                    setOpen(false);
                  }}
                >
                  <FormattedMessage id="leave" />
                </RedButton>
              </DialogActions>
            </Dialog>
          </div>
        </DialogActions>
      </Dialog>
    );
  };

  const renderAlretCreationConfirmationModal = () => (
    <Dialog
      maxWidth={"xs"}
      fullWidth={true}
      open={openCreate}
      onClose={handleClickCreateOpen}
    >
      <DialogTitle>
        <div className="flex items-center relative">
          <BlackNormalText>
            <FormattedMessage id="alert created!" />
          </BlackNormalText>
          <button
            className="absolute right-0 top-1"
            onClick={() => {
              setOpenCreate(false);
              setChosenRecipients([]);
              setChosenStation("");
              setChosenSetting("");
              setWithEmail(false);
              setRules([]);
            }}
          >
            <GrayClose />
          </button>
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <SmalBlackText>
          <FormattedMessage id="your alert has been saved, to modify it, go to “My alerts”." />
        </SmalBlackText>
      </DialogContent>
      <DialogActions>
        <BlueButton
          onClick={() => {
            setOpenCreate(false);
            setChosenRecipients([]);
            setChosenStation("");
            setChosenSetting("");
            setRules([]);
          }}
        >
          OK
        </BlueButton>
      </DialogActions>
    </Dialog>
  );
  const renderGridView = () => {
    return (
      <div
        className="flex"
        style={{
          flexWrap: "wrap",
          marginLeft: "-1%",
          alignItems: "flex-start",
          height: "100%",
        }}
      >
        {alerts.map((alert, indx) => (
          <>
            <div
              style={{
                width: "264px",
                border: "1px solid #EBECF3",
                marginLeft: "2%",
                marginBottom: "2%",
                background: "white",
              }}
            >
              {!alertsRealTimeValues[indx] && (
                <Skeleton variant="rectangular" width="100%" height={216} />
              )}
              <div className="flex justify-between" style={{ padding: "10px" }}>
                {alertsRealTimeValues[indx] && (
                  <>
                    <div className="flex justify-between">
                      <GreyText style={{ fontFamily: "Satoshi" }}>{`${intl
                        .formatMessage({ id: "Alert" })
                        .toUpperCase()} ${indx + 1}`}</GreyText>
                      {!alert.activateNotifs && (
                        <MuteIcon
                          style={{
                            marginRight: "-15%",
                          }}
                        />
                      )}
                    </div>
                    <div className="flex justify-between">
                      {checkIsAlert(
                        alert.rules,
                        alertsRealTimeValues[indx].split(" ")[0]
                      ) && <AlertOnIcon />}
                      <HorizontalMoreIcon
                        style={{ cursor: "pointer", marginRight: "-15%" }}
                        onClick={(event) => {
                          setAnchorModifAlert(event.currentTarget);
                          setChosenAlertToModif(alert);
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
              {alertsRealTimeValues[indx] && (
                <>
                  <div style={{ marginLeft: "15%" }}>
                    <Gauge
                      label={alert.chosenSetting}
                      units={alertsRealTimeValues[indx].split(" ")[1]}
                      value={alertsRealTimeValues[indx].split(" ")[0]}
                      station={stationToUpperCase(alert.chosenStation[0])}
                      alertRules={alert.rules}
                    />
                    <div
                      className="flex justify-center items-center mb-4"
                      style={{
                        flexDirection: "column",
                        marginRight: "17%",
                      }}
                    >
                      <GrayText
                        style={{ fontFamily: "Satoshi", fontWeight: 500 }}
                      >
                        {alert.chosenStation[0]}
                      </GrayText>
                      <GrayText
                        style={{ fontFamily: "Satoshi", fontWeight: 500 }}
                      >
                        {intl.formatMessage({ id: "Today" })}
                        {(new Date().toString().includes("+")
                          ? new Date()
                              .toString()
                              .split(" ")[5]
                              ?.split("+")[1]
                              .slice(0, -2)
                          : new Date()
                              .toString()
                              .split(" ")[5]
                              ?.split("-")[1]
                              .slice(0, -2)) !==
                        userPreferencesInfo?.time.split("UTC+")[1].toString()
                          ? format(
                              new Date(
                                applyUTCtoDate(
                                  applyUTCtoDate(
                                    new Date(),
                                    new Date().toString().includes("+")
                                      ? new Date()
                                          .toString()
                                          .split(" ")[5]
                                          .replace("GMT", "UTC")
                                          .slice(0, -2)
                                          ?.replace("+", "-")
                                      : new Date()
                                          .toString()
                                          .split(" ")[5]
                                          .replace("GMT", "UTC")
                                          .slice(0, -2)
                                          ?.replace("-", "+")
                                  ),
                                  userPreferencesInfo?.time
                                )
                              ),
                              "HH:mm"
                            )
                          : format(new Date(), "HH:mm")}
                      </GrayText>
                    </div>
                  </div>
                  <Divider />
                </>
              )}

              {!isReaderOnly(userRoles) &&
                userRoles[alert.chosenStation[0].toLowerCase()] !==
                  "reader" && (
                  <>
                    <div
                      className="flex justify-between items-center ml-2"
                      style={{ height: "44px" }}
                    >
                      <div className="flex justify-between items-center">
                        <BlackText
                          style={{
                            fontWeight: 500,
                            fontFamily: "Satoshi",
                            fontSize: "14px",
                          }}
                        >
                          {intl.formatMessage({ id: "Active alert" })}
                        </BlackText>
                        <LightTooltip
                          placement="bottom-start"
                          title={intl.formatMessage({
                            id: "Activez et désactivez l’alerte pour l’ensemble des collaborateurs. Vous pouvez aussi mettre l’alerte en silence afin de couper vos notifications.",
                          })}
                        >
                          <a
                            onMouseEnter={() =>
                              setInfoClicked((prevInfo) =>
                                prevInfo.map((clicked, InfoIndx) =>
                                  InfoIndx === indx ? !clicked : clicked
                                )
                              )
                            }
                            onMouseLeave={() =>
                              setInfoClicked((prevInfo) =>
                                prevInfo.map((clicked, InfoIndx) =>
                                  InfoIndx === indx ? !clicked : clicked
                                )
                              )
                            }
                          >
                            {infoClicked[indx] ? (
                              <InfoBulleOnIcon
                                style={{
                                  marginRight: "-10%",
                                }}
                              />
                            ) : (
                              <InfoBulleOffIcon
                                style={{
                                  marginRight: "-10%",
                                }}
                              />
                            )}
                          </a>
                        </LightTooltip>
                      </div>
                      <ToggleSwitch
                        small
                        id={alert._id}
                        checked={alert.activateAlerts}
                        onChange={() => setAlertToDeactivate(alert)}
                      />
                    </div>
                    <Divider />
                  </>
                )}
              {alert.activateAlerts && (
                <>
                  <div
                    className="flex justify-between items-center ml-2"
                    style={{ height: "44px" }}
                  >
                    <BlackText
                      style={{
                        fontWeight: 500,
                        fontFamily: "Satoshi",
                        fontSize: "14px",
                      }}
                    >
                      Notifications
                    </BlackText>
                    <ToggleSwitch
                      small
                      id={(Math.random() + 1).toString(36).substring(7)}
                      checked={
                        alert.chosenRecipients.find(
                          (chosenRecipient) =>
                            chosenRecipient.email === user.email
                        )
                          ? !alert.chosenRecipients.find(
                              (chosenRecipient) =>
                                chosenRecipient.email === user.email
                            )?.muted
                          : false
                      }
                      onChange={async () => {
                        let newRecipients = {};
                        if (
                          !alert.chosenRecipients.find(
                            (recipient) => recipient.email === user.email
                          )
                        ) {
                          const userData = await axios
                            .get(
                              process.env.REACT_APP_FRONTEND_URL +
                                `/api/users/getUserInformation/${user.email}`
                            )
                            .then(
                              (res) =>
                                res.data.filter(
                                  (user) => user.team === alert.chosenStation[0]
                                )[0]
                            );
                          const mutedKey = "muted";
                          userData[mutedKey] = false;
                          newRecipients = [...alert.chosenRecipients, userData];
                          updateAlertApi(
                            { ...alert, chosenRecipients: newRecipients },
                            alert._id
                          ).finally(() => handleGetAlerts());
                        } else {
                          newRecipients = alert.chosenRecipients.map(
                            (chosenRecipient) => {
                              if (chosenRecipient.email === user.email) {
                                return {
                                  ...chosenRecipient,
                                  muted: !chosenRecipient.muted,
                                };
                              } else return chosenRecipient;
                            }
                          );
                          updateAlertApi(
                            { ...alert, chosenRecipients: newRecipients },
                            alert._id
                          ).finally(() => handleGetAlerts());
                        }
                      }}
                    />
                  </div>
                  <Divider />
                </>
              )}
              <div className="flex justify-between items-center ml-2">
                <BlackText
                  style={{
                    fontWeight: 500,
                    fontFamily: "Satoshi",
                    fontSize: "14px",
                  }}
                >
                  <FormattedMessage id="Trigger history" />
                </BlackText>
                {historyClicked[indx] ? (
                  <DropUpIcon
                    style={{ cursor: "pointer", marginTop: "-5px" }}
                    onClick={() => {
                      setHistoryClicked((prevHistory) =>
                        prevHistory.map((clicked, historyIndx) =>
                          historyIndx === indx ? !clicked : false
                        )
                      );
                      setTriggerHistory();
                    }}
                  />
                ) : (
                  <DropDownIcon
                    style={{ cursor: "pointer", marginTop: "-5px" }}
                    onClick={() => {
                      setHistoryClicked((prevHistory) =>
                        prevHistory.map((history, historyIndx) =>
                          historyIndx === indx ? !history : false
                        )
                      );
                      setTriggerHistory();
                      alertsHistoryTriggers(alert._id);
                    }}
                  />
                )}
              </div>
              {historyClicked[indx] && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                    padding: "10px 10px",
                  }}
                >
                  {(!triggerHistory || triggerHistory.length === 0) && (
                    <div
                      style={{
                        color: "#8488A5",
                        fontWeight: 300,
                        fontSize: 12,
                        fontFamily: "Roboto",
                      }}
                    >
                      <FormattedMessage id="This alert has not been triggered" />
                    </div>
                  )}
                  {triggerHistory &&
                    triggerHistory?.map((expandedAlertHistory) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          fontSize: 12,
                          width: "238px",
                          height: "45px",
                          borderRadius: "4px",
                          padding: "10px 21px 10px 21px",
                          border: "1px solid #EBECF3",
                          fontWeight: 300,
                          alignItems: "center",
                          fontFamily: "Roboto",
                        }}
                      >
                        <div style={{ color: "#8488A5" }}>
                          {expandedAlertHistory.timedate}
                        </div>
                        <div>{expandedAlertHistory.value}</div>
                        <HeartBeatChart />
                      </div>
                    ))}
                </div>
              )}
            </div>
          </>
        ))}
        <Menu
          anchorEl={anchorModifAlert}
          open={openModifAlert}
          sx={{
            "& .MuiMenu-paper": {
              boxShadow: "0px 0px 7px 0px rgb(41, 117, 255, 0.31)",
            },
          }}
          style={{
            marginLeft: "-2%",
          }}
          onClose={() => setAnchorModifAlert(null)}
        >
          {chosenAlertToModif &&
            userRoles[chosenAlertToModif.chosenStation[0].toLowerCase()] !==
              "reader" && (
              <ModifAlertMenuItemWithHover
                onClick={() => {
                  setAnchorModifAlert(null);
                }}
              >
                <ListItemText onClick={() => setEditAlertButtonClicked(true)}>
                  <SmalBlackText
                    style={{
                      fontFamily: "Satoshi",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {intl.formatMessage({ id: "edit" })}
                  </SmalBlackText>
                </ListItemText>
              </ModifAlertMenuItemWithHover>
            )}
          <ModifAlertMenuItemWithHover
            onClick={() => {
              setAnchorModifAlert(null);
            }}
          >
            <ListItemText onClick={() => setSeeDetailsButtonClicked(true)}>
              <SmalBlackText
                style={{
                  fontFamily: "Satoshi",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                {intl.formatMessage({ id: "See the details" })}
              </SmalBlackText>
            </ListItemText>
          </ModifAlertMenuItemWithHover>
          {chosenAlertToModif &&
            userRoles[chosenAlertToModif.chosenStation[0].toLowerCase()] !==
              "reader" && (
              <>
                <Divider />
                <ModifAlertMenuItemWithHover
                  onClick={() => {
                    setAnchorModifAlert(null);
                  }}
                >
                  <ListItemText onClick={() => setDeleteButtonClicked(true)}>
                    <ErrorText
                      style={{
                        fontFamily: "Satoshi",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      {intl.formatMessage({ id: "delete" })}
                    </ErrorText>
                  </ListItemText>
                </ModifAlertMenuItemWithHover>
              </>
            )}
        </Menu>
      </div>
    );
  };
  const useStyles = makeStyles({
    table: {
      "& tbody tr:last-child td": {
        border: "none",
      },
      "& tbody tr:last-child th": {
        border: "none",
      },
      "& tbody tr:nth-child(2n+1) td:last-child": {
        borderRight: "1px solid #EBECF3",
      },
      "& tbody tr:nth-child(2n+1) th": {
        borderLeft: "1px solid #EBECF3",
        background: "white",
      },
      "& tbody tr:nth-child(2n+1) td": {
        background: "white",
      },
      "& tbody tr:nth-child(2n) td": {
        paddingTop: "3px !important",
        paddingBottom: "3px !important",
      },
      "& tbody tr:nth-child(2n) th": {
        paddingTop: "3px !important",
        paddingBottom: "3px !important",
      },
    },
  });
  const classes = useStyles();
  const [icon, setIcon] = useState(false);

  const renderListView = () => {
    return (
      <TableContainer>
        <Table
          className={classes.table}
          sx={{
            minWidth: 650,
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "1px solid #EBECF3",
            },
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <Col style={{ marginLeft: 10, marginRight: 12 }}>
                    <HeaderText>
                      <FormattedMessage id="usermanagement.param1" />
                    </HeaderText>
                  </Col>
                  <Col>
                    <FlechIcon />
                  </Col>
                </Row>
              </TableCell>
              <TableCell align="right">
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <Col style={{ marginRight: 12 }}>
                    <HeaderText>
                      <FormattedMessage id="setting" />
                    </HeaderText>
                  </Col>
                  <Col>
                    <FlechIcon />
                  </Col>
                </Row>
              </TableCell>
              <TableCell align="left">
                <HeaderText>
                  <FormattedMessage id="current value" />
                </HeaderText>
              </TableCell>
              <TableCell align="right">
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <Col style={{ marginRight: 12 }}>
                    <HeaderText>Station</HeaderText>
                  </Col>
                  <Col>
                    <FlechIcon />
                  </Col>
                </Row>
              </TableCell>
              {!isReaderOnly(userRoles) && (
                <TableCell align="left">
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <Col style={{ marginRight: 12 }}>
                      <HeaderText>
                        <FormattedMessage id="activate alert" />
                      </HeaderText>
                    </Col>
                    <Col>
                      <LightTooltip
                        placement="bottom-start"
                        title={intl.formatMessage({
                          id: "Activez et désactivez l’alerte pour l’ensemble des collaborateurs. Vous pouvez aussi mettre l’alerte en silence afin de couper vos notifications.",
                        })}
                      >
                        <a
                          style={{ borderColor: "white" }}
                          onMouseEnter={() => setIcon(true)}
                          onMouseLeave={() => setIcon(false)}
                        >
                          {icon ? (
                            <InfoBulleOnIcon
                              style={{
                                marginRight: "-5%",
                              }}
                            />
                          ) : (
                            <InfoBulleOffIcon
                              style={{
                                marginRight: "-5%",
                              }}
                            />
                          )}
                        </a>
                      </LightTooltip>
                    </Col>
                    <Col>
                      <FlechIcon />
                    </Col>
                  </Row>
                </TableCell>
              )}
              <TableCell align="left">
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <Col style={{ marginRight: 12 }}>
                    <HeaderText>Notifications</HeaderText>
                  </Col>
                  <Col>
                    <FlechIcon />
                  </Col>
                </Row>
              </TableCell>
              <TableCell align="left">
                <HeaderText></HeaderText>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alerts.map((alert, indx) => (
              <>
                <TableRow key={indx}>
                  <StyledTableCell
                    align="left"
                    padding="none"
                    component="th"
                    scope="row"
                  >
                    <Row>
                      <Col
                        style={{
                          paddingLeft: 10,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      >
                        <GreyText
                          style={{
                            fontFamily: "Satoshi",
                            fontWeight: 500,
                            fontSize: "12px",
                          }}
                        >
                          {`${intl
                            .formatMessage({ id: "Alert" })
                            .toUpperCase()} ${indx + 1}`}
                        </GreyText>
                        {!alert.activateNotifs && <MuteIcon />}
                        {checkIsAlert(
                          alert.rules,
                          alertsRealTimeValues[indx].split(" ")[0]
                        ) && <AlertOnIcon />}
                      </Col>
                    </Row>
                  </StyledTableCell>
                  <StyledTableCell align="left" padding="none">
                    <SmalBlackText
                      style={{
                        fontFamily: "Satoshi",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      {alert.chosenSetting}
                    </SmalBlackText>
                  </StyledTableCell>
                  <StyledTableCell align="left" padding="none">
                    {alertsRealTimeValues[indx] === undefined && (
                      <CircularProgress
                        size="4vh"
                        style={{ marginLeft: "30px" }}
                      />
                    )}
                    {alertsRealTimeValues[indx] === "no data" ||
                    alertsRealTimeValues[indx] === "undefined undefined" ? (
                      <BlueGrayText
                        style={{
                          fontFamily: "Satoshi",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                        activateAlerte={false}
                      >
                        No data
                      </BlueGrayText>
                    ) : checkIsAlert(
                        alert.rules,
                        alertsRealTimeValues[indx].split(" ")[0]
                      ) ? (
                      <BlueGrayText
                        style={{
                          fontFamily: "Satoshi",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "#F04545",
                        }}
                      >
                        {alertsRealTimeValues[indx]}
                      </BlueGrayText>
                    ) : (
                      <BlueGrayText
                        style={{
                          fontFamily: "Satoshi",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                        activateAlerte={alert.activateAlerts}
                      >
                        {alertsRealTimeValues[indx]}
                      </BlueGrayText>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="left" padding="none">
                    <SmalBlackText
                      style={{
                        fontFamily: "Satoshi",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      {alert.chosenStation[0]}
                    </SmalBlackText>
                  </StyledTableCell>
                  {!isReaderOnly(userRoles) &&
                    userRoles[alert.chosenStation[0].toLowerCase()] !==
                      "reader" && (
                      <StyledTableCell align="left" padding="none">
                        <ToggleSwitch
                          small
                          id={alert._id}
                          checked={alert.activateAlerts}
                          onChange={() => setAlertToDeactivate(alert)}
                        />
                      </StyledTableCell>
                    )}

                  {!isReaderOnly(userRoles) &&
                    userRoles[alert.chosenStation[0].toLowerCase()] ===
                      "reader" && (
                      <StyledTableCell
                        align="left"
                        padding="none"
                      ></StyledTableCell>
                    )}

                  <StyledTableCell align="left" padding="none">
                    {alert.activateAlerts && (
                      <ToggleSwitch
                        id={(Math.random() + 1).toString(36).substring(7)}
                        small
                        checked={
                          alert.chosenRecipients.find(
                            (chosenRecipient) =>
                              chosenRecipient.email === user.email
                          )
                            ? !alert.chosenRecipients.find(
                                (chosenRecipient) =>
                                  chosenRecipient.email === user.email
                              )?.muted
                            : false
                        }
                        onChange={async () => {
                          let newRecipients = {};
                          if (
                            !alert.chosenRecipients.find(
                              (recipient) => recipient.email === user.email
                            )
                          ) {
                            const userData = await axios
                              .get(
                                process.env.REACT_APP_FRONTEND_URL +
                                  `/api/users/getUserInformation/${user.email}`
                              )
                              .then(
                                (res) =>
                                  res.data.filter(
                                    (user) =>
                                      user.team === alert.chosenStation[0]
                                  )[0]
                              );
                            const mutedKey = "muted";
                            userData[mutedKey] = false;
                            newRecipients = [
                              ...alert.chosenRecipients,
                              userData,
                            ];
                            updateAlertApi(
                              { ...alert, chosenRecipients: newRecipients },
                              alert._id
                            ).finally(() => handleGetAlerts());
                          } else {
                            newRecipients = alert.chosenRecipients.map(
                              (chosenRecipient) => {
                                if (chosenRecipient.email === user.email) {
                                  return {
                                    ...chosenRecipient,
                                    muted: !chosenRecipient.muted,
                                  };
                                } else return chosenRecipient;
                              }
                            );
                            updateAlertApi(
                              { ...alert, chosenRecipients: newRecipients },
                              alert._id
                            ).finally(() => handleGetAlerts());
                          }
                        }}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    padding="none"
                    style={{ cursor: "pointer" }}
                  >
                    <MoreIcon
                      onClick={(event) => {
                        setAnchorModifAlert(event.currentTarget);
                        setChosenAlertToModif(alert);
                      }}
                    />
                  </StyledTableCell>
                </TableRow>
                <TableRow key={indx}>
                  <StyledTableCell
                    align="left"
                    padding="none"
                    component="th"
                    scope="row"
                  >
                    <Row>
                      <Col
                        style={{
                          paddingLeft: 10,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "20px",
                        }}
                      ></Col>
                    </Row>
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    padding="none"
                  ></StyledTableCell>
                  <StyledTableCell
                    align="left"
                    padding="none"
                  ></StyledTableCell>
                  <StyledTableCell
                    align="left"
                    padding="none"
                  ></StyledTableCell>
                  <StyledTableCell
                    align="left"
                    padding="none"
                  ></StyledTableCell>
                  <StyledTableCell
                    align="left"
                    padding="none"
                  ></StyledTableCell>
                  <StyledTableCell
                    align="left"
                    padding="none"
                  ></StyledTableCell>
                </TableRow>
              </>
            ))}

            <Menu
              anchorEl={anchorModifAlert}
              open={openModifAlert}
              sx={{
                "& .MuiMenu-paper": {
                  boxShadow: "0px 0px 7px 0px rgb(41, 117, 255, 0.31)",
                },
              }}
              style={{
                marginLeft: "-5%",
              }}
              onClose={() => setAnchorModifAlert(null)}
            >
              {chosenAlertToModif &&
                userRoles[chosenAlertToModif.chosenStation[0].toLowerCase()] !==
                  "reader" && (
                  <ModifAlertMenuItemWithHover
                    onClick={() => {
                      setAnchorModifAlert(null);
                    }}
                  >
                    <ListItemText
                      onClick={() => setEditAlertButtonClicked(true)}
                    >
                      <SmalBlackText
                        style={{
                          fontFamily: "Satoshi",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        {intl.formatMessage({ id: "edit" })}
                      </SmalBlackText>
                    </ListItemText>
                  </ModifAlertMenuItemWithHover>
                )}
              <ModifAlertMenuItemWithHover
                onClick={() => {
                  setAnchorModifAlert(null);
                }}
              >
                <ListItemText onClick={() => setSeeDetailsButtonClicked(true)}>
                  <SmalBlackText
                    style={{
                      fontFamily: "Satoshi",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {intl.formatMessage({ id: "See the details" })}
                  </SmalBlackText>
                </ListItemText>
              </ModifAlertMenuItemWithHover>

              {chosenAlertToModif &&
                userRoles[chosenAlertToModif.chosenStation[0].toLowerCase()] !==
                  "reader" && (
                  <>
                    <Divider />
                    <ModifAlertMenuItemWithHover
                      onClick={() => {
                        setAnchorModifAlert(null);
                      }}
                    >
                      <ListItemText
                        onClick={() => setDeleteButtonClicked(true)}
                      >
                        <ErrorText
                          style={{
                            fontFamily: "Satoshi",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {intl.formatMessage({ id: "delete" })}
                        </ErrorText>
                      </ListItemText>
                    </ModifAlertMenuItemWithHover>
                  </>
                )}
            </Menu>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  const renderAlertScreen = () => {
    return (
      <div className="mr-2 h-full">
        <div className="flex justify-between">
          <StyledBlackBoldText style={{ fontFamily: "Satoshi" }}>
            <FormattedMessage id="my alerts" />
          </StyledBlackBoldText>
          <div>
            <button
              className="mr-3"
              onClick={() => {
                setGridView(true);
                setListView(false);
              }}
            >
              {gridView ? <BlueGridView /> : <GridViewIcon />}
            </button>
            <button
              onClick={() => {
                setListView(true);
                setGridView(false);
              }}
            >
              {listView ? <BlueLineViewIcon /> : <LineViewIcon />}
            </button>
          </div>
        </div>

        <div
          className="flex justify-between ml-2 mt-4"
          style={{ flexDirection: "row" }}
        >
          <HeaderText></HeaderText>
        </div>
        {listView ? renderListView() : renderGridView()}
      </div>
    );
  };

  return (
    <>
      {alerts.length !== 0 ? (
        <div
          className={`${!isMobile && "ml-16"} w-full h-full`}
          style={{
            paddingBottom: "-8px",
            paddingTop: "-8px",
          }}
        >
          <div
            className="flex justify-between border-b-1 border-primary border-opacity-20 p-2 bg-white"
            style={{
              padding: "16px",
              position: "sticky",
              top: "0px",
              zIndex: 1,
            }}
          >
            <BlackBoldTextSatoshiFont>
              <FormattedMessage id="Alerts" />
            </BlackBoldTextSatoshiFont>
            {!isReaderOnly(userRoles) && (
              <StyledButton
                style={{
                  fontFamily: "Roboto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => setOpen(true)}
              >
                <FormattedMessage id="Create an alert" />
              </StyledButton>
            )}
          </div>
          <div className="p-4 overflow-y-auto">
            {renderAlertScreen()}
            {open && renderCreateAlertsButtonClicked(open, setOpen)}
            {openCreate && renderAlretCreationConfirmationModal()}
            {alertToDeactivate && renderActivateAlertClicked()}
            {editAlertButtonClicked && renderEditAlert()}
            {deleteButtonClicked && renderDeleteAlertButtonClicked()}
            {seeDetailsButtonClicked && renderSeeAlertDetails()}
          </div>
        </div>
      ) : (
        <div className="flex justify-between bg-white w-full h-full">
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "Column",
              marginLeft: "10%",
            }}
          >
            <StyledBigText align="left" style={{ marginBottom: "15px" }}>
              <FormattedMessage
                id="Create your first alert!"
                values={{ br: <br /> }}
              />
            </StyledBigText>
            <StyledParagraph align="left" style={{ marginBottom: "20px" }}>
              <FormattedMessage
                id="Be informed in real time of the evolution of your chronicles. Notify your collaborators by e-mail or Whatsapp."
                values={{ br: <br /> }}
              />
            </StyledParagraph>
            <button
              onClick={() => setOpen(true)}
              style={{
                width: "128px",
                background: "#2975ff",
                height: "32px",
                padding: "10 34px",
                fontFamily: "Roboto",
                borderRadius: "5px",
                fontWeight: 700,
                fontSize: "12px",
                color: "#FFFFFF",
              }}
            >
              <FormattedMessage id="Create an alert" />
            </button>
          </Col>
          <StyledImage alt="" src="./assets/Alerts.png" />
          {open && renderCreateAlertsButtonClicked(open, setOpen)}
        </div>
      )}
    </>
  );
});

// Alerts.whyDidYouRender = true;
export default Alerts;

const MenuItemWithHover = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "#EBECF3",
    },
  },
})(StyledMenuItem);

const ModifMenuItemWithHover = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "#EBECF3",
    },
  },
})(ModifMenuItem);

const ModifAlertMenuItemWithHover = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "#EBECF3",
    },
  },
})(ModifAlertMenuItem);

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "white",
    color: "#2975FF",
    boxShadow: "0px 0px 7px 0px rgb(41, 117, 255, 0.31)",
    maxWidth: 200,
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Roboto",
  },
}))(Tooltip);
