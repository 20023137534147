/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useLocation, useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as LogoIcon } from "../icons/logo.svg";
import { ReactComponent as EqualizerIcon } from "../icons/equalizer.svg";
import { ReactComponent as GearFilledIcon } from "../icons/gear-filled.svg";
import { ReactComponent as GearIcon } from "../icons/gear.svg";
import { ReactComponent as LogoutIcon } from "../icons/logout.svg";
import { ReactComponent as ExtraplotIcon } from "../icons/extraplot.svg";
import { ReactComponent as PeopleFillIcon } from "../icons/people-fill.svg";
import { ReactComponent as ContactIcon } from "../icons/contact.svg";
import { ReactComponent as AlertsIcon } from "../icons/alerts.svg";

import { Row } from "react-flexbox-grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import { setAddPostDialogOpen, setSelectedTab } from "../Redux/actions";
import { isReaderOnly } from "./../utils/roles";
import Settings from "../components/Settings/Settings.component";
import { createPicture } from "../Pages/UsersManagement/Const";
import { capitalizeFirstLetter } from "../utils/char";
import { Divider } from "@mui/material";

export default function Sidebar() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.isMobile);
  const history = useHistory();
  const userRoles = useSelector((state) => state.userRoles);

  const handleAlertsClicked = () => {
    history.push("/alerts");
  };

  const riverlabsWithAtLeastWriterRole =
    userRoles &&
    Object.keys(userRoles)
      .filter((key) => userRoles[key] !== "reader")
      .join(", ");

  return (
    <div className="flex fixed bottom-0 w-screen h-14 z-40 md:h-auto  md:w-16 md:bottom-0 md:top-0 md:flex-col">
      <div className="flex md:flex-col flex-grow bg-dark md:pb-4 md:gap-10  md:items-center">
        <div className="hidden md:bg-white md:h-16 md:w-full md:flex md:items-center md:justify-center md:rounded-b-8px md:shadow-logo-shadow">
          <LogoIcon
            style={{
              cursor: "pointer",
            }}
            onClick={() => history.push("/dashboard")}
          />
        </div>
        <div className="w-full md:p-2">
          <nav className="h-full flex justify-around items-center justify-center md:m-auto md:flex-col md:gap-2 ">
            {isMobile !== null && isMobile === true && (
              <div className="flex flex-col justify-center items-center">
                <AccountButton />
                <p className="text-white text-xxs">Profile</p>
              </div>
            )}
            <div className="flex flex-col justify-center items-center">
              <SidebarButton toComponent={"/dashboard"} label={"dashboard"}>
                <svg
                  width="26"
                  height="27"
                  viewBox="0 0 26 27"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d)">
                    <path d="M5 13H11C11.55 13 12 12.55 12 12V4C12 3.45 11.55 3 11 3H5C4.45 3 4 3.45 4 4V12C4 12.55 4.45 13 5 13ZM5 21H11C11.55 21 12 20.55 12 20V16C12 15.45 11.55 15 11 15H5C4.45 15 4 15.45 4 16V20C4 20.55 4.45 21 5 21ZM15 21H21C21.55 21 22 20.55 22 20V12C22 11.45 21.55 11 21 11H15C14.45 11 14 11.45 14 12V20C14 20.55 14.45 21 15 21ZM14 4V8C14 8.55 14.45 9 15 9H21C21.55 9 22 8.55 22 8V4C22 3.45 21.55 3 21 3H15C14.45 3 14 3.45 14 4Z" />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d"
                      x="-3"
                      y="-2"
                      width="32"
                      height="32"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="2" />
                      <feGaussianBlur stdDeviation="2" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </SidebarButton>
              {isMobile !== null && isMobile === true && (
                <p className="text-white text-xxs">Dashboard</p>
              )}
            </div>

            {user &&
              user.groups &&
              (user.groups.includes("/monticello") ||
                user.groups.includes("/demo")) &&
              !isReaderOnly(userRoles) &&
              riverlabsWithAtLeastWriterRole.includes("monticello") && (
                <div className="flex flex-col justify-center items-center">
                  <SidebarButton
                    toComponent={"/monitoring"}
                    label={"monitoring"}
                  >
                    <EqualizerIcon />
                  </SidebarButton>
                  {isMobile !== null && isMobile === true && (
                    <p className="text-white text-xxs">Automate</p>
                  )}
                </div>
              )}
            {isMobile !== null &&
              isMobile === false &&
              !isReaderOnly(userRoles) && (
                <SidebarButton toComponent={"/protocole"} label={"protocole"}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M4 16H7C7.55 16 8 15.55 8 15C8 14.45 7.55 14 7 14H4C3.45 14 3 14.45 3 15C3 15.55 3.45 16 4 16ZM10.5 16H13.5C14.05 16 14.5 15.55 14.5 15C14.5 14.45 14.05 14 13.5 14H10.5C9.95 14 9.5 14.45 9.5 15C9.5 15.55 9.95 16 10.5 16ZM17 16H20C20.55 16 21 15.55 21 15C21 14.45 20.55 14 20 14H17C16.45 14 16 14.45 16 15C16 15.55 16.45 16 17 16ZM4 20C4.55 20 5 19.55 5 19C5 18.45 4.55 18 4 18C3.45 18 3 18.45 3 19C3 19.55 3.45 20 4 20ZM8 20C8.55 20 9 19.55 9 19C9 18.45 8.55 18 8 18C7.45 18 7 18.45 7 19C7 19.55 7.45 20 8 20ZM12 20C12.55 20 13 19.55 13 19C13 18.45 12.55 18 12 18C11.45 18 11 18.45 11 19C11 19.55 11.45 20 12 20ZM16 20C16.55 20 17 19.55 17 19C17 18.45 16.55 18 16 18C15.45 18 15 18.45 15 19C15 19.55 15.45 20 16 20ZM20 20C20.55 20 21 19.55 21 19C21 18.45 20.55 18 20 18C19.45 18 19 18.45 19 19C19 19.55 19.45 20 20 20ZM4 12H10C10.55 12 11 11.55 11 11C11 10.45 10.55 10 10 10H4C3.45 10 3 10.45 3 11C3 11.55 3.45 12 4 12ZM14 12H20C20.55 12 21 11.55 21 11C21 10.45 20.55 10 20 10H14C13.45 10 13 10.45 13 11C13 11.55 13.45 12 14 12ZM3 5V7C3 7.55 3.45 8 4 8H20C20.55 8 21 7.55 21 7V5C21 4.45 20.55 4 20 4H4C3.45 4 3 4.45 3 5Z" />
                  </svg>
                </SidebarButton>
              )}
            {!isReaderOnly(userRoles) && (
              <div className="flex flex-col justify-center items-center">
                <SidebarButton toComponent={"/extraPlot"} label={"extraPlot"}>
                  <ExtraplotIcon />
                </SidebarButton>
                {isMobile !== null && isMobile === true && (
                  <p className="text-white text-xxs">ExtraPlot</p>
                )}
              </div>
            )}

            {!isReaderOnly(userRoles) && (
              <div className="flex flex-col justify-center items-center">
                <SidebarButton toComponent={"/extraCode"} label={"extraCode"}>
                  <svg
                    width="20"
                    height="12"
                    viewBox="0 0 20 12"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.69998 9.89997L2.79998 5.99997L6.69998 2.09997C7.08998 1.70997 7.08998 1.08997 6.69998 0.699971C6.30998 0.309971 5.68998 0.309971 5.29998 0.699971L0.70998 5.28997C0.31998 5.67997 0.31998 6.30997 0.70998 6.69997L5.29998 11.3C5.68998 11.69 6.30998 11.69 6.69998 11.3C7.08998 10.91 7.08998 10.29 6.69998 9.89997ZM13.3 9.89997L17.2 5.99997L13.3 2.09997C12.91 1.70997 12.91 1.08997 13.3 0.699971C13.69 0.309971 14.31 0.309971 14.7 0.699971L19.29 5.28997C19.68 5.67997 19.68 6.30997 19.29 6.69997L14.7 11.3C14.31 11.69 13.69 11.69 13.3 11.3C12.91 10.91 12.91 10.29 13.3 9.89997Z" />
                  </svg>
                </SidebarButton>
                {isMobile !== null && isMobile === true && (
                  <p className="text-white text-xxs">ExtraCode</p>
                )}
              </div>
            )}
            <div className="flex flex-col justify-center items-center">
              <button
                onClick={() => {
                  history.push("/dashboard");
                  dispatch(setSelectedTab(1));
                  dispatch(setAddPostDialogOpen(true));
                }}
                className="text-grey-lighter w-8 h-8 flex items-center justify-center md:hidden focus:outline-none"
              >
                <svg
                  className="h-full w-full flex items-center justify-center"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM16 13H13V16C13 16.55 12.55 17 12 17C11.45 17 11 16.55 11 16V13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H11V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V11H16C16.55 11 17 11.45 17 12C17 12.55 16.55 13 16 13Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
              {isMobile !== null && isMobile === true && (
                <p className="text-white text-xxs">Add post</p>
              )}
            </div>
          </nav>
        </div>
      </div>

      <div className="hidden md:pb-2 md:bg-black md:flex md:flex-col md:gap-2 md:items-center md:h-59">
        <button
          style={{ cursor: "pointer", marginTop: "16px" }}
          onClick={handleAlertsClicked}
          className="flex items-center mt-2 justify-center p-2 pb-0 font-medium rounded-md text-gray-500 hover:text-white 
            hover:bg-black focus:outline-none focus:bg-black transition ease-in-out duration-150"
        >
          <AlertsIcon />
        </button>
        <AccountButton />
      </div>
    </div>
  );
}

function AccountButton() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const kc = useSelector((state) => state.kc);
  const user = useSelector((state) => state.user);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSettings = (open, setOpen) => {
    return <Settings open={open} setOpen={setOpen} />;
  };

  const handleLogout = () => {
    kc &&
      kc
        .logout()
        .then(() => console.log("ok"))
        .catch((err) => console.log("err"));
    setAnchorEl(null);
  };
  const handleUserManagement = () => {
    history.push("/users-management");
    setAnchorEl(null);
  };

  const handleContact = () => {
    history.push("/contact");
    setAnchorEl(null);
  };

  const handleProfile = () => {
    history.push("/profile");
    setAnchorEl(null);
  };

  return (
    <>
      <button
        onClick={handleClick}
        className="flex items-center mt-2 justify-center p-2 pb-0 font-medium rounded-md text-gray-500 hover:text-white hover:bg-black focus:outline-none 
        focus:bg-black transition ease-in-out duration-150"
        style={{ marginBottom: "16px", cursor: "pointer" }}
      >
        <GearFilledIcon />
      </button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div
          style={{
            height: "100px",
            width: "232px",
            marginTop: "15px",
          }}
        >
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {user && createPicture(user.given_name, 38, 38)}
          </Row>
          <Row
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontWeight: 500,
                fontSize: "12px",
                color: "#000000",
                fontFamily: "Roboto",
              }}
            >
              {user &&
                capitalizeFirstLetter(user.given_name) +
                  " " +
                  capitalizeFirstLetter(user.family_name)}
            </p>
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Roboto",
            }}
          >
            <MenuItem onClick={handleProfile}>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "12px",
                  color: "#2975FF",
                  fontFamily: "Roboto",
                }}
              >
                <FormattedMessage id="See my profile" />
              </p>
            </MenuItem>
          </Row>
        </div>
        <Divider />
        <MenuItem
          onClick={() => {
            setOpen(true);
            setAnchorEl(null);
          }}
          style={{ marginTop: "10px" }}
        >
          <GearIcon className="mr-2" />
          <p
            style={{
              fontWeight: 400,
              fontSize: "12px",
              color: "#000000",
              fontFamily: "Roboto",
            }}
          >
            <FormattedMessage id="My preferences" />
          </p>
        </MenuItem>
        <MenuItem onClick={handleUserManagement}>
          <PeopleFillIcon className="mr-2" />
          <p
            style={{
              fontWeight: 400,
              fontSize: "12px",
              color: "#000000",
              fontFamily: "Roboto",
            }}
          >
            <FormattedMessage id="sidebar.param2" />
          </p>
        </MenuItem>
        <MenuItem onClick={handleContact}>
          <ContactIcon className="mr-2" />
          <p
            style={{
              fontWeight: 400,
              fontSize: "12px",
              color: "#000000",
              fontFamily: "Roboto",
            }}
          >
            <FormattedMessage id="Contact" />
          </p>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <LogoutIcon className="mr-2" />
          <p
            style={{
              fontWeight: 400,
              fontSize: "12px",
              color: "red",
              fontFamily: "Roboto",
            }}
          >
            <FormattedMessage id="Logout" />
          </p>
        </MenuItem>
        {open && handleSettings(open, setOpen)}
      </Menu>
    </>
  );
}

function SidebarButton({ children, toComponent, label, toHref }) {
  const [isSelected, setIsSelected] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === `/${label}`) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [location]);
  return (
    <>
      {toComponent && (
        <Link
          to={toComponent}
          className={`${
            isSelected ? "bg-primary text-white" : "text-grey-lighter"
          } w-8 h-8  md:w-12 md:h-12 group flex items-center justify-center p-1 md:p-3 rounded-custom   hover:text-white  focus:outline-none  transition ease-in-out duration-150`}
        >
          {children}
        </Link>
      )}
      {toHref && (
        <a
          href={toHref}
          target="_blank"
          className={`${
            isSelected ? "bg-primary text-white" : "text-grey-lighter"
          }  w-8 h-8  md:w-12 md:h-12 group flex items-center justify-center p-1 md:p-3 rounded-custom   hover:text-white  focus:outline-none  transition ease-in-out duration-150`}
        >
          {children}
        </a>
      )}
    </>
  );
}
