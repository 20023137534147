/* eslint-disable import/no-anonymous-default-export */
import { LOCALES } from "../locales";
export default {
  [LOCALES.ENGLISH]: {
    "My preferences": "My preferences",
    Logout: "Logout",
    Contact: "Contact",
    "Do you have a question": "Do you have a question ?",
    "Contact us by e-mail or phone": "Contact us by e-mail or phone.",
    Phone: "Phone",
    "See my profile": "My profile",
    "Send e-mail": "Send e-mail",
    "My profile": "My profile",
    "addcard.param1": "Add or delete your favorit cards from here 😉",
    "addcard.param2": "reset",
    "sidebar.param1": "Logout",
    "sidebar.param3": "My settings",
    "sidebar.param2": "Team management",
    "usermanagement.param1": "Name",
    "usermanagement.param2": "Role",
    "usermanagement.param3": "Access and rights",
    "usermanagement.param4": "Status",
    "usermanagement.param5": "Team members",
    "usermanagement.param6": "Invite",
    "usermanagement.param7":
      "All modules + All access and team management rights",
    "usermanagement.param8":
      "All modules + Can invite/remove. readers and writers",
    "usermanagement.param9": "Dashboard + Newsfeed + Can invite/delete readers",
    "usermanagement.param10": "Admin",
    "usermanagement.param11": "Writer",
    "usermanagement.param12": "Reader",
    "usermanagement.param13": "Remove from team",
    "usermanagement.param14": "Invite collaborators",
    "usermanagement.param15": "E-mail address",
    "usermanagement.param16": "Add collaborators",
    "usermanagement.param17": "Cancel",
    "usermanagement.param18": "Email is invalid",
    "usermanagement.param19": "Remove collaborator",
    "usermanagement.param20":
      " will no longer have access to the Extralab platform",
    "usermanagement.param21": "Remove",
    "usermanagement.param22": "Access",
    "usermanagement.param23":
      "Access to all modules {br} All team management rights.",
    "usermanagement.param24":
      "Access to all modules  {br} Can invite/remove readers and writers",
    "usermanagement.param25":
      "Access to Dashboard + Newsfeed {br} Can invite/delete readers.",
    "Cet utilisateur est Admin dans une autre équipe":
      "This user is Admin in another team, you can only invite him to {br} a new team as a Reader.",
    "settings.param1": "Languages",
    "settings.param2": "Time zone",
    "settings.param3": "Time",
    "settings.param4": "Save",
    team: "Team",
    role: "Role",
    envoyer: "Send",
    "Invitation sent successfully": "Invitation sent successfully 🥳",
    "User deleted successfully": "User deleted successfully",
    "Role updated successfully": "Role updated successfully",
    "Invitez des collaborateurs à rejoindre Extralab":
      "Invite colleagues {br} to join Extralab!",
    "Your colleagues will receive an email that will allow them to access the application according to the role you assign them.":
      "Your colleagues will receive an email that will allow them {br} to access the application according to the role you {br} assign them.",
    "est Admin dans une autre équipe":
      "is an Admin in another team, you can invite {br} him to a new team only as a Reader. To invite Admins and Writers, {br} create a new invitation.",
    "Toutes les équipes": "All teams",
    "settings.param5": "Units of measure",
    "automate.chart.tab2": "Graphic",
    "automate.annex.tab1": "Pump",
    "automate.annex.tab2": "Valve",
    "automate.annex.tab3": "Unclogging",
    "automate.annex.tab4": "Filtered water",
    "automate.annex.tab5": "Light",
    "automate.annex.pump.param1": "Advanced parameters",
    "automate.annex.pump.param2": "Pump on / off selector",
    "automate.annex.pump.param3": "Manual mode",
    "automate.annex.pump.param4": "Automatic mode",
    "automate.annex.pump.param5": "Pump speed reference",
    "automate.annex.pump.param6": "Pump flow measurement",
    "automate.annex.pump.param7": "Proportionality parameter ",
    "automate.annex.pump.param8": "Integrator parameter",
    "automate.annex.pump.param9": "Derivator Parameter",
    "automate.annex.infomessage1": "Automatic mode desactivated !",
    "automate.annex.infomessage2": "Manual mode desactivated !",
    "automate.annex.infomessage3":
      "You are not authorized to access this section !",
    "automate.annex.valve.param1": "Valve opening instruction",
    "automate.annex.valve.param2": "Valve pressure measurement",
    "automate.annex.Unclogging.param1": "Unclogging timer",
    "automate.annex.Unclogging.param2": "Next unclogging in",
    "automate.annex.Unclogging.param3": "Start a new cycle",
    "automate.annex.Unclogging.param4": "Unclogging pump parameters",
    "automate.annex.Unclogging.param5": "Pump cleaning time",
    "automate.annex.Unclogging.param6": "Unclogging valve parameters",
    "automate.annex.Unclogging.param7": "Valve opening time",
    "automate.annex.Unclogging.param8": "Analysis tank parameters",
    "automate.annex.Unclogging.param9": "Tank opening time",
    "automate.annex.FilteredWater.param1": "Filter bar cleaning timer",
    "automate.annex.FilteredWater.param2": "Next cleaning in",
    "automate.annex.FilteredWater.param3": "Cleaning parameters",
    "automate.annex.FilteredWater.param4": "Step",
    "automate.annex.FilteredWater.param5": "Valve closing time",
    "automate.annex.FilteredWater.param6": "Ultrasound activation time",
    "automate.annex.FilteredWater.param7": "air Valve closing time",
    "automate.annex.FilteredWater.param8": "Equilibrium time",
    "automate.annex.Light.param1": "Indoor spot light",
    "automate.annex.Light.param2": "Interior light RL 215",
    "automate.annex.Light.param3": "Exterior light5",
    "dashboard.chart.param4": "Interventions",
    "dashboard.chart.param5": "Download",
    "dashboard.chart.param6": "Last point",
    "dashboard.chart.param7": "Chart settings",
    "dashboard.chart.param8": "Connect Nulls :",
    "dashboard.chart.param9": "Show yaxis datazoom :",
    "dashboard.chart.param10": "AutoScale :",
    "dashboard.chart.param11": "Toolbox options",
    "dashboard.chart.param12": "Markpoint options",
    "dashboard.chart.param13":
      "NB: numbers here are formatted to maximum 2 digits after decimal point",
    "dashboard.chart.param14": "Boundary gap:",
    "dashboard.chart.param15": "disabled",
    "dashboard.chart.param16":
      "NB: if min and max are set manually this field will be ignored.",
    "dashboard.chart.param17": "NB: this value is converted to %.",
    "dashboard.chart.param18": "Max value:",
    "dashboard.chart.param19": "manual:",
    "dashboard.chart.param20": "auto",
    "dashboard.chart.param21": "Min value:",
    "dashboard.chart.param22": "manual",
    "dashboard.chart.param23":
      " NB: scatter and bar options may not be ideal for large data windows.Please choose smaller ones to see the result.",
    "dashboard.chart.param24": "Close",
    "dashboard.chart.param25": "Relative dates",
    "dashboard.chart.param26": "Yesterday",
    "dashboard.chart.param27": "Today",
    "dashboard.chart.param28": "Relative periods",
    "dashboard.chart.param29": "this week",
    "dashboard.chart.param30": "last week",
    "dashboard.chart.param31": "this month",
    "dashboard.chart.param32": "last month",
    "dashboard.chart.param33": "This year",
    "dashboard.chart.param34": "Last year",
    "dashboard.chart.param35": "Customize the period",
    "dashboard.chart.param36": "All data",
    "dashboard.chart.param37": "Live",
    "dashboard.chart.param38": "show more",
    "dashboard.chart.param39": "show less",
    "dashboard.chart.param40": "Valid series",
    "dashboard.chart.param41": "Raw series",
    "dashboard.chart.param42": "Invalid series",
    "dashboard.chart.param43": "Please wait 🧐",
    "dashboard.chart.param44": "Data loading...",
    "dashboard.chart.param45": "LIVE",
    "dashboard.chart.param46": " at ",
    "dashboard.chart.param47": "No data found😔",
    "dashboard.annex.param1": "Hey {name}, what's new at {riverlab} ? 🤓",
    "dashboard.annex.param3": "See on chart",
    "dashboard.annex.param4": "Create a post",
    "dashboard.annex.param5": "Report",
    "dashboard.annex.param7": "Delete this post",
    "dashboard.annex.param8": "See on chart",
    "dashboard.annex.param9": "Sorry, no standards found for this calibration ",
    "dashboard.annex.param10": "Standards",
    "dashboard.annex.param11": "Sorry, no blanks found for this calibration ",
    "dashboard.annedx.param12": "Created the ",
    "dashboard.annex.param13": " New calibration no.",
    "dashboard.annex.param14": "Sorry, no posts found 🤓",
    "dashboard.annex.param15": "you have to select at least one variable",
    "dashboard.annex.param16": "Data type",
    "dashboard.annex.param17": "Post deleted successfully 🥳",
    "dashboard.annex.param18": "Image uploaded successfully 🥳 !",
    "dashboard.annex.param19":
      "Could not get data from DataBase 😔, Please reload or contact tech team!",
    "dashboard.annex.param20":
      "Could not get posts 😔, Please reload or contact tech team!",
    "dashboard.annex.param21":
      "Could not delete post 😔, Please contact tech team!",
    "dashboard.annex.param22":
      "Could not upload image 😔, try again or contact tech team !",
    "dashboard.annex.param23": "Image uploaded successfully 🥳 !",
    "dashboard.annex.param24": "Post added successfully 🥳",
    "dashboard.annex.param25":
      "Could not add post 😔, Please contact tech team !",
    "dashboard.annex.param26":
      "Could not get attachement of post_id {post_id}😔, Please contact tech team to fix the problem !",
    "protocol.firstView.param1": "Protocol",
    "protocol.firstView.param3": "New protocol",
    "protocol.firstView.param4": "Create a new protocol",
    "protocol.firstView.param5": "Copy an existing protocol",
    "protocol.firstView.param6": "Live Protocol",
    "protocol.firstView.param7": "Active protocol",
    "protocol.firstView.param8": "Inactive protocol",
    "protocol.firstView.param9": "Created by",
    "protocol.firstView.param10": "Applied from",
    "protocol.firstView.param11": "to",
    "protocol.firstView.param13": "Protocol at ",
    "protocol.firstView.param14": "Apply the protocol",
    "protocol.firstView.param16": "About this protocol",
    "protocol.firstView.param17": "Name",
    "protocol.firstView.param18": "observatory",
    "protocol.firstView.param19": "start date",
    "protocol.firstView.param20": "end date",
    "protocol.firstView.param21": "Find a protocol",
    "protocol.firstView.param22": "Why are you creating this new protocol? 🤩",
    "protocol.firstView.param23": "Title of the new protocol",
    "protocol.firstView.param24": "Overview",
    "protocol.firstView.param25": "View by variable",
    "protocol.SecondView.param17":
      "Create rules to build up your protocol bit by bit",
    "protocol.SecondView.param18": "Create your first rule",
    "protocol.SecondView.param19": "New rule",
    "protocol.SecondView.param20": "Create a rule",
    "protocol.SecondView.param21": "Apply to data",
    annuler: "cancel",
    "Select Riverlab": "Select {riverlab} Riverlab",
    "You cannot acceed to the Protocol panel for the Riverlab of the metric you have selected, select a Riverlab chip to acceed to its Protocol panel.":
      "You cannot acceed to the Protocol panel for the {br} Riverlab of the metric you have selected, select a {br} {riverlab} chip to acceed to its Protocol panel.",
    "You cannot acceed to the Monitoring panel for the Riverlab of the metric you have selected, select a Riverlab chip to acceed to its Monitoring panel.":
      "You cannot acceed to the Monitoring panel for the {br} Riverlab of the metric you have selected, select a {br} {riverlab} chip to acceed to its Monitoring panel.",
    "protocol.SecondView.param23": "Save",
    "protocol.SecondView.param24": "A protocol is made up of several rules",
    "protocol.SecondView.param25": "Modify the rule",
    "protocol.SecondView.param26": "Rule",
    "protocol.SecondView.param27": "Instruction",
    "protocol.SecondView.param28":
      "To create your rules, select parameters, then create your instructions!",
    "protocol.SecondView.param29": "Choose a laboratory",
    "protocol.SecondView.param30": "Parameters to filter",
    "protocol.SecondView.param31": "PHYSICO-CHEMICAL PARAMETERS",
    "protocol.SecondView.param32": "TECHNICAL PARAMETERS",
    "protocol.SecondView.param33": "Instructions",
    "protocol.SecondView.param34": "Add instruction",
    "protocol.SecondView.param35": "Download in csv",
    "protocol.SecondView.param36": "Download your data from",
    "protocol.SecondView.param37": "Choose a Cadence",
    "protocol.SecondView.param38": "Choose variables",
    "protocol.SecondView.param39": "Choose an interval",
    "protocol.SecondView.param40": "Filter",
    "protocol.SecondView.param41":
      "Start the data filtration process by clicking on the button above to be able to download it!",
    "protocol.SecondView.param42":
      "Please note that the process may take a few seconds",
    "protocol.SecondView.param43":
      "Your filtered data is ready to be downloaded",
    "protocol.SecondView.param44": "Changes have been saved!",
    "protocol.SeconView.param45": "All protocols",
    "protocol.SecondView.param46":
      "Current modifications will be deleted, this action is irreversible.",
    "protocol.SecondView.param47": "Continue",
    "protocol.SecondView.param48": "Cancel",
    "extraplot.param1": "No graphs found😔",
    "extraplot.param2": "Please add new one using the top button!",
    "extraplot.param3": "Add new chart",
    "extraplot.param4":
      "Are you sure you want to permanently delete this graph",
    "extraplot.param5": "Delete",
    "extraplot.param6": "Cancel",
    "extraplot.annex.param1": "Graph Config",
    "extraplot.annex.param2": "Data couples",
    "extraplot.annex.param3": "new couple",
    "extraplot.annex.param4": "Choose your chart data",
    "extraplot.annex.param5": "By default time is chosen as",
    "extraplot.annex.param6": "for",
    "extraplot.annex.param7": "Please choose your",
    "extraplot.annex.param8": "variable to add it on chart",
    "extraplot.annex.param9": "close",
    "extraplot.annex.param10": "Title options",
    "extraplot.annex.param11": "Title text",
    "extraplot.annex.param12": "Subtitle text",
    "extraplot.annex.param13": "Legend config",
    "extraplot.annex.param14": "Show",
    "extraplot.annex.param15": "Vertical alignement :",
    "extraplot.annex.param16": "Horizental alignement :",
    "extraplot.annex.param17": "General config",
    "extraplot.annex.param18": "Theme config",
    "extraplot.annex.param19": "Light themes:",
    "extraplot.annex.param20": "Dark themes:",
    "extraplot.annex.param21": "Grid config",
    "extraplot.annex.param22": "Left space : ",
    "extraplot.annex.param23": "Right space : ",
    "extraplot.annex.param24": "Top space : ",
    "extraplot.annex.param25": "Bottom space : ",
    "extraplot.annex.param26": "Back to dashboard",
    "extraplot.annex.param27": "Save graph",
    "extraplot.annex.param28": "Created on :",
    "extraplot.annex.param29": "chart title",
    "extraplot.annex.param30": "chart subtitle",
    "extraplot.annex.param31": "top",
    "extraplot.annex.param32": "middle",
    "extraplot.annex.param33": "bottom",
    "extraplot.annex.param34": "left",
    "extraplot.annex.param35": "center",
    "extraplot.annex.param36": "right",
    "extraplot.annex.param37": "Failed to save updates",
    "extraplot.annex.param38":
      "New graph is added please go back to extraplot dashboard to find it!",
    "extraplot.annex.param39": "Saved graph updates",
    "extraplot.annex.param40": "Could not delete graph please try again !",
    "extraplot.annex.param41": "Graph was deleted",
    "cardssection.param1": "Live",
    //cations
    Lithium: "Lithium",
    Sodium: "Sodium",
    Potassium: "Potassium",
    Magnesium: "Magnesium",
    Calcium: "Calcium",
    Strontium: "Strontium",
    Ammonium: "Ammonium",
    Silicium: "Silicium",
    //Anions
    Fluorures: "Fluorides",
    Chlorures: "Chlorides",
    Bromures: "Bromides",
    Sulfates: "Sulfates",
    Phosphates: "Phosphates",
    Nitrates: "Nitrates",
    Nitrites: "Nitrites",
    //pc parameters
    "Temperature Bac Noir": "Water Tank Temperature",
    pH: "pH",
    Conductivite: "Conductivity",
    Turbidite: "Turbidity",
    O2: "O2",
    "Niveau Riviere": "River level",
    COD: "COD",
    COT: "COT",
    Silice: "Silica",
    Durete: "Water hardness",
    "Conductivite EF": "conductivity FW",
    "O2 EF": "O2 FW",
    "pH EF": "pH FW",
    //T parameters
    "Pression Pompe": "Pump Pressure",
    "Consigne Pression Pompe": "Pump Pressure Setpoint",
    "Pression EF": "FW Pressure",
    "Debit Pompe": "Pump Flow",
    "Consigne Debit Pompe": "Pump Flow Setpoint",
    "Debit EF": "FW Flow",
    "Debit Riviere": "Discharge",
    "Vitesse Moteur": "Engine Speed",
    "Temperature Exterieure": "Outside temperature",
    "Temperature Interieure": "Indoor Temperature",
    "Ouverture Vanne": "Valve opening",
    "Consigne Ouverture Vanne": "Valve opening instruction",
    "Prochain Nettoyage": "Next Cleaning",
    "Fermeture Vanne": "Valve closing",
    "Pression Circuit 1": "Circuit Pressure 1",
    "Pression Circuit 2": "Circuit Pressure 2",
    "Pression Circuit 3": "Circuit Pressure 3",
    "Pression Circuit 4": "Circuit Pressure 4",
    "Pression Circuit 5": "Circuit Pressure 5",
    "Volume Eau 1": "Volume Water 1",
    "Volume Eau 2": "Volume Water 2",
    "Volume Eau 3": "Volume Water 3",
    "Niveau Cuve 1": "Tank level 1",
    "Niveau Cuve 2": "Tank level 2",
    "Niveau Cuve 3": "Tank level 3",
    niveau_cuve_3: "niveau_cuve_3",
    "Chlore Redox": "Chlore Redox",
    "Température Eau": "Water Temperature",
    "Conductivité (actuelle)": "Conductivity (current)",
    "Conductivité (spécifique)": "Conductivity (specific)",

    "O2 dissous": "Dissolved O2",
    "Saturation O2": "Saturation O2",
    "Pression Air": "Air pressure",
    Densité: "Density",
    "Niveau Rivière": "River Level",
    "Potentiel Rédox": "Redox potential",
    PaO2: "PaO2",
    "Pression Eau": "Water pressure",
    "Total Solides Dissous": "Total Dissolved Solids",
    Turbidité: "Turbidity",
    "Niveau Batterie": "Niveau Batterie",
    "Voltage Externe": "External Voltage",
    "Debit Eau Brute": "Raw Water Flow",
    "Température Bac Noir": "Black Bin Temperature",
    Conductivité: "Conductivity",
    "Pression Eau Filtrée": "Filtered Water Pressure",
    "Débit Eau Filtrée": "Filtered Water Flow",
    reset: "reset",
    réinitialiser: "reset",
    MasCamargues: "MasCamargues",
    "Pression avant filtres": "Pressure before filters",
    "Pression entre filtres": "Pressure between filters",
    "Pression après filtres": "Pressure after filters",
    "Pression avant charbon actif": "Pressure before activated carbon",
    "Pression alimentation": "Supply pressure",
    "Eau alimentation": "water supply",
    "Eau relevage": "Water lift",
    "Eau adoucie": "Soft water",
    "Eau alimentation inst.": "Water supply inst.",
    "Eau relevage inst.": "Lifted water inst.",
    "Eau adoucie inst.": "Softened water inst.",
    R2Lavage: "R2Lavage",
    "Volume Eau 1 Direct": "Volume Water 1 Direct",
    "Volume Eau 2 Direct": "Volume Water 2 Direct",
    "Volume Eau 3 Direct": "Volume Water 3 Direct",
    RLDemo1: "RLDemo1",
    "Conductivité Eau Filtrée": "Filtered Water Conductivity",
    "Température Extérieure": "Outdoor temperature",

    //Automate
    "Etat Lumiere Exterieure": "Exterior light status",
    "Etat Lumiere Interieure": "Interior Light State",
    "Pompe Marche": "Pump state",
    timedate: "timedate",
    "Vanne Marche": "Valve state",
    "Etat Lumiere RL215": "RL215 light state",
    Fichier: "File",
    "Ajouter des tags": "Add tags",
    "Ajouter des paramétres pour identifier vos publications plus facilement !":
      "Add parameters to identify your posts more easily!",
    Arsenic: "Arsenic",
    Mercure: "Mercury",
    //other
    "paramètres météo": "WEATHER PARAMETERS",
    "plus de filtres": "more filters",
    panne: "breakdown",
    pompe: "pump",
    "eau brut": "raw water",
    "eau filtrée": "filtered water",
    sondes: "probes",
    analyseurs: "analyzers",
    "chromato-ionique": "chromato-ionic",
    "data connexion": "data connexion",
    riviére: "river",
    "raw data": "raw data",
    "validated data": "validated data",
    "Télécharger les données de": "Download data from",
    "get data from DataBase": "get data from DataBase",
    minute: "1 minute",
    "10minutes": "10 minutes",
    hour: "hour",
    day: "day",
    all: "all",
    "Getting data drom database": "Getting data drom database",
    "le processus peut prendre quelques secondes":
      "The process may take a few seconds",
    onduleur: "inverter",
    Onduleur: "inverter",
    "alarme TOC": "TOC Alarm",
    "Alarme TOC": "TOC Alarm",
    "Max Acide": "Max Acid",
    "Max Max Acide": "Max Max Acid",
    "Max Base": "Max Basic",
    "Max Max Base": "Max Max Basic",
    "Cde M/A Durete": "Cde M/A Alcalinity",
    RESET: "RESET",
    Orgeval: "Orgeval",
    Naizin: "Naizin",
    Aubure: "Aubure",
    Monticello: "Monticello",
    paramétres: "parameters",
    enregistrer: "save",
    Zinc: "Zinc",
    "Ajouter un type pour le post": "Add a type for your post",
    "Ajouter un intervalle de temps pour l'intervention":
      "Add a time interval for your intervention",
    "Ajouter une description": "Add a description for your post",
    De: "From",
    A: "To",
    "Frequence Pompe": "Pump Frequency",
    Gauges: "Gauges",
    "Timer Decolmatage": "Unclogging Timer",
    Français: "French",
    French: "French",
    Anglais: "English",
    Alerts: "Alerts",
    "Create an alert": "Create an alert",
    "new alert": "New alert",
    metric: "Metric",
    "Trigger rules": "TRIGGER RULES",
    rule: "Rule",
    "Value greater than": "Value greater than ...",
    "Value lower than": "Value lower than ...",
    "Value between": "Value between ...",
    setting: "Params",
    Temperature: "Temperature",
    "Choose a condition type": "Choose a condition type",
    units: "units",
    decimals: "decimals",
    and: "and",
    "create the alert": "Create the alert",
    validate: "Validate",
    "add a rule": "add a rule",
    "NOTIFICATION SETTINGS": "NOTIFICATION SETTINGS",
    "notification in news feed": "Notification in news feed",
    "by WhatsApp message or e-mail": "By WhatsApp message or e-mail",
    "add a recipient": "Add a recipient",
    "recipient n°1": "Recipient n°1",
    recipients: "Recipients",
    "select recipients": "Select recipients",
    "select all collaborators": "Select all collaborators",
    "whole team": "Whole {station} team",
    "edit recipients": "Edit recipients",
    "Add recipients": "Add recipients",
    "notify by Whatsapp message": "Notify by Whatsapp message",
    "notify by e-mail": "Notify by e-mail",
    next: "Next",
    "Message d’alerte dans Extralab": "Message d’alerte dans Extralab",
    "message in Extralab Whatsapp": "Message in Extralab Whatsapp",
    edit: "Edit",
    delete: "Delete",
    temperature: "Temperature",
    pressure: "Pressure",
    debit: "Debit",
    "cancel alert creation": "Cancel alert creation",
    "your changes will not be saved.": "Your changes will not be saved.",
    leave: "Leave",
    "alert created!": "Alert created!",
    "your alert has been saved, to modify it, go to “My alerts”.":
      "Your alert has been saved, to modify it, go to “My alerts”.",
    "An alert triggers a message in the News Feed for all collaborators. Each employee can individually silence an alert.":
      "An alert triggers a message in the News Feed for all collaborators. Each employee can individually silence an alert.",
    "Alert message in the Riverlab Whatsapp group":
      "Alert message in the Riverlab Whatsapp group",
    "Send emails to selected recipients": "Send emails to selected recipients",
    "my alerts": "My alerts",
    "current value": "Current value",
    "activate alert": "Activate alert",
    mute: "Mute",
    "Veuillez saisir une valeur numérique.": "Please enter a numeric value.",
    "Veuillez saisir une valeur comprise entre 0 et 14.":
      "Please enter a value between 0 and 14.",
    "Veuillez saisir une valeur positive ou nulle.":
      "Please enter a positive or null value.",
    "Un décimal ne peut avoir qu'une valeur positive ou nulle.":
      "A decimal can only have a positive or zero value.",
    "See the details": "See the details",
    Alert: "Alert",
    Today: "Today at ",
    "Active alert": "Active alert",
    "Trigger history": "Trigger history",
    "Delete alert for all collaborators": "Delete alert for all collaborators",
    "Please note that this alert will be deleted for all employees. Triggers will no longer be recorded. Note that an alert can also be silenced.":
      "Please note that this alert will be deleted for all employees. {br} Triggers will no longer be recorded. {br} Note that an alert can also be silenced.",
    "please note that the alert will be activated for all employees.":
      "Please note that the alert will be activated for all employees.",
    activate: "Activate",
    "desactivate the alert": "Desactivate the alert",
    "please note that the alert will be deactivated for all employees. Triggers will no longer be recorded.":
      "Please note that the alert will be deactivated for all employees. {br} Triggers will no longer be recorded.",
    desactivate: "Desactivate",
    NOTIFICATIONS: "NOTIFICATIONS",
    "This alert has not been triggered": "This alert has not been triggered",
    "this user": "This user",
    "You don't have the right to delete users with this role":
      "You don't have the right to delete users with this role",
    "Create your first alert!": "Create your first alert!",
    "Be informed in real time of the evolution of your chronicles. Notify your collaborators by e-mail or Whatsapp.":
      "Be informed in real time of the evolution of your chronicles. {br} Notify your collaborators by e-mail or Whatsapp.",
    "Team management": "Team management",
    Team: "Team",
    Station: "Station",
    "Select a team": "Select a team",
    "Activez et désactivez l’alerte pour l’ensemble des collaborateurs. Vous pouvez aussi mettre l’alerte en silence afin de couper vos notifications.":
      "Activate and deactivate the alert for all employees. You can also mute the alert to mute your notifications.",
    battery: "battery",
    chlore: "chlore",
    "Alert threshold at": "Alert threshold at {valeur} {unité}.",
    "Index UV": "Index UV",
    Salinité: "Salinity",
    Resistivité: "Resistivity",
    "Température Air": "Air temperature",
    Guilin: "Guilin",
    MasCamarguesVortex: "MasCamarguesVortex",
    Visibilité: "Visibility",
    "Coucher du soleil": "Sunset",
    "Lever du soleil": "Sunrise",
    "Vitesse du vent": "Wind speed",
    "Vitesse rafale": "Burst speed",
    "Direction vent": "Wind direction",
    "Temp. ressentie": "Temp. felt",
    "Point de rosée": "dew point",
    Humidité: "Humidity",
    "Vitesse de Surface": "Surface speed",
    "Température Intérieure": "Interieure temperature",
    "pH Eau Filtrée": "pH filtred water",
    "O2 dissous Eau Filtrée": "Dissolved O2 Filtered Water",
    "Dureté/Alcalinité": "Dureté/Alcalinité",
    "Air pressure": "Air pressure",
    "Valve position": "Valve position",
    "Room temperature": "Room temperature",
    "Pump engine speed": "Pump engine speed",
    "Filtered water flow": "Filtered water flow",
    "Water flow": "Water flow",
    "Water pressure": "Water pressure",
    "Dissolved O2": "Dissolved O2",
    Conductivity: "Conductivity",
    "Water temperature": "Water temperature",

    "Commande Analyseur Dureté": "Commande analyzer Durete",
    "Niveau 2 Bac Base": "Level 2 Basic Bac",
    "Niveau 1 Bac Base": "Level 1 Basic Bac",
    "Niveau 1 Bac Acide": "Level 1 Acid Bac",
    "Niveau 2 Bac Acide": "Level 2 Acid Bac",
    "Alarme Bac COT": "Alarm Bac COT",
    "Statut de l'onduleur": "Inverter status",
    "Fréquence du Moteur": "Motor Frequency",
    Intervention: "Intervention",
    Report: "Report",
    signalement: "signalement",
    English: "English",
    "Publish the post": "Publish the post",
  },
};
