import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import cloneDeep from "lodash.clonedeep";
import ConfigTab from "../ConfigTab/ConfigTab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "./../Accordion/Accordion";

export default function MarkpointConfig({
  graphType,
  serieid,
  markpointConfig,
  setOption,
}) {
  const [expanded, setExpanded] = useState("");

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function handleUpdate(id, newValue, name) {
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.option.series.map((serie) => {
        if (serie.id === serieid) {
          serie.markPoint.data = serie.markPoint.data.map((markpoint) => {
            if (markpoint.id === id) {
              markpoint[name] = newValue;
            }
            return markpoint;
          });
        }
        return serie;
      });
      return newOption;
    });
  }

  return (
    <Accordion
      expanded={expanded === "markpoint"}
      onChange={handleChangeAccordion("markpoint")}
      backgroundColor="white"
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="flex items-center">
          <p className="text-primary font-medium">
            MarkPoint: {markpointConfig.type}
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <div className="mb-2">
            <p className="font-medium text-xs">Type:</p>
            <ConfigTab
              items={["min", "max", "average", "custom"]}
              color="primary"
              name="type"
              selectedValue={markpointConfig.type}
              handleOnClick={(ev) => {
                handleUpdate(
                  markpointConfig.id,
                  ev.target.value,
                  ev.target.name
                );
              }}
            />
          </div>
          {markpointConfig.type === "custom" && (
            <div className="border-1 border-primary border-opacity-20 px-2 rounded-8px">
              <p className="font-medium text-xs text-grey-lighter my-1">
                Custom config :
              </p>
              <div className="mb-2">
                <p className="font-medium text-xs">X coord (pix/%) :</p>
                <TextField
                  onChange={(ev) => {
                    handleUpdate(
                      markpointConfig.id,
                      ev.target.value,
                      ev.target.name
                    );
                  }}
                  name="x"
                  value={markpointConfig.x}
                  className="w-full"
                  id="standard-basic"
                  variant="outlined"
                  placeholder="x(pix/%)"
                />
              </div>
              <div className="mb-2">
                <p className="font-medium text-xs">Y coord (pix/%) :</p>
                <TextField
                  onChange={(ev) => {
                    handleUpdate(
                      markpointConfig.id,
                      ev.target.value,
                      ev.target.name
                    );
                  }}
                  name="y"
                  value={markpointConfig.y}
                  className="w-full"
                  id="standard-basic"
                  variant="outlined"
                  placeholder="y(pix/%)"
                />
              </div>
              <div className="mb-2">
                <p className="font-medium text-xs">value :</p>
                <TextField
                  onChange={(ev) => {
                    handleUpdate(
                      markpointConfig.id,
                      ev.target.value,
                      ev.target.name
                    );
                  }}
                  name="value"
                  value={markpointConfig.value}
                  className="w-full"
                  id="standard-basic"
                  variant="outlined"
                  placeholder="value"
                />
              </div>
            </div>
          )}
          <div className="mb-2">
            <p className="font-medium text-xs">Name :</p>
            <TextField
              onChange={(ev) => {
                handleUpdate(
                  markpointConfig.id,
                  ev.target.value,
                  ev.target.name
                );
              }}
              name="name"
              value={markpointConfig.name}
              className="w-full"
              id="standard-basic"
              variant="outlined"
              placeholder="Name"
            />
          </div>
          <div className="mb-2">
            <p className="font-medium text-xs">Symbol:</p>
            <ConfigTab
              items={["pin", "circle", "rect", "roundRect"]}
              color="primary"
              name="symbol"
              selectedValue={markpointConfig.symbol}
              handleOnClick={(ev) => {
                handleUpdate(
                  markpointConfig.id,
                  ev.target.value,
                  ev.target.name
                );
              }}
            />
            <ConfigTab
              items={["triangle", "diamond", "arrow", "none"]}
              color="primary"
              name="symbol"
              selectedValue={markpointConfig.symbol}
              handleOnClick={(ev) => {
                handleUpdate(
                  markpointConfig.id,
                  ev.target.value,
                  ev.target.name
                );
              }}
            />
          </div>
          <div className="mb-2">
            <p className="font-medium text-xs">Symbol size :</p>
            <TextField
              onChange={(ev) => {
                handleUpdate(
                  markpointConfig.id,
                  ev.target.value,
                  ev.target.name
                );
              }}
              name="symbolSize"
              value={markpointConfig.symbolSize}
              className="w-full"
              id="standard-basic"
              variant="outlined"
              placeholder="Symbol size"
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
