import React, { useState } from "react";
import cloneDeep from "lodash.clonedeep";
import { ReactComponent as AddIcon } from "../../../../icons/add.svg";
import MarkLineConfig from "./MarkLineConfig";
import { ReactComponent as MarklineIcon } from "../../../../icons/dashed-arrow.svg";
import { SkeletonGreybgButton } from "../../../../Shared/Butttons";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "./../Accordion/Accordion";
import { DEFAULT_MARKLINE_CONFIG } from "../../Const";

export default function MarkLineSection({ seriesConfig, setOption }) {
  const [expandedMarkLine, setExpandedMarkLine] = useState("");

  const handleChangeAccordionMarkLine = (panel) => (event, newExpanded) => {
    setExpandedMarkLine(newExpanded ? panel : false);
  };

  const markLinesList = seriesConfig.markLine.data.map((markLine) => {
    return (
      <MarkLineConfig
        key={markLine.id}
        markLineConfig={markLine}
        serieid={seriesConfig.id}
        setOption={setOption}
      />
    );
  });

  function handleAddNewMarkLine(serieid) {
    const id = Math.floor(Math.random() * 10000);
    setOption((prev) => {
      const newOption = cloneDeep(prev);
      newOption.option.series.map((serie) => {
        if (serie.id === serieid) {
          serie.markLine.data = [
            ...serie.markLine.data,
            { ...DEFAULT_MARKLINE_CONFIG, id: id },
          ];
        }
        return serie;
      });
      return newOption;
    });
  }

  return (
    <Accordion
      expanded={expandedMarkLine === "markLine"}
      onChange={handleChangeAccordionMarkLine("markLine")}
      backgroundColor="rgba(142, 155, 187, 0.08)"
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="flex items-center">
          <p className="text-primary font-medium flex gap-2">
            <MarklineIcon className="h-5 rotate-90 text-primary" />
            MarkLines
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {markLinesList}
        <SkeletonGreybgButton
          id="close"
          handleOnClick={() => {
            handleAddNewMarkLine(seriesConfig.id);
          }}
          selected={false}
        >
          <div className="flex gap-2 justify-center items-center">
            <AddIcon className="h-3 w-3" />
            new MarkLine
          </div>
        </SkeletonGreybgButton>
      </AccordionDetails>
    </Accordion>
  );
}
