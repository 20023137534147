import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as AddIcon } from "../../../icons/add.svg";
import { ReactComponent as CopyIcon } from "../../../icons/copy.svg";
import { blankProtocol } from "../Const";

export default function NewProtocolButton({
  setfirstView,
  setSelectedProtocol,
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button
        className="flex w-full  h-9.25 bg-primary rounded-8px text-white text-sm items-center justify-center shadow-blue-25 mt-4 focus:outline-none"
        onClick={() => {
          setIsOpen(!isOpen);
          setSelectedProtocol(blankProtocol);
        }}
      >
        <AddIcon className="mr-3"></AddIcon>
        <FormattedMessage id="protocol.firstView.param3" />
      </button>
      {isOpen === true && (
        <div className="bg-white rounded-8px p-2 shadow-blue-8">
          <button
            className="flex items-center justify-center hover:bg-light-bg cursor-pointer"
            onClick={() => {
              setfirstView(false);
            }}
          >
            <AddIcon className="text-secondary mr-5"></AddIcon>
            <p className="text-dark-text text-sm font-normal hover:font-medium h-8 flex items-center justify-center">
              <FormattedMessage id="protocol.firstView.param4" />
            </p>
          </button>
          <button
            className="flex items-center justify-center focus:bg-light-bg cursor-pointer"
            disabled
          >
            <CopyIcon className="text-grey-text mr-5"></CopyIcon>
            <p className="text-dark-text text-sm font-normal hover:font-medium h-8 flex items-center justify-center">
              <FormattedMessage id="protocol.firstView.param5" />
            </p>
          </button>
        </div>
      )}
    </div>
  );
}
