import withStyles from "@mui/styles/withStyles";
import TextField from "@mui/material/TextField";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@mui/styles/makeStyles";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

//select with input adornment
export const CssTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      height: "100%",
      width: "100%",
      fontSize: "12px",
      fontWeight: 500,
      backgroundColor: "#FFFF",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "#FFFF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2C5AFF",
    },
    "& .Mui-disabled": {
      color: "rgba(44, 90, 255, 0.15)",
    },
    "& .MuiOutlinedInput-adornedStart": {
      padding: "0px 0px 0px 6px",
    },

    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(44, 90, 255, 0.15)",
    },
    "& .MuiOutlinedInput-input": {
      height: "100%",
      alignItems: "center",
      display: "flex",
      padding: "0px 30px 0px 10px",
      "&:hover": {
        backgroundColor: "#FFFF",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "8px",
        borderColor: "rgba(44, 90, 255, 0.15)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(44, 90, 255, 0.15)",
      },
      "&:hover:not(.Mui-disabled) fieldset": {
        borderColor: "#2C5AFF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2C5AFF",
      },
    },
  },
})(TextField);

export const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&.Mui-selected": {
      backgroundColor: "#2C5AFF",
    },
  },
}))(MenuItem);

//classes for embedded textfield select menu
export const customMenuUseStyles = makeStyles((theme) => ({
  menu: {
    boxShadow: "none",
    "& .MuiListItem-root.Mui-selected": {
      backgroundColor: "rgba(44, 90, 255, 0.2)",
    },
    "& .MuiListItem-root.Mui-selected:hover": {
      backgroundColor: "rgba(44, 90, 255, 0.2)",
    },
    "& .MuiListItem-button:hover": {
      backgroundColor: "rgba(44, 90, 255, 0.1)",
    },
  },
  paper: {
    "& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper":
      {
        boxShadow: "0px 4px 24px 0px rgba(0, 48, 79, 0.1)",
        borderRadius: "8px",
      },
  },
}));

export const SimpleMenuStyles = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 8,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid rgba(44, 90, 255, 0.15)",
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Arial",
      "Helvetica",
      "Satoshi",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderColor: "#2C5AFF",
    },
  },
}));
