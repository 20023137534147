import styled from "styled-components";
import { Col } from "react-flexbox-grid";
export const BlackBoldText = styled.p`
  font-weight: 700;
  font-size: 20px;
  color: #0d1441;
  font-family: Satoshi;
`;

export const GreyText = styled.p`
  font-weight: 400;
  font-size: 14px;
  margin-left: 10px;
  color: #8488a5;
  font-family: Roboto;
`;

export const SmallGreyText = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: #8488a5;
`;

export const DivWithMargin = styled.div`
  margin-bottom: 15px;
`;

export const ColWithPadding = styled(Col)`
  padding: 100px 40%;
`;

export const StyledImage = styled.img`
  width: calc(100vw - 64px);
`;

export const GridWithPaddingTop = styled.div`
  padding-top: 20px;
`;
