import { useState, useEffect} from "react";
import axios from "axios";

export function useSearchParameters (selectedObs) {
    const [anions, setAnions] = useState(null);
    const [cations, setCations] = useState(null);
    const [physico_chemicalParams, setPhysico_chemicalParams] = useState(null);
    const [technicalParams, setTechnicalParams] = useState(null);
    const [meteoParams, setMeteoParams] = useState(null);

    useEffect(() => {
      if(selectedObs){
        axios
        .get(process.env.REACT_APP_API_URL + `/observatories/${selectedObs}/elements`)
        .then((res) => {
          //console.log("anions : ", res.data.anions);
          setAnions(res.data.anions);
          setCations(res.data.cations);
          setPhysico_chemicalParams(res.data.parametres_physico_chimiques);
          setTechnicalParams(res.data.parametres_techniques);
          setMeteoParams(res.data.parametres_meteo);
        })
        .catch((err) => {
          console.log(err);
        });
      }
    }, [selectedObs])

    return [anions,cations, physico_chemicalParams, technicalParams, meteoParams]



}