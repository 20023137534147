import TextField from "@mui/material/TextField";
import withStyles from "@mui/styles/withStyles";
import { createImageFromInitials } from "../../components/utils";
import { getRandomColor } from "../../components/utils";

export const createPicture = (name, height, width) => {
  let imgSrc = "";
  return (
    <div>
      <img
        id="preview"
        src={
          imgSrc.length <= 0
            ? createImageFromInitials(500, name, getRandomColor(name))
            : imgSrc
        }
        alt="profile-pic"
        style={{
          borderRadius: height,
          width: width,
          height: height,
        }}
      />
    </div>
  );
};

export const StyledTextField = withStyles({
  root: {
    "& ::placeholder": {
      color: "#8488A5",
      opacity: 0.5 /* Firefox */,
      size: "14px",
      fontFamily: "Roboto",
    },
    "& .MuiInputBase-root": {
      height: "46px",
      width: "308px",
    },
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderColor: "#F5F5F5",
      backgroundColor: "#FAFBFF",
      "& fieldset": {
        borderColor: "#F5F5F5",
      },
      "&:hover fieldset": {
        borderColor: "#2C5AFF",
      },
      "&.Mui-focused": {
        backgroundColor: "#EBECF3",
      },
    },
  },
})(TextField);
