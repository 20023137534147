const theme6 = {
    "version": 1,
    "themeName": "essos",
    "theme": {
        "seriesCnt": "4",
        "backgroundColor": "rgba(242,234,191,0.15)",
        "titleColor": "#893448",
        "subtitleColor": "#d95850",
        "textColorShow": false,
        "textColor": "#333",
        "markTextColor": "#ffffff",
        "color": [
            "#893448",
            "#d95850",
            "#eb8146",
            "#ffb248",
            "#f2d643",
            "#ebdba4"
        ],
        "borderColor": "#ccc",
        "borderWidth": 0,
        "visualMapColor": [
            "#893448",
            "#d95850",
            "#eb8146",
            "#ffb248",
            "#f2d643",
            "rgb(247,238,173)"
        ],
        "legendTextColor": "#999999",
        "kColor": "#eb8146",
        "kColor0": "transparent",
        "kBorderColor": "#d95850",
        "kBorderColor0": "#58c470",
        "kBorderWidth": "2",
        "lineWidth": "2",
        "symbolSize": "6",
        "symbol": "emptyCircle",
        "symbolBorderWidth": "2",
        "lineSmooth": true,
        "graphLineWidth": 1,
        "graphLineColor": "#aaaaaa",
        "mapLabelColor": "#893448",
        "mapLabelColorE": "#893448",
        "mapBorderColor": "#999999",
        "mapBorderColorE": "#eb8146",
        "mapBorderWidth": 0.5,
        "mapBorderWidthE": 1,
        "mapAreaColor": "#f3f3f3",
        "mapAreaColorE": "#ffb248",
        "axes": [
            {
                "type": "all",
                "name": "通用坐标轴",
                "axisLineShow": true,
                "axisLineColor": "#aaaaaa",
                "axisTickShow": false,
                "axisTickColor": "#333",
                "axisLabelShow": true,
                "axisLabelColor": "#999999",
                "splitLineShow": true,
                "splitLineColor": [
                    "#e6e6e6"
                ],
                "splitAreaShow": false,
                "splitAreaColor": [
                    "rgba(250,250,250,0.05)",
                    "rgba(200,200,200,0.02)"
                ]
            },
            {
                "type": "category",
                "name": "类目坐标轴",
                "axisLineShow": true,
                "axisLineColor": "#333",
                "axisTickShow": true,
                "axisTickColor": "#333",
                "axisLabelShow": true,
                "axisLabelColor": "#333",
                "splitLineShow": false,
                "splitLineColor": [
                    "#ccc"
                ],
                "splitAreaShow": false,
                "splitAreaColor": [
                    "rgba(250,250,250,0.3)",
                    "rgba(200,200,200,0.3)"
                ]
            },
            {
                "type": "value",
                "name": "数值坐标轴",
                "axisLineShow": true,
                "axisLineColor": "#333",
                "axisTickShow": true,
                "axisTickColor": "#333",
                "axisLabelShow": true,
                "axisLabelColor": "#333",
                "splitLineShow": true,
                "splitLineColor": [
                    "#ccc"
                ],
                "splitAreaShow": false,
                "splitAreaColor": [
                    "rgba(250,250,250,0.3)",
                    "rgba(200,200,200,0.3)"
                ]
            },
            {
                "type": "log",
                "name": "对数坐标轴",
                "axisLineShow": true,
                "axisLineColor": "#333",
                "axisTickShow": true,
                "axisTickColor": "#333",
                "axisLabelShow": true,
                "axisLabelColor": "#333",
                "splitLineShow": true,
                "splitLineColor": [
                    "#ccc"
                ],
                "splitAreaShow": false,
                "splitAreaColor": [
                    "rgba(250,250,250,0.3)",
                    "rgba(200,200,200,0.3)"
                ]
            },
            {
                "type": "time",
                "name": "时间坐标轴",
                "axisLineShow": true,
                "axisLineColor": "#333",
                "axisTickShow": true,
                "axisTickColor": "#333",
                "axisLabelShow": true,
                "axisLabelColor": "#333",
                "splitLineShow": true,
                "splitLineColor": [
                    "#ccc"
                ],
                "splitAreaShow": false,
                "splitAreaColor": [
                    "rgba(250,250,250,0.3)",
                    "rgba(200,200,200,0.3)"
                ]
            }
        ],
        "axisSeperateSetting": false,
        "toolboxColor": "#999999",
        "toolboxEmphasisColor": "#666666",
        "tooltipAxisColor": "#cccccc",
        "tooltipAxisWidth": 1,
        "timelineLineColor": "#893448",
        "timelineLineWidth": 1,
        "timelineItemColor": "#893448",
        "timelineItemColorE": "#ffb248",
        "timelineCheckColor": "#eb8146",
        "timelineCheckBorderColor": "#ffb248",
        "timelineItemBorderWidth": 1,
        "timelineControlColor": "#893448",
        "timelineControlBorderColor": "#893448",
        "timelineControlBorderWidth": 0.5,
        "timelineLabelColor": "#893448",
        "datazoomBackgroundColor": "rgba(255,255,255,0)",
        "datazoomDataColor": "rgba(255,178,72,0.5)",
        "datazoomFillColor": "rgba(255,178,72,0.15)",
        "datazoomHandleColor": "#ffb248",
        "datazoomHandleWidth": "100",
        "datazoomLabelColor": "#333"
    }
}

export default theme6