import ReactECharts from "echarts-for-react";

export default function ChartSection({ option, echartRef }) {
  return (
    <div className="h-full w-full flex items-center justify-center">
      <ChartComponent option={option} echartRef={echartRef} />
    </div>
  );
}
function ChartComponent({ option, echartRef }) {
  return (
    <ReactECharts
      ref={echartRef}
      option={option.option}
      style={{ height: "100%", width: "100%" }}
      notMerge={option.notMerge}
      theme={option.theme}
    />
  );
}
