export default function ConfigTab({
  items,
  color,
  name,
  selectedValue,
  handleOnClick,
}) {
  return (
    <div
      className={`inline-flex gap-1 justify-center border-1 border-${color} rounded-8px p-1`}
    >
      {items.map((item) => {
        return (
          <button
            key={item}
            name={name}
            value={item}
            className={`${
              selectedValue === item
                ? "bg-primary text-white"
                : "text-primary bg-white"
            } font-medium py-1 px-2 rounded-8px focus:outline-none`}
            onClick={handleOnClick}
          >
            {item}
          </button>
        );
      })}
    </div>
  );
}
