import { addHours, format } from "date-fns";

export const formateDate = (date) => {
  return date.toISOString().replace("T", " ").split(".")[0];
};

export const applyUTCtoDate = (date, utcType) => {
  if (!utcType) return date;
  const formattedDated = new Date(date);
  const hoursToAdd = parseInt(utcType.replace("UTC", ""));
  return format(addHours(formattedDated, hoursToAdd), "yyyy-MM-dd HH:mm:ss");
};
