import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
  BlackBoldText,
  MediumText,
  GreyText,
  BlueButton,
  CenterColumn,
  StyledImage,
} from "./Contact.style";
import { Row, Col } from "react-flexbox-grid";
import { Link } from "react-router-dom";

const Contact = () => {
  const isMobile = useSelector((state) => state.isMobile);
  const intl = useIntl();
  const ButtonMailto = ({ mailto, label }) => {
    return (
      <BlueButton>
        <Link
          to="#"
          onClick={(e) => {
            window.location.href = mailto;
            e.preventDefault();
          }}
        >
          {label}
        </Link>
      </BlueButton>
    );
  };
  return (
    <div
      className={`${!isMobile && "ml-16"} bg-white h-full w-full pb-2`}
      style={{ paddingTop: "16px" }}
    >
      <div className="flex justify-between border-b-1 border-primary border-opacity-20 p-2">
        <p
          style={{
            fontFamily: "Satoshi",
            fontWeight: 700,
            fontSize: "20px",
            color: "#0D1441",
          }}
        >
          <FormattedMessage id="Contact" />
        </p>
      </div>
      <div className="overflow-hidden w-full ">
        <Row
          className="flex justify-between"
          style={{ marginRight: "8px", flexWrap: "nowrap" }}
        >
          <CenterColumn>
            <BlackBoldText>
              <FormattedMessage id="Do you have a question" />
            </BlackBoldText>
            <MediumText>
              <FormattedMessage id="Contact us by e-mail or phone" />
            </MediumText>
            <GreyText>E-mail</GreyText>
            <MediumText>contact@extralab-system.com</MediumText>
            <GreyText>
              <FormattedMessage id="Phone" />
            </GreyText>
            <MediumText>+33 (6) 37 83 58 43</MediumText>
            <ButtonMailto
              label={intl.formatMessage({ id: "Send e-mail" })}
              mailto="mailto:contact@extralab-system.com"
            />
          </CenterColumn>
          <Col>
            <StyledImage alt="" src="./assets/contact.png" />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Contact;
