import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import StatCardsSection from "../../sharedComponents/StatCardsSection/StatCardsSection";
import withStyles from "@mui/styles/withStyles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import ReactECharts from "echarts-for-react";
import Slider from "@mui/material/Slider";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as LockIcon } from "../../icons/lock-line.svg";
import { ReactComponent as StartStopIcon } from "../../icons/start-stop.svg";
import upSound from "../../sound/up.mp3";
import downSound from "../../sound/down.mp3";
import Tooltip from "@mui/material/Tooltip";
import { useSnackbar } from "notistack";
import useSound from "use-sound";
import axios from "axios";
import { useDebounce } from "../../hooks/debounce-hook";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import cloneDeep from "lodash.clonedeep";
import SwipeableViews from "react-swipeable-views";
import { setSelectedTab } from "../../Redux/actions";
import { Col, Row } from "react-flexbox-grid";
import { capitalizeFirstLetter } from "../../utils/char";
import { StyledImage } from "../Contact/Contact.style";
import { formatRiverlabForBackend } from "../../utils/riverlabs";

const CssTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      height: "37px",
      width: "100%",
    },
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2C5AFF",
    },
    "& .Mui-disabled": {
      color: "rgba(44, 90, 255, 0.15)",
    },
    "& .MuiOutlinedInput-adornedStart": {
      padding: "0px 0px 0px 6px",
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(44, 90, 255, 0.15)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(44, 90, 255, 0.15)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(44, 90, 255, 0.15)",
      },
      "&:hover:not(.Mui-disabled) fieldset": {
        borderColor: "#2C5AFF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2C5AFF",
      },
    },
  },
})(TextField);

const BlueSwitch = withStyles({
  switchBase: {
    color: "rgba(44, 90, 255, 0.5)",
    "&$checked": {
      color: "rgba(44, 90, 255, 1)",
    },
    "&$checked + $track": {
      backgroundColor: "rgba(44, 90, 255, 1)",
    },
  },
  checked: {},
  track: {},
})(Switch);

const CustomSlider = withStyles({
  root: {
    color: "rgba(44, 90, 255, 1)",
    "&.Mui-disabled": {
      color: "rgba(44, 90, 255, 0.15)",
    },
  },
})(Slider);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    color: "#202337",
    boxShadow: "0 12px 44px 0px rgba(44, 90, 255, 0.25)",
    fontSize: 16,
    borderRadius: "8px",
    padding: "15px",
  },
}))(Tooltip);

const DEFAULT_OPTION = {
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data: ["test"],
  },
  toolbox: {
    show: false,
    feature: {
      dataView: { readOnly: false },
      restore: {},
      saveAsImage: {},
    },
  },
  grid: {
    top: 10,
    left: 60,
    right: 30,
    bottom: 30,
  },
  dataZoom: {
    show: false,
    start: 0,
    end: 100,
  },
  xAxis: [
    {
      type: "category",
      boundaryGap: false,
      splitLine: {
        show: true,
      },
      data: [],
      axisLine: { show: true },
      axisPointer: { show: true },
    },
  ],
  yAxis: [
    {
      type: "value",
      scale: true,
    },
  ],
  series: [
    {
      id: "chartLine",
      type: "line",
      data: [],
      smooth: true,
      symbolSize: 10,
      symbol: "circle",
      lineStyle: {
        color: "#3D80FB",
        width: 1,
      },
      itemStyle: {
        color: "#3D80FB",
        borderWidth: 2,
        borderColor: "#FFF",
      },
    },
  ],
};

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #FFFF",
  },
  indicator: {
    backgroundColor: "#FF7715",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    color: "#6C7195",
    fontSize: "0.9rem",
    marginRight: 1,
    "&:hover": {
      color: "#6C7195",
      opacity: 1,
      fontWeight: "400",
    },
    "&$selected": {
      color: "#202337",
      fontWeight: "600",
    },
    "&:focus": {
      color: "#202337",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="h-full"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="h-full">{children}</div>}
    </div>
  );
}

export default function MonitoringPage() {
  const [automatData, setAutomatData] = useState(null);
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);
  const userRoles = useSelector((state) => state.userRoles);
  const user = useSelector((state) => state.user);
  const socket = useSelector((state) => state.socket);
  const registered = useSelector((state) => state.registered);
  const isMobile = useSelector((state) => state.isMobile);
  const selectedTabIndex = useSelector((state) => state.selectedTab);
  const dispatch = useDispatch();
  const isReader =
    userRoles &&
    (userRoles[formatRiverlabForBackend(currentSelectedCard.riverlab)] ===
      undefined ||
      userRoles[formatRiverlabForBackend(currentSelectedCard.riverlab)] ===
        "reader");
  const riverlabsWithAtLeastWriterRole =
    userRoles &&
    Object.keys(userRoles)
      .filter((key) => userRoles[key] !== "reader")
      .join(", ");
  const handleTabChange = (event, newValue) => {
    dispatch(setSelectedTab(newValue));
  };

  const handleChangeIndex = (index) => {
    dispatch(setSelectedTab(index));
  };

  function getAutomatonData() {
    if (user && user.groups.includes("/demo")) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            `/automaton/${currentSelectedCard.riverlab}`
        )
        .then((res) => {
          setAutomatData(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      axios
        .get(process.env.REACT_APP_API_URL + `/automaton/Monticello`)
        .then((res) => {
          setAutomatData(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  useEffect(() => {
    if (currentSelectedCard) {
      getAutomatonData();
    }
  }, [currentSelectedCard]);

  useEffect(() => {
    if (currentSelectedCard && registered) {
      socket.emit("changeactiveelement", {
        riverlab: currentSelectedCard.riverlab,
        element: currentSelectedCard.element,
      });
    }
  }, [registered, currentSelectedCard, socket]);

  return (
    <>
      {isMobile !== null && isMobile === false && (
        <div className="md:pl-16 pt-20 w-screen h-screen">
          <StatCardsSection />
          {isReader ? (
            <Row
              className="bg-white rounded-8px overflow-hidden h-full w-full flex justify-between"
              style={{ marginRight: "8px", flexWrap: "nowrap" }}
            >
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: "100px",
                }}
              >
                <p
                  style={{
                    fontWeight: 700,
                    fontSize: "25px",
                    color: "#0D1441",
                    marginBottom: "15px",
                    fontFamily: "Satoshi",
                  }}
                >
                  <FormattedMessage
                    id="Select Riverlab"
                    values={{
                      riverlab: "Monticello",
                      // capitalizeFirstLetter(riverlabsWithAtLeastWriterRole),
                    }}
                  />
                </p>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#0D1441",
                    fontFamily: "Roboto",
                  }}
                >
                  <FormattedMessage
                    id="You cannot acceed to the Monitoring panel for the Riverlab of the metric you have selected, select a Riverlab chip to acceed to its Monitoring panel."
                    values={{
                      riverlab: capitalizeFirstLetter(
                        riverlabsWithAtLeastWriterRole
                      ),
                      br: <br />,
                    }}
                  />
                </p>
              </Col>
              <Col>
                <StyledImage alt="" src="./assets/protocol.png" />
              </Col>
            </Row>
          ) : (
            <div className="grid grid-cols-12  gap-2 h-full px-2 relative">
              <div className="col-span-7 rounded-8px bg-white rounded-8px p-3 overflow-hidden">
                <ChartSection />
              </div>
              <div className="col-span-5 bg-white rounded-8px overflow-auto divide-y-2 divide-transparent-blue-15%">
                {automatData && (
                  <AnnexSection
                    automatData={automatData}
                    getAutomatonData={getAutomatonData}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {isMobile !== null && isMobile === true && (
        <div className="pt-18 pb-14 h-full w-full flex flex-col">
          <StatCardsSection />
          <AntTabs
            variant="fullWidth"
            value={selectedTabIndex}
            onChange={handleTabChange}
            aria-label="ant example"
            className="h-6 w-full flex items-center justify-center"
          >
            <AntTab label="Realtime" className="focus:outline-none " />
            <AntTab label="Monitoring" className="focus:outline-none " />
          </AntTabs>
          <SwipeableViews
            index={selectedTabIndex}
            onChangeIndex={handleChangeIndex}
            className="flex-grow"
            containerStyle={{ height: "100%" }}
          >
            <TabPanel value={selectedTabIndex} index={0} className="h-full">
              <div className="h-full" id="chartSection">
                <ChartSection />
              </div>
            </TabPanel>
            <TabPanel value={selectedTabIndex} index={1} className="h-full">
              <div
                className=" overflow-auto  bg-white rounded-8px"
                id="AnnexSection"
              >
                {automatData && (
                  <AnnexSection
                    automatData={automatData}
                    getAutomatonData={getAutomatonData}
                  />
                )}
              </div>
            </TabPanel>
          </SwipeableViews>
        </div>
      )}
    </>
  );
}

function DynamicChart() {
  const [option, setOption] = useState(DEFAULT_OPTION);
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);
  const [setEchartRef] = useState(null);
  const socket = useSelector((state) => state.socket);
  const registered = useSelector((state) => state.registered);

  //register to listen to realtime data chanel
  useEffect(() => {
    socket &&
      socket.on("dynamic_graph", (res) => {
        setOption((prev) => {
          const newOption = cloneDeep(prev);
          const data0 = newOption.series[0].data;
          data0.push(res.value);
          newOption.xAxis[0].data.push(moment().format("yyyy-MM-DD h:mm:ss"));
          if (newOption.xAxis[0].data.length >= 50) {
            data0.shift();
            newOption.xAxis[0].data.shift();
          }
          return newOption;
        });
      });
    return () => {
      socket && socket.removeAllListeners("dynamic_graph");
      socket && socket.emit("leave", "dynamic_graph", () => {});
    };
  }, [registered, socket]);

  //join dynamic_graph room
  useEffect(() => {
    if (registered) {
      socket.emit("join", "dynamic_graph", () => {});
    }
  }, [registered, socket]);

  //notify ws of the selected card you are on
  useEffect(() => {
    if (currentSelectedCard) {
      setOption((prev) => {
        const newOption = cloneDeep(prev);
        newOption.series[0].data = [];
        newOption.xAxis[0].data = [];
        return newOption;
      });
    }
  }, [currentSelectedCard]);

  return (
    <ReactECharts
      option={option}
      className="overflow-hidden"
      style={{ height: "100%", width: "100%" }}
      ref={(e) => {
        setEchartRef(e);
      }}
      opts={{ renderer: "canvas" }}
    />
  );
}

function AutaumatonStatsSection() {
  const socket = useSelector((state) => state.socket);
  const [stats, setstats] = useState(null);
  const registered = useSelector((state) => state.registered);
  const [dico, setdico] = useState(null);
  const user = useSelector((state) => state.user);
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);

  //get first state for automaton data
  useEffect(() => {
    if (user && currentSelectedCard && user.groups.includes("/demo")) {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            `/observatories/${currentSelectedCard.riverlab}/realtime/gauges`
        )
        .then((res) => {
          setstats(res.data.data);
        })
        .catch((err) => console.error("error", err));
    } else {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            `/observatories/Monticello/realtime/gauges`
        )
        .then((res) => {
          setstats(res.data.data);
        })
        .catch((err) => console.error("error", err));
    }
  }, [user, currentSelectedCard]);

  //get first state for automaton data
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + `/observatories/Monticello/gauges`)
      .then((res) => {
        setdico(res.data.gauges);
      })
      .catch((err) => console.error("error", err));
  }, []);

  //join realtime_automaton room chanel
  useEffect(() => {
    if (registered) {
      socket.emit("join", "realtime_automaton", () => {});

      return () => {
        socket.emit("leave", "realtime_automaton", () => {});
      };
    }
  }, [registered, socket]);

  const gaugeList =
    stats && dico
      ? stats.map((elem, index) => {
          if (elem.element.toLowerCase().includes("time")) {
            return (
              <TimerComponenet
                key={elem.element + elem.riverlab + index}
                elem={elem}
              />
            );
          } else if (
            [
              "Etat Lumiere Exterieure",
              "Etat Lumiere Interieure",
              "Etat Lumiere RL215",
              "Vanne Marche",
              "Pompe Marche",
            ].includes(elem.element)
          ) {
            return (
              <OnOffstatesComponents
                key={elem.element + elem.riverlab + index}
                elem={elem}
              />
            );
          } else {
            return (
              <div
                key={elem.element + elem.riverlab + index}
                className="h-40 bg-light-bg md:mb-2 rounded-8px"
                id={"automaton" + elem.element + elem.value}
              >
                <Gauge
                  value={elem.value}
                  name={elem.element}
                  unit={elem.unit}
                  min={dico[elem.element] ? dico[elem.element].min : 0}
                  max={dico[elem.element] ? dico[elem.element].max : 100}
                  color={
                    dico[elem.element] &&
                    elem.value <= dico[elem.element].warning
                      ? "rgb(60, 179, 113)"
                      : "rgb(255, 0, 0)"
                  }
                />
              </div>
            );
          }
        })
      : [];

  return (
    <div className="grid grid-cols-2 gap-1 md:grid-cols-4">{gaugeList}</div>
  );
}

function TimerComponenet({ elem }) {
  const [timeState, settime] = useState(elem.value);
  const socket = useSelector((state) => state.socket);
  const registered = useSelector((state) => state.registered);

  //register to listen to realtime_automaton data chanel
  useEffect(() => {
    registered &&
      socket.on("realtime_automaton", (res) => {
        for (let i = 0; i < res.data.length - 1; i++) {
          if (res.data[i].element === elem.element) {
            settime(res.data[i].value);
            return;
          }
        }
      });
    return () => {
      registered && socket.removeAllListeners("realtime_automaton");
    };
  }, [elem.element, registered, socket]);

  return (
    <div
      className="h-40 bg-light-bg md:mb-2 rounded-8px flex justify-center items-center relative "
      key={"automaton" + elem.element + timeState}
      id={"automaton" + elem.element + timeState}
    >
      <p className="text-primary text-sm font-medium pt-3 absolute top-0 left-0 right-0 text-center">
        <FormattedMessage id={elem.element} />
      </p>
      <div className="flex gap-2">
        <div className="text-center">
          <div className="h-10 w-10 md:h-12 md:w-12 bg-primary text-white rounded-8px flex items-center justify-center">
            <p className="font-medium text-xl">{moment(timeState).hours()}</p>
          </div>
          <p className="text-xs">Hours</p>
        </div>
        <p className="mt-2 font-medium text-2xl">:</p>
        <div className="text-center">
          <div className="h-10 w-10 md:h-12 md:w-12 bg-primary text-white rounded-8px flex items-center justify-center">
            <p className="font-medium text-xl">
              {moment(timeState).format("mm")}
            </p>
          </div>
          <p className="text-xs">Minutes</p>
        </div>
        <p className="mt-2 font-medium text-2xl">:</p>
        <div className="text-center">
          <div className="h-10 w-10 md:h-12 md:w-12 bg-primary text-white rounded-8px flex items-center justify-center">
            <p className="font-medium text-xl">
              {moment(timeState).format("ss")}
            </p>
          </div>
          <p className="text-xs">Seconds</p>
        </div>
      </div>
    </div>
  );
}

function OnOffstatesComponents({ elem }) {
  const [state, setstate] = useState(elem.value);
  const socket = useSelector((state) => state.socket);
  const registered = useSelector((state) => state.registered);

  //register to listen to realtime_automaton data chanel
  useEffect(() => {
    registered &&
      socket.on("realtime_automaton", (res) => {
        //setstats(res.data)
        for (let i = 0; i < res.data.length - 1; i++) {
          if (res.data[i].element === elem.element) {
            setstate(res.data[i].value);
            return;
          }
        }
      });
    return () => {
      registered &&
        socket.removeAllListeners("OnOffstatesComponents realtime_automaton");
    };
  }, [elem.element, registered, socket]);

  return (
    <div
      className="h-40 bg-light-bg md:mb-2 rounded-8px flex flex-col"
      key={"automaton" + elem.element + state}
      id={"automaton" + elem.element + state}
    >
      <p className="text-primary text-sm font-medium pt-3 text-center">
        <FormattedMessage id={elem.element} />
      </p>
      <div className="flex flex-grow items-center justify-center">
        <div
          className={`${
            state === true ? "bg-light-green" : "bg-warning"
          } h-24 w-24 rounded-full text-white flex items-center justify-center`}
        >
          <p className="font-medium text-3xl">
            {state === true ? "ON" : "OFF"}
          </p>
        </div>
      </div>
    </div>
  );
}

function ChartSection() {
  const [chartTabValue, setChartTabValue] = useState(0);

  const handleChartTabChange = (event, newValue) => {
    setChartTabValue(newValue);
  };

  return (
    <div className="h-full flex flex-col">
      <AntTabs
        value={chartTabValue}
        onChange={handleChartTabChange}
        aria-label="ant example"
        className="h-8 flex items-center justify-center bg-white"
      >
        <AntTab
          label={<FormattedMessage id="Gauges" />}
          className="focus:outline-none "
        />
        <AntTab
          label={<FormattedMessage id="automate.chart.tab2" />}
          className="focus:outline-none "
        />
      </AntTabs>
      <TabPanel
        value={chartTabValue}
        index={0}
        className="flex-grow overflow-auto"
      >
        <AutaumatonStatsSection />
      </TabPanel>
      <TabPanel value={chartTabValue} index={1} className="pt-4 flex-grow">
        <DynamicChart />
      </TabPanel>
    </div>
  );
}

function AnnexSection({ automatData, getAutomatonData }) {
  const [annexTabValue, setAnnexTabValue] = useState(0);
  const [advancedparams, setadvancedparams] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [playOn] = useSound(upSound, { volume: 0.25, playbackRate: 3 });
  const [playOff] = useSound(downSound, { volume: 0.25, playbackRate: 3 });
  const handleAnnexTabChange = (event, newValue) => {
    setAnnexTabValue(newValue);
  };

  return (
    <>
      <div>
        <AntTabs
          value={annexTabValue}
          onChange={handleAnnexTabChange}
          aria-label="ant example"
          className="p-0"
          variant="scrollable"
          scrollButtons="auto"
        >
          <AntTab
            label={<FormattedMessage id="automate.annex.tab1" />}
            className="focus:outline-none"
          />
          <AntTab
            label={<FormattedMessage id="automate.annex.tab2" />}
            className="focus:outline-none"
          />
          <AntTab
            label={<FormattedMessage id="automate.annex.tab3" />}
            className="focus:outline-none"
          />
          <AntTab
            label={<FormattedMessage id="automate.annex.tab4" />}
            className="focus:outline-none"
          />
          <AntTab
            label={<FormattedMessage id="automate.annex.tab5" />}
            className="focus:outline-none"
          />
        </AntTabs>
        <TabPanel value={annexTabValue} index={0}>
          <div className="p-1 flex flex-col divide-y divide-primary divide-opacity-15">
            <div className="mb-2 p-3">
              <div className="flex justify-end">
                <button
                  className={`${
                    advancedparams ? "border-opacity-15" : "border-opacity-100"
                  } text-primary border-1  border-primary  flex text-sm  font-medium  rounded-8px px-3 py-2 focus:outline-none`}
                  onClick={() => {
                    setadvancedparams(!advancedparams);
                  }}
                >
                  <LockIcon className="mr-3 w-4 h-5" />
                  <FormattedMessage id="automate.annex.pump.param1" />
                </button>
              </div>
              <div className="flex justify-between items-center p-4 border-1 border-primary border-opacity-15 rounded-8px  w-full mt-3">
                <FormattedMessage id="automate.annex.pump.param2" />
                <TurnOnOff
                  initialState={automatData.pompe_marche}
                  enqueueSnackbar={enqueueSnackbar}
                  messages={{
                    success: "Pompe en marche",
                    error: "Pompe arrêtée",
                  }}
                  playMusic={true}
                  playOn={playOn}
                  playOff={playOff}
                />
              </div>
            </div>
            <div className="p-3">
              <ManualAutoMode
                param="pump"
                initialState={{
                  mode: automatData.pompe_pid_actif,
                  manualValue: automatData.consigne_pompe_manuel,
                  autoValue: automatData.consigne_debit,
                }}
                enqueueSnackbar={enqueueSnackbar}
                messages={{
                  success: "Consigne pompe remise à ",
                  error: "La mise a jours de la valeur a échoué",
                }}
                fieldText={{
                  manual: <FormattedMessage id="automate.annex.pump.param5" />,
                  auto: <FormattedMessage id="automate.annex.pump.param6" />,
                }}
                target={{
                  manualOrAuto: "pompe_pid_actif",
                  manual: "consigne_pompe_manuel",
                  auto: "consigne_debit",
                }}
                getAutomatonData={getAutomatonData}
              />
            </div>
            <div className="px-4 py-2">
              <AdvancedParametersSection
                initialState={{
                  p: automatData.pompe_pid_p,
                  i: automatData.pompe_pid_i,
                  d: automatData.pompe_pid_d,
                }}
                isDisabled={advancedparams}
                enqueueSnackbar={enqueueSnackbar}
                target={{
                  p: "pompe_pid_p",
                  i: "pompe_pid_i",
                  d: "pompe_pid_d",
                }}
                getAutomatonData={getAutomatonData}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={annexTabValue} index={1}>
          <div className="p-1 flex flex-col divide-y divide-primary divide-opacity-15">
            <div className="p-3">
              <div className="flex justify-end">
                <button
                  className={`${
                    advancedparams ? "border-opacity-15" : "border-opacity-100"
                  } text-primary border-1  border-primary  flex text-sm  font-medium  rounded-8px px-3 py-2 focus:outline-none`}
                  onClick={() => {
                    setadvancedparams(!advancedparams);
                  }}
                >
                  <LockIcon className="mr-3 w-4 h-5" />
                  <FormattedMessage id="automate.annex.pump.param1" />
                </button>
              </div>
              <ManualAutoMode
                param="valve"
                initialState={{
                  mode: automatData.vanne_pid_actif,
                  manualValue: automatData.consigne_vanne_manuel,
                  autoValue: automatData.consigne_pression,
                }}
                enqueueSnackbar={enqueueSnackbar}
                messages={{
                  success: "Consigne vanne remise à ",
                  error: "La mise a jours de la valeur a échoué",
                }}
                fieldText={{
                  manual: <FormattedMessage id="automate.annex.valve.param1" />,
                  auto: <FormattedMessage id="automate.annex.valve.param2" />,
                }}
                target={{
                  manualOrAuto: "vanne_pid_actif",
                  manual: "consigne_vanne_manuel",
                  auto: "consigne_pression",
                }}
                getAutomatonData={getAutomatonData}
              />
            </div>
            <div className="px-4 py-2">
              <AdvancedParametersSection
                initialState={{
                  p: automatData.vanne_pid_p,
                  i: automatData.vanne_pid_i,
                  d: automatData.vanne_pid_d,
                }}
                isDisabled={advancedparams}
                enqueueSnackbar={enqueueSnackbar}
                messages={{
                  success: "valeur mise a jours à ",
                  error: "La mise a jours de la valeur a échoué",
                }}
                target={{
                  p: "vanne_pid_p",
                  i: "vanne_pid_i",
                  d: "vanne_pid_d",
                }}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={annexTabValue} index={2}>
          <div className="p-3">
            <p className="text-primary font-medium pt-2">
              <FormattedMessage id="automate.annex.Unclogging.param1" />
            </p>
            <PeriodPickerComponent
              initialState={automatData.decolmatage_intervalle}
              enqueueSnackbar={enqueueSnackbar}
              target="decolmatage_intervalle"
              text="automate.annex.Unclogging.param2"
              getAutomatonData={getAutomatonData}
            />
            <LaunchNewCycle
              initialState={automatData.decolmatage_lancement_manuel}
              enqueueSnackbar={enqueueSnackbar}
              target="decolmatage_lancement_manuel"
              text={<FormattedMessage id="automate.annex.Unclogging.param3" />}
              color="#FF7715"
            />
            <p className="text-primary font-medium p-2 pt-5">
              <FormattedMessage id="automate.annex.Unclogging.param4" />
            </p>
            <div className="flex justify-between items-center p-4 border-1 border-primary border-opacity-15 rounded-8px h-14 w-full mt-2">
              <p className="text-sm text-dark-text font-normal">
                <FormattedMessage id="automate.annex.Unclogging.param5" />
              </p>
              <div className="flex">
                <div className="mr-2 w-36">
                  <InputComponent
                    initialState={automatData.decolmatage_pompe}
                    enqueueSnackbar={enqueueSnackbar}
                    messages={{
                      success: "Consigne vanne remise à ",
                      error: "La mise a jours de la valeur a échoué",
                    }}
                    target={"decolmatage_pompe"}
                    isDisabled={false}
                    getAutomatonData={getAutomatonData}
                  />
                </div>
                <div className="h-9 w-14 bg-primary bg-opacity-3 border-1 border-primary border-opacity-15 rounded-8px flex items-center justify-center">
                  sec
                </div>
              </div>
            </div>
            <ManualAutoMode
              param="pump"
              initialState={{
                mode: automatData.decolmatage_pompe_pid,
                manualValue: automatData.decolmatage_pompe_manuel_vitesse,
                autoValue: automatData.decolmatage_pompe_pid_vitesse,
              }}
              enqueueSnackbar={enqueueSnackbar}
              messages={{
                success: "Consigne pompe remise à ",
                error: "La mise a jours de la valeur a échoué",
              }}
              fieldText={{
                manual: <FormattedMessage id="automate.annex.pump.param5" />,
                auto: <FormattedMessage id="automate.annex.pump.param5" />,
              }}
              target={{
                manualOrAuto: "decolmatage_pompe_pid",
                manual: "decolmatage_pompe_manuel_vitesse",
                auto: "decolmatage_pompe_pid_vitesse",
              }}
              getAutomatonData={getAutomatonData}
            />
            <p className="text-primary font-medium p-2 pt-5">
              <FormattedMessage id="automate.annex.Unclogging.param6" />
            </p>
            <div className="flex justify-between items-center p-4 border-1 border-primary border-opacity-15 rounded-8px h-14 w-full mt-2">
              <p className="text-sm text-dark-text font-normal">
                <FormattedMessage id="automate.annex.Unclogging.param7" />
              </p>
              <div className="flex">
                <div className="mr-2 w-36">
                  <InputComponent
                    initialState={automatData.decolmatage_vanne}
                    enqueueSnackbar={enqueueSnackbar}
                    messages={{
                      success: "Consigne vanne remise à ",
                      error: "La mise a jours de la valeur a échoué",
                    }}
                    target={"decolmatage_vanne"}
                    isDisabled={false}
                    getAutomatonData={getAutomatonData}
                  />
                </div>
                <div className="h-9 w-14 bg-primary bg-opacity-3 border-1 border-primary border-opacity-15 rounded-8px flex items-center justify-center">
                  sec
                </div>
              </div>
            </div>
            <ManualAutoMode
              param="valve"
              initialState={{
                mode: automatData.decolmatage_vanne_pid,
                manualValue: automatData.decolmatage_vanne_manuel_ouverture,
                autoValue: automatData.decolmatage_vanne_pid_ouverture,
              }}
              enqueueSnackbar={enqueueSnackbar}
              messages={{
                success: "Consigne vanne remise à ",
                error: "La mise a jours de la valeur a échoué",
              }}
              fieldText={{
                manual: <FormattedMessage id="automate.annex.valve.param1" />,
                auto: <FormattedMessage id="automate.annex.valve.param2" />,
              }}
              target={{
                manualOrAuto: "decolmatage_vanne_pid",
                manual: "decolmatage_vanne_manuel_ouverture",
                auto: "decolmatage_vanne_pid_ouverture",
              }}
              getAutomatonData={getAutomatonData}
            />
            <p className="text-primary font-medium p-2 pt-5">
              <FormattedMessage id="automate.annex.Unclogging.param8" />
            </p>
            <div className="flex justify-between items-center p-4 border-1 border-primary border-opacity-15 rounded-8px h-14 w-full mt-2 mb-2">
              <p className="text-sm text-dark-text font-normal">
                <FormattedMessage id="automate.annex.Unclogging.param9" />
              </p>
              <div className="flex">
                <div className="mr-2 w-36">
                  <InputComponent
                    initialState={automatData.decolmatage_ouverture_bac}
                    enqueueSnackbar={enqueueSnackbar}
                    messages={{
                      success: "Consigne vanne remise à ",
                      error: "La mise a jours de la valeur a échoué",
                    }}
                    target={"decolmatage_ouverture_bac"}
                    isDisabled={false}
                    getAutomatonData={getAutomatonData}
                  />
                </div>
                <div className="h-9 w-14 bg-primary bg-opacity-3 border-1 border-primary border-opacity-15 rounded-8px flex items-center justify-center">
                  sec
                </div>
              </div>
            </div>
            <LaunchNewCycle
              initialState={automatData.decolmatage_lancement_manuel}
              enqueueSnackbar={enqueueSnackbar}
              messages={{
                success: "Consigne vanne remise à ",
                error: "La mise a jours de la valeur a échoué",
              }}
              target="decolmatage_bac_flush"
              text="Flush"
              color="#2C5AFF"
            />
          </div>
        </TabPanel>
        <TabPanel value={annexTabValue} index={3} className="px-1">
          <div className="divide-y divide-primary divide-opacity-15 ">
            <div className="mt-3">
              <p className="text-primary font-medium p-2">
                <FormattedMessage id="automate.annex.FilteredWater.param1" />
              </p>
              <div className="pt-2 pb-4 px-4">
                <PeriodPickerComponent
                  initialState={automatData.nettoyage_ef_intervalle}
                  enqueueSnackbar={enqueueSnackbar}
                  target="nettoyage_ef_intervalle"
                  text="automate.annex.FilteredWater.param2"
                />
                <LaunchNewCycle
                  initialState={automatData.decolmatage_lancement_manuel}
                  enqueueSnackbar={enqueueSnackbar}
                  messages={{
                    success: "Consigne vanne remise à ",
                    error: "La mise a jours de la valeur a échoué",
                  }}
                  target="nettoyage_ef_lancement_manuel"
                  text={
                    <FormattedMessage id="automate.annex.Unclogging.param3" />
                  }
                  color="#FF7715"
                />
              </div>
            </div>
            <div>
              <p className="text-primary font-medium p-2 pt-5">
                <FormattedMessage id="automate.annex.FilteredWater.param3" />
              </p>
              <div className="py-2 px-4">
                <p className="text-sm text-primary font-normal">
                  <FormattedMessage id="automate.annex.FilteredWater.param4" />{" "}
                  1
                </p>
                <div className="flex justify-between items-center p-4 border-1 border-primary border-opacity-15 rounded-8px h-14 w-full mt-2">
                  <p className="text-sm text-dark-text font-normal">
                    <FormattedMessage id="automate.annex.FilteredWater.param5" />
                  </p>
                  <div className="flex">
                    <div className="mr-2 w-36">
                      <InputComponent
                        initialState={automatData.nettoyage_ef_attente_1}
                        enqueueSnackbar={enqueueSnackbar}
                        messages={{
                          success: "Consigne Etape 1 de nettoyage EF remise à ",
                          error: "La mise a jours de la valeur a échoué",
                        }}
                        isDisabled={false}
                        target="nettoyage_ef_attente_1"
                        getAutomatonData={getAutomatonData}
                      />
                    </div>
                    <div className="h-9 w-14 bg-primary bg-opacity-3 border-1 border-primary border-opacity-15 rounded-8px flex items-center justify-center">
                      sec
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-2 px-4">
                <p className="text-sm text-primary font-normal">
                  <FormattedMessage id="automate.annex.FilteredWater.param4" />{" "}
                  2
                </p>
                <div className="flex justify-between items-center p-4 border-1 border-primary border-opacity-15 rounded-8px h-14 w-full mt-2">
                  <p className="text-sm text-dark-text font-normal">
                    <FormattedMessage id="automate.annex.FilteredWater.param6" />
                  </p>
                  <div className="flex">
                    <div className="mr-2 w-36">
                      <InputComponent
                        initialState={automatData.nettoyage_ef_ultrason}
                        enqueueSnackbar={enqueueSnackbar}
                        messages={{
                          success: "Consigne Etape 2 de nettoyage EF remise à ",
                          error: "La mise a jours de la valeur a échoué",
                        }}
                        isDisabled={false}
                        target="nettoyage_ef_ultrason"
                        getAutomatonData={getAutomatonData}
                      />
                    </div>
                    <div className="h-9 w-14 bg-primary bg-opacity-3 border-1 border-primary border-opacity-15 rounded-8px flex items-center justify-center">
                      sec
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-2 px-4">
                <p className="text-sm text-primary font-normal">
                  <FormattedMessage id="automate.annex.FilteredWater.param4" />{" "}
                  3
                </p>
                <div className="flex justify-between items-center p-4 border-1 border-primary border-opacity-15 rounded-8px h-14 w-full mt-2">
                  <p className="text-sm text-dark-text font-normal">
                    <FormattedMessage id="automate.annex.FilteredWater.param7" />
                  </p>
                  <div className="flex">
                    <div className="mr-2 w-36">
                      <InputComponent
                        initialState={automatData.nettoyage_ef_ultrason_air}
                        enqueueSnackbar={enqueueSnackbar}
                        messages={{
                          success: "Consigne Etape 3 de nettoyage EF remise à ",
                          error: "La mise a jours de la valeur a échoué",
                        }}
                        isDisabled={false}
                        target="nettoyage_ef_ultrason_air"
                        getAutomatonData={getAutomatonData}
                      />
                    </div>
                    <div className="h-9 w-14 bg-primary bg-opacity-3 border-1 border-primary border-opacity-15 rounded-8px flex items-center justify-center">
                      sec
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-2 px-4">
                <p className="text-sm text-primary font-normal">
                  <FormattedMessage id="automate.annex.FilteredWater.param4" />{" "}
                  4
                </p>
                <div className="flex justify-between items-center p-4 border-1 border-primary border-opacity-15 rounded-8px h-14 w-full mt-2">
                  <p className="text-sm text-dark-text font-normal">
                    <FormattedMessage id="automate.annex.FilteredWater.param8" />
                  </p>
                  <div className="flex">
                    <div className="mr-2 w-36">
                      <InputComponent
                        initialState={automatData.nettoyage_ef_attente_2}
                        enqueueSnackbar={enqueueSnackbar}
                        messages={{
                          success: "Consigne Etape 4 de nettoyage EF remise à ",
                          error: "La mise a jours de la valeur a échoué",
                        }}
                        isDisabled={false}
                        target="nettoyage_ef_attente_2"
                        getAutomatonData={getAutomatonData}
                      />
                    </div>
                    <div className="h-9 w-14 bg-primary bg-opacity-3 border-1 border-primary border-opacity-15 rounded-8px flex items-center justify-center">
                      sec
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={annexTabValue} index={4}>
          <div className="p-4">
            <Light
              initialState={automatData.lumiere_interieure_etat}
              enqueueSnackbar={enqueueSnackbar}
              text={<FormattedMessage id="automate.annex.Light.param1" />}
              api="/automaton/togglelight"
              getAutomatonData={getAutomatonData}
            />
            <Light
              initialState={automatData.lumiere_rl215_etat}
              enqueueSnackbar={enqueueSnackbar}
              text={<FormattedMessage id="automate.annex.Light.param2" />}
              api="/automaton/togglelight_rl"
              getAutomatonData={getAutomatonData}
            />
            <Light
              initialState={automatData.lumiere_exterieure_etat}
              enqueueSnackbar={enqueueSnackbar}
              text={<FormattedMessage id="automate.annex.Light.param3" />}
              api="/automaton/togglelight_rl"
              getAutomatonData={getAutomatonData}
            />
          </div>
        </TabPanel>
      </div>
    </>
  );
}

// Widget component
function LaunchNewCycle({
  initialState,
  enqueueSnackbar,
  target,
  text,
  color,
}) {
  const user = useSelector((state) => state.user);
  const [url, setUrl] = useState("/automaton/Monticello/push");
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);

  useEffect(() => {
    if (user && currentSelectedCard && user.groups.includes("/demo")) {
      setUrl(`/automaton/${currentSelectedCard.riverlab}/push`);
    }
  }, [user, currentSelectedCard]);

  const submit = () => {
    let action = {
      target_property: target,
      value: true,
    };
    axios
      .post(process.env.REACT_APP_API_URL + url, action)
      .then((res) => {
        enqueueSnackbar("Un nouveau cycle de décolmatage vient d'être lancé", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <button
      className="bg-secondary rounded-8px font-medium w-full text-white h-12 focus:outline-none"
      style={{ backgroundColor: color }}
      onClick={submit}
    >
      {text}
    </button>
  );
}

function PeriodPickerComponent({
  initialState,
  enqueueSnackbar,
  target,
  text,
  getAutomatonData,
}) {
  const h = Math.floor(initialState / 3600);
  const m = Math.floor((initialState - h * 3600) / 60);
  const s = initialState - h * 3600 - m * 60;
  const [time, setTime] = useState({ h: h, m: m, s: s });
  const [error, setError] = useState({
    h: null,
    m: null,
    s: null,
    latest: null,
  });

  const submit = (param, url) => {
    let action = {
      target_property: target,
      value: param,
    };
    axios
      .post(process.env.REACT_APP_API_URL + url, action)
      .then((res) => {
        enqueueSnackbar("Prochain décomatage dans " + param + " seconds", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
        getAutomatonData();
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const [debouncedState, setDebouncedState] = useDebounce(time, 2000, submit);

  const handleChange = (value) => {
    const newObj = { ...time, [value.target.name]: value.target.value };
    setTime(newObj);
    if (value.target.name === "h") {
      if (value.target.value === "") {
        setError((prev) => {
          return { ...prev, [value.target.name]: null, latest: null };
        });
        const newObj = { ...time, h: 0 };
        setTime(newObj);
        setDebouncedState(
          Number(newObj.s) + Number(newObj.m * 60) + Number(newObj.h * 3600)
        );
      } else {
        if (isNaN(value.target.value)) {
          setError((prev) => {
            return {
              ...prev,
              [value.target.name]: "must be a number",
              latest: "must be a number",
            };
          });
        } else {
          if (!Number.isInteger(Number(value.target.value))) {
            setError((prev) => {
              return {
                ...prev,
                [value.target.name]: "must be an integer",
                latest: "must be an integer",
              };
            });
          } else {
            if (Number(value.target.value) < 0) {
              setError((prev) => {
                return {
                  ...prev,
                  [value.target.name]: "must be a positive number",
                  latest: "must be a positive number",
                };
              });
            } else {
              if (error.s) {
                setError((prev) => {
                  return {
                    ...prev,
                    [value.target.name]: null,
                    latest: prev.s + " in s",
                  };
                });
              } else if (error.m) {
                setError((prev) => {
                  return {
                    ...prev,
                    [value.target.name]: null,
                    latest: prev.m + " in m",
                  };
                });
              } else {
                setError((prev) => {
                  return { ...prev, [value.target.name]: null, latest: null };
                });
                setDebouncedState(
                  Number(newObj.s) +
                    Number(newObj.m * 60) +
                    Number(newObj.h * 3600)
                );
              }
            }
          }
        }
      }
    } else {
      if (value.target.value === "") {
        setError((prev) => {
          return { ...prev, [value.target.name]: null, latest: null };
        });
        const newObj = { ...time, [value.target.name]: 0 };
        setTime(newObj);
        setDebouncedState(
          Number(newObj.s) + Number(newObj.m * 60) + Number(newObj.h * 3600)
        );
      } else {
        if (isNaN(value.target.value)) {
          setError((prev) => {
            return {
              ...prev,
              [value.target.name]: "must be a number",
              latest: "must be a number",
            };
          });
        } else {
          if (!Number.isInteger(Number(value.target.value))) {
            setError((prev) => {
              return {
                ...prev,
                [value.target.name]: "must be an integer",
                latest: "must be an integer",
              };
            });
          } else {
            if (Number(value.target.value) < 0) {
              setError((prev) => {
                return {
                  ...prev,
                  [value.target.name]: "must be a positive number",
                  latest: "must be a positive number",
                };
              });
            } else {
              if (Number(value.target.value) > 59) {
                setError((prev) => {
                  return {
                    ...prev,
                    [value.target.name]: "maximum 59",
                    latest: "maximum 59",
                  };
                });
              } else {
                if (error.h) {
                  setError((prev) => {
                    return {
                      ...prev,
                      [value.target.name]: null,
                      latest: error.h + " in h",
                    };
                  });
                } else if (error.m && value.target.name === "s") {
                  setError((prev) => {
                    return {
                      ...prev,
                      [value.target.name]: null,
                      latest: error.m + " in m",
                    };
                  });
                } else if (error.s && value.target.name === "m") {
                  setError((prev) => {
                    return {
                      ...prev,
                      [value.target.name]: null,
                      latest: error.s + " in s",
                    };
                  });
                } else {
                  setError((prev) => {
                    return { ...prev, [value.target.name]: null, latest: null };
                  });
                  setDebouncedState(
                    Number(newObj.s) +
                      Number(newObj.m * 60) +
                      Number(newObj.h * 3600)
                  );
                }
              }
            }
          }
        }
      }
    }
  };

  return (
    <div className="flex justify-between items-center border-1 border-primary border-opacity-15 rounded-8px h-14 w-full mt-2 mb-2">
      <div>
        <p className="text-sm text-dark-text font-normal p-4">
          <FormattedMessage id={text} />
        </p>
      </div>
      {error.latest && <p className="text-error">{error.latest}</p>}
      <div className=" flex items-center pr-1 w-60">
        <div className="flex items-center">
          <CssTextField
            name="h"
            disabled={false}
            variant="outlined"
            id="custom-css-outlined-input"
            onChange={handleChange}
            value={time.h}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <p className="text-primary text-sm">h:</p>
                </InputAdornment>
              ),
            }}
          />
          <p className="mx-2">:</p>
          <CssTextField
            name="m"
            disabled={false}
            variant="outlined"
            id="custom-css-outlined-input"
            onChange={handleChange}
            value={time.m}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <p className="text-primary text-sm">m:</p>
                </InputAdornment>
              ),
            }}
          />
          <p className="mx-2">:</p>
          <CssTextField
            name="s"
            disabled={false}
            variant="outlined"
            id="custom-css-outlined-input"
            onChange={handleChange}
            value={time.s}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <p className="text-primary text-sm">s:</p>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
}

function TurnOnOff({
  initialState,
  enqueueSnackbar,
  messages,
  playMusic,
  playOn,
  playOff,
}) {
  const [onOffState, setOnOffState] = useState(initialState);
  const [url, setUrl] = useState("/automaton/Monticello/pump/toggle");
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user && currentSelectedCard && user.groups.includes("/demo")) {
      setUrl(`/automaton/${currentSelectedCard.riverlab}/pump/toggle`);
    }
  }, [user, currentSelectedCard]);

  const handleAction = () => {
    if (!onOffState) {
      if (playMusic) playOn();
      axios
        .get(process.env.REACT_APP_API_URL + url)
        .then((res) => {
          enqueueSnackbar(messages.success, {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      if (playMusic) playOff();
      axios
        .get(process.env.REACT_APP_API_URL + url)
        .then((res) => {
          enqueueSnackbar(messages.error, {
            variant: "warning",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
    setOnOffState(!onOffState);
  };
  return (
    <button
      className={`${
        onOffState ? "bg-light-green" : "bg-secondary"
      } text-white rounded-8px py-2 px-3 focus:outline-none`}
      onClick={handleAction}
    >
      <div className="flex flex-row gap-2">
        <StartStopIcon />
        <p className="font-medium text-md">{onOffState ? "ON" : "OFF"}</p>
      </div>
    </button>
  );
}

function Light({ initialState, enqueueSnackbar, text, api, getAutomatonData }) {
  const [manualMode, setManualMode] = useState(initialState);
  const handleManualMode = () => {
    if (!manualMode) {
      axios
        .get(process.env.REACT_APP_API_URL + api)
        .then((res) => {
          enqueueSnackbar("light on", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
          setManualMode(!manualMode);
          getAutomatonData();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      axios
        .get(process.env.REACT_APP_API_URL + api)
        .then((res) => {
          enqueueSnackbar("light off", {
            variant: "warning",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
          setManualMode(!manualMode);
          getAutomatonData();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  return (
    <div>
      <div className="flex justify-between items-center p-4 border-1 border-primary border-opacity-15 rounded-8px h-14 w-full mt-3">
        <p className="text-sm text-dark-text font-normal">{text}</p>
        <div className="flex items-center">
          <p
            className={`${
              manualMode ? "text-grey-text" : "text-dark-text"
            } text-sm`}
          >
            OFF
          </p>
          <BlueSwitch
            checked={manualMode}
            onChange={handleManualMode}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <p
            className={`${
              !manualMode ? "text-grey-text" : "text-dark-text"
            } text-sm`}
          >
            ON
          </p>
        </div>
      </div>
    </div>
  );
}

function ManualAutoMode({
  param,
  initialState,
  enqueueSnackbar,
  messages,
  fieldText,
  target,
  getAutomatonData,
}) {
  const [manualMode, setManualMode] = useState(initialState.mode);
  const user = useSelector((state) => state.user);
  const [url, setUrl] = useState("/automaton/Monticello/push");
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);
  useEffect(() => {
    if (user && currentSelectedCard && user.groups.includes("/demo")) {
      setUrl(`/automaton/${currentSelectedCard.riverlab}/push`);
    }
  }, [user, currentSelectedCard]);

  const handleManualMode = () => {
    let action = {
      target_property: target.manualOrAuto,
      value: !manualMode,
    };
    axios
      .post(process.env.REACT_APP_API_URL + url, action)
      .then((res) => {
        if (!manualMode) {
          enqueueSnackbar("Mode automatique activé", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        } else {
          enqueueSnackbar("Mode manuel activé", {
            variant: "warning",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        }
        setManualMode(!manualMode);
        getAutomatonData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const ValveInputContentValidation = (event, setError, setDebouncedState) => {
    if (event.target.value === "") {
      setError("Please Type Something");
    } else {
      if (isNaN(event.target.value)) {
        setError("Please type a valid format");
      } else {
        if ((event.target.value > 3) | (event.target.value < 0)) {
          setError("Not in the range");
        } else {
          setError(null);
          setDebouncedState(event.target.value);
        }
      }
    }
  };

  const PumpInputContentValidation = (event, setError, setDebouncedState) => {
    if (event.target.value === "") {
      setError("Please Type Something");
    } else {
      if (isNaN(event.target.value)) {
        setError("Please type a valid format");
      } else {
        if (!Number.isInteger(Number(event.target.value))) {
          setError("Please Type an integer");
        } else {
          if ((event.target.value > 1700) | (event.target.value < 500)) {
            setError("Not in the range");
          } else {
            setError(null);
            setDebouncedState(event.target.value);
          }
        }
      }
    }
  };

  return (
    <div>
      <div className="flex justify-center items-center p-4 border-1 border-primary border-opacity-15 rounded-8px h-14 w-full mt-3">
        <div className="flex items-center">
          <p
            className={`${
              manualMode ? "text-grey-text" : "text-dark-text"
            } text-sm`}
          >
            <FormattedMessage id="automate.annex.pump.param3" />
          </p>
          <BlueSwitch
            checked={manualMode}
            onChange={handleManualMode}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <p
            className={`${
              !manualMode ? "text-grey-text" : "text-dark-text"
            } text-sm`}
          >
            <FormattedMessage id="automate.annex.pump.param4" />
          </p>
        </div>
      </div>
      <PopoverSection
        isActive={manualMode}
        text={<FormattedMessage id="automate.annex.infomessage1" />}
      >
        <div className="flex justify-between items-center p-4 border-1 border-primary border-opacity-15 rounded-8px h-14 w-full mt-2">
          <p
            className={`${
              manualMode ? "text-primary text-opacity-15" : "text-dark-text "
            } text-sm font-normal`}
          >
            {fieldText.manual}
          </p>
          <ManualMode
            initialState={initialState.manualValue}
            isDisabled={manualMode}
            enqueueSnackbar={enqueueSnackbar}
            messages={messages}
            target={target.manual}
            getAutomatonData={getAutomatonData}
          />
        </div>
      </PopoverSection>
      <PopoverSection
        isActive={!manualMode}
        text={<FormattedMessage id="automate.annex.infomessage2" />}
      >
        <div>
          <AutoMode
            initialState={initialState.autoValue}
            isDisabled={!manualMode}
            enqueueSnackbar={enqueueSnackbar}
            messages={messages}
            fieldText={fieldText.auto}
            target={target.auto}
            validationFunc={
              param === "pump"
                ? PumpInputContentValidation
                : ValveInputContentValidation
            }
            unity={param === "pump" ? "l/h" : "bar"}
            getAutomatonData={getAutomatonData}
          />
        </div>
      </PopoverSection>
    </div>
  );
}

function ManualMode({
  initialState,
  isDisabled,
  enqueueSnackbar,
  messages,
  target,
  getAutomatonData,
}) {
  const [pupmSpeed, setPupmSpeed] = useState(initialState);

  const submit = (param, url) => {
    let action = {
      target_property: target,
      value: Number(param),
    };
    axios
      .post(process.env.REACT_APP_API_URL + url, action)
      .then((res) => {
        enqueueSnackbar(messages.success + param, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
        getAutomatonData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [debouncedState, setDebouncedState] = useDebounce(
    pupmSpeed,
    2000,
    submit
  );

  const handleChange = (event, newValue) => {
    setPupmSpeed(newValue);
    setDebouncedState(newValue);
  };

  return (
    <div className="flex items-center">
      <div className="w-40 mr-2 flex items-center">
        <CustomSlider
          disabled={isDisabled}
          value={typeof pupmSpeed === "number" ? pupmSpeed : 10}
          onChange={handleChange}
          aria-labelledby="discrete-slider-custom"
          step={5}
          valueLabelDisplay="auto"
        />
      </div>
      <p
        className={`${
          isDisabled ? "text-primary text-opacity-15" : "text-dark-text"
        } text-sm font-medium`}
      >
        {pupmSpeed} %
      </p>
    </div>
  );
}

function AutoMode({
  initialState,
  isDisabled,
  enqueueSnackbar,
  messages,
  fieldText,
  target,
  validationFunc,
  unity,
  getAutomatonData,
}) {
  const [autoMode, setAutoMode] = useState(initialState);
  const [error, setError] = useState(null);

  const submit = (param, url) => {
    let action = {
      target_property: target,
      value: Number(param),
    };
    axios
      .post(process.env.REACT_APP_API_URL + url, action)
      .then((res) => {
        enqueueSnackbar(messages.success + param, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
        getAutomatonData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [debouncedState, setDebouncedState] = useDebounce(
    autoMode,
    2000,
    submit
  );

  const handleChange = (event) => {
    setAutoMode(event.target.value);
    validationFunc(event, setError, setDebouncedState);
  };

  return (
    <div className="flex justify-between items-center p-4 border-1 border-primary border-opacity-15 rounded-8px h-14 w-full mt-2">
      <p
        className={`${
          isDisabled ? " text-opacity-15 text-primary" : "text-dark-text"
        } text-sm font-normal `}
      >
        {fieldText}
      </p>
      {error && <p className="text-error">{error}</p>}
      <div className="flex">
        <div className="mr-2 w-36">
          <CssTextField
            disabled={isDisabled}
            variant="outlined"
            id="custom-css-outlined-input"
            onChange={handleChange}
            value={autoMode}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <p
                    className={`${
                      isDisabled ? " text-opacity-15" : "text-opacity-100"
                    } text-primary`}
                  >
                    Max :
                  </p>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div
          className={`${
            isDisabled
              ? "bg-white text-primary text-opacity-15"
              : " bg-primary bg-opacity-3 text-dark-text"
          } h-9 w-14 border-1 border-primary border-opacity-15 rounded-8px flex items-center justify-center`}
        >
          {unity}
        </div>
      </div>
    </div>
  );
}

function Gauge({ value, name, unit, color, max, min }) {
  const socket = useSelector((state) => state.socket);
  const intl = useIntl();
  const registered = useSelector((state) => state.registered);
  const [GaugeOption, setGaugeOption] = useState({
    tooltip: {
      formatter: "{a} <br/>{b} : {c}%",
    },
    series: [
      {
        type: "gauge",
        radius: "100%",
        center: ["50%", "60%"],
        startAngle: 200,
        endAngle: -20,
        min: value > 0 ? min : value - 5,
        max: value > 0 ? max : 0,
        splitNumber: 1,
        axisLabel: {
          distance: -39,
          color: "auto",
        },
        pointer: {
          show: false,
        },
        pointer: {
          icon: "path://M15.5212 5.19713L14.2043 4.47885L15.5212 5.19713C16.4299 3.53119 15.2241 1.5 13.3264 1.5L4.69553 1.5C2.79788 1.5 1.5921 3.53119 2.50079 5.19713L6.81625 13.1088C7.76379 14.846 10.2582 14.846 11.2057 13.1088L15.5212 5.19713Z",
          length: 10,
          width: 11,
          offsetCenter: [0, "-100%"],
          itemStyle: {
            color: "auto",
          },
        },
        detail: {
          width: "60%",
          lineHeight: 40,
          height: 40,
          offsetCenter: [0, "-20%"],
          valueAnimation: true,
          formatter: function (value) {
            return "{value|" + value + "}{unit|" + unit + "}";
          },
          rich: {
            value: {
              fontSize: 25,
              fontWeight: 400,
              color: "#202337",
            },
            unit: {
              fontSize: 12,
              color: "#202337",
              padding: [0, 0, -5, 5],
            },
          },
        },
        title: {
          offsetCenter: [0, "5%"],
          fontSize: 14,
          color: "#2C5AFF",
          formatter: function (name) {
            return "{value|" + name + "}";
          },
          rich: {
            value: {
              fontSize: 25,
              fontWeight: 400,
              color: "#202337",
            },
          },
        },
        axisTick: {
          length: 10,
          distance: 6,
          lineStyle: {
            color: "auto",
            width: 1,
          },
        },
        axisLine: {
          lineStyle: {
            width: 3,
            color: [
              [(value - min) / (max - min), color],
              [1, "rgba(61, 128, 251, 0.15)"],
            ],
          },
        },
        splitLine: {
          show: false,
        },
        data: [
          {
            value: value,
            name: intl.formatMessage({ id: name }),
          },
        ],
      },
    ],
  });

  //register to listen to realtime_automaton data chanel
  useEffect(() => {
    registered &&
      socket.on("realtime_automaton", (res) => {
        for (let i = 0; i < res.data.length - 1; i++) {
          if (res.data[i].element === name) {
            setGaugeOption((prev) => {
              const newOption = cloneDeep(prev);
              newOption.series[0].detail.formatter = () => {
                return (
                  "{value|" +
                  res.data[i].value +
                  "}{unit|" +
                  res.data[i].unit +
                  "}"
                );
              };
              newOption.series[0].axisLine.lineStyle.color = [
                [(res.data[i].value - min) / (max - min), color],
                [1, "rgba(61, 128, 251, 0.15)"],
              ];
              newOption.series[0].data[0].value = res.data[i].value;
              return newOption;
            });
          }
        }
      });
    return () => {
      registered && socket.removeAllListeners("realtime_automaton");
    };
  }, [color, max, min, name, registered, socket]);

  return (
    <ReactECharts
      option={GaugeOption}
      style={{ height: "100%", width: "100%" }}
    />
  );
}

function PopoverSection({ children, isActive, text }) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    if (isActive) {
      setOpen(true);
    }
  };
  return (
    <LightTooltip
      title={text}
      placement="left"
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
    >
      {children}
    </LightTooltip>
  );
}

function InputComponent({
  initialState,
  enqueueSnackbar,
  messages,
  target,
  isDisabled,
  getAutomatonData,
}) {
  const [inputState, setInputState] = useState(initialState);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.user);
  const [url, setUrl] = useState("/automaton/Monticello/push");
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);

  useEffect(() => {
    if (user && currentSelectedCard && user.groups.includes("/demo")) {
      setUrl(`/automaton/${currentSelectedCard.riverlab}/push`);
    }
  }, [user, currentSelectedCard]);

  const handleInputState = (event) => {
    setInputState(event.target.value);
    if (event.target.value === "") {
      setError("Please type something");
    } else {
      if (isNaN(event.target.value)) {
        setError("Please type a number");
      } else {
        if (event.target.value < 0) {
          setError("Please type a posive number");
        } else {
          setError(null);
          setDebouncedInputState(event.target.value);
        }
      }
    }
  };

  const submitInput = (param, url) => {
    let action = {
      target_property: target,
      value: Number(param),
    };
    axios
      .post(process.env.REACT_APP_API_URL + url, action)
      .then((res) => {
        enqueueSnackbar(messages.success + param, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
        getAutomatonData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [debouncedInputState, setDebouncedInputState] = useDebounce(
    inputState,
    1000,
    submitInput,
    url
  );

  return (
    <CssTextField
      disabled={isDisabled}
      variant="outlined"
      id="custom-css-outlined-input"
      onChange={handleInputState}
      value={inputState}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <p
              className={`${
                isDisabled ? " text-opacity-15" : "text-opacity-100"
              } text-primary`}
            >
              Consigne :
            </p>
          </InputAdornment>
        ),
      }}
    />
  );
}

function PumpConfigParameters({
  initialState,
  enqueueSnackbar,
  messages,
  target,
  isDisabled,
  text,
  getAutomatonData,
}) {
  const [inputState, setInputState] = useState(initialState);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.user);
  const [url, setUrl] = useState("/automaton/Monticello/push");
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);

  useEffect(() => {
    if (user && currentSelectedCard && user.groups.includes("/demo")) {
      setUrl(`/automaton/${currentSelectedCard.riverlab}/push`);
    }
  }, [user, currentSelectedCard]);

  const handleInputState = (event) => {
    setInputState(event.target.value);
    if (event.target.value === "") {
      setError("Please type something");
    } else {
      if (isNaN(event.target.value)) {
        setError("Please type a number");
      } else {
        if ((target === "pompe_pid_p") | (target === "vanne_pid_p")) {
          if ((event.target.value < 0) | (event.target.value > 1000)) {
            setError("Not in the range (0 - 1000)");
          } else {
            setError(null);
            setDebouncedInputState(event.target.value);
          }
        } else {
          if (event.target.value < 0) {
            setError("Please type a positive number");
          } else {
            setError(null);
            setDebouncedInputState(event.target.value);
          }
        }
      }
    }
  };

  const submitInput = (param, url) => {
    let action = {
      target_property: target,
      value: Number(param),
    };
    axios
      .post(process.env.REACT_APP_API_URL + url, action)
      .then((res) => {
        enqueueSnackbar(messages.success + param, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
        getAutomatonData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [debouncedInputState, setDebouncedInputState] = useDebounce(
    inputState,
    1000,
    submitInput,
    url
  );

  return (
    <div className="flex justify-between items-center p-4 border-1 border-primary border-opacity-15 rounded-8px h-14 w-full mt-2">
      <p
        className={`${
          isDisabled
            ? " text-opacity-15 text-primary"
            : "text-dark-text font-normal"
        } text-sm`}
      >
        <FormattedMessage id={text} />
      </p>
      {error && <p className="text-error text-xs flex items-center">{error}</p>}
      <div className="flex">
        <div className="mr-2 w-36">
          <CssTextField
            disabled={isDisabled}
            variant="outlined"
            id="custom-css-outlined-input"
            onChange={handleInputState}
            value={inputState}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <p
                    className={`${
                      isDisabled ? " text-opacity-15" : "text-opacity-100"
                    } text-primary`}
                  >
                    Consigne :
                  </p>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
}

function AdvancedParametersSection({
  initialState,
  isDisabled,
  enqueueSnackbar,
  messages,
  target,
  getAutomatonData,
}) {
  return (
    <div className="mt-2">
      <PopoverSection
        isActive={isDisabled}
        text={<FormattedMessage id="automate.annex.infomessage3" />}
      >
        <div>
          <PumpConfigParameters
            text="automate.annex.pump.param7"
            initialState={initialState.p}
            enqueueSnackbar={enqueueSnackbar}
            messages={{
              success: "Paramétre P remis à ",
              error: "La mise a jours de la valeur a échoué",
            }}
            isDisabled={isDisabled}
            target={target.p}
            getAutomatonData={getAutomatonData}
          />
          <PumpConfigParameters
            text="automate.annex.pump.param8"
            initialState={initialState.i}
            enqueueSnackbar={enqueueSnackbar}
            messages={{
              success: "Paramétre I remis à ",
              error: "La mise a jours de la valeur a échoué",
            }}
            isDisabled={isDisabled}
            target={target.i}
          />
          <PumpConfigParameters
            text="automate.annex.pump.param9"
            initialState={initialState.d}
            enqueueSnackbar={enqueueSnackbar}
            messages={{
              success: "Paramétre D remis à ",
              error: "La mise a jours de la valeur a échoué",
            }}
            isDisabled={isDisabled}
            target={target.d}
          />
        </div>
      </PopoverSection>
    </div>
  );
}
