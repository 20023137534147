/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import StatCardsSection from "../../sharedComponents/StatCardsSection/StatCardsSection";
import axios from "axios";
import { useSelector } from "react-redux";
import FirstView from "../../components/Protocol/FirstView/FirstView";
import SecondView from "../../components/Protocol/SecondView/SecondView";
import { blankProtocol } from "../../components/Protocol/Const";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "react-flexbox-grid";
import { capitalizeFirstLetter } from "../../utils/char";
import { StyledImage } from "../Contact/Contact.style";
import {
  formatRiverlabForBackend,
  stationToUpperCaseProt,
} from "../../utils/riverlabs";

export default function ProtocolPage() {
  const [protocols, setProtocols] = useState(null);
  const [firstView, setfirstView] = useState(true);
  const [selectedProtocol, setSelectedProtocol] = useState(blankProtocol);
  const user = useSelector((state) => state.user);
  const userRoles = useSelector((state) => state.userRoles);
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);
  const isReader =
    currentSelectedCard &&
    (userRoles[formatRiverlabForBackend(currentSelectedCard.riverlab)] ===
      undefined ||
      userRoles[formatRiverlabForBackend(currentSelectedCard.riverlab)] ===
        "reader");
  const riverlabsWithAtLeastWriterRole = Object.keys(userRoles)
    .filter((key) => userRoles[key] !== "reader")
    .join(", ");

  useEffect(() => {
    if (currentSelectedCard) {
      const api =
        user &&
        process.env.REACT_APP_API_URL +
          `/protocols/observatory/` +
          currentSelectedCard.riverlab;
      axios
        .get(api)
        .then((res) => {
          setProtocols(res.data.protocols);
        })
        .catch((err) => console.log("error", err));
    }
  }, [currentSelectedCard]);

  return (
    <>
      <div className="ml-16 mt-20 w-full">
        <StatCardsSection />
        {isReader && (
          <Row
            className="bg-white rounded-8px overflow-hidden h-full w-full flex justify-between"
            style={{ marginRight: "8px", flexWrap: "nowrap" }}
          >
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "100px",
              }}
            >
              <p
                style={{
                  fontWeight: 700,
                  fontSize: "25px",
                  color: "#0D1441",
                  marginBottom: "15px",
                  fontFamily: "Satoshi",
                }}
              >
                <FormattedMessage
                  id="Select Riverlab"
                  values={{
                    riverlab: stationToUpperCaseProt(
                      riverlabsWithAtLeastWriterRole
                    ),
                  }}
                />
              </p>
              <p
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#0D1441",
                  fontFamily: "Roboto",
                }}
              >
                <FormattedMessage
                  id="You cannot acceed to the Protocol panel for the Riverlab of the metric you have selected, select a Riverlab chip to acceed to its Protocol panel."
                  values={{
                    riverlab: capitalizeFirstLetter(
                      riverlabsWithAtLeastWriterRole
                    ),
                    br: <br />,
                  }}
                />
              </p>
            </Col>
            <Col>
              <StyledImage alt="" src="./assets/protocol.png" />
            </Col>
          </Row>
        )}
        {firstView && !isReader && (
          <FirstView
            protocols={protocols}
            setfirstView={setfirstView}
            setSelectedProtocol={setSelectedProtocol}
          />
        )}
        {!firstView && !isReader && (
          <SecondView
            selectedProtocol={selectedProtocol}
            setfirstView={setfirstView}
            setProtocols={setProtocols}
          />
        )}
      </div>
    </>
  );
}
