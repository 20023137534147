import React, { useState,createContext } from "react";
import {Menu} from '@mui/material';
export const MenuContext = createContext('Default Value');

export function CustomMenu ({children, anchorEl,setAnchorEl}) {
    
    const openup = Boolean(anchorEl)

    //handle menu close
    const handleClose = () => {
    setAnchorEl(null);
    }

    return(
        <>
            <Menu
                anchorEl={anchorEl}
                open={openup}
                onClose={handleClose}
            >
                {children}
            </Menu>
        </>
    )
}