import { ReactComponent as BarChartIcon } from "../../icons/bar-chart.svg";
import {
  TimeseriesIconComposite,
  ScatterSeriesIconComposite,
} from "./Annex/SeriesIconComposite";

import theme1 from "../../Themes/theme1";
import theme2 from "../../Themes/theme2";
import theme3 from "../../Themes/theme3";
import theme4 from "../../Themes/theme4";
import theme5 from "../../Themes/theme5";
import theme6 from "../../Themes/theme6";
import theme7 from "../../Themes/theme7";
import theme8 from "../../Themes/theme8";
import theme9 from "../../Themes/theme9";
import theme10 from "../../Themes/theme10";
import theme11 from "../../Themes/theme11";
import theme12 from "../../Themes/theme12";
import theme13 from "../../Themes/theme13";

export const GRAPH_TYPES = {
  timeseries: {
    id: "timeseries",
    title: "Timeseries",
    subtitle: "Time based line,area, scatter charts",
    icon: TimeseriesIconComposite,
  },
  scatter: {
    id: "scatter",
    title: "Scatter",
    subtitle: "Any var per any var chart",
    icon: ScatterSeriesIconComposite,
  },
  barchart: {
    id: "barchart",
    title: "Bar chart",
    subtitle: "Custom barchart",
    icon: BarChartIcon,
  },
};

export const DEFAULT_OPTION = {
  option: {
    grid: {
      bottom: 65,
      left: 40,
      right: 40,
      top: 40,
    },
    legend: {
      show: false,
      left: "center",
      top: "top",
    },
    title: {
      show: true,
      text: "",
      subtext: "",
      left: "left",
      textStyle: {
        fontSize: 20,
        fontWeight: 500,
      },
    },
    toolbox: {
      show: true,
      feature: {
        dataZoom: {},
      },
    },
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "cross",
      },
    },
    yAxis: [],
    xAxis: [],
    dataZoom: [
      {
        type: "inside",
        id: "insidex",
        start: 0,
        end: 100,
      },
      {
        type: "slider",
        id: "sliderx",
        height: 25,
        showDetail: false,
        realtime: false,
        fillerColor: "rgba(47,69,84,0.25)",
        dataBackground: {
          lineStyle: { color: "#3D80FB", width: 2, opacity: 1 },
          areaStyle: { color: "#3D80FB", opacity: 0.15 },
        },
        start: 0,
        end: 100,
      },
    ],
    markPoint: {
      data: [],
    },
    series: [],
  },
  notMerge: false,
  theme: "shine",
  graph_id: "",
  timedate: "",
  graph_type: "timeseries",
  date_start: "2021-01-01 00:00:00",
  date_end: "2021-12-31 23:59:59",
  couples: [],
};

export const RELATIVE_DATES = [
  "Custom Date",
  "Last 5 minutes",
  "Last 15 minutes",
  "Last 30 minutes",
  "Last 1 hour",
  "Last 3 hours",
  "Last 6 hours",
  "Last 12 hours",
  "Last 24 hours",
  "Last 2 days",
  "Last 7 days",
  "Yesterday",
  "Last week",
  "Last month",
  "Last year",
  "Today",
  "This week",
  "This month",
  "This year",
];

export const DEFAULT_MARKPOINT_CONFIG = {
  symbol: "pin",
  symbolSize: 50,
  type: "average",
  name: "",
};

export const DEFAULT_MARKLINE_CONFIG = {
  precision: 2,
  type: "average",
  label: "",
};

export const DEFAULT_DARK_THEMES = [theme1, theme5, theme10, theme11];
export const DEFAULT_LIGHT_THEMES = [
  theme2,
  theme3,
  theme4,
  theme6,
  theme7,
  theme8,
  theme9,
  theme12,
  theme13,
];

export const RegularListTest = ({
  items,
  ressourceName,
  itemComponenet: ItemComponenet,
  ...props
}) => {
  if (!items) return [];

  return (
    <>
      {items.map((item, i) => {
        return (
          <ItemComponenet
            key={i}
            {...{ [ressourceName]: item }}
            {...props}
            index={i}
          />
        );
      })}
    </>
  );
};
