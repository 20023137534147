import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as ExtraplotIcon } from "../../../../icons/extraplot.svg";
import { ReactComponent as CloseIcon } from "../../../../icons/close.svg";
import { ReactComponent as CheckFilledIcon } from "../../../../icons/check.svg";
import { ReactComponent as InfoFilledIcon } from "../../../../icons/info-filled.svg";
import { SkeletonGreybgButton } from "../../../../Shared/Butttons";
import DialogContainer from "./DialogContainer";
import Test from "./Test";

export default function DialogContentContainer({
  open,
  setopen,
  handleAddNewCouple,
  graphType,
  option,
}) {
  const [config, setconfig] = useState({ x: null, y: null });

  function handleClose() {
    setopen(false);
    setconfig({ x: null, y: null });
  }

  function resetConfigParam(name) {
    setconfig((prev) => {
      return { ...prev, [name]: null };
    });
  }

  return (
    <>
      <DialogContainer
        open={open}
        setopen={setopen}
        onClose={handleClose}
        maxWidth="md"
      >
        <div className="flex justify-between">
          <div className="flex gap-2 font-medium text-xl items-center font-bold">
            <ExtraplotIcon className="text-primary" />
            <FormattedMessage id="extraplot.annex.param4" />
          </div>
          <button className="focus:outline-none" onClick={handleClose}>
            <CloseIcon className="text-grey-lighter h-3 w-3" />
          </button>
        </div>
        <div>
          {config.x && (
            <div className="bg-primary bg-opacity-8  flex justify-between rounded-8px py-1 px-3 font-medium my-2">
              <div className="flex gap-2 items-center">
                <div className="text-white h-7 w-7 bg-success rounded-full flex items-center justify-center mr-2">
                  <CheckFilledIcon className="h-4 w-4" />
                </div>
                <div>
                  <p className="flex gap-1 items-center text-xs">
                    You have chosen{" "}
                    <span className="text-success">{config.x.element}</span> at{" "}
                    <span className="text-success">{config.x.observatory}</span>{" "}
                    as an <span className="text-success">XAxis</span>
                  </p>
                  <p className="text-grey-lighter text-xs">
                    Please choose your{" "}
                    <span className="text-success">YAxis</span> variable to add
                    it on chart
                  </p>
                  <p className="text-grey-lighter text-xs">
                    PS: delete this panel to deselect the variable
                  </p>
                </div>
              </div>
              <button
                className="focus:outline-none"
                onClick={() => {
                  resetConfigParam("x");
                }}
              >
                <CloseIcon className="h-3 w-3" />
              </button>
            </div>
          )}
          {graphType === "Timeseries" && (
            <div className="bg-primary bg-opacity-8 rounded-8px py-1 px-3 font-medium my-2">
              <div className="flex gap-2">
                <div className="text-white p-2 bg-primary rounded-full flex items-center justify-center mr-2">
                  <InfoFilledIcon className="h-5 w-5" />
                </div>
                <div>
                  <p className="flex gap-1 items-center text-xs">
                    <FormattedMessage id="extraplot.annex.param5" />{" "}
                    <span className="text-primary">XAxis</span>{" "}
                    <FormattedMessage id="extraplot.annex.param6" />{" "}
                    <span className="text-primary">TimeSeries</span>
                  </p>
                  <p className="text-grey-lighter text-xs">
                    <FormattedMessage id="extraplot.annex.param7" />{" "}
                    <span className="text-primary"> YAxis</span>{" "}
                    <FormattedMessage id="extraplot.annex.param8" />{" "}
                  </p>
                </div>
              </div>
            </div>
          )}
          <Test
            config={config}
            setconfig={setconfig}
            handleAddNewCouple={handleAddNewCouple}
            setopen={setopen}
            graphType={graphType}
            option={option}
          />
        </div>
        <SkeletonGreybgButton
          id="close"
          handleOnClick={handleClose}
          selected={false}
        >
          <p>
            <FormattedMessage id="extraplot.annex.param9" />
          </p>
        </SkeletonGreybgButton>
      </DialogContainer>
    </>
  );
}
