/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import Tooltip from "@mui/material/Tooltip";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import { ReactComponent as OpenCalendarIcon } from "../../../../icons/openCalendar.svg";
import { ReactComponent as HomeIcon } from "../../../../icons/home.svg";
import { ReactComponent as DragIcon } from "../../../../icons/drag-indicator.svg";
import { ReactComponent as PencilIcon } from "../../../../icons/pencil.svg";
import { ReactComponent as MoreIcon } from "../../../../icons/more.svg";
import { ReactComponent as AddIcon } from "../../../../icons/add.svg";
import { ReactComponent as EqualizerIcon } from "../../../../icons/equalizer.svg";
import { format } from "date-fns";

import {
  setProtocolStartDate,
  setProtocolEndDate,
} from "../../../../Redux/actions";
import ParamsDialog from "./ParamsDialog";
import {
  Accordion,
  CustomInputComponent,
  useStylesAccordion,
  useStylesecond,
  Alert,
} from "../../Const";

export default function AnnexSection({
  selectedProtocol,
  setProtocols,
  applyProtocolWithoutSave_ref,
  verifyChangesExist_ref,
}) {
  const classes = useStylesecond();
  const accordionClasses = useStylesAccordion();
  const [start_date, setStart_date] = useState(selectedProtocol.start_date);
  const [end_date, setEnd_date] = useState(selectedProtocol.end_date);
  const [name, setName] = useState(selectedProtocol.name);
  const [description, setDescription] = useState("");
  const [ordered_rules, setOrdered_rules] = useState(
    selectedProtocol.ordered_rules.length === 0
      ? []
      : selectedProtocol.ordered_rules
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [chosenBlock, setchosenBlock] = useState(null);
  const [selectedObs, setSelectedObs] = useState(selectedProtocol.observatory);
  const [target, setTarget] = useState(
    selectedProtocol.ordered_rules.length !== 0
      ? selectedProtocol.ordered_rules[0].target
      : []
  );
  const [expanded, setExpanded] = useState(false);
  const [snackbarIsOpen, setsnackbarIsOpen] = useState(false);
  const owner = useSelector((state) => state.user).preferred_username;
  const [obsParamsDico, setObsParamsDico] = useState(null);
  const dispatch = useDispatch();
  const currentSelectedCard = useSelector((state) => state.currentSelectedCard);
  const intl = useIntl();

  useEffect(() => {
    dispatch(setProtocolStartDate(selectedProtocol.start_date));
    dispatch(setProtocolEndDate(selectedProtocol.end_date));
  }, []);
  const verifyChangesExist = () => {
    const protoobj = {
      name: name,
      protocol_id: selectedProtocol.protocol_id,
      observatory: selectedObs,
      groups: {},
      ordered_rules: ordered_rules,
      start_date: start_date,
      end_date: end_date,
    };
    if (
      selectedProtocol.observatory === protoobj.observatory &&
      Date(selectedProtocol.start_date) === Date(protoobj.start_date) &&
      Date(selectedProtocol.end_date) === Date(protoobj.end_date) &&
      selectedProtocol.name === protoobj.name &&
      selectedProtocol.ordered_rules === protoobj.ordered_rules
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    verifyChangesExist_ref.current = verifyChangesExist;
  }, [name, selectedObs, ordered_rules, start_date, end_date]);

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `/observatories/${selectedProtocol.observatory}/elements`
      )
      .then((res) => {
        setObsParamsDico(Object.values(res.data).flat());
      })
      .catch((err) => console.log("error", err));
  }, []);

  const handleSnackbarClick = () => {
    setsnackbarIsOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setsnackbarIsOpen(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const ApplyProtocol = () => {
    const proto = {
      name: name,
      protocol_id: selectedProtocol.protocol_id,
      observatory: selectedObs,
      groups: {},
      ordered_rules: ordered_rules.flat(),
      start_date: format(new Date(start_date), "yyyy-MM-dd HH:mm:ss"),
      end_date: format(new Date(end_date), "yyyy-MM-dd HH:mm:ss"),
    };
    applyProtocolWithoutSave_ref.current(proto);
  };

  const CancelModifications = () => {
    setName(selectedProtocol.name);
    setSelectedObs(selectedProtocol.observatory);
    setStart_date(selectedProtocol.start_date);
    setEnd_date(selectedProtocol.end_date);
    setOrdered_rules(
      selectedProtocol.ordered_rules.length === 0
        ? []
        : selectedProtocol.ordered_rules
    );
  };

  const submitNewProtocol = () => {
    const proto = {
      name: name,
      protocol_id: selectedProtocol.protocol_id,
      observatory: selectedObs,
      groups: {},
      ordered_rules: ordered_rules.flat(),
      start_date: format(new Date(start_date), "yyyy-MM-dd HH:mm:ss"),
      end_date: format(new Date(end_date), "yyyy-MM-dd HH:mm:ss"),
      owner: owner,
    };
    const api =
      process.env.REACT_APP_API_URL +
      `/protocols/observatory/` +
      currentSelectedCard.riverlab;
    axios
      .post(api, proto)
      .then((res) => {
        axios
          .get(
            process.env.REACT_APP_API_URL +
              `/protocols/observatory/` +
              currentSelectedCard.riverlab
          )
          .then((res) => {
            handleSnackbarClick();
            setProtocols(res.data.protocols);
          })
          .catch((err) => console.log("error", err));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveNewBlock = (block) => {
    let ordered_rulesCopy = Array.from(ordered_rules);
    ordered_rulesCopy[chosenBlock.idx] = block;
    setOrdered_rules(ordered_rulesCopy);
  };

  const domInstructionsList = (instructions) => {
    return instructions.map((elem) => {
      return (
        <div className="mt-2" key={`inst ${Math.random()}`}>
          <div className="flex w-full ">
            <div className="flex items-center justify-center text-grey-text mr-2 items-center">
              <DragIcon></DragIcon>
            </div>
            <div className="grid grid-cols-11 gap-2 flex-grow">
              <div className="col-span-5 rounded-8px bg-light-bg border-1 border-primary border-opacity-15 py-1 px-2">
                {elem.filtering_quantity}
              </div>
              <div className="col-span-3 rounded-8px bg-light-bg border-1 border-primary border-opacity-15 py-1 px-2">
                <div className="flex">
                  <p className="text-primary text-sm ">Min :</p>
                  <p className="ml-1">{elem.min_threshold}</p>
                </div>
              </div>
              <div className="col-span-3 rounded-8px bg-light-bg border-1 border-primary border-opacity-15 py-1 px-2">
                <div className="flex">
                  <p className="text-primary text-sm ">Max :</p>
                  <p className="ml-1">{elem.max_threshold}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const createSvg = (pathsArray, label) => {
    const paths = pathsArray.map((elem) => {
      return <path d={elem} />;
    });
    return (
      <Tooltip title={label}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          {paths}
        </svg>
      </Tooltip>
    );
  };

  const domBlocksList = ordered_rules.map((block, bidx) => {
    let children;
    const targetList = block[0].target.map((elem, tidx) => {
      if (obsParamsDico) {
        let obj = obsParamsDico.filter((item) => {
          return item.id === elem;
        })[0];
        children = obj ? (
          obj.formule_chimique !== "" ? (
            <Tooltip title={obj.label}>
              <p>
                {obj.formule_chimique.element}
                <span style={{ fontSize: "10px" }}>
                  <sub>{obj.formule_chimique.element.indice}</sub>
                  <sup>{obj.formule_chimique.exposant}</sup>
                </span>
              </p>
            </Tooltip>
          ) : (
            createSvg(obj.svg, obj.label)
          )
        ) : (
          elem
        );
      }
      return (
        <div
          className="bg-grey-panel border border-border-panel text-grey-light rounded-full h-8 w-8 flex items-center justify-center mr-1 mb-1 text-xs"
          key={`target ${bidx} ${tidx}`}
        >
          {children}
        </div>
      );
    });

    return (
      <Accordion
        expanded={expanded === "panel" + bidx}
        onChange={handleChange("panel" + bidx)}
        square={false}
        className="mb-2"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={"panel" + bidx + "bh-content"}
          id={"panel" + bidx + "bh-header"}
        >
          <Typography className={accordionClasses.heading}>
            <FormattedMessage id="protocol.SecondView.param26" /> {bidx + 1}
          </Typography>
          <Typography className={accordionClasses.secondaryHeading}>
            {block.length} <FormattedMessage id="protocol.SecondView.param27" />{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full">
            <div className="flex justify-between">
              <div className="flex flex-wrap">{targetList}</div>
              <button
                className="flex text-primary font-medium text-xs focus:outline-none"
                onClick={() => {
                  setchosenBlock({ block: block, idx: bidx });
                  setModalIsOpen(true);
                }}
              >
                <p>
                  <FormattedMessage id="protocol.SecondView.param25" />
                </p>
                <PencilIcon className="ml-2 h-4 w-4 flex items-center " />
              </button>
            </div>
            {domInstructionsList(block)}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  });

  return (
    <div
      className={classes.root}
      //   className="flex flex-col h-full divide-y divide-transparent-blue-15%"
    >
      <div className="pt-4 pb-2 px-4">
        <div className=" text-xl font-medium">
          <p className="flex items-center">
            <FormattedMessage id="protocol.firstView.param13" />
            <span className="text-secondary text-2xl font-medium ml-3">
              {selectedObs}
            </span>
            <HomeIcon className="ml-1 h-7 w-7" />
          </p>
        </div>
      </div>
      <div className="py-3 px-4">
        <p className="text-base font-medium text-dark-text mb-2">
          <FormattedMessage id="protocol.firstView.param14" />
        </p>
        <div className="flex items-center">
          <div className="w-10 px-1 text-center">
            <p className="text-sm font-normal text-dark-text">
              <FormattedMessage id="De" />
            </p>
          </div>
          <div className="flex-grow">
            <KeyboardDateTimePicker
              value={start_date}
              onChange={setStart_date}
              showTodayButton
              format="yyyy/MM/DD HH:mm"
              inputVariant="outlined"
              className={classes.datePicker}
              keyboardIcon={<OpenCalendarIcon className="focus:outline-none" />}
            />
          </div>
          <div className="w-10 px-1 text-center">
            <p className="text-sm font-normal text-dark-text">
              <FormattedMessage id="protocol.firstView.param11" />
            </p>
          </div>
          <div className="flex-grow">
            <KeyboardDateTimePicker
              value={end_date}
              onChange={setEnd_date}
              showTodayButton
              format="yyyy/MM/DD HH:mm"
              inputVariant="outlined"
              className={classes.datePicker}
              keyboardIcon={<OpenCalendarIcon className="focus:outline-none" />}
            />
          </div>
        </div>
      </div>
      <div className="py-4 px-3 overflow-y-auto">
        <div className="flex mb-6">
          <div className="flex-grow">
            <TextField
              variant="standard"
              id="standard-basic"
              className={clsx(classes.title, "w-full")}
              value={name}
              placeholder={intl.formatMessage({
                id: "protocol.firstView.param23",
              })}
              onChange={(ev) => {
                setName(ev.target.value);
              }}
              inputProps={{
                InputComponent: CustomInputComponent,
              }}
            />
          </div>
          <MoreIcon />
        </div>
        <div className="mb-6">
          <p className="text-base text-dark-text font-medium">
            <FormattedMessage id="protocol.firstView.param16" />
          </p>
          <TextField
            variant="standard"
            id="standard-basic"
            className={clsx(classes.description, "w-full")}
            placeholder={intl.formatMessage({
              id: "protocol.firstView.param22",
            })}
            value={description}
            onChange={(ev) => {
              setDescription(ev.target.value);
            }}
          />
        </div>
        {ordered_rules.length === 0 && (
          <div className="text-center flex-grow flex flex-col items-center">
            <div className="flex justify-center">
              <img src="./assets/empty.png" />
            </div>
            <p className="text-base font-medium text-normal mt-4">
              <FormattedMessage id="protocol.SecondView.param24" /> 🤓
            </p>
            <p className="text-grey-text text-xs font-normal">
              <FormattedMessage id="protocol.SecondView.param17" />
            </p>
            <div className="flex justify-center">
              <button
                className="flex bg-primary text-white rounded-8px shadow-blue-25 px-3 py-2.5 items-center justify-center mt-6 focus:outline-none"
                onClick={() => {
                  setchosenBlock({ block: [], idx: ordered_rules.length });
                  setModalIsOpen(true);
                }}
              >
                <AddIcon className="h-2.5 w-2.5 text-white mr-2" />
                <p className="text-sm font-medium">
                  <FormattedMessage id="protocol.SecondView.param18" />
                </p>
              </button>
            </div>
          </div>
        )}
        {ordered_rules.length !== 0 && (
          <div className="py-6 px-4 overflow-y-auto">
            {domBlocksList}
            <div className="flex justify-between mt-6">
              <div className="flex">
                <EqualizerIcon className="text-secondary mr-2" />
                <p className="text-dark-text text-base font-medium">
                  <FormattedMessage id="protocol.SecondView.param19" />
                </p>
              </div>
              <button
                className="flex items-center text-primary bg-primary bg-opacity-8 rounded-8px py-2 px-3 focus:outline-none"
                onClick={() => {
                  setchosenBlock({ block: [], idx: ordered_rules.length });
                  setModalIsOpen(true);
                }}
              >
                <AddIcon className="mr-1" />
                <p className="text-sm font-medium">
                  <FormattedMessage id="protocol.SecondView.param20" />
                </p>
              </button>
            </div>
          </div>
        )}
        {ordered_rules.length !== 0 && (
          <div className="py-6 px-4">
            <button
              className="w-full text-white bg-secondary py-3 rounded-8px font-medium focus:outline-none"
              onClick={ApplyProtocol}
            >
              <FormattedMessage id="protocol.SecondView.param21" />
            </button>
          </div>
        )}
        <div className="flex justify-between pt-4">
          <button
            className="px-3 py-2 border-1 border-transparent-blue-15% text-primary text-sm rounded-8px"
            onClick={CancelModifications}
          >
            <FormattedMessage id="annuler" />
          </button>
          <div className="flex">
            <button
              className="px-3 py-2 text-sm text-white bg-primary rounded-8px focus:outline-none"
              onClick={() => {
                submitNewProtocol();
              }}
            >
              <FormattedMessage id="protocol.SecondView.param23" />
            </button>
          </div>
        </div>
        <Snackbar
          open={snackbarIsOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity="success">
            <FormattedMessage id="protocol.SecondView.param44" />
          </Alert>
        </Snackbar>
      </div>
      {modalIsOpen && (
        <ParamsDialog
          setModalIsOpen={setModalIsOpen}
          modalIsOpen={modalIsOpen}
          ordered_rules={chosenBlock.block}
          setOrdered_rules={setOrdered_rules}
          saveNewBlock={saveNewBlock}
          setSelectedObs={setSelectedObs}
          selectedObs={currentSelectedCard && currentSelectedCard.riverlab}
          target={target}
          setTarget={setTarget}
        />
      )}
    </div>
  );
}
