/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import moment from "moment";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import LinearProgress from "@mui/material/LinearProgress";
import { FormattedMessage } from "react-intl";
import DialogActions from "@mui/material/DialogActions";
import { ReactComponent as FilterIcon } from "../../../../icons/filter.svg";
import { ReactComponent as OpenCalendarIcon } from "../../../../icons/openCalendar.svg";
import { ReactComponent as DownloadIcon } from "../../../../icons/download.svg";
import DialogButton from "./DialogButton";
import { CustomDatePicker } from "../../Const";

export default function DownloadCSVDialog({
  isOpen,
  setIsOpen,
  series,
  x,
  protocolId,
  observatory,
}) {
  const protocolStartDate = useSelector((state) => state.protocolStart_date);
  const protocolEndDate = useSelector((state) => state.protocolEnd_date);
  const [selectedCadence, setselectedCadence] = useState("1min");
  const [selectedVariables, setSelectedVariables] = useState(
    Object.keys(series)
  );
  const [downloadStartDate, setDownloadStartDate] = useState(protocolStartDate);
  const [downloadEndDate, setDownloadEndDate] = useState(protocolEndDate);
  const [rowData, setRowData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [isFiltering, setIsFiltering] = useState(false);

  useEffect(() => {
    if (isFiltering && rowData != null) {
      filtering();
    }
  }, [isFiltering, rowData]);

  const handleDownloadStartDateChange = (value) => {
    setDownloadStartDate(value.format("yyyy-MM-DD h:mm:ss"));
    setFilteredData(null);
  };

  const handleDownloadEndDateChange = (value) => {
    setDownloadEndDate(value.format("yyyy-MM-DD h:mm:ss"));
    setFilteredData(null);
  };

  const onCadenceSelected = (cadence) => {
    setFilteredData(null);
    setselectedCadence(cadence);
  };

  const binarySearch = (arr, target, shift) => {
    var midpoint = Math.floor(arr.length / 2);
    if (arr.length === 1) {
      return shift;
    }
    if (
      moment(arr[midpoint]) === moment(target) ||
      (moment(arr[midpoint]) > moment(target) &&
        moment(arr[midpoint - 1]) < moment(target))
    ) {
      return shift + midpoint;
    }
    if (moment(arr[midpoint]) < moment(target)) {
      return binarySearch(arr.slice(midpoint), target, shift + midpoint);
    }
    if (moment(arr[midpoint]) > moment(target)) {
      return binarySearch(arr.slice(0, midpoint), target, shift + 0);
    }
  };

  const filterWithDates = (start, end, series, currentX) => {
    let filteredSeries = {};
    let startIdx = binarySearch(currentX, start, 0);
    let endIdx = binarySearch(currentX, end, 0);
    endIdx = moment(currentX[endIdx]) === moment(end) ? endIdx : endIdx - 1;
    Object.keys(series).forEach((elem) => {
      filteredSeries[elem] = series[elem].slice(startIdx, endIdx + 1);
    });
    return {
      x: currentX.slice(startIdx, endIdx + 1),
      filteredSeries: filteredSeries,
    };
  };
  const formatToDownload = (xfiltered, filteredSeries) => {
    let data = [];
    for (let i = 0; i < xfiltered.length; i++) {
      let row = [];
      row.push(xfiltered[i]);
      Object.keys(filteredSeries).forEach((item) =>
        row.push(filteredSeries[item][i])
      );
      data.push(row);
    }
    setFilteredData(data);
  };
  const filtering = () => {
    if (rowData != null) {
      let currentX = rowData.data.x;
      let currentSeries = rowData.data;
      delete currentSeries["x"];
      delete currentSeries["sizes"];
      //step 1 : filter by selected variables
      let filteredSeries = {};
      selectedVariables.forEach((elem) => {
        filteredSeries[elem] = currentSeries[elem];
      });
      //step 2 : filter by start and end dates
      const dataFilteredWithDates = filterWithDates(
        downloadStartDate,
        downloadEndDate,
        filteredSeries,
        currentX
      );
      //step 2 : prepare data for download
      formatToDownload(
        dataFilteredWithDates.x,
        dataFilteredWithDates.filteredSeries
      );
      setRowData(null);
      setIsFiltering(false);
    }
  };
  const getAndFilterData = (event) => {
    if (rowData !== null) setIsFiltering(true);
    getDataPromise()
      .then((res) => {
        setRowData(res);
      })
      .catch((err) => {
        console.log(err);
        setIsFiltering(false);
      });
  };
  const getDataPromise = () => {
    if (selectedCadence !== "1min") {
      return axios.get(
        process.env.REACT_APP_API_URL +
          `/protocols/${observatory}/id/${protocolId}/qualify?sample_rate=${selectedCadence}`
      );
    } else {
      let res = {
        data: {
          sizes: [],
          x: x,
          ...series,
        },
      };
      return Promise.resolve(res);
    }
  };

  const getHeaders = () => {
    let headers = [];
    headers.push("Dates");
    selectedVariables.forEach((elem) => {
      headers.push(elem);
    });
    return headers;
  };

  const onVariableSelected = (variable) => {
    setFilteredData(null);
    let selectedVariables_copy = Array.from(selectedVariables);
    if (selectedVariables.includes(variable)) {
      selectedVariables_copy = selectedVariables_copy.filter((elem) => {
        return elem !== variable;
      });
    } else {
      selectedVariables_copy.push(variable);
    }
    setSelectedVariables(selectedVariables_copy);
  };

  const variablesDomList = Object.keys(series).map((elem) => {
    return (
      <DialogButton
        selected={selectedVariables.includes(elem) ? true : false}
        onSelect={onVariableSelected}
        text={elem}
        key={elem}
      />
    );
  });

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
    >
      <div className="divide-y divide-primary divide-opacity-15">
        <div className="px-8 py-5">
          <div className=" text-xl font-medium">
            <p className="flex items-center">
              <FormattedMessage id="protocol.SecondView.param36" />
              <span className="text-secondary text-2xl font-medium ml-2">
                {observatory}
              </span>
              <DownloadIcon className="text-green ml-2 w-4 h-6" />
            </p>
          </div>
        </div>
        <div className="px-8 py-5">
          <p className="text-lg text-dark-text font-medium mb-2">
            <FormattedMessage id="protocol.SecondView.param37" />
          </p>
          <div className="flex">
            <DialogButton
              selected={selectedCadence === "1min" ? true : false}
              onSelect={onCadenceSelected}
              text={"1min"}
            />
            <DialogButton
              selected={selectedCadence === "10min" ? true : false}
              onSelect={onCadenceSelected}
              text={"10min"}
            />
            <DialogButton
              selected={selectedCadence === "hour" ? true : false}
              onSelect={onCadenceSelected}
              text={"hour"}
            />
            <DialogButton
              selected={selectedCadence === "day" ? true : false}
              onSelect={onCadenceSelected}
              text={"day"}
            />
          </div>
        </div>
        <div className="px-8 py-5">
          <p className="text-lg text-dark-text font-medium mb-2">
            <FormattedMessage id="protocol.SecondView.param38" />
          </p>
          <div className="flex flex-wrap">{variablesDomList}</div>
        </div>
        <div className="px-8 py-5">
          <p className="text-lg text-dark-text font-medium mb-2">
            <FormattedMessage id="protocol.SecondView.param39" />
          </p>
          <div className="flex items-center h-10">
            <p className="text-primary text-md mx-4 font-semibold">
              <FormattedMessage id="De" />
            </p>
            <CustomDatePicker
              value={downloadStartDate}
              onChange={handleDownloadStartDateChange}
              showTodayButton
              format="yyyy/MM/DD HH:mm"
              inputVariant="outlined"
              minDate={protocolStartDate}
              maxDate={protocolEndDate}
              className="h-full"
              keyboardIcon={<OpenCalendarIcon className="focus:outline-none" />}
            />
            <p className="text-primary text-md mx-4 font-semibold">
              <FormattedMessage id="protocol.firstView.param11" />
            </p>
            <CustomDatePicker
              value={downloadEndDate}
              onChange={handleDownloadEndDateChange}
              showTodayButton
              format="yyyy/MM/DD HH:mm"
              inputVariant="outlined"
              minDate={protocolStartDate}
              maxDate={protocolEndDate}
              //className = {classes.datePicker}
              keyboardIcon={<OpenCalendarIcon className="focus:outline-none" />}
            />
          </div>
        </div>
        <div className="px-8 py-5">
          {!isFiltering && filteredData === null && (
            <button
              type="button"
              className="bg-secondary text-white rounded-8px p-2 w-full flex focus:outline-none justify-center items-center font-semibold"
              onClick={(event) => {
                getAndFilterData(event);
              }}
            >
              <FilterIcon className="mr-2" />
              <FormattedMessage id="protocol.SecondView.param40" />
            </button>
          )}
          {isFiltering && (
            <div>
              <p className="text-xl">Data is being filtering ...</p>
              <LinearProgress />
            </div>
          )}
          <div className="flex items-center justify-center">
            {filteredData && (
              <div className="mt-6">
                <p className="text-sm text-dark font-medium mb-5 ">
                  <FormattedMessage id="protocol.SecondView.param43" />
                </p>
                <div className="flex justify-center">
                  <CSVLink
                    data={filteredData}
                    separator={";"}
                    headers={getHeaders()}
                    filename={`Data${observatory}(${selectedCadence})_${downloadStartDate}_${downloadEndDate}.csv`}
                  >
                    <button
                      type="button"
                      className="flex text-white rounded-8px bg-primary shadow-blue-shadow py-2 px-3 text-sm font-medium focus:outline-none"
                    >
                      <DownloadIcon className="text-green mr-2" />
                      <FormattedMessage id="protocol.SecondView.param35" />
                    </button>
                  </CSVLink>
                </div>
              </div>
            )}
            {filteredData === null && (
              <div>
                <div className="flex pt-6 justify-center">
                  <p className="text-sm text-dark font-medium mr-2">
                    <FormattedMessage id="protocol.SecondView.param41" />
                  </p>
                  <span className="animate-bounce">
                    <DownloadIcon className="text-green mr-2 transform rotate-180" />
                  </span>
                </div>
                <div className="flex mt-2 justify-center">
                  <p className="text-xs text-red-500 font-medium">
                    <FormattedMessage id="protocol.SecondView.param42" />
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <DialogActions>
        <button
          onClick={() => {
            setIsOpen(false);
            setFilteredData(null);
          }}
          className="text-primary border-1 border-primary border-opacity-15 mr-2 py-2 px-3 rounded-8px text-sm font-medium focus:outline-none"
        >
          <FormattedMessage id="annuler" />
        </button>
      </DialogActions>
    </Dialog>
  );
}
