import axios from "axios";
import { formatRiverlabForBackend } from "../../utils/riverlabs";

export const getAllUsers = async (connectedRiverlab, setUsers) => {
  const connectedRiverlabLowerCase = connectedRiverlab.map((river) =>
    formatRiverlabForBackend(river)
  );
  const allUsers = await axios
    .get(process.env.REACT_APP_FRONTEND_URL + `/api/users/getUsers`, {
      params: {
        team: connectedRiverlabLowerCase,
      },
    })
    .then((resp) => resp.data);
  setUsers(allUsers.sort((a, b) => (a.team > b.team ? 1 : -1)));
};

export const handleSortUsers = (users, setUsers) => {
  const sortedUsers = users.sort((a, b) => {
    if ("firstName" in a && "firstName" in b) {
      if (a.firstName < b.firstName) {
        return -1;
      }
      if (a.firstName > b.firstName) {
        return 1;
      }
    } else if ("firstName" in a && !("firstName" in b)) {
      if (a.firstName < b.email) {
        return -1;
      }
      if (a.firstName > b.email) {
        return 1;
      }
    } else if (!("firstName" in a) && "firstName" in b) {
      if (a.email < b.firstName) {
        return -1;
      }
      if (a.email > b.firstName) {
        return 1;
      }
    } else if (!("firstName" in a) && !("firstName" in b)) {
      if (a.email < b.email) {
        return -1;
      }
      if (a.email > b.email) {
        return 1;
      }
    }
    return 0;
  });
  setUsers(sortedUsers);
};
