/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
export default function ParamPanel({ target, id, children }) {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    if (target.includes(id)) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [target]);
  return (
    <div
      className={`${
        selected
          ? "bg-primary text-white shadow-blue-25 outline-none "
          : " bg-app-bg"
      } 
      flex  text-xs font-medium rounded-8px items-center justify-center border-1 border-app-bg hover:border-primary`}
    >
      {children}
    </div>
  );
}
