import { DEFAULT_OPTION, GRAPH_TYPES } from "../Const";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "./Accordion/Accordion";

export default function GraphTypeSection({
  expanded,
  handleChangeAccordion,
  graphType,
  setOption,
}) {
  function handleGraphTypeChange(newType) {
    setOption((prev) => {
      return {
        ...prev,
        notMerge: true,
        graph_type: newType.id,
        couples: [],
        option: {
          ...prev.option,
          grid: { ...DEFAULT_OPTION.option.grid },
          series: [],
          xAxis: [],
          yAxis: [],
        },
      };
    });
  }

  return (
    <Accordion
      expanded={expanded.has("panel1")}
      onChange={handleChangeAccordion("panel1")}
      backgroundColor="white"
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <div className="flex items-center gap-2">
          <graphType.icon height={8} width={8} />
          <p className="font-medium">{graphType.title}</p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="flex flex-col gap-2">
          {Object.values(GRAPH_TYPES).map((type) => {
            return (
              <button
                disabled={type.title === "Bar chart"}
                onClick={() => {
                  handleGraphTypeChange(type);
                }}
                className={`${
                  type.title === graphType.title
                    ? "border-1 border-primary"
                    : ""
                } flex gap-2 bg-grey-panel rounded-8px p-2 focus:outline-none`}
              >
                <type.icon height={12} width={12} className="h-12 w-12" />
                <div>
                  <p className="font-medium flex justify-start">{type.title}</p>
                  <p className="text-xs flex justify-start">{type.subtitle}</p>
                </div>
              </button>
            );
          })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
